import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PricingSurchargeCondition,
  PricingSurchargeConditionType,
  PricingSurchargeRule,
  PricingSurchargeVaryCondition,
} from '@idearoom/types';
import { I18nKeys } from '../constants/I18nKeys';
import { updateSurchargeProperty } from '../ducks/pricingSlice';
import { SurchargeRuleProperty, SurchargeUpdateProperty } from '../constants/PricingSurcharge';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  ariaLabel: {
    color: 'white',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
    marginRight: '10px',
  },
});

interface Props {
  anchorEl: Element | null;
  pricingSurchargeVaryCondition?: PricingSurchargeVaryCondition;
  rule?: PricingSurchargeRule;
  onClose(): void;
}

const getDefaultRange = (
  rule?: PricingSurchargeRule,
  condition?: PricingSurchargeVaryCondition,
): { minimum: number; maximum: number } => {
  const existingCondition = rule?.conditions?.find(
    (r) => condition && r.type === condition.value,
  ) as PricingSurchargeCondition;

  const newMin = existingCondition?.minimum || 0;
  const newMax = existingCondition?.maximum || 0;

  return { minimum: newMin, maximum: newMax };
};

export const PricingSurchargeSubtotalConditionMenu: React.FC<Props> = ({
  anchorEl,
  onClose,
  pricingSurchargeVaryCondition,
  rule,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const updateSurchargeRuleCondition = (
    id: number,
    type: PricingSurchargeConditionType,
    range: { minimum: number; maximum: number },
  ): void => {
    dispatch(
      updateSurchargeProperty([
        {
          property: SurchargeUpdateProperty.Rules,
          value: { id, property: SurchargeRuleProperty.Conditions, value: { type, value: range } },
        },
      ]),
    );
  };

  const saveRangeCondition = (
    minimum: number,
    maximum: number,
    type?: PricingSurchargeConditionType,
    surchargeRule?: PricingSurchargeRule,
  ): void => {
    if (type && surchargeRule) {
      updateSurchargeRuleCondition(surchargeRule.id, type, { minimum, maximum });
      onClose();
    }
  };

  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);

  useEffect(() => {
    // On open, set (or reset) the min and max values to the default values
    if (anchorEl) {
      setMin(getDefaultRange(rule, pricingSurchargeVaryCondition).minimum);
      setMax(getDefaultRange(rule, pricingSurchargeVaryCondition).maximum);
    }
  }, [anchorEl]);

  return (
    <Dialog open={Boolean(anchorEl)} onClose={onClose}>
      <DialogTitle id="form-dialog-title">
        {t(I18nKeys.PricingSubtotalTitle, {
          condition: pricingSurchargeVaryCondition && pricingSurchargeVaryCondition.label,
        })}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <Typography>{t(I18nKeys.PricingSubtotalRangeStart)}</Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <TextField
              size="small"
              label="Low"
              variant="standard"
              value={min}
              onChange={(e: any): void => {
                setMin(parseInt(e.target.value, 10) || 0);
              }}
            />
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography>{t(I18nKeys.PricingSubtotalRangeEnd)}</Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <TextField
              size="small"
              label="High"
              variant="standard"
              value={max}
              onChange={(e: any): void => {
                setMax(parseInt(e.target.value, 10) || 0);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          color="primary"
          onClick={(): void => {
            saveRangeCondition(
              min,
              max,
              pricingSurchargeVaryCondition
                ? (pricingSurchargeVaryCondition.value as PricingSurchargeConditionType)
                : undefined,
              rule,
            );
          }}
        >
          {t(I18nKeys.DialogApplyButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
