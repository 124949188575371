import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { SalesRep } from '../types/SalesRep';
import { Environment } from '../constants/Environment';

export const salesRepApi = createApi({
  reducerPath: 'salesRepApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/salesreps',
  }),
  endpoints: (builder) => ({
    /**
     * Adds an array of sales reps for a clientId
     */
    addSalesReps: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        salesReps: SalesRep[];
        environment?: Environment;
      }
    >({
      query: ({ groupId, clientId, salesReps, environment }) => ({
        url: ``,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            clientId,
            salesReps,
            environment,
          },
        },
      }),
    }),

    /**
     * Add a list of sales reps to a list of clients
     */
    addSalesRepsToClients: builder.mutation<
      void,
      {
        groupId: string;
        clientIds: string[];
        salesReps: SalesRep[];
      }
    >({
      query: ({ groupId, clientIds, salesReps }) => ({
        url: `/add`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: {
            clientIds,
            salesReps,
          },
        },
      }),
    }),

    /**
     * Removes a sales rep from clientIds
     */
    removeSalesRep: builder.mutation<
      void,
      {
        groupId: string;
        email: string;
        clientIds: string[];
      }
    >({
      query: ({ groupId, email, clientIds }) => ({
        url: `/delete/${email}`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: {
            email,
            clientIds,
          },
        },
      }),
    }),

    /**
     * Removes a list of sales rep
     */
    removeSalesRepsFromClient: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        emails: string[];
      }
    >({
      query: ({ groupId, clientId, emails }) => ({
        url: `/delete`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            emails,
          },
        },
      }),
    }),
  }),
});

export const { useAddSalesRepsMutation, useRemoveSalesRepMutation, useRemoveSalesRepsFromClientMutation } = salesRepApi;
