import _ from 'lodash';
import { CellMetadataProperty } from '../constants/ClientData';
import { CellMetadata } from '../types/ClientData';
import { UpdateClientDataMetadata } from '../ducks/clientDataSlice';

/**
 * Updates the cell metadata with the new value for the given property
 *
 * @param clientId
 * @param cellMetadata
 * @param table
 * @param rowId
 * @param column
 * @param prop
 * @param value
 * @returns
 */
export const getUpdatedCellMetadata = (
  clientId: string,
  cellsMetadata: CellMetadata[],
  table: string,
  rowId: string,
  column: string,
  prop: CellMetadataProperty,
  value?: string,
): CellMetadata | null => {
  // Check if the cell metadata is already in the cellMetadata array
  const cellMetadataIndex = cellsMetadata.findIndex(
    (cellMetadataItem) =>
      cellMetadataItem.clientId === clientId && cellMetadataItem.table === table && cellMetadataItem.rowId === rowId,
  );

  // If the cell metadata is already in the array
  if (cellMetadataIndex !== -1 && prop) {
    // If the cell metadata is already in the array, update the value
    const currentCellMetadata = cellsMetadata[cellMetadataIndex];
    const { metadata } = currentCellMetadata;

    let newMetadata = {
      ...(typeof metadata === 'string' ? JSON.parse(metadata) : metadata),
    };
    if (value) {
      newMetadata = {
        ...newMetadata,
        [column]: { ...newMetadata[column], [prop]: value },
      };
    } else if (newMetadata[column]) {
      if (newMetadata[column][prop]) {
        newMetadata[column] = { ...newMetadata[column] };
        delete newMetadata[column][prop];
      }
      if (Object.keys(newMetadata[column]).length === 0) {
        delete newMetadata[column];
      }
    }

    return { ...currentCellMetadata, metadata: newMetadata };
  }

  if (value) {
    // If the cell metadata is not in the array, returns a new one. However, if we are removing a metadata prop
    // we don't really need to create an entry for it
    const newRow: CellMetadata = {
      clientId,
      table,
      rowId,
      metadata: {
        [column]: {
          [prop]: value,
        },
      },
    };
    return newRow;
  }
  return null;
};

/**
 * Updates the cell metadata with new values for the given property
 *
 * @param clientId
 * @param table
 * @param updates
 * @returns
 */
export const getUpdatedCellsMetadata = (
  clientId: string,
  table: string,
  updates: UpdateClientDataMetadata[],
): CellMetadata[] => {
  if (!updates.length) return [];

  const [{ cellsMetadata = [] }] = updates;
  const newCellMetadata = _.cloneDeep(cellsMetadata);

  updates.forEach(({ colId, rowId, metadataProperty, value }) => {
    const cellMetadata = getUpdatedCellMetadata(
      clientId,
      newCellMetadata,
      table,
      rowId,
      colId,
      metadataProperty,
      value,
    );
    if (cellMetadata) {
      const cellMetadataIndex = newCellMetadata.findIndex(
        (cellMetadataItem) =>
          cellMetadataItem.clientId === clientId &&
          cellMetadataItem.table === table &&
          cellMetadataItem.rowId === rowId,
      );
      if (cellMetadataIndex !== -1) {
        newCellMetadata[cellMetadataIndex] = cellMetadata;
      } else {
        newCellMetadata.push(cellMetadata);
      }
    }
  });

  return newCellMetadata;
};
