import { useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

export const ClientDataChangesSummaryDiffCell: React.FC<ICellRendererParams> = ({
  valueFormatted,
}: ICellRendererParams) => {
  const theme = useTheme();
  if (Array.isArray(valueFormatted)) {
    return (
      <>
        {valueFormatted.map((part, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              // eslint-disable-next-line no-nested-ternary
              backgroundColor: part.added
                ? theme.palette.diffAdd.main
                : part.removed
                ? theme.palette.diffRemove.main
                : undefined,
            }}
          >
            {part.value}
          </span>
        ))}
      </>
    );
  }

  return <span>{valueFormatted}</span>;
};
