/* eslint-disable @typescript-eslint/explicit-function-return-type */
import scriptjs from 'scriptjs';

/**
 * Ensure we add this script tag to our page so Google Analytics 4 is available to use as soon as this file is imported.
 *
 * We only need one call to this once use googleAnalyticsInstance.addTrackerId to associate properties.
 */
scriptjs(`https://www.googletagmanager.com/gtag/js`);

/**
 * gtag should only be called from within googleAnalyticsInstance to ensure structure.
 *
 * gtag should live in its own file so we can test googleAnalyticsInstance. This file will be substituted with gTagNode
 *   when compiling for node projects to ensure that we are both not running analytics on server projects, and can spy
 *   on the calls to gtag by using its exported gtagCalls.
 */
window.dataLayer = window.dataLayer || [];
export function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}
