/**
 * Ensure that we consistently apply extraDimensionsProps to Google Analytics 4 events.
 *
 * originalArguments is an Argument array but there's no type for this.
 *
 * usage in gtag function: gtagPrepareArguments(arguments, extraDimensionsProps).
 */
export const gtagPrepareArguments = (originalArguments: IArguments | any[], extraDimensionsProps = {}): any => {
  if (originalArguments[0] === 'event') {
    // Always apply argument #2 to argument #3 as "eventKey"
    const eventKey = originalArguments[1];

    // Apply extraDimensionsProps to element #3
    // eslint-disable-next-line no-param-reassign
    originalArguments[2] = {
      ...(originalArguments[2] || {}),
      ...extraDimensionsProps,
      eventKey,
    };
  }

  return originalArguments;
};
