/* eslint-disable react-hooks/rules-of-hooks */
import useWindowFocus from 'use-window-focus';
import { unknownGroup } from '../constants/Group';
import { SystemGroups } from '../constants/SystemGroups';
import { useAppSelector } from '../hooks';
import { useGetOpenBranchesQuery } from '../services/clientDataApi';
import { ReportData } from '../types/Reports';
import { OnlyExistingKeys } from '../types/Repo';
import { useGetSuppressionListQuery } from '../services/sesApi';

const EMPTY_REPORT_DATA_REFERENCE: ReadonlyArray<ReportData> = [];

type UseClientDataRepoReturn<UseBranchReportDataType, UseEmailSuppressionReportDataType> = OnlyExistingKeys<{
  /**
   * Is fetching from the server and there's no cached data yet
   */
  isInitializingBranchReportData: UseBranchReportDataType extends true ? boolean : never;
  isLoadingBranchReportData: UseBranchReportDataType extends true ? boolean : never;
  branchReportData: UseBranchReportDataType extends true ? ReportData[] : never;

  emailSuppressionReportData: UseEmailSuppressionReportDataType extends true ? ReportData[] : never;
  isInitializingEmailSuppressionReportData: UseEmailSuppressionReportDataType extends true ? boolean : never;
  isLoadingEmailSuppressionReportData: UseEmailSuppressionReportDataType extends true ? boolean : never;
  refetchEmailSuppressionReport: () => Promise<ReportData>;
}>;

/**
 * Hook for fetching the most common data for the currently selected report.
 * The useData... params are used so we don't end up subscribing this component to unnecessary queries.
 *
 * @param useData param used to specify the data the component needs to fetch.
 */
export const useReportRepo = <
  UseBranchReport extends boolean = false,
  UseEmailSuppressionReport extends boolean = false,
>({
  useBranchReport,
  useEmailSuppressionReport,
}: {
  useBranchReport?: UseBranchReport;
  useEmailSuppressionReport?: UseEmailSuppressionReport;
} = {}): UseClientDataRepoReturn<UseBranchReport, UseEmailSuppressionReport> => {
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const result: any = {};

  if (useBranchReport && groupId === SystemGroups.IdeaRoom) {
    const isWindowFocused = useWindowFocus();

    const { currentData: reportData, isFetching: isLoadingBranchReportData } = useGetOpenBranchesQuery(
      { clientId: 'idearoom-reference' },
      {
        skip: !groupId || !useBranchReport,
        // refetch every 2 minutes
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 2 * 60 * 1000 : undefined,
      },
    );
    result.branchReportData = reportData || EMPTY_REPORT_DATA_REFERENCE;
    result.isInitializingBranchReportData = !reportData && isLoadingBranchReportData;
    result.isLoadingBranchReportData = isLoadingBranchReportData;
  }

  if (useEmailSuppressionReport && groupId === SystemGroups.IdeaRoom) {
    const isWindowFocused = useWindowFocus();

    const {
      currentData: reportData,
      isFetching: isLoadingEmailSuppressionReportData,
      refetch: refetchEmailSuppressionReport,
    } = useGetSuppressionListQuery(undefined, {
      skip: !groupId || !useEmailSuppressionReport,
      // refetch every 2 minutes
      // stops pooling if window is not focused
      pollingInterval: isWindowFocused ? 2 * 60 * 1000 : undefined,
    });
    result.emailSuppressionReportData = reportData || EMPTY_REPORT_DATA_REFERENCE;
    result.isInitializingEmailSuppressionReportData = !reportData && isLoadingEmailSuppressionReportData;
    result.isLoadingEmailSuppressionReportData = isLoadingEmailSuppressionReportData;
    result.refetchEmailSuppressionReport = refetchEmailSuppressionReport;
  }

  return result;
};
