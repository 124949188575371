import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import { IFloatingFilterReactComp } from 'ag-grid-react';
import { IFloatingFilterParams } from 'ag-grid-community';
import { DEFAULT_TEXT_FILTER_TYPE } from '../constants/ClientData';
import { useAppSelector } from '../hooks';

const useStyles = makeStyles(() => ({
  filterInput: {
    width: '100%',
    padding: '4px 0',
    '& .MuiOutlinedInput-root': {
      fontSize: '0.85rem',
    },
    '& .MuiOutlinedInput-input': {
      padding: '3px 0 3px 8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      paddingRight: '0',
    },
  },
  clearButton: {
    paddingRight: '4px',
    color: '#4286F4',
  },
  clearIcon: {
    fontSize: '1.1rem',
  },
}));

export const ClientDataTextColumnFilter = React.forwardRef<IFloatingFilterReactComp, IFloatingFilterParams>(
  (props, ref) => {
    const classes = useStyles();
    const [currentValue, setCurrentValue] = useState<string>('');
    const [filterType, setFilterType] = useState<string>(DEFAULT_TEXT_FILTER_TYPE);
    const inputRef = useRef<HTMLInputElement>(null);
    const { highlightedCell } = useAppSelector((state) => state?.clientData);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => ({
      onParentModelChanged(parentModel) {
        let filterValue = parentModel ? `${parentModel.filter}` : '';
        if (parentModel) {
          setFilterType(parentModel.type);
          if (['blank', 'notBlank'].includes(parentModel.type)) {
            filterValue = parentModel.type === 'blank' ? '<blank>' : '<not blank>';
          }
        }
        if (inputRef.current) {
          inputRef.current.value = filterValue;
        }
        setCurrentValue(filterValue);
      },
    }));

    const onSetColumnFilter = (value: string) => {
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(filterType, value);
      });
    };

    const onClearColumnFilter = () => {
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(
          ['blank', 'notBlank'].includes(filterType) ? DEFAULT_TEXT_FILTER_TYPE : filterType,
          '',
        );
      });
    };

    useEffect(() => {
      const highlightCol = highlightedCell?.colId === props.column.getColId();

      if (inputRef) {
        const element = inputRef.current?.parentElement?.parentElement;
        if (element) {
          if (highlightCol && !element.classList.contains('ag-column-hover')) element.classList.add('ag-column-hover');
          if (!highlightCol && element.classList.contains('ag-column-hover'))
            element.classList.remove('ag-column-hover');
        }
      }
    }, [highlightedCell, inputRef]);

    return (
      <TextField
        ref={inputRef}
        id="text-column-filter"
        value={currentValue}
        onChange={(input) => onSetColumnFilter(input.target.value)}
        className={classes.filterInput}
        disabled={['blank', 'notBlank'].includes(filterType)}
        hiddenLabel
        size="small"
        slotProps={{
          input: {
            endAdornment: currentValue && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear column filter"
                  onClick={onClearColumnFilter}
                  onMouseDown={onClearColumnFilter}
                  edge="end"
                  className={classes.clearButton}
                >
                  <HighlightOff className={classes.clearIcon} />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
    );
  },
);
