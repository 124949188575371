import { useCallback } from 'react';
import { useAppSelector } from '../hooks';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';

/**
 * Hook to return a function that will return a client's name given its key.
 *
 * Limits search to current clientId's associated product.
 */
export const useGetClientName = () => {
  const { group } = useAppSelector((state) => state.currentUser);
  const { clientId } = useAppSelector((state) => state.clientData);

  const getClientName = useCallback(
    (clientKey: string) => {
      if (!group) {
        return clientKey;
      }

      if (!group.configurators) {
        return clientKey;
      }
      const { configurator: product } = mapClientIdToConfiguratorAndVendor(clientId);
      const config = group.configurators.find((c) => c.key === product && c.vendor === clientKey);
      if (!config) {
        return clientKey;
      }

      // name should exist at this point.
      return config?.name || clientKey;
    },
    [group, clientId],
  );

  return getClientName;
};
