import React from 'react';
import { Dialogs } from '../constants/Dialogs';
import { useAppSelector } from '../hooks';
import { PublishResultDialog } from './PublishResultDialog';

export const ClientDataPublishResultDialog: React.FC = () => {
  const { clientDataType, vendorsToPublish, publishMergeResult } = useAppSelector((state) => state.clientData);

  return (
    <PublishResultDialog
      publishMergeResult={publishMergeResult}
      dialogKey={Dialogs.ClientDataPublishResult}
      clientDataType={clientDataType}
      vendorsToPublish={vendorsToPublish}
    />
  );
};
