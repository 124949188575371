import lodash from 'lodash';

/**
 * Returns a copy of the object with all undefined and null values removed.
 * @param object object to remove null and undefined values from
 * @returns cleaned object
 */
export const removeMissingProps = (object: any): any => {
  if (lodash.isArray(object)) return object.map((element) => removeMissingProps(element));
  if (!lodash.isPlainObject(object) || Object.keys(object).length === 0) return object;
  const cleanObject = lodash.omitBy({ ...object }, lodash.isNil);
  // For each key in cleanObject, the key should equal the result of removeMissingProps on the value
  return lodash.mapValues(cleanObject, (value) => removeMissingProps(value));
};

/**
 * Map an object to a key value pair array.
 * Allows the passing of additionalProps to the mapped object with the key value. This is useful for
 * adding additional props such as override, etc. that share the same value.
 *
 * @param object any
 * @param additionalProps string[]
 * @returns { key: any; value: any }[]
 */
export const mapObjectToKeyValuePair = (obj: any, additionalProps: string[] = []): { key: any; value: any }[] => {
  // Throw an error if obj is not an object
  if (!obj || typeof obj !== 'object') {
    throw new Error('mapObjectToKeyValuePair: obj cannot be undefined and must be an object');
  }

  const keyValuePair = Object.keys(obj).map((key) => ({
    key,
    value: obj[key],
    ...additionalProps.reduce((acc, prop) => {
      acc[prop] = obj[key];
      return acc;
    }, {} as any),
  }));
  return keyValuePair;
};

/**
 * Remove unnecessary properties from an object including nested objects and arrays
 *
 * @param object any
 * @param properties string[]
 */
export const removeProperties = (object: any, properties: string[]): any => {
  // Throw an error if object is not an object
  if (typeof object !== 'object') {
    throw new Error('removeProperties: object must be an object');
  }

  // Throw an error if properties is not an array
  if (!Array.isArray(properties)) {
    throw new Error('removeProperties: properties must be an array');
  }

  // Remove properties from object
  const newObject = mapObjectToKeyValuePair(object).reduce((acc, { key, value }) => {
    if (properties.includes(key)) {
      return acc;
    }

    // Check if a properties string is a nested object
    if (properties.some((property) => property.includes(`${key}.`))) {
      const nestedProperties = properties
        .filter((property) => property.startsWith(`${key}.`))
        .map((property) => property.replace(`${key}.`, ''));
      const nestedObject = removeProperties(value, nestedProperties);
      return { ...acc, [key]: nestedObject };
    }

    // If value is an object, recursively call removeProperties
    if (value && typeof value === 'object') {
      return { ...acc, [key]: removeProperties(value, properties) };
    }

    return { ...acc, [key]: value };
  }, {});

  return newObject;
};

/**
 * Compares two objects and returns true if they are equal
 *
 * @param object1 any
 * @param object2 any
 * @returns boolean
 */
export const areObjectsEqual = (object1: any, object2: any): boolean => {
  // return true if the objects are the same
  if (object1 === object2) return true;

  // return false if the types are different or if one is an array and the other is not
  if (typeof object1 !== typeof object2) return false;
  if (Array.isArray(object1) !== Array.isArray(object2)) return false;

  // return false if they are both numbers and not equal
  if (typeof object1 === 'number' && typeof object2 === 'number' && object1 !== object2) return false;

  // return false if they are both strings and not equal
  if (typeof object1 === 'string' && typeof object2 === 'string' && object1 !== object2) return false;

  // return false if they are both booleans and not equal
  if (typeof object1 === 'boolean' && typeof object2 === 'boolean' && object1 !== object2) return false;

  if (Array.isArray(object1)) {
    if (object1.length !== object2.length) return false;
    for (let i = 0; i < object1.length; i += 1) {
      if (!areObjectsEqual(object1[i], object2[i])) return false;
    }
    return true;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (!Object.prototype.hasOwnProperty.call(object2, key) || !areObjectsEqual(object1[key], object2[key])) {
      return false;
    }
  }

  return true;
};
