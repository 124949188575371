import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { MenuItems } from '../constants/MenuItems';
import { MenuState } from '../types/MenuState';

// Action types

export enum MenuActionTypes {
  SET_FOCUSED_MENU_ITEM = 'SET_FOCUSED_MENU_ITEM',
}

// Reducer

export const INITIAL_MENU_STATE: MenuState = {
  focusedMenuItem: undefined,
};

export const menuReducer: Reducer<MenuState> = (state = INITIAL_MENU_STATE, menuAction) => {
  switch (menuAction.type) {
    case MenuActionTypes.SET_FOCUSED_MENU_ITEM: {
      const {
        payload: { menuItem },
      } = menuAction;

      return { focusedMenuItem: menuItem };
    }

    default: {
      return state;
    }
  }
};

// Action creators
export const setFocusedMenuItem = (menuItem: MenuItems): AnyAction =>
  action(MenuActionTypes.SET_FOCUSED_MENU_ITEM, { menuItem });
