import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';
import { Autocomplete, CircularProgress, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fuzzyMatchIncludes } from '../utils/stringUtils';
import { DropdownArrow } from '../images/DropdownArrow';
import { KeyboardKeys } from '../constants/ClientData';

const MULTI_VALUE_SEPARATOR = ',';

enum MultiSelectListType {
  COMMA_SEPARATED = 'comma-separated',
  JSON_ARRAY = 'json-array',
}

const useStyles = makeStyles<Theme, { width: number }>(() => ({
  autoComplete: {
    width: ({ width }) => `${width}px`,
  },
  input: {
    boxShadow: 'var(--ag-input-focus-box-shadow)',
    borderColor: 'var(--ag-input-focus-border-color)',
    border: 'var(--ag-borders-input) var(--ag-input-border-color)',
    backgroundColor: 'white',
    paddingLeft: '3px',
    paddingRight: '3px',
    fontSize: 'calc(var(--ag-font-size) + 1px)',
    fontFamily: 'var(--ag-font-family)',
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
  },
  option: {
    padding: '2px 6px !important',
    fontSize: '14px',
    textWrap: 'nowrap',
  },
}));

type Props = {
  cellEditorParams: ICellEditorParams;
  options: string[];
  loading: boolean;
  freeSolo?: boolean;
  multiple?: boolean;
};

/**
 * Our DataGrid Autocomplete/Dropdown cell editor.
 * This editor implements MUI's Autocomplete with a few customizations for working correctly with the grid as for our needs.
 * Allows single or multiple values and strict or non-strict values.
 * Multi-value lists can be structured as comma separated values or as a JSON string array.
 */
export const DataGridAutocompleteCellEditor = React.forwardRef<ICellEditorReactComp, Props>((props: Props, ref) => {
  const { cellEditorParams, options, loading, freeSolo = true, multiple = false } = props;
  const { value: initialCellValue, eventKey, stopEditing, eGridCell } = cellEditorParams;
  const classes = useStyles({ width: eGridCell.getBoundingClientRect().width });

  const { initialValue, initialInputValue, multiSelectListType } = useMemo(() => {
    let normalizedInitialValue: string[] | string | undefined = initialCellValue;
    let selectListType: MultiSelectListType | undefined;
    if (multiple) {
      selectListType = MultiSelectListType.COMMA_SEPARATED;
      let trimmedInitialValue = (initialCellValue || '').trim();
      if (trimmedInitialValue.startsWith('[') && trimmedInitialValue.endsWith(']')) {
        // remove a single layer of brackets.
        trimmedInitialValue = initialCellValue.slice(1, initialCellValue.length - 1);
        selectListType = MultiSelectListType.JSON_ARRAY;
      }

      normalizedInitialValue = trimmedInitialValue
        .split(MULTI_VALUE_SEPARATOR)
        .map((v: string) => {
          const trimmedValue = v.trim();
          if (trimmedValue.startsWith('"') && trimmedValue.endsWith('"')) {
            // remove a single layer of double quotes.
            return trimmedValue.slice(1, trimmedValue.length - 1);
          }
          return trimmedValue;
        })
        .filter((v: string) => v !== null && v !== '');
    }
    let normalizedInitialInputValue = multiple ? '' : initialCellValue;
    if (eventKey && eventKey.length === 1) {
      normalizedInitialInputValue = eventKey;
      if (!multiple) normalizedInitialValue = eventKey;
    }

    return {
      initialValue: normalizedInitialValue,
      initialInputValue: normalizedInitialInputValue,
      multiSelectListType: selectListType,
    };
  }, [initialCellValue, multiple, eventKey]);

  const [value, setValue] = useState<string[] | string | undefined>(initialValue);
  const [inputValue, setInputValue] = useState<string | null>(initialInputValue);
  const cancelEditingRef = useRef(false);

  const [highlightedOption, setHighlightedOption] = useState<string | null>(null);
  const [selection, setSelection] = React.useState<{ start: number; end: number } | undefined>(undefined);
  const refInput = useRef<HTMLInputElement | null>(null);

  const inputValueRef = useRef<string | null>(initialInputValue);
  inputValueRef.current = inputValue;

  useEffect(() => {
    // Update the selection following a paste, only after the value has been updated
    if (selection !== undefined) {
      const { start, end } = selection;
      refInput.current?.setSelectionRange(start, end);
      setSelection(undefined);
    }
  }, [inputValue, selection]);

  useImperativeHandle(ref, () => ({
    getValue() {
      if (cancelEditingRef.current) {
        return initialCellValue;
      }
      const currentInputValue = inputValueRef.current;
      if (multiple) {
        // When user types values separated by commas and exit by clicking away we need to break those values into
        // separated options for validation (if freeSolo=false)
        const selectedValues = [
          ...(value as string[]),
          ...(currentInputValue || '').split(MULTI_VALUE_SEPARATOR),
        ].filter((v) => v !== undefined && v !== null && v?.trim() !== '' && (freeSolo || options.includes(v)));

        if (initialCellValue === null && selectedValues.length === 0) {
          return initialCellValue;
        }

        // Builds the list back to the original cell format
        if (multiSelectListType === MultiSelectListType.JSON_ARRAY) {
          return JSON.stringify(selectedValues);
        }
        return selectedValues.join(MULTI_VALUE_SEPARATOR);
      }
      if (initialCellValue === null && currentInputValue === '') {
        return initialCellValue;
      }
      if (
        currentInputValue !== '' &&
        currentInputValue !== null &&
        !freeSolo &&
        !options.includes(currentInputValue || '')
      ) {
        return initialCellValue;
      }
      return currentInputValue;
    },

    insertValue(val: string) {
      const pastedValue = `${val}`;
      const currentValue = inputValue || '';
      const maxSelection = currentValue.length;
      let { selectionStart, selectionEnd } = (refInput.current || {}) as {
        selectionStart?: number;
        selectionEnd?: number;
      };
      // As a default, paste at the end of the current value
      if (selectionStart === undefined) selectionStart = maxSelection;
      if (selectionEnd === undefined) selectionEnd = maxSelection;

      const newSelectionStart = selectionStart + pastedValue.length;
      setSelection({ start: newSelectionStart, end: newSelectionStart });
      setInputValue(currentValue.substring(0, selectionStart) + pastedValue + currentValue.substring(selectionEnd));
    },

    setSelection(newSelection: { start: number; end: number }) {
      setSelection(newSelection);
    },

    getSelection() {
      return { start: refInput.current?.selectionStart, end: refInput.current?.selectionEnd };
    },
  }));

  return (
    <div className="ag-cell-edit-wrapper">
      <div className="ag-wrapper ag-input-wrapper ag-text-area-input-wrapper">
        <Autocomplete
          disablePortal
          id="lookup-autocomplete"
          options={options}
          loading={loading}
          value={value}
          multiple={multiple}
          onChange={(event, newValue) => {
            if (!multiple) {
              /* 
              Force exit the editor when the user selects w/ a click on a single-value dropdown.
              Uses a ref to keep the value returned by getValue() as stopEditing() immediatelly destroys the editor,
              not waiting for the next render cycle where getValue() would be updated with a new inputValue reference.
            */
              inputValueRef.current = newValue as string;
              stopEditing(true);
            } else {
              // Makes sure to break any inputValues that were entered with commas into different options
              const newSelectedOptions = (newValue as string[])
                .reduce<string[]>((acc, val) => [...acc, ...val.split(MULTI_VALUE_SEPARATOR)], [])
                .filter((v) => freeSolo || options.includes(v));
              setValue(newSelectedOptions);
            }
          }}
          inputValue={inputValue || ''}
          isOptionEqualToValue={multiple ? () => false : undefined} // Allows multi-value dropdowns to have duplicates
          onInputChange={(e, newValue) => setInputValue(newValue)}
          size="small"
          openOnFocus
          // Set MUI's Autocomplete to always be freeSolo and handle invalid values on our side.
          // I was seeing a few issues with strict values on multi-select dropdowns
          freeSolo
          forcePopupIcon
          disableClearable
          filterOptions={(optionList, { inputValue: iv }) =>
            optionList.filter((o) => fuzzyMatchIncludes(o as string, iv))
          }
          classes={{ root: classes.autoComplete, option: classes.option, loading: classes.option }}
          onHighlightChange={(e, option, reason) => {
            // If the user is selecting options through Arrow Keys we make Enter select it
            if (reason === 'keyboard' || reason === 'auto') {
              setHighlightedOption(option as string);
            } else {
              setHighlightedOption(null);
            }
          }}
          popupIcon={<DropdownArrow />}
          onKeyDown={(e) => {
            const isOptionInListHighlighted = highlightedOption !== null && highlightedOption !== undefined;
            if (e.key === KeyboardKeys.Escape) {
              if (isOptionInListHighlighted) {
                setHighlightedOption(null);
              } else {
                // Using a ref as `stopEditing` destroys the Editor
                cancelEditingRef.current = true;
                stopEditing(true);
              }
            }
            if (e.key === KeyboardKeys.Enter) {
              /*
              Multi-value dropdown
               - When no option is highlighted or the input is empty Enter exits the editor
              Single-value dropdown
               - When an option is highlighted, select it.
               - MUI's Autocomplete triggers `onChange` which stops editing
               - If the inputValue is the same as the initial value then MUI's Autocomplete doesn't trigger `onChange`,
                 so we have to force stop editing
            */
              if (multiple) {
                if (!isOptionInListHighlighted && !inputValue?.length) {
                  stopEditing(true);
                }
              } else if (isOptionInListHighlighted) {
                setInputValue(highlightedOption);
              } else if (inputValue === initialInputValue || !inputValue?.length) {
                stopEditing(true);
              }
              setHighlightedOption(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              autoFocus
              variant="standard"
              inputRef={refInput}
              slotProps={{
                input: {
                  ...params.InputProps,
                  classes: { root: classes.input },
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
});
