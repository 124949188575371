/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppState } from './types/AppState';
import { store } from './index';

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export type AppDispatch = ReturnType<typeof useAppDispatch>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
type RootState = AppState; // TODO: connect this to the store.
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
