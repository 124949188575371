import moment from 'moment';

/**
 * Takes a file and encodes it to a base64 string
 *
 * @param file file to be encoded
 * @returns file as an encode as base64 string
 */
export const toBase64 = (file: File | Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject): void => {
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      if (typeof reader.result === 'string') {
        // Replace the file data text at the beginning of the base64 string
        // data:image/imageType;base64 for images
        // data:application/octet-stream;base64 for KML files
        resolve(reader.result.replace(/^data:.*;base64,/, ''));
      } else {
        resolve(reader.result);
      }
    };
    reader.onerror = (error): void => reject(error);
  });

/**
 * Create a timestamped file name for a vendor file
 *
 * @param clientId ClientId used to get the vendor name
 * @param file Image object with a 'name' prop
 * @param prefix Prefix to add to the file name (eg. 'logo-')
 * @returns File name with a datetime stamp
 */
export const generateFileName = (clientId: string, file: any, prefix?: string): string => {
  const fileExtension = file.name.split('.').slice(-1);
  return `${prefix || ''}${clientId.substring(clientId.indexOf('-') + 1)}-${moment(new Date(Date())).format(
    'YYYYMMDDhhmmss',
  )}.${fileExtension}`;
};

/**
 * Takes a file name and removes the file extension
 *
 * @param fileName name of the file to remove the extension from
 * @returns file name without the extension
 */
export const removeFileExtension = (fileName: string): string => fileName.replace(/\.[^/.]+$/, '');
