import {
  Box,
  Grid2 as Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UILayoutVersion } from '@idearoom/types';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import {
  SitesMessageType,
  SiteDetailDataFields,
  leadsFormPanelFieldsMap,
  leadsPopupDelayOptions,
  sitesFieldsMap,
} from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { SiteInputLabel } from './SiteInputLabel';
import { I18nKeys } from '../constants/I18nKeys';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { sanitizeInput } from '../utils/inputUtils';
import { SomeAllowed } from './SomeAllowed';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { useAppDispatch } from '../hooks';

const MAX_MOBILE_LENGTH = 55;

interface OwnProps {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

type Props = OwnProps;

const useStyles = makeStyles({
  mainContainer: {
    padding: '0 8px',
  },
  sectionContainer: {
    padding: '0 0 0 24px',
  },
  titleText: {
    paddingTop: '5px',
  },
  descriptionText: {
    padding: '10px 0',
  },
  inputLabel: {
    marginTop: '16px',
  },
  fieldContainer: {
    padding: '8px 0',
  },
  divider: {
    width: '100%',
    margin: '15px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    border: 'none',
  },
});

export const SitesPromotionPanel: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [leadTitle, setLeadTitle] = React.useState(
    data[SiteDetailDataFields.LeadsPopupTitle] !== null
      ? data[SiteDetailDataFields.LeadsPopupTitle]
      : t(I18nKeys.LeadsPopupDefaultTitle),
  );

  const [leadMessage, setLeadMessage] = React.useState(
    data[SiteDetailDataFields.LeadsPopupMessage] !== null
      ? data[SiteDetailDataFields.LeadsPopupMessage]
      : t(I18nKeys.LeadsPopupDefaultMessage),
  );

  const boostLayoutSelected = (data[SiteDetailDataFields.Layout] || UILayoutVersion.Classic) === UILayoutVersion.Mobile;

  const getValue = (key: SiteDetailDataFields, defaultValue: string) => {
    if (key === SiteDetailDataFields.LeadsPopupTitle) {
      return leadTitle;
    }
    if (key === SiteDetailDataFields.LeadsPopupMessage) {
      return leadMessage;
    }
    return data[key as SiteDetailDataFields] ? data[key as SiteDetailDataFields] : defaultValue || '';
  };

  const openAllowedHTMLTagsDialog = (): void => {
    dispatch(openDialog({ dialog: Dialogs.AllowedHtmlTags }));
  };

  const titleAndDescription = (titleI18nKey: string, descriptionI18nKey: string, secondary?: any) => (
    <>
      <Grid size={{ xs: 12 }}>
        <Typography variant="subtitle1" className={classes.titleText}>
          {t(titleI18nKey)}
        </Typography>
      </Grid>
      {secondary ? (
        <>
          <Grid size={{ xs: 10 }}>
            <Typography variant="subtitle2" color="text.secondary" className={classes.descriptionText}>
              {t(descriptionI18nKey)}
            </Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>{secondary}</Grid>
        </>
      ) : (
        <Grid size={{ xs: 12 }}>
          <Typography variant="subtitle2" color="text.secondary" className={classes.descriptionText}>
            {t(descriptionI18nKey)}
          </Typography>
        </Grid>
      )}
    </>
  );

  const radioGroup = (
    siteDataField: SiteDetailDataFields,
    defaultValue: any,
    radioOptions: { value: string; labelI18nKey: string }[],
  ) => (
    <Grid size={12}>
      <FormControl>
        <RadioGroup
          aria-label={siteDataField}
          name={siteDataField}
          value={data[siteDataField] || defaultValue}
          onChange={(e): void => vendorPropChange(e.target.name, e.target.value)}
        >
          {radioOptions.map((option) => (
            <FormControlLabel value={option.value} control={<Radio color="primary" />} label={t(option.labelI18nKey)} />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const textField = (
    key: SiteDetailDataFields,
    label: string,
    siteDetail: SiteDetail,
    props?: any,
    value?: string,
    defaultValue?: string,
  ) => (
    <Grid size={12} className={classes.fieldContainer}>
      <FormControl fullWidth>
        <TextField
          label={label}
          name={key}
          onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
          onBlur={(e: any): void => vendorPropChange(e.target.name, sanitizeInput(e.target.value))}
          value={
            value || data[key as SiteDetailDataFields] || defaultValue || sitesFieldsMap[key]?.props?.defaultValue || ''
          }
          variant="filled"
          disabled={disabledSiteDetailField(key as SiteDetailDataFields, siteDetail, isIdeaRoomUser, selectedGroupId)}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        />
      </FormControl>
    </Grid>
  );

  const promoBannerCustom: boolean = data[SiteDetailDataFields.PromotionalBannerType] === SitesMessageType.Custom;
  const showSecondMessages: boolean =
    data[SiteDetailDataFields.PromotionalBannerType] === SitesMessageType.Custom &&
    (data[SiteDetailDataFields.PromotionalBannerDesktopMessage2] !== null ||
      data[SiteDetailDataFields.PromotionalBannerMobileMessage2] !== null);

  const addRemoveSecondMessage = (add: boolean) => {
    if (add) {
      vendorPropChange(SiteDetailDataFields.PromotionalBannerDesktopMessage2, '');
      vendorPropChange(SiteDetailDataFields.PromotionalBannerMobileMessage2, '');
    } else {
      vendorPropChange(SiteDetailDataFields.PromotionalBannerDesktopMessage2, null);
      vendorPropChange(SiteDetailDataFields.PromotionalBannerMobileMessage2, null);
    }
  };

  const promoBannerMobile1TextLength: number =
    data[SiteDetailDataFields.PromotionalBannerMobileMessage1]?.toString().length || 0;
  const promoBannerMobile2TextLength: number =
    data[SiteDetailDataFields.PromotionalBannerMobileMessage2]?.toString().length || 0;
  const deliveryMessageTextLength: number = data[SiteDetailDataFields.DeliveryMessage]?.toString().length || 0;

  return (
    <Grid container className={classes.mainContainer}>
      {boostLayoutSelected && (
        <>
          <Grid size={12}>
            <Grid container>
              {titleAndDescription(
                I18nKeys.SitesPromotionalBannerTitle,
                I18nKeys.SitesPromotionalBannerDescription,
                <SomeAllowed onClick={openAllowedHTMLTagsDialog} className={classes.descriptionText}>
                  {t(I18nKeys.SomeHtmlTagsAllowed)}
                </SomeAllowed>,
              )}
              <Grid container size={12} className={classes.sectionContainer}>
                {radioGroup(SiteDetailDataFields.PromotionalBannerType, SitesMessageType.Standard, [
                  { value: SitesMessageType.Standard, labelI18nKey: I18nKeys.SitesPromotionalBannerStandard },
                  { value: SitesMessageType.Custom, labelI18nKey: I18nKeys.SitesPromotionalBannerCustom },
                ])}
                {textField(
                  SiteDetailDataFields.PromotionalBannerDesktopMessage1,
                  t(I18nKeys.SitesPromotionalBannerDesktopMessage, { count: showSecondMessages ? 1 : undefined }),
                  data,
                  undefined,
                  promoBannerCustom
                    ? undefined
                    : t(I18nKeys.SitesPromotionalBannerDefaultDesktopMessage, {
                        email: data[SiteDetailDataFields.EmailAddress],
                        phone: data[SiteDetailDataFields.PhoneNumber],
                      }),
                  t(I18nKeys.SitesPromotionalBannerDefaultDesktopMessage, {
                    email: data[SiteDetailDataFields.EmailAddress],
                    phone: data[SiteDetailDataFields.PhoneNumber],
                  }),
                )}
                {textField(
                  SiteDetailDataFields.PromotionalBannerMobileMessage1,
                  t(I18nKeys.SitesPromotionalBannerMobileMessage, { count: showSecondMessages ? 1 : undefined }),
                  data,
                  {
                    error: promoBannerCustom && promoBannerMobile1TextLength > MAX_MOBILE_LENGTH,
                    helperText:
                      promoBannerCustom && promoBannerMobile1TextLength > MAX_MOBILE_LENGTH
                        ? t(I18nKeys.SitesPromotionalMaxMobileLengthWarning)
                        : undefined,
                  },
                  promoBannerCustom
                    ? undefined
                    : t(I18nKeys.SitesPromotionalBannerDefaultMobileMessage, {
                        phone: data[SiteDetailDataFields.PhoneNumber],
                      }),
                  t(I18nKeys.SitesPromotionalBannerDefaultMobileMessage, {
                    phone: data[SiteDetailDataFields.PhoneNumber],
                  }),
                )}
                {showSecondMessages && (
                  <>
                    <Typography variant="subtitle1" color="text.secondary">
                      {t(I18nKeys.SitesPromotionalBannerRotatingMessages)}
                    </Typography>
                    {textField(
                      SiteDetailDataFields.PromotionalBannerDesktopMessage2,
                      t(I18nKeys.SitesPromotionalBannerDesktopMessage, { count: 2 }),
                      data,
                    )}
                    {textField(
                      SiteDetailDataFields.PromotionalBannerMobileMessage2,
                      t(I18nKeys.SitesPromotionalBannerMobileMessage, { count: 2 }),
                      data,
                      {
                        error: promoBannerCustom && promoBannerMobile2TextLength > MAX_MOBILE_LENGTH,
                        helperText:
                          promoBannerCustom && promoBannerMobile2TextLength > MAX_MOBILE_LENGTH
                            ? t(I18nKeys.SitesPromotionalMaxMobileLengthWarning)
                            : undefined,
                      },
                    )}
                  </>
                )}
                {promoBannerCustom && (
                  <Grid size={12} className={classes.fieldContainer}>
                    <Button variant="outlined" onClick={() => addRemoveSecondMessage(!showSecondMessages)}>
                      {showSecondMessages
                        ? t(I18nKeys.SitesPromotionalBannerRemoveButton)
                        : t(I18nKeys.SitesPromotionalBannerAddButton)}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <hr className={classes.divider} />
          </Grid>

          <Grid size={12}>
            <Grid container>
              {titleAndDescription(
                I18nKeys.SitesDeliveryMessageSectionTitle,
                I18nKeys.SitesDeliveryMessageSectionDescription,
              )}
              <Grid container size={12} className={classes.sectionContainer}>
                {radioGroup(SiteDetailDataFields.DeliveryMessageType, SitesMessageType.Standard, [
                  { value: SitesMessageType.Standard, labelI18nKey: I18nKeys.SitesDeliveryMessageStandard },
                  { value: SitesMessageType.Custom, labelI18nKey: I18nKeys.SitesDeliveryMessageCustom },
                ])}
                {textField(SiteDetailDataFields.DeliveryMessage, t(I18nKeys.SitesDeliveryMessageTitle), data, {
                  error: deliveryMessageTextLength > MAX_MOBILE_LENGTH,
                  helperText:
                    data[SiteDetailDataFields.DeliveryMessageType] === SitesMessageType.Custom &&
                    deliveryMessageTextLength > MAX_MOBILE_LENGTH
                      ? t(I18nKeys.SitesPromotionalMaxMobileLengthWarning)
                      : undefined,
                })}
              </Grid>
            </Grid>

            <hr className={classes.divider} />
          </Grid>

          <Grid size={12}>
            <Grid container>
              {titleAndDescription(
                I18nKeys.SitesInstallationMessageSectionTitle,
                I18nKeys.SitesInstallationMessageSectionDescription,
              )}
              <Grid container size={12} className={classes.sectionContainer}>
                {radioGroup(SiteDetailDataFields.InstallationMessageType, SitesMessageType.Standard, [
                  { value: SitesMessageType.Standard, labelI18nKey: I18nKeys.SitesInstallationMessageStandard },
                  { value: SitesMessageType.Custom, labelI18nKey: I18nKeys.SitesInstallationMessageCustom },
                ])}
                {textField(SiteDetailDataFields.InstallationMessage, t(I18nKeys.SitesInstallationMessageTitle), data, {
                  minRows: '3',
                  multiline: true,
                })}
              </Grid>
            </Grid>

            <hr className={classes.divider} />
          </Grid>
        </>
      )}
      <Grid size={12}>
        {titleAndDescription(I18nKeys.SitesLeadGenerationSectionTitle, I18nKeys.SitesLeadGenerationSectionDescription)}
        <Grid size={12}>
          {Object.entries(leadsFormPanelFieldsMap)
            .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
            .map(([key, field]) => {
              const { props: gridProps = {}, i18nKey } = field as SiteDetailField;
              const checkboxFields = [
                SiteDetailDataFields.EnableClientManagedLeadsForm,
                SiteDetailDataFields.LeadsEnableEmailPopup,
              ];
              if (checkboxFields.includes(key as SiteDetailDataFields)) {
                return (
                  <Grid size={{ xs: gridProps.xs || 12 }} key={key}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={key}
                            checked={
                              data[key as SiteDetailDataFields] === undefined
                                ? (!!gridProps.defaultValue as boolean)
                                : (!!data[key as SiteDetailDataFields] as boolean)
                            }
                            onChange={(e): void => vendorPropChange(e.target.name, e.target.checked)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={disabledSiteDetailField(
                              key as SiteDetailDataFields,
                              data,
                              isIdeaRoomUser,
                              selectedGroupId,
                            )}
                          />
                        }
                        label={
                          <>
                            {ideaRoomOnlySiteDetailField(
                              key as SiteDetailDataFields,
                              data,
                              isIdeaRoomUser,
                              selectedGroupId,
                            ) && <IdeaRoomOnlyIndicator />}
                            <Typography style={{ display: 'inline' }}>{t(i18nKey)}</Typography>
                          </>
                        }
                      />
                    </Box>
                  </Grid>
                );
              }
              if (SiteDetailDataFields.LeadsPopupDelay === (key as SiteDetailDataFields)) {
                return (
                  <Grid size={{ xs: 6, sm: gridProps.sm || 6 }} className={classes.fieldContainer} key={key}>
                    <FormControl fullWidth style={{ marginBottom: '3px' }}>
                      <SiteInputLabel
                        shrink
                        className={classes.inputLabel}
                        htmlFor={`${key}-select`}
                        i18nKey={i18nKey}
                      />
                      <LoadingSelect
                        disabled={disabledSiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        )}
                        value={data[key as SiteDetailDataFields.LeadsPopupDelay] || gridProps.defaultValue}
                        onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
                        inputProps={{
                          displayEmpty: false,
                          name: key,
                          id: `${key}-select`,
                        }}
                        variant="filled"
                        loading={false}
                      >
                        {leadsPopupDelayOptions.map((option: any) => (
                          <MenuItem key={option.key} value={option.key}>
                            {t(option.label)}
                          </MenuItem>
                        ))}
                      </LoadingSelect>
                    </FormControl>
                  </Grid>
                );
              }
              if (SiteDetailDataFields.LeadsRequireForDesign === (key as SiteDetailDataFields)) {
                return (
                  <Grid size={{ xs: 6 }} style={{ padding: '12px' }} key={key}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disabledSiteDetailField(
                              SiteDetailDataFields.LeadsRequireForDesign,
                              data,
                              isIdeaRoomUser,
                              selectedGroupId,
                            )}
                            name={SiteDetailDataFields.LeadsRequireForDesign}
                            checked={!!data.leadsRequireForDesign}
                            onChange={(e: any): void => vendorPropChange(e.target.name, e.target.checked)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label={t(
                          (leadsFormPanelFieldsMap[SiteDetailDataFields.LeadsRequireForDesign] as SiteDetailField)
                            .i18nKey,
                        )}
                      />
                    </FormControl>
                  </Grid>
                );
              }
              return (
                <Grid container size={12} className={classes.fieldContainer} key={key}>
                  <FormControl fullWidth>
                    <SiteInputLabel shrink className={classes.inputLabel} i18nKey={i18nKey} />
                    <TextField
                      disabled={disabledSiteDetailField(
                        key as SiteDetailDataFields,
                        data,
                        isIdeaRoomUser,
                        selectedGroupId,
                      )}
                      hidden={hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                      name={key}
                      onChange={(e): void => {
                        if (key === SiteDetailDataFields.LeadsPopupTitle) {
                          setLeadTitle(e.target.value);
                        } else {
                          setLeadMessage(e.target.value);
                        }
                        vendorPropChange(e.target.name, e.target.value);
                      }}
                      value={getValue(key as SiteDetailDataFields, gridProps.defaultValue)}
                      type="text"
                      variant="filled"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...([SiteDetailDataFields.LeadsPopupMessage].includes(key as SiteDetailDataFields)
                        ? {
                            minRows: '2',
                            multiline: true,
                            onBlur: (e): void => {
                              vendorPropChange(e.target.name, e.target.value);
                            },
                          }
                        : {})}
                    />
                  </FormControl>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
