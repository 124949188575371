/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  Typography,
  Grid2 as Grid,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { PricingSurchargeAttributes, PricingSurchargeCalculationType, PricingSurchargeCategory } from '@idearoom/types';
import { PricingCategories, shedPricingCategories, SurchargeUpdateProperty } from '../constants/PricingSurcharge';
import { AppState } from '../types/AppState';
import { PricingSurchargeSelectableChip } from './PricingSurchargeSelectableChip';
import { isCarportView } from '../utils/clientIdUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CommonSurchargeUpdateProperty, updateSurchargeProperty } from '../ducks/pricingSlice';
import { usePricingRepo } from '../hooks/usePricingRepo';

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: '2%',
  },
  textField: {
    '& > *': {
      width: '35ch',
    },
  },
  spacing: {
    marginTop: '10px',
  },
});

export const PricingSurchargeCalculation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { clientId } = useAppSelector((state: AppState) => state.clientData);
  const isCarportViewClient = isCarportView(clientId);

  const { selectedPricingSurcharge } = usePricingRepo({
    useBranches: true,
    useSelectedPricingSurcharge: true,
  });
  const { key: pricingSelection, categories, label, isTaxable, affectsDeposit } = selectedPricingSurcharge;

  const [labelValue, setLabelValue] = useState(label);
  const [isTaxableValue, setIsTaxableValue] = useState(isTaxable);
  const [affectsDepositValue, setAffectsDepositValue] = useState(affectsDeposit);

  const handleCategoryChange = (pricingCategory: PricingSurchargeCategory): void => {
    dispatch(updateSurchargeProperty([{ property: SurchargeUpdateProperty.Categories, value: pricingCategory }]));
  };

  const handleCalculationChange = (prop: keyof PricingSurchargeAttributes, value: string | boolean): void => {
    dispatch(updateSurchargeProperty([{ property: prop as CommonSurchargeUpdateProperty, value }]));
  };

  const debouncedCalculationChangeHandler = useMemo(() => debounce(handleCalculationChange, 400), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(
    () => () => {
      debouncedCalculationChangeHandler.cancel();
    },
    [],
  );

  return (
    <>
      <FormControl component="fieldset">
        {isCarportViewClient ? (
          <>
            <Typography>{t(I18nKeys.PricingCalculationInclude)}</Typography>
            <Grid key="category-grid" container direction="row" alignItems="flex-start" spacing={1}>
              {PricingCategories.map((category) => (
                <Grid key={`${category.value}-grid`}>
                  <PricingSurchargeSelectableChip
                    chip={category}
                    selected={categories.includes(category.value as PricingSurchargeCategory)}
                    handleChipSelection={handleCategoryChange}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <RadioGroup
            aria-label="pricing-calculations"
            name="pricingCalculations"
            value={categories[0]}
            onChange={(_, value): void => handleCategoryChange(value as PricingSurchargeCategory)}
          >
            {shedPricingCategories.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={t(
                  pricingSelection === PricingSurchargeCalculationType.Surcharge
                    ? option.surchargeLabel
                    : option.lineItemLabel,
                )}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
      {pricingSelection === PricingSurchargeCalculationType.Surcharge ? (
        <Grid container className={classes.spacing} alignItems="center">
          <FormGroup row>
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingCalculationLineItemDescription)}
              value={labelValue}
              onChange={(e): void => {
                setLabelValue(e.target.value);
                debouncedCalculationChangeHandler(SurchargeUpdateProperty.Label, e.target.value);
              }}
            />
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  name="taxable"
                  color="primary"
                  checked={!!isTaxableValue}
                  onChange={(e): void => {
                    setIsTaxableValue(e.currentTarget.checked);
                    debouncedCalculationChangeHandler(SurchargeUpdateProperty.IsTaxable, e.currentTarget.checked);
                  }}
                />
              }
              label={t(I18nKeys.PricingCalculationTaxable)}
            />
            {isCarportViewClient && (
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    name="affects-dealer-deposit"
                    color="primary"
                    checked={!!affectsDepositValue}
                    onChange={(e): void => {
                      setAffectsDepositValue(e.currentTarget.checked);
                      debouncedCalculationChangeHandler(
                        SurchargeUpdateProperty.AffectsDeposit,
                        e.currentTarget.checked,
                      );
                    }}
                  />
                }
                label={t(I18nKeys.PricingCalculationIncludeDealerDeposit)}
              />
            )}
          </FormGroup>
        </Grid>
      ) : (
        <Typography color="textSecondary" variant="body2">
          {isCarportViewClient
            ? t(I18nKeys.PricingCalculationCarportTaxation)
            : t(I18nKeys.PricingCalculationShedTaxation)}
        </Typography>
      )}
    </>
  );
};
