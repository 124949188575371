import React from 'react';
import { Chip, Divider, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BlueMuted, DarkText, GrayMuted } from '../constants/ChipColors';
import { OrderOwner } from '../types/OrderOwner';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { SearchInput } from './SearchInput';

interface Props {
  readonly: boolean;
  owner: OrderOwner;
  options: OrderOwner[];
  onOrderOwnerChange(orderOwner: OrderOwner): void;
}

export const notAssigned: OrderOwner = { name: 'Not Assigned', email: '' };

export const OrderOwnerSelector: React.FC<Props> = ({
  readonly,
  owner = notAssigned,
  options,
  onOrderOwnerChange,
}: Props) => {
  const [ownerEmail, setOwnerEmail] = React.useState(owner.email);
  const [chipLabel, setChipLabel] = React.useState(owner.name);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();
  const [filteredOptions, setFilteredOptions] = React.useState<OrderOwner[]>(options);
  const [searchTerm, setDialogSearchTerm] = React.useState<string>('');

  React.useEffect(() => {
    // don't bother searching until there's at least 2 characters
    if (searchTerm.length < 2) {
      setFilteredOptions(options);
      return;
    }
    const tests = [
      (option: OrderOwner): boolean => stringToLowerIncludes(option.name, searchTerm),
      (option: OrderOwner): boolean => stringToLowerIncludes(option.email, searchTerm),
    ];

    setFilteredOptions(options.filter((option) => tests.some((test) => test(option))));
  }, [options, searchTerm]);

  const resetSearchTerm = (): void => setDialogSearchTerm('');

  const handleChipClicked = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setMenuAnchorEl(anchorElRef.current);
    setMenuOpen(!menuOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any): void => {
    event.stopPropagation();
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (orderOwner: OrderOwner): void => {
    onOrderOwnerChange(orderOwner);
    if (!readonly) {
      setChipLabel(orderOwner.name);
      setOwnerEmail(orderOwner.email);
    }
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Chip
        deleteIcon={<ArrowDropDownIcon style={{ color: DarkText }} />}
        clickable
        onDelete={handleChipClicked}
        onClick={handleChipClicked}
        label={chipLabel}
        ref={anchorElRef}
        style={{ backgroundColor: ownerEmail ? BlueMuted : GrayMuted }}
      />
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleClose}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => {
            event.stopPropagation();
          }}
        >
          {options.length > 10 && (
            <SearchInput
              searchTerm={searchTerm}
              startAdornment={<SearchIcon />}
              variant="outlined"
              onClearClick={resetSearchTerm}
              onChange={setDialogSearchTerm}
            />
          )}
        </MenuItem>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
            handleMenuItemClick(notAssigned);
          }}
        >
          {notAssigned.name}
        </MenuItem>
        <Divider />
        {filteredOptions.map((option) => (
          <MenuItem
            key={option.email}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              event.stopPropagation();
              handleMenuItemClick(option);
            }}
          >
            {option.name ? option.name : option.email}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
