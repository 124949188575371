import { makeStyles } from '@mui/styles';
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import {
  Grid2 as Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Configurator } from '../types/Configurator';
import { unknownGroup } from '../constants/Group';
import { ConfiguratorAppBar } from './ConfiguratorAppBar';
import { SitesBasicInformation } from './SitesBasicInformation';
import { SitesConfiguratorSettings } from './SitesConfiguratorSettings';
import { SitesColorsAndScene } from './SitesColorsAndScene';
import { SitesEmailAndContact } from './SitesEmailAndContact';
import { SitesLogosAndWatermarks } from './SitesLogosAndWatermarks';
import { SitesInformationalPanel } from './SitesInformationalPanel';
import { SitesPricingInformation } from './SitesPricingInformation';
import { SitesFloorplanOptions } from './SitesFloorplanOptions';
import { SitesAdvancedSection } from './SitesAdvancedSection';
import { SitesPromotionPanel } from './SitesPromotionPanel';
import { setSearchHidden } from '../ducks/search';
import { isIdeaRoomUser } from '../utils/userUtils';
import { addDispatchCommandToUndo } from '../utils/undoManagerUtils';
import { unknownUser } from '../types/User';
import { I18nKeys } from '../constants/I18nKeys';
import { MaximumDigits } from '../constants/PricingSurcharge';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { SITE_DETAIL_TABLE } from '../constants/ClientData';
import {
  setClientDataBranch,
  setClientDataType,
  setClientId,
  setSelectedTable,
  updateClientData,
  UpdateClientData,
} from '../ducks/clientDataSlice';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { SiteDetailPublishResultDialog } from './SiteDetailPublishResultDialog';
import { EmailDomainDialog } from './EmailDomainDialog';
import { isNotSiteDetailBranch } from '../utils/vendorDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { UserPreference } from '../constants/User';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  rootGrid: {
    flex: 1,
  },
  accordion: {
    width: '100%',
    '&$expanded': {
      margin: '0',
    },
  },
  sectionTitle: {
    marginLeft: '8px',
    fontWeight: 'bold',
  },
  accordionSummary: {
    '&$expanded': {
      margin: '20px 0 0 0',
    },
  },
  expanded: {},
  accordionDetails: {
    paddingTop: 0,
  },
});

export const Sites: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>({});

  const { clientId, clientDataType, clientDataBranch, selectedTable, isCreatingBranch } = useAppSelector(
    (state: AppState) => state?.clientData,
  );

  if (clientDataType !== ClientDataType.Vendor) {
    dispatch(setClientDataType(ClientDataType.Vendor));
  }

  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const vendorData = useAppSelector((state: AppState) => state?.vendorData.vendorData);
  const { activeBranches, isInitializingSelectedTableData, selectedTableData } = useClientDataRepo({
    useBranches: true,
    useSelectedTableData: true,
    useClientTablesColumns: true, // This is needed for getting active branches
    skipClientDataFetch: isNotSiteDetailBranch,
  });

  const {
    group: { configurators: groupConfigs = [], groupId: selectedGroupId } = unknownGroup,
    preferences: {
      [UserPreference.SitesPreferences]: { sectionsState: savedSectionsState = {}, ...sitesProfilePreferences } = {},
    } = {},
  } = useAppSelector((state) => state.currentUser);
  const { user } = useAppSelector((state) => state.currentUser || unknownUser);
  const [selectedConfigurator, setConfigurator] = useState<Configurator | undefined>(
    groupConfigs.find((c) => c.clientId === selectedViewerId) || groupConfigs[0],
  );
  const [sectionsState, setSectionsState] = useState<{
    [section: string]: boolean;
  }>(savedSectionsState);

  const onSectionChange = (section: string): void => {
    setSectionsState((prevSectionsState) => {
      const newSectionsState = {
        ...prevSectionsState,
        [section]: !prevSectionsState?.[section],
      };
      dispatch(
        saveUserPreferences({
          userPreference: UserPreference.SitesPreferences,
          preferences: {
            sectionsState: newSectionsState,
            ...sitesProfilePreferences,
          },
        }),
      );
      return newSectionsState;
    });
  };

  if (selectedViewerId && selectedViewerId !== clientId) {
    dispatch(setClientId(selectedViewerId));
  }

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  });

  useEffect(() => {
    if (selectedTableData) {
      const { vendorData: { vendor: configVendorData = {} } = {} } = selectedConfigurator || {};
      // There should only be one row of data per client
      // Combine the table data with data from vendorData
      setTableData({ ...configVendorData, ...vendorData, ...selectedTableData[0] });
    }
  }, [selectedTableData, vendorData, selectedConfigurator]);

  if (selectedTable !== SITE_DETAIL_TABLE) {
    dispatch(setSelectedTable(SITE_DETAIL_TABLE));
  }

  useEffect(() => {
    if (
      clientDataBranch !== ClientDataBranch.SiteDetail &&
      activeBranches.find((branch) => branch.branchType === ClientDataBranch.SiteDetail)
    ) {
      dispatch(setClientDataBranch(ClientDataBranch.SiteDetail));
    } else if (clientDataBranch !== ClientDataBranch.SiteDetail && clientDataBranch !== ClientDataBranch.Main) {
      dispatch(setClientDataBranch(ClientDataBranch.Main));
    }
  }, [dispatch, clientDataBranch, activeBranches]);

  useEffect(() => {
    if (isInitializingSelectedTableData || isCreatingBranch) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isInitializingSelectedTableData, isCreatingBranch]);

  type SiteDetailChange = { column: string; value: any; percentage: boolean };

  // TODO: Add debounce handler for input fields to prevent the branch creation from being called after the first key press
  // TODO: Add undo/redo for site details
  const handleSiteDetailChange = (
    column: string,
    value: any,
    percentage: boolean,
    changes: SiteDetailChange[] = [],
  ): void => {
    const allChanges = [{ column, value, percentage }, ...changes];
    const { [ClientDataFixedColumns.RowId]: rowId } = selectedTableData[0];

    setTableData((prevTableData: any) => {
      let updatedTableData = prevTableData;
      const { oldRows: undoUpdates, newRows: updates } = allChanges.reduce(
        ({ oldRows, newRows }, { value: val, column: col, percentage: percent }) => {
          let columnValue = val;
          if (columnValue !== undefined && columnValue !== '' && percent) {
            const valueAsString =
              val.length > MaximumDigits.PercentChange ? val.slice(0, MaximumDigits.PercentChange) : val;
            columnValue = (parseFloat(valueAsString) / 100).toString();
          }

          oldRows.push({
            table: SITE_DETAIL_TABLE,
            column: col,
            value: tableData[col],
            rowData: {
              [ClientDataFixedColumns.RowId]: rowId,
              [col]: tableData[col],
            },
          });
          newRows.push({
            table: SITE_DETAIL_TABLE,
            column: col,
            value: columnValue,
            rowData: {
              [ClientDataFixedColumns.RowId]: rowId,
              [col]: columnValue,
            },
          });
          updatedTableData = { ...updatedTableData, [col]: columnValue };
          return { oldRows, newRows };
        },
        { oldRows: [], newRows: [] } as { oldRows: UpdateClientData[]; newRows: UpdateClientData[] },
      );

      const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, SITE_DETAIL_TABLE);

      addDispatchCommandToUndo(
        dispatch,
        [updateClientData({ rows: undoUpdates, branch: ClientDataBranch.SiteDetail })],
        [updateClientData({ rows: updates, branch: ClientDataBranch.SiteDetail })],
        clientDataTableId,
        true,
      );
      return { ...tableData, ...updatedTableData };
    });
  };

  const setClientAndConfigurator = (newClientId: string): void => {
    if (selectedViewerId !== newClientId) {
      setIsLoading(true);
      dispatch(setClientId(newClientId));
      setConfigurator(groupConfigs.find((c) => c.clientId === newClientId) || groupConfigs[0]);
    }
  };

  const sections: { key: string; title: string; component: ReactNode; hidden: boolean; ideaRoomOnly?: boolean }[] =
    tableData && [
      {
        key: 'basicInformation',
        title: t(I18nKeys.SitesSectionBasicInformation),
        component: (
          <SitesBasicInformation
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'configuratorSettings',
        title: t(I18nKeys.SitesSectionConfiguratorSettings),
        component: (
          <SitesConfiguratorSettings
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'emailAndContact',
        title: t(I18nKeys.SitesSectionEmailAndContact),
        component: (
          <SitesEmailAndContact
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'logosAndWatermarks',
        title: t(I18nKeys.SitesSectionLogos),
        component: (
          <SitesLogosAndWatermarks
            data={tableData}
            clientId={clientId}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'colorsAndScene',
        title: t(I18nKeys.SitesSectionColorsAndScene),
        component: (
          <SitesColorsAndScene
            data={tableData}
            clientId={clientId}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'informationalPanel',
        title: t(I18nKeys.SitesSectionInformationPanel),
        component: (
          <SitesInformationalPanel
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'promotionalPanel',
        title: t(I18nKeys.SitesSectionLeadsPopupPanel),
        component: (
          <SitesPromotionPanel
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
      },
      {
        key: 'sitePricingInformation',
        title: t(I18nKeys.SitesSectionPricingInformation),
        component: (
          <SitesPricingInformation
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
      },
      {
        key: 'floorplans',
        title: t(I18nKeys.SitesSectionFloorplans),
        component: (
          <SitesFloorplanOptions
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: !tableData.proSubscriptionEnabled,
      },
      {
        key: 'siteAdvancedSection',
        title: t(I18nKeys.SitesSectionAdvanced),
        component: (
          <SitesAdvancedSection
            data={tableData}
            vendorPropChange={handleSiteDetailChange}
            isIdeaRoomUser={isIdeaRoomUser(user)}
            selectedGroupId={selectedGroupId}
          />
        ),
        hidden: false,
        ideaRoomOnly: false,
      },
    ];

  return (
    <div className={classes.root}>
      <ConfiguratorAppBar
        enabledOnProperty="clientId"
        isVendorProperty={false}
        configurators={groupConfigs}
        onTabChange={setClientAndConfigurator}
      />
      {!isLoading && (
        <Grid
          className={classes.rootGrid}
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {(!selectedConfigurator || selectedConfigurator) &&
            sections &&
            sections
              .filter((section) => !section.hidden)
              .map((section, i) => (
                <React.Fragment key={section.key}>
                  <Accordion
                    defaultExpanded
                    classes={{ root: classes.accordion, expanded: classes.expanded }}
                    expanded={!!sectionsState?.[section.key]}
                    onChange={() => onSectionChange(section.key)}
                  >
                    <AccordionSummary
                      classes={{ content: classes.accordionSummary, expanded: classes.expanded }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      {section.ideaRoomOnly && <IdeaRoomOnlyIndicator />}
                      <Typography className={classes.sectionTitle} variant="overline">
                        {section.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>{section.component}</AccordionDetails>
                  </Accordion>
                  {/* Only add divider if not last section */}
                  {i < sections.length - 1 && <Divider />}
                </React.Fragment>
              ))}
        </Grid>
      )}
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
        </div>
      )}
      <SiteDetailPublishResultDialog />
      <EmailDomainDialog />
    </div>
  );
};
