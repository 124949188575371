import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import { alpha, styled } from '@mui/material/styles';
import { ButtonBase, Stack, Theme, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedPricingSheetId, setSelectedSizeBasedPricingSheetId } from '../ducks/pricingSlice';
import { PricingSheet } from '../types/PricingSheet';
import { I18nKeys } from '../constants/I18nKeys';
import { SearchInput } from './SearchInput';
import { PricingAttributesLabel } from './PricingAttributesLabel';
import { matchExists } from '../utils/searchUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import hiddenPriceSrc from '../images/hiddenPriceIcon.svg';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import { AppState } from '../types/AppState';
import { PricingTab } from '../constants/Pricing';
import { PricingSheetAttribute } from '../types/PricingSheetAttribute';
import { PricingSheetPrice } from '../types/PricingSheetPrice';
import { PricingClientUpdateNavigation } from './PricingClientUpdateNavigation';
import { getPricingSheetLabelParts } from '../utils/pricingSheetUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
  },
  searchField: {
    padding: '0px 0px 6px',
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    '& .MuiInputBase-root': {
      borderRadius: '8px',
    },
    '& input': {
      padding: '10px 0px',
    },
  },
  stack: {
    flex: 1,
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    overflow: 'auto',
  },
  list: {
    textAlign: 'start',
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(4)}`,
    margin: 0,
  },
  toolTipList: {
    textAlign: 'start',
    padding: `${theme.spacing(2)}`,
    margin: 0,
  },
  sheetTitle: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  attributes: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

interface ButtonProps {
  selected?: boolean;
  changes?: boolean;
}

const AttributesButton = styled(ButtonBase)<ButtonProps>((props) => {
  // Background gradients to show the orange sheet changed indicator
  const background = props.changes
    ? `linear-gradient(to right, #E4A767CC 7px, ${alpha('#000', 0.04)}, ${alpha('#000', 0.04)} 7px)`
    : `linear-gradient(0deg, ${alpha('#000', 0.04)}, ${alpha('#000', 0.04)})`;

  const backgroundSelected = props.changes
    ? `linear-gradient(to right, #E4A767CC 7px, ${alpha(props.theme.palette.primary.main, 0.12)} 7px)`
    : `linear-gradient(0deg, ${alpha(props.theme.palette.primary.main, 0.12)}, ${alpha(
        props.theme.palette.primary.main,
        0.12,
      )})`;

  return {
    border: '1px solid #DADADA',
    borderRadius: '8px',
    justifyContent: 'space-between',
    padding: '0px 16px 0px 0px',
    color: alpha('#000', 0.87),
    '&:hover': {
      background: props.changes
        ? `linear-gradient(to right, #E4A767CC 7px, ${alpha(props.theme.palette.primary.main, 0.12)} 7px)`
        : `linear-gradient(0deg, ${alpha(props.theme.palette.primary.main, 0.12)}, ${alpha(
            props.theme.palette.primary.main,
            0.12,
          )})`,
    },

    ...(props.selected
      ? {
          background: backgroundSelected,
        }
      : {
          background,
        }),
  };
});

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    disableInteractive
    disableHoverListener={props.disableHoverListener}
    TransitionProps={{ timeout: 600 }}
    title={props.title}
    classes={{ popper: className }}
  >
    {props.children}
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
  },
});

type Props = {
  selectedPricingSheet?: PricingSheet;
  pricingSheets: PricingSheet[];
  cellMetadataDiff: CellMetadata[];
  pricingDiffs: {
    table: string;
    changes: ClientDataTableRowDiff[];
  }[];
};

export const PricingAttributes: React.FC<Props> = ({
  selectedPricingSheet,
  pricingSheets,
  cellMetadataDiff,
  pricingDiffs,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [filteredPricingBaseSheets, setFilteredPricingBaseSheets] = React.useState<PricingSheet[]>([]);

  const pricingTab = useAppSelector((state: AppState) => state?.viewer?.selectedPricingTabId || '');

  useEffect(() => {
    setFilteredPricingBaseSheets(
      !searchValue
        ? pricingSheets
        : pricingSheets.filter((pricingSheet) =>
            getPricingSheetLabelParts(pricingSheet, pricingTab, t).some(
              (label) => label && matchExists(label, searchValue, {}),
            ),
          ),
    );
  }, [pricingSheets, searchValue]);

  // Not the most fancy code, but the tooltip will be removed in the near future.
  const [isOverflowed, setIsOverflow] = React.useState<boolean[]>([]);
  const arrayRef = React.useRef<HTMLElement[][]>([]);
  useEffect(() => {
    if (pricingSheets.length > 0) {
      let change = false;
      arrayRef.current.forEach((elements, index) => {
        const overflowed = !!elements.find(
          (element: HTMLElement) =>
            element && (element.scrollWidth > element.clientWidth || element.clientHeight < element.scrollHeight),
        );
        if (overflowed !== isOverflowed[index]) {
          change = true;
          isOverflowed[index] = overflowed;
        }
      });
      if (change) {
        setIsOverflow([...isOverflowed]);
      }
    }
  }, [pricingSheets, isOverflowed, setIsOverflow, arrayRef.current.length]);

  const handleAttributeClick = (id: string): void => {
    switch (pricingTab) {
      case PricingTab.Base:
        dispatch(setSelectedPricingSheetId(id));
        break;
      case PricingTab.SizeBased:
        dispatch(setSelectedSizeBasedPricingSheetId(id));
        break;
      default:
        break;
    }
  };

  const checkSheetForChanges = ({
    changes,
    prices = [],
  }: {
    id: string;
    attributes: PricingSheetAttribute[];
    changes?: boolean;
    prices?: PricingSheetPrice[];
  }): boolean => {
    if (changes !== undefined) return changes;
    const diffRowIds = Array.from(
      new Set(
        [...pricingDiffs.flatMap(({ changes: c }) => c), ...cellMetadataDiff].map(
          (val) => val[ClientDataFixedColumns.RowId],
        ),
      ),
    );
    return prices.some(({ [ClientDataFixedColumns.RowId]: rowId }) => diffRowIds.includes(rowId));
  };

  const searchRef = React.useRef<HTMLDivElement>(null);
  const clearSearch = (): void => {
    setSearchValue('');
    searchRef.current?.focus();
  };

  return (
    <div className={classes.root}>
      <Stack className={classes.stack} direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        {!pricingSheets.length && (
          <AttributesButton className={classes.list}>
            <Typography className={classes.attributes} variant="body2">
              {t(I18nKeys.PricingBaseAttributeEmpty)}
            </Typography>
          </AttributesButton>
        )}
        <div>
          {pricingTab === PricingTab.SizeBased && <PricingClientUpdateNavigation setSearchValue={setSearchValue} />}
          {!!pricingSheets.length && (
            <SearchInput
              ref={searchRef}
              searchTerm={searchValue}
              startAdornment={<Search />}
              resultsDisplay={{ count: filteredPricingBaseSheets.length, label: 'found' }}
              onClearClick={() => clearSearch()}
              onKeyDown={(e): void => {
                if (e.key === 'Escape') {
                  e.preventDefault();
                  clearSearch();
                }
              }}
              onChange={(val: string) => setSearchValue(val)}
              classes={classes}
            />
          )}
        </div>
        {!!pricingSheets.length && !filteredPricingBaseSheets.length && (
          <Typography
            className={classes.attributes}
            variant="body2"
            color="text.secondary"
            style={{ textAlign: 'center' }}
          >
            {t(I18nKeys.PricingBaseAttributeNoneFound)}
          </Typography>
        )}
        {!!filteredPricingBaseSheets.length &&
          filteredPricingBaseSheets.map((sheet, index) => {
            const isHiddenStyle = sheet?.prices?.every(({ hidden }) =>
              Object.values(hidden).reduce((acc, isHidden) => acc && isHidden, true),
            );
            return (
              <NoMaxWidthTooltip
                key={sheet.id}
                disableHoverListener={!isOverflowed[index]}
                title={
                  <ul className={classes.toolTipList}>
                    {sheet.attributes
                      .filter((attribute) => !attribute.hide)
                      .map(({ label, value }) => (
                        <li key={`${value}`}>
                          <Typography variant="body2">{label || `${value}`}</Typography>
                        </li>
                      ))}
                  </ul>
                }
              >
                <AttributesButton
                  changes={checkSheetForChanges(sheet) || undefined}
                  selected={sheet.id === selectedPricingSheet?.id}
                  onClick={(): void => handleAttributeClick(sheet.id)}
                >
                  <PricingAttributesLabel
                    classes={classes}
                    pricingSheet={sheet}
                    setRef={(instance) => {
                      if (instance && !sheet?.priceSetLabel) {
                        if (!arrayRef.current[index]) {
                          arrayRef.current[index] = [];
                        }
                        arrayRef.current[index].push(instance);
                      }
                    }}
                    searchTerm={searchValue}
                  />
                  {isHiddenStyle && <img alt="Hidden price" src={hiddenPriceSrc} />}
                </AttributesButton>
              </NoMaxWidthTooltip>
            );
          })}
      </Stack>
    </div>
  );
};
