import React from 'react';
import {
  Grid2 as Grid,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  FormHelperText,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialog } from './Dialog';
import { ConfiguratorFormFields } from '../constants/FormFields';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ConfiguratorFormFieldType, ConfiguratorFormType, Product } from '../constants/Configurator';
import { AppState } from '../types/AppState';
import {
  ConfiguratorFormRadioSelectField,
  ConfiguratorFormSelectField,
  ConfiguratorFormTextField,
  ConfiguratorFormFields as ConfiguratorFormFieldsType,
  ConfiguratorFormValues,
} from '../types/Configurator';
import { I18nKeys } from '../constants/I18nKeys';
import { LoadingButton } from './LoadingButton';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog } from '../ducks/dialogSlice';
import {
  getDefaultConfiguratorFormValues,
  validateConfiguratorForm,
  mapDatasetConfigToClassic,
  addConfigurator,
  updateConfigurator,
  addGroup,
  addConfiguratorToGroup,
  updateGroup,
  getConfiguratorFormProps,
  getConfiguratorFormSelectOptions,
} from '../utils/configuratorUtils';
import { useGetSuppliersQuery, useGetTemplateVendorsQuery, useGetVendorsQuery } from '../services/clientDataApi';
import { SALESVIEW } from '../constants/App';
import { unknownGroup } from '../constants/Group';
import { fetchGroup } from '../ducks/group';
import { ConfiguratorDialogTextField } from './ConfiguratorDialogTextField';
import { ConfiguratorDialogSelectField } from './ConfiguratorDialogSelectField';
import { ConfiguratorDialogRadioSelectField } from './ConfiguratorDialogRadioSelectField';
import { titleCaseToKebabCase } from '../utils/stringUtils';
import { fetchCurrentUserData } from '../ducks/currentUserSlice';

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    '& .MuiInputBase-root': {
      borderRadius: '0px',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export const ConfiguratorDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const {
    dialog: {
      key: dialogKey = undefined,
      form: dialogForm = undefined,
      group = undefined,
      configurator = undefined,
    } = {},
    group: { group: selectedGroup = unknownGroup } = {},
  } = useAppSelector((state: AppState) => state || {});
  const form = dialogForm as ConfiguratorFormType;

  const [submitErrorMessage, setSubmitErrorMessage] = React.useState<string | undefined>(undefined);
  const [loadingMessage, setLoadingMessage] = React.useState<string | undefined>(undefined);
  const setMessage = (message: string | undefined, tokens: any) => {
    setLoadingMessage(message ? t(message, tokens) : undefined);
  };

  const [formValues, setFormValues] = React.useState<ConfiguratorFormValues>({});
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const queryClientId = React.useMemo(
    () => `${mapDatasetConfigToClassic(formValues[ConfiguratorFormFields.Configurator] as string)}-${SALESVIEW}`,
    [formValues],
  );
  const skip = !queryClientId || !dialogKey;
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useGetSuppliersQuery(
    {
      clientId: queryClientId,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      skip: skip || ![ConfiguratorFormType.AddGroupWithDefault, ConfiguratorFormType.AddNewConfigurator].includes(form),
    },
  ) as { data: { key: string; name: string }[]; isLoading: boolean };
  const { data: vendors = [], isLoading: isLoadingVendors } = useGetVendorsQuery(
    {
      clientId: queryClientId,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      skip:
        skip ||
        ![
          ConfiguratorFormType.AddExistingConfigurator,
          ConfiguratorFormType.AddGroupWithDefault,
          ConfiguratorFormType.AddNewConfigurator,
        ].includes(form),
    },
  ) as { data: { key: string; name: string }[]; isLoading: boolean };
  const { data: templateVendors = [], isLoading: isLoadingTemplates } = useGetTemplateVendorsQuery(
    {
      clientId: queryClientId,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      skip: skip || ![ConfiguratorFormType.AddGroupWithDefault, ConfiguratorFormType.AddNewConfigurator].includes(form),
    },
  ) as { data: string[]; isLoading: boolean };

  const { title, submitButtonLabel, fields } = getConfiguratorFormProps(form);
  const fieldsWithSelectOptions = getConfiguratorFormSelectOptions({
    fields,
    suppliers,
    isLoadingSuppliers,
    vendors,
    isLoadingVendors,
    templateVendors,
    isLoadingTemplates,
    t,
  });

  const onSubmit = async (values: ConfiguratorFormValues) => {
    let { groupId } = selectedGroup;
    try {
      switch (form) {
        case ConfiguratorFormType.AddGroup:
          await addGroup(values, dispatch);
          break;
        case ConfiguratorFormType.AddGroupWithDefault:
          groupId = await addGroup(values, dispatch);
          await addConfigurator(values, groupId, dispatch, setMessage);
          await addConfiguratorToGroup({ ...unknownGroup, groupId }, values, dispatch);
          break;
        case ConfiguratorFormType.EditGroup:
          if (group) await updateGroup(group, values, dispatch);
          break;
        case ConfiguratorFormType.AddNewConfigurator:
          await addConfigurator(values, groupId, dispatch, setMessage);
          await addConfiguratorToGroup(selectedGroup, values, dispatch);
          break;
        case ConfiguratorFormType.AddExistingConfigurator:
          await addConfiguratorToGroup(selectedGroup, values, dispatch);
          break;
        case ConfiguratorFormType.EditConfigurator: {
          await updateConfigurator(groupId, values, dispatch, setMessage);
          break;
        }
        default:
          break;
      }
      dispatch(closeDialog());
      setLoadingMessage(undefined);
      if (![ConfiguratorFormType.AddExistingConfigurator].includes(form)) {
        dispatch(fetchCurrentUserData());
      }
      if (groupId) dispatch(fetchGroup(groupId));
    } catch (e) {
      const { message = 'Something went wrong. Please try again.' } = e as Error;
      setSubmitErrorMessage(message);
    }
  };

  React.useEffect(() => {
    const fetchInitialFormValues = async () => {
      const initialFormValues = await getDefaultConfiguratorFormValues(fields, dispatch, {
        form,
        group,
        selectedGroupId: selectedGroup.groupId,
        configurator,
        t,
      });
      setFormValues(initialFormValues);
    };
    if (selectedGroup.groupId) {
      fetchInitialFormValues();
    }
  }, [dialogKey, group, configurator, form, selectedGroup]);

  const onChange = (event: any) => {
    const { name, id, value } = event.target;
    if (loading) return;

    const fieldName = name || id;
    const updatedFormValues = {
      ...formValues,
      ...(fieldName === ConfiguratorFormFields.Product && value === Product.Supplier
        ? { [ConfiguratorFormFields.Supplier]: '' }
        : {}),
      ...(fieldName === ConfiguratorFormFields.Product && value === Product.Dealer
        ? { [ConfiguratorFormFields.SupplierDataTemplate]: '' }
        : {}),
      ...(fieldName === ConfiguratorFormFields.Key ? { [ConfiguratorFormFields.GenerateKey]: false } : {}),
      ...(formValues[ConfiguratorFormFields.GenerateKey] && fieldName === ConfiguratorFormFields.Name
        ? { [ConfiguratorFormFields.Key]: titleCaseToKebabCase(value) }
        : {}),
      [fieldName]: value,
    };
    setFormValues(updatedFormValues);
  };

  const isHelperLabel = (field: ConfiguratorFormFieldsType) => !(field as any)?.type && (field as any)?.getHelperLabel;
  const isFormText = (field: ConfiguratorFormFieldsType) => !(field as any)?.type && (field as any)?.label;

  if (!form) return null;
  return (
    <Dialog dialogKey={Dialogs.Configurator}>
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent>
        <Grid container>
          {fieldsWithSelectOptions.map((field, i, arr) => {
            const { type, fieldName, label, getHelperLabel, getXS } = field as any;
            const xs = getXS ? getXS(form) : 12;

            const firstChild = i === 0;
            const lastChild = i === arr.length - 1;
            const prevColumns = (arr as ConfiguratorFormFieldsType[]).slice(0, i).reduce((acc, f) => {
              const width = (f as any)?.getXS(form) || 12;
              return acc + width;
            }, 0);
            const leftField = xs < 12 && !(prevColumns % 12);
            const rightField = xs < 12 && !((prevColumns + xs) % 12);
            const nextField = arr[i + (leftField ? 2 : 1)] as any;

            // Wait for the last radio select to render radio group
            if (
              type === ConfiguratorFormFieldType.RadioSelect &&
              i !== arr.length - 1 &&
              (arr[i + 1] as any)?.type === ConfiguratorFormFieldType.RadioSelect
            )
              return null;

            return (
              <Grid
                key={fieldName || i}
                size={{ xs }}
                className={classes.fieldContainer}
                style={{
                  paddingRight: leftField ? '8px' : '0px',
                  paddingLeft: rightField ? '8px' : '0px',
                  paddingTop: firstChild || isHelperLabel(field) ? '0px' : '8px',
                  paddingBottom: lastChild || isHelperLabel(nextField) || isFormText(field) ? '0px' : '8px',
                }}
              >
                {type === ConfiguratorFormFieldType.Text && (
                  <ConfiguratorDialogTextField
                    form={form}
                    formValues={formValues}
                    errors={errors}
                    field={field as ConfiguratorFormTextField}
                    onChange={onChange}
                  />
                )}
                {type === ConfiguratorFormFieldType.Select && (
                  <ConfiguratorDialogSelectField
                    form={form}
                    formValues={formValues}
                    errors={errors}
                    field={field as ConfiguratorFormSelectField}
                    onChange={onChange}
                  />
                )}
                {type === ConfiguratorFormFieldType.RadioSelect && (
                  <ConfiguratorDialogRadioSelectField
                    form={form}
                    formValues={formValues}
                    errors={errors}
                    fields={
                      arr.slice(
                        arr.slice(0, i + 1).reduce((beforeIndex, _, j) => {
                          if (j > beforeIndex && (arr[j] as any)?.type !== ConfiguratorFormFieldType.RadioSelect)
                            return j;
                          return beforeIndex;
                        }, 0) + 1,
                        i + 1,
                      ) as ConfiguratorFormRadioSelectField[]
                    }
                    onChange={onChange}
                  />
                )}
                {isFormText(field) && <Typography>{t(label)}</Typography>}
                {isHelperLabel(field) && (
                  <FormHelperText style={{ padding: '2px 0px 0px 14px' }}>{t(getHelperLabel(form))}</FormHelperText>
                )}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      {submitErrorMessage && (
        <DialogContentText className={classes.error} style={{ padding: '0px 24px' }}>
          {submitErrorMessage}
        </DialogContentText>
      )}
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => {
            setFormValues({});
            dispatch(closeDialog());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </LoadingButton>
        <LoadingButton
          loading={loading}
          onClick={async () => {
            setLoading(true);
            setErrors({});
            setSubmitErrorMessage(undefined);
            const err = await validateConfiguratorForm(fields, formValues, dispatch, form, selectedGroup.groupId);
            if (Object.keys(err).length === 0) {
              await onSubmit(formValues);
            }
            setErrors(err);
            setLoading(false);
          }}
          color="primary"
        >
          {(loading && loadingMessage) || t(submitButtonLabel)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
