import { Permission } from '@idearoom/types';
import { SystemGroups } from '../constants/SystemGroups';
import { Group } from '../types/Group';
import { GroupMember } from '../types/GroupMember';
import { User } from '../types/User';
import { filterUserPermissions } from './permissionUtils';

export const isIdeaRoomUserEmail = (email: string): boolean =>
  email.toLowerCase().endsWith('@idearoominc.com') || email.toLowerCase().endsWith('@idearoom.com');

export const isIdeaRoomUser = (user?: User | GroupMember): boolean => (user ? isIdeaRoomUserEmail(user.email) : false);

export const isIdeaRoomGroup = (groupId: string): boolean => groupId === SystemGroups.IdeaRoom;

export const isCurrentUserSuperUser = (user?: User): boolean =>
  (user !== undefined && user.permissions !== undefined && user.permissions.includes(Permission.SuperUser)) ||
  isIdeaRoomUser(user);

export const isCurrentUserAdmin = (user?: User): boolean =>
  (user !== undefined &&
    user.permissions !== undefined &&
    (user.permissions.includes(Permission.Admin) || user.permissions.includes(Permission.SuperUser))) ||
  isIdeaRoomUser(user);

export const isCurrentUserManager = (user?: User): boolean =>
  user !== undefined &&
  user.permissions !== undefined &&
  filterUserPermissions(user.permissions).includes(Permission.Manager);

export const isCurrentUserAdminOrManager = (user?: User): boolean =>
  isCurrentUserSuperUser(user) || isCurrentUserAdmin(user) || isCurrentUserManager(user);

export const isCurrentUserGroupAdmin = (user?: User, currentUserGroup?: Group, selectedGroup?: Group): boolean => {
  const isIdearoomGroup = selectedGroup !== undefined && isIdeaRoomGroup(selectedGroup.groupId);

  return (
    currentUserGroup !== undefined &&
    selectedGroup !== undefined &&
    ((currentUserGroup.groupId === selectedGroup.groupId && isCurrentUserAdmin(user)) ||
      (isIdeaRoomGroup(currentUserGroup.groupId) && !isIdearoomGroup))
  );
};

export const isCurrentUserGroupSuperUser = (user?: User, currentUserGroup?: Group, selectedGroup?: Group): boolean => {
  const isIdearoomGroup = selectedGroup !== undefined && isIdeaRoomGroup(selectedGroup.groupId);

  return (
    currentUserGroup !== undefined &&
    selectedGroup !== undefined &&
    ((currentUserGroup.groupId === selectedGroup.groupId && isCurrentUserSuperUser(user)) ||
      (isIdeaRoomGroup(currentUserGroup.groupId) && !isIdearoomGroup))
  );
};
