import React from 'react';
import { makeStyles } from '@mui/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = (fullSize: boolean, minHeight: number) =>
  makeStyles(() => ({
    accordion: {
      width: '100%',
      '&$expanded': {
        margin: '0',
        minHeight: `${minHeight}px`,
      },
      ...(fullSize
        ? {
            '&$expanded': {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              minHeight: `${minHeight}px`,
            },
            '& .MuiCollapse-wrapper': {
              height: '100%',
            },
          }
        : {}),
    },
    viewTitle: {
      margin: '0px 4px 0px 0px',
      fontWeight: '700',
      fontSize: '13px',
    },
    viewResults: {
      fontSize: '13px',
    },
    accordionSummary: {
      flexDirection: 'row-reverse',
      backgroundColor: '#F5F5F5',
      border: '1px solid #D0D0D0',
      padding: '0px 5px',
      '& .MuiAccordionSummary-content': {
        margin: '5px',
      },
    },
    expanded: {},
    accordionDetails: {
      padding: '0px',
      height: fullSize ? '100%' : '',
    },
    region: {
      height: fullSize ? '100%' : '',
    },
    collapse: {
      height: fullSize ? '100% !important' : '',
    },
  }))();

type Props = {
  hideExpand?: boolean;
  expanded: boolean;
  handleExpand: () => void;
  title: string;
  resultCount: number;
  children?: any;
  fullSize?: boolean;
  minHeight?: number;
};

export const DataGridAccordion: React.FC<Props> = ({
  hideExpand = true,
  expanded,
  handleExpand,
  title,
  resultCount,
  children,
  fullSize = false,
  minHeight = 0,
}) => {
  const classes = useStyles(fullSize, minHeight);

  return hideExpand ? (
    <div>{children}</div>
  ) : (
    <Accordion
      disableGutters
      elevation={0}
      square
      TransitionProps={{ unmountOnExit: true, className: classes.collapse }}
      classes={{ root: classes.accordion, expanded: classes.expanded, region: classes.region }}
      expanded={expanded}
      onChange={() => handleExpand()}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        style={{ minHeight: '30px' }}
        expandIcon={<ExpandMoreIcon style={{ fontSize: '24px' }} />}
      >
        <Typography className={classes.viewTitle}>{title}</Typography>
        <Typography className={classes.viewResults}>{`(${resultCount})`}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};
