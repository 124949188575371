import React from 'react';
import { ListItemIcon, MenuItem } from '@mui/material';

type Props = {
  selected?: boolean;
  checked?: boolean;
  checkIcon?: React.ReactNode;
  children?: React.ReactNode;
} & React.ComponentProps<typeof MenuItem>;

export const CheckMenuItem: React.FC<Props> = ({ selected, checked, checkIcon, children, ...otherProps }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MenuItem selected={selected} {...otherProps}>
    {checkIcon && <ListItemIcon style={!checked ? { visibility: 'hidden' } : {}}>{checkIcon}</ListItemIcon>}
    {children}
  </MenuItem>
);
