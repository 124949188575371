import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { IconButton } from '@mui/material';
import { DropdownArrow } from '../images/DropdownArrow';

export const ClientDataLookupCell: React.FC<ICellRendererParams> = ({
  valueFormatted,
  api,
  node,
  column,
}: ICellRendererParams) => (
  <>
    {valueFormatted}
    <IconButton
      className="ag-grid-dropdown-cell-button"
      style={{ padding: '0px', position: 'absolute', right: '0', top: '0' }}
      onClick={() => {
        const { rowIndex } = node;
        if (column && rowIndex !== null) {
          api.startEditingCell({ colKey: column.getColId(), rowIndex });
          /*
            FIXME: For some reason, a single call to `startEditingCell` inside a button in a custom cell renderer doesn't work.
            This works perfectly on a non-button element but this is discouraged, so I found a workaround which is calling 
            `startEditingCell` twice and the second one wrapped on a `setTimeout` so it gets triggered on the next frame only.
           */
          setTimeout(() => {
            api.startEditingCell({ colKey: column.getColId(), rowIndex });
          }, 0);
        }
      }}
    >
      <DropdownArrow />
    </IconButton>
  </>
);
