import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Hidden } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { setWhatsNewDialog } from '../ducks/settings';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { sortRows } from '../utils/sortUtils';
import { WhatsNewMenu } from './WhatsNewMenu';
import { Header, Table } from './Table';
import { TableControls } from './TableControls';
import { WhatsNew } from '../types/WhatsNew';
import { getDateAsNumbers } from '../utils/dateUtils';
import { MessageCell } from './MessageCell';
import { CellContent } from './CellContent';

type CellProps = {
  row: WhatsNew;
};

const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
const formatterNoYear = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });

// correct syntax https://v4.mui.com/styles/basics/#adapting-the-styled-components-api
const DateCellContent = styled(CellContent)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
});

const DateCell: React.FC<CellProps> = ({ row }: CellProps) => {
  const date = new Date(row.date);

  return (
    <DateCellContent disabled={row.readonly}>
      <Box>
        {date.getFullYear() === new Date().getFullYear() ? formatterNoYear.format(date) : formatter.format(date)}
      </Box>
    </DateCellContent>
  );
};

const TitleCellContent = styled(CellContent)({
  whiteSpace: 'nowrap',
});

const TitleCell: React.FC<CellProps> = ({ row }: CellProps) => (
  <TitleCellContent disabled={row.readonly}>{row.title}</TitleCellContent>
);

interface WhatsNewActionCellProps {
  row: WhatsNew;
  showWhatsNewMenu: boolean;
}

const getWhatsNewActionComponent = (): React.FC<any> => {
  const UserActionCell: React.FC<WhatsNewActionCellProps> = ({
    row,
    showWhatsNewMenu = true,
  }: WhatsNewActionCellProps) => {
    const whatsNew = row as WhatsNew;
    const { updating } = whatsNew;
    return (
      <div style={{ display: 'flex' }}>
        {updating && <CircularProgress size={24} color="primary" />}

        {!updating && showWhatsNewMenu && <WhatsNewMenu whatsNew={whatsNew} />}
      </div>
    );
  };

  return UserActionCell;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { flex: 1 },
  button: {
    color: theme.palette.text.primary,
  },
  whatsNewCards: {
    flex: 1,
    paddingBottom: '70px',
  },
  whatsNewCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  whatsNewCardHeader: { paddingBottom: '0' },
  whatsNewCardActions: { paddingTop: '0' },
  whatsNewCardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  whatsNewCardContentLine: {
    color: theme.palette.text.secondary,
  },
}));

interface OwnProps {
  loading: boolean;
  clientId: string;
  whatsNew: WhatsNew[];
}

interface DispatchProps {
  openWhatsNewDialog(clientId: string, whatsNew: WhatsNew): void;
}

type Props = OwnProps & DispatchProps;

const WhatsNewTableComponent: React.FC<Props> = ({ clientId, openWhatsNewDialog, loading, whatsNew }: Props) => {
  const classes = useStyles();
  const [sortState, setSortState] = React.useState<SortProperty[]>([]);
  const sortableProperties: SortableProperty[] = [
    { i18nKey: 'Date', property: 'date' },
    { i18nKey: 'Title', property: 'title' },
  ];

  const headers: Header[] = [
    { i18nKey: 'Date', property: 'date', CellComponent: DateCell },
    { i18nKey: 'Title', property: 'title', CellComponent: TitleCell },
    { i18nKey: 'Message', property: 'message', CellComponent: MessageCell },
    { i18nKey: '', property: 'actions', CellComponent: getWhatsNewActionComponent() },
  ];

  const handleSort = (sortProperties: SortProperty[]): void => {
    sortRows(whatsNew, sortProperties);
    setSortState(sortProperties);
  };

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Table
          headers={headers}
          sortProperties={sortState}
          sortableProperties={sortableProperties}
          onSort={handleSort}
          rows={whatsNew}
          loading={loading}
          handleRowClick={(row): void => openWhatsNewDialog(clientId, row)}
        />
      </Hidden>
      <Hidden smUp>
        <>
          <TableControls sortProperties={sortState} sortableProperties={sortableProperties} onSort={handleSort} />
          <div className={classes.whatsNewCards}>
            {whatsNew &&
              whatsNew.length > 0 &&
              whatsNew.map((whatsnew) => {
                const WhatsNewActionComponent = getWhatsNewActionComponent();
                return (
                  <Card className={classes.whatsNewCard} key={whatsnew.id}>
                    <CardHeader
                      className={classes.whatsNewCardHeader}
                      action={<WhatsNewMenu whatsNew={whatsnew} />}
                      title={getDateAsNumbers(whatsnew.date)}
                    />
                    <CardContent className={classes.whatsNewCardContent}>
                      <Box className={classes.whatsNewCardContentLine}>{whatsnew.title}</Box>
                    </CardContent>
                    <CardContent className={classes.whatsNewCardContent}>
                      <Box className={classes.whatsNewCardContentLine}>{whatsnew.message}</Box>
                    </CardContent>
                    <CardActions disableSpacing className={classes.whatsNewCardActions}>
                      <WhatsNewActionComponent row={whatsnew} showWhatsNewMenu={false} />
                    </CardActions>
                  </Card>
                );
              })}
          </div>
        </>
      </Hidden>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openWhatsNewDialog: (clientId: string, whatsNew: WhatsNew): void => {
    dispatch(setWhatsNewDialog(clientId, whatsNew));
    dispatch(openDialog({ dialog: Dialogs.WhatsNew }));
  },
});

export const WhatsNewTable = connect(undefined, mapDispatchToProps)(WhatsNewTableComponent);
