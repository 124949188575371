import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { PreviewDialog } from './PreviewDialog';
import { Dialogs } from '../constants/Dialogs';
import { usePricingRepo } from '../hooks/usePricingRepo';

export const PricingSurchargePreviewDialog: React.FC = () => {
  const { t } = useTranslation();
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });
  const branch = activeBranches.find((metadata) => metadata.branchType === ClientDataBranch.PricingSurcharge);

  return (
    <PreviewDialog
      dialogKey={Dialogs.PricingSurchargePreview}
      dialogTitle={t(I18nKeys.PricingSurchargePreviewConfirmationTitle)}
      dialogMessage={t(I18nKeys.PricingBasePreviewConfirmationMessage)}
      dialogConfirmationButton={t(I18nKeys.PricingBasePreviewConfirmationButton)}
      branch={branch}
      clientBranch={ClientDataBranch.PricingSurcharge}
      dataType={ClientDataType.Vendor}
    />
  );
};
