import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { openSalesViewWhatsNewSuccess, removeWhatsNew as removeWhatsNewAction } from '../ducks/settings';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { WhatsNew } from '../types/WhatsNew';
import { disabled } from '../theme';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';

interface OwnProps {
  whatsNew: WhatsNew;
}

interface DispatchProps {
  previewWhatsNew(whatsNew: WhatsNew): void;
  confirmRemoval(confirmAction: AnyAction, title: string, description: string): void;
}

type Props = OwnProps & DispatchProps;

const WhatsNewMenuComponent: React.FC<Props> = ({ whatsNew, previewWhatsNew, confirmRemoval }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="group-whats-new-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="whats-new-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemText
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              previewWhatsNew(whatsNew);
              handleClose(event);
            }}
            primary={t(I18nKeys.WhatsNewPreview)}
          />
        </MenuItem>
        <MenuItem>
          <ListItemText
            primaryTypographyProps={whatsNew.readonly ? { style: { opacity: disabled } } : {}}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              if (!whatsNew.readonly) {
                confirmRemoval(removeWhatsNewAction(whatsNew), '', t(I18nKeys.WhatsNewConfirmationDialogDescription));
                handleClose(event);
              }
            }}
            primary={t(I18nKeys.DialogDeleteButton)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  previewWhatsNew: (whatsNew: WhatsNew): void => {
    dispatch(openSalesViewWhatsNewSuccess([whatsNew]));
    dispatch(openDialog({ dialog: Dialogs.WhatsNewSalesView }));
  },
  confirmRemoval: (confirmAction: AnyAction, confirmationTitle: string, description: string): void => {
    dispatch(
      openConfirmationDialog([confirmAction], [], confirmationTitle, description, i18n.t(I18nKeys.DialogDeleteButton)),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  },
});

export const WhatsNewMenu = connect(undefined, mapDispatchToProps)(WhatsNewMenuComponent);
