import { Header } from '../components/Table';
import { I18nDialog } from '../types/I18n';
import { SortableProperty } from '../types/SortableProperty';
import { I18nKeys } from './I18nKeys';

export const i18nKeyColumnHeader: Header = {
  i18nKey: I18nKeys.TableHeaderKey,
  property: 'key',
  cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
};

export const i18nOriginalTextHeader: Header = {
  i18nKey: I18nKeys.TableHeaderOriginalText,
  property: 'value',
  cellStyle: { whiteSpace: 'nowrap', maxWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis' },
};

export const i18nOverrideTextHeader: Header = {
  i18nKey: I18nKeys.TableHeaderOverrideText,
  property: 'override',
  cellStyle: { whiteSpace: 'nowrap', maxWidth: '50px', overflow: 'hidden', textOverflow: 'ellipsis' },
};

export const i18nValueHeader: Header = {
  i18nKey: I18nKeys.TableHeaderValue,
  property: 'override',
  // cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
};

export const i18nSortableProperties: SortableProperty[] = [
  { i18nKey: I18nKeys.TableHeaderKey, property: 'key' },
  { i18nKey: I18nKeys.TableHeaderOriginalText, property: 'value' },
];

export const unknownI18nDialog: I18nDialog = {
  clientId: undefined,
  language: undefined,
  key: '',
  value: '',
  override: undefined,
  updating: false,
};

export const defaultI18nNamespace = 'translation';
