import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { reset, formValueSelector } from 'redux-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { ClientDataNoteForm, FormData } from './ClientDataNoteForm';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CellMetadataProperty, KeyboardKeys } from '../constants/ClientData';
import { AppState } from '../types/AppState';
import { getPropertyFromCellMetadata, hasCellMetadataProperty } from '../utils/clientDataUtils';
import { ClientDataNoteDialogFormFields } from '../constants/FormFields';

import { ClientDataBranch } from '../constants/ClientDataBranch';
import { CellMetadata } from '../types/ClientData';
import { UpdateClientDataMetadata } from '../ducks/clientDataSlice';

type Props = {
  branch?: ClientDataBranch;
  cellMetadata: CellMetadata[];
  saveNote: (updates: UpdateClientDataMetadata[]) => void;
  dialogKey: Dialogs;
};

export const NoteDialog: React.FC<Props> = (props: Props) => {
  const { branch, cellMetadata, saveNote, dialogKey } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { cellNote: { rowIds = [], colIds = [] } = {} } = useAppSelector((state: AppState) => state.clientData || {});
  const { key: selectedDialogKey } = useAppSelector((state) => state?.dialog);
  const currentNote = useAppSelector((state: AppState) =>
    formValueSelector(Forms.ClientDataNote)(state, ClientDataNoteDialogFormFields.Note),
  );

  const [rowId] = rowIds;
  const [colId] = colIds;
  const noteExists = hasCellMetadataProperty(cellMetadata, rowId, colId, CellMetadataProperty.Note);
  const initialNote = noteExists
    ? getPropertyFromCellMetadata(cellMetadata, rowId, colId, CellMetadataProperty.Note)
    : '';
  const initialValues = {
    [ClientDataNoteDialogFormFields.Note]: initialNote,
  } as FormData;

  const onSaveNote = (): void => {
    if (branch) {
      saveNote(
        rowIds.flatMap((r) =>
          colIds.map((c) => ({
            cellsMetadata: cellMetadata,
            rowId: r,
            colId: c,
            metadataProperty: CellMetadataProperty.Note,
            value: currentNote,
          })),
        ),
      );
    }
    dispatch(closeDialogFunc());
  };

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === KeyboardKeys.Enter && selectedDialogKey === dialogKey) {
        onSaveNote();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Dialog dialogKey={dialogKey}>
      <DialogTitle>
        {t(noteExists ? I18nKeys.ClientDataEditNoteDialogTitle : I18nKeys.ClientDataAddNoteDialogTitle)}
      </DialogTitle>
      <DialogContent>
        <ClientDataNoteForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(reset(Forms.ClientDataNote));
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSaveNote} color="primary" disabled={currentNote === initialNote}>
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
