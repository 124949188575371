import { SALESVIEW } from '../constants/App';
import { salesViewConfigurator } from '../constants/Configurator';
import { clientSupplierDelimiter } from '../constants/PricingSurcharge';
import { ClientDataType } from '../constants/ClientDataType';
import { Configurator } from '../types/Configurator';
import { SystemGroups } from '../constants/SystemGroups';
import { PricingSheetTableAgGrid } from '../constants/PricingBase';

export const SHEDVIEW = 'shedview';
export const CARPORTVIEW = 'carportview';
export const IDEAROOM = 'idearoom';

export const IDEAROOM_CLIENT_ID = `${SystemGroups.IdeaRoom.toLowerCase()}-${ClientDataType.Reference}`;
/**
 * Maps a clientId to human readable product name
 *
 * @param clientId
 */
export const mapClientIdToProduct = (clientId: string): string => {
  if (clientId.startsWith(SHEDVIEW)) {
    return 'Sheds';
  }
  if (clientId.startsWith(CARPORTVIEW)) {
    return 'Carports';
  }
  if (clientId.startsWith(IDEAROOM)) {
    return 'Shared';
  }
  throw new Error(`Unsupported clientId: ${clientId}`);
};

/**
 * Maps clientKey and product to a clientId string
 *
 * @param clientKey
 * @param product
 */
export const mapClientKeyAndProductToClientId = (clientKey: string, product: string): string => {
  if (product.toLowerCase() === 'sheds') {
    return `${SHEDVIEW}-${clientKey}`;
  }
  if (product.toLowerCase() === 'carports') {
    return `${CARPORTVIEW}-${clientKey}`;
  }
  if (product.toLowerCase() === 'shared') {
    return `${IDEAROOM}-${clientKey}`;
  }
  throw new Error(`Unsupported product: ${product}`);
};

/**
 * Maps a Configurator object to a clientId string
 *
 * @param configurator
 */
export const mapConfiguratorToClientId = (configurator?: Pick<Configurator, 'key' | 'vendor'>): string =>
  configurator ? `${configurator.key}-${configurator.vendor}` : '';

/**
 * Maps a clientId string to the configurator and vendor it represents
 *
 * @param clientId
 */
export const mapClientIdToConfiguratorAndVendor = (clientId: string): { configurator: string; vendor: string } => {
  if (clientId.startsWith(SHEDVIEW)) {
    return {
      configurator: SHEDVIEW,
      vendor: clientId.substring(9),
    };
  }
  if (clientId.startsWith(CARPORTVIEW)) {
    return {
      configurator: CARPORTVIEW,
      vendor: clientId.substring(12),
    };
  }
  if (clientId.startsWith(IDEAROOM)) {
    return {
      configurator: IDEAROOM,
      vendor: clientId.substring(9),
    };
  }
  throw new Error(`Unsupported clientId: ${clientId}`);
};

/**
 * Maps a clientId string to the configurator it represents
 *
 * @param clientId
 * @param configurator
 */
export const getConfiguratorFromClientId = (clientId?: string): Configurator | undefined => {
  if (!clientId) {
    return undefined;
  }

  const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);

  return {
    key: configurator,
    vendor,
    clientId,
    updating: false,
  };
};

/**
 * Checks if clientId is for CarportView
 *
 * @param clientId
 */
export const isCarportView = (clientId?: string): boolean => (clientId ? clientId.startsWith(CARPORTVIEW) : false);

/**
 * Converts a clientId to vendor name
 *
 * @param clientId
 */
export const getVendorFromClientId = (clientId?: string): string => {
  if (!clientId) {
    return '';
  }

  if (
    clientId.indexOf('-') === -1 ||
    [SHEDVIEW, CARPORTVIEW, IDEAROOM, SALESVIEW].every((product) => !clientId.startsWith(product))
  ) {
    return clientId;
  }

  // Split the clientId by '-'
  // Remove the first portion of the clientId ('shedview' or 'carportview')
  // The join them back together in case there are clientId's with a '-' (ir-atomic-buildings)
  return clientId.split('-').slice(1).join('-');
};

/**
 * Converts a clientId to site/configurator name
 *
 * @param clientId
 * @param configurators
 */
export const getSiteFromClientId = (clientId?: string, configurators: Configurator[] = []): string | undefined => {
  if (!clientId) {
    return '';
  }

  let vendor: string;
  if (clientId.startsWith(SHEDVIEW)) {
    vendor = clientId.substring(9);
  } else if (clientId.startsWith(CARPORTVIEW)) {
    vendor = clientId.substring(12);
  } else if (clientId.startsWith(IDEAROOM)) {
    vendor = clientId.substring(9);
  } else {
    return clientId;
  }

  if (configurators.length < 1) {
    return vendor;
  }

  const matchingConfigurator = configurators.find((configurator) => configurator.vendor === vendor);
  if (matchingConfigurator) {
    return matchingConfigurator.name ? matchingConfigurator.name : vendor;
  }
  return vendor;
};

/**
 * Converts clientId and supplierKey to clientSupplier
 *
 * @param {string} clientId
 * @param {string} supplierKey
 * @return {string} clientId and supplierKey joined with a `:`
 */
export const mapClientAndSupplierToClientSupplier = (clientId?: string, supplierKey?: string): string => {
  if (!clientId) {
    return '';
  }

  if (!supplierKey) {
    return clientId;
  }

  return `${clientId}${clientSupplierDelimiter}${supplierKey}`;
};

/**
 * Converts clientId and clientDataType to clientDataId
 *
 * @param {string} clientId
 * @param {ClientType} clientDataType
 * @return {string} clientId and clientDataType joined with a `:`
 */
export const mapClientAndDataTypeToClientDataId = (clientId?: string, clientDataType?: ClientDataType): string => {
  if (!clientId) return '';
  if (!clientDataType) return clientId;
  return `${clientId}:${clientDataType}`;
};

/**
 * Gets clientId and clientDataType from clientDataId
 *
 * @param {string} clientDataId
 * @return {{clientId: string, clientDataType: ClientType}} clientId and clientDataType
 */
export const getClientAndDataTypeFromClientDataId = (
  clientDataId?: string,
): { clientId: string; clientDataType: ClientDataType } => {
  if (!clientDataId) return { clientId: '', clientDataType: ClientDataType.Supplier };
  const [clientId, clientDataType] = clientDataId.split(':');
  return { clientId, clientDataType: clientDataType as ClientDataType };
};

/**
 * Converts clientId, clientDataType, and table to undoStackId
 *
 * @param {string} clientId
 * @param {ClientType} clientDataType
 * @param {string} table
 * @return {string} clientId, clientDataType, and table joined with a `:`
 */
export const mapClientAndDataTypeAndTableToUndoStackId = (
  clientId?: string,
  clientDataType?: ClientDataType,
  table?: string,
): string => {
  const clientDataId = mapClientAndDataTypeToClientDataId(clientId, clientDataType);
  if (!table) return clientDataId;
  return `${clientDataId}:${table}`;
};

/**
 * Converts clientId, and selectedPricingSheetId to undoStackId
 *
 * @param {string} clientId
 * @param {string} selectedPricingSheetId
 * @return {string} PricingSheetTableAgGrid, clientId, and selected price id joined with a `:`
 */
export const mapClientAndPricingSheetToUndoStackId = (clientId?: string, selectedPricingSheetId?: string): string =>
  `${PricingSheetTableAgGrid}:${clientId}:${selectedPricingSheetId}`;

/**
 * Gets the product from a clientDataId. Returns undefined if no product is found.
 *
 * @param {string} clientDataId clientId:product
 * @returns {string} product
 */
export const getProductFromClientDataId = (clientDataId?: string): string | undefined => {
  if (!clientDataId) return undefined;
  const [_, product] = clientDataId.split(':');
  if (!product) return undefined;
  return product;
};

/**
 * Gets clientId from clientSupplier
 *
 * @param {string} clientSupplier
 * @return {string} clientId
 */
export const getClientIdFromClientSupplier = (clientSupplier?: string): string => {
  if (!clientSupplier) {
    return '';
  }

  return clientSupplier.split(clientSupplierDelimiter)[0];
};

/**
 * Gets supplierKey from clientSupplier
 *
 * @param {string} clientSupplier
 * @return {string} supplierKey
 */
export const getSupplierKeyFromClientSupplier = (clientSupplier?: string): string => {
  if (!clientSupplier) {
    return '';
  }

  return clientSupplier.split(clientSupplierDelimiter)[1] || '';
};

/**
 * Gets a Configurator object based on the groupId for the SalesView configurator app bar
 * @param {string} groupId
 * @return {Configurator} Configurator object
 */
export const getGroupSalesViewConfigurator = (groupId?: string): Configurator => {
  if (!groupId) {
    return salesViewConfigurator;
  }
  return {
    clientId: `${SALESVIEW}-${groupId}`,
    name: 'SalesView',
    key: `${SALESVIEW}-${groupId}`,
    vendor: `${SALESVIEW}-${groupId}`,
    vendorData: { clientId: `${SALESVIEW}-${groupId}` },
    updating: false,
  };
};

/**
 * Maps a configurator to a configurator supplier
 * @param {Configurator[]} configurators
 * @returns {string} configurator supplier
 */
export const configuratorToConfiguratorSupplier = (configurators: Configurator[]): string[] =>
  configurators.reduce((previous, current) => {
    if (current.suppliers && current.suppliers.length > 0) {
      return previous.concat(
        current.suppliers.map((supplier) =>
          mapClientAndSupplierToClientSupplier(current.clientId, supplier.supplierKey),
        ),
      );
    }
    return previous.concat(`${current.clientId}`);
  }, [] as string[]);
