import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { AppState } from '../types/AppState';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';

interface DispatchProps {
  confirmAction(
    pendingConfirmationActions: AnyAction[],
    pendingConfirmationFunctions: Function[],
    keepDialogOpen: boolean,
  ): void;
  closeDialog(): void;
}

interface StateProps {
  pendingConfirmationActions: AnyAction[];
  pendingConfirmationFunctions: Function[];
  confirmationTitle: string;
  confirmationDescription: string;
  confirmationButtonText: string;
  cancelButtonText: string;
  keepDialogOpen: boolean;
  id: string;
}

type Props = StateProps & DispatchProps;

const ConfirmationComponent: React.FC<Props> = ({
  pendingConfirmationActions,
  pendingConfirmationFunctions,
  confirmationTitle,
  confirmationDescription,
  confirmationButtonText,
  cancelButtonText,
  keepDialogOpen,
  id,
  confirmAction,
  closeDialog,
}: Props) => (
  <Dialog
    dialogKey={Dialogs.Confirmation}
    aria-labelledby="confirmation-dialog-title"
    aria-describedby="confirmation-dialog-description"
  >
    {confirmationTitle && <DialogTitle id="confirmation-dialog-title">{confirmationTitle}</DialogTitle>}
    <DialogContent>
      <DialogContentText
        id="confirmation-dialog-description"
        dangerouslySetInnerHTML={{
          __html: confirmationDescription,
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="primary" id={`${id}-confirmation-dialog-cancel-button`}>
        {cancelButtonText}
      </Button>
      <Button
        onClick={(): void => {
          confirmAction(pendingConfirmationActions, pendingConfirmationFunctions, keepDialogOpen);
        }}
        color="primary"
        autoFocus
        id={`${id}-confirmation-dialog-confirm-button`}
      >
        {confirmationButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  confirmAction: (
    pendingConfirmationActions: AnyAction[],
    pendingConfirmationFunctions: Function[],
    keepDialogOpen: boolean,
  ): void => {
    if (pendingConfirmationFunctions.length > 0) pendingConfirmationFunctions.forEach((func) => func());
    if (pendingConfirmationActions.length > 0) pendingConfirmationActions.forEach((action) => dispatch(action));
    if (!keepDialogOpen) dispatch(closeDialogFunc());
  },
  closeDialog: (): void => {
    dispatch(closeDialogFunc());
  },
});

const mapStateToProps = ({
  confirmation: {
    pendingConfirmationFunctions,
    pendingConfirmationActions,
    confirmationTitle,
    confirmationDescription,
    confirmationButtonText,
    cancelButtonText,
    keepDialogOpen,
    id,
  },
}: AppState): StateProps => ({
  pendingConfirmationFunctions,
  pendingConfirmationActions,
  confirmationTitle,
  confirmationDescription,
  confirmationButtonText,
  cancelButtonText,
  keepDialogOpen,
  id,
});

export const ConfirmDialog = connect(mapStateToProps, mapDispatchToProps)(ConfirmationComponent);
