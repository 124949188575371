import { DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector, submit } from 'redux-form';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LoadingButton } from './LoadingButton';
import { unknownUser } from '../types/User';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { ClientDataPublishForm, FormData } from './ClientDataPublishForm';
import { Forms } from '../constants/Forms';
import { ClientDataPublishFields } from '../constants/FormFields';
import {
  useGetClientPublishedVersionsQuery,
  usePublishClientDataMutation,
  useSaveBranchMetadataMutation,
} from '../services/clientDataApi';
import {
  setChangeSummaryOpen,
  setClientDataBranch,
  setPublishMergeResult,
  setVendorsToPublish,
} from '../ducks/clientDataSlice';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { CELL_METADATA_TABLE, MergeStatus } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { ClientDataPublishDialogStripes } from './ClientDataPublishDialogStripes';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataSuppliersVersionBumpList } from './ClientDataSuppliersVersionBumpList';
import { unknownGroup } from '../constants/Group';
import { Environment } from '../constants/Environment';
import { config } from '../config/config';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
});

export const ClientDataPublishDialog: React.FC = () => {
  const [publishClientData, { isLoading: isPublishing }] = usePublishClientDataMutation();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [saveBranchMetadata] = useSaveBranchMetadataMutation();
  const environment = config.environment.STAGE || 'development';

  const { user = unknownUser } = useAppSelector((state) => state?.currentUser);
  const {
    clientDataBranch = ClientDataBranch.Main,
    clientDataType,
    clientId,
  } = useAppSelector((state) => state?.clientData);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const {
    authors = [],
    changedTables = [],
    description = '',
  } = activeBranches.find((metadata) => metadata.branchType === clientDataBranch) || {};

  const { currentData: clientPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, groupId, dataType: clientDataType },
    {
      skip: !clientId || !clientDataType || clientDataType !== ClientDataType.Vendor,
    },
  );

  const displayTablesAsNumber = changedTables.length > 3;
  const displayAuthorsAsNumber = authors.length > 3;
  const localizedAuthors = authors.map((authorName) =>
    authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
  );
  const tableLabels = changedTables.filter((table) => table !== CELL_METADATA_TABLE).map(compoundCaseToTitleCase);
  const metadataChanges = changedTables.includes(CELL_METADATA_TABLE);

  const canSubmit = useAppSelector(
    (state) =>
      formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.PreviewedChanges) &&
      formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.CustomerIsAware) &&
      formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.VerifiedQuotes) &&
      (formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.Description) || '').trim(),
  );

  const formDataDescription = useAppSelector((state) =>
    formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.Description),
  );

  const vendorsToPublish = useAppSelector((state) =>
    formValueSelector(Forms.ClientDataPublish)(state, ClientDataPublishFields.PublishVendors),
  );

  const saveBranchDescription = () => {
    if (formDataDescription !== description) {
      saveBranchMetadata({
        branch: clientDataBranch,
        dataType: clientDataType,
        clientId,
        description: formDataDescription,
        groupId,
      });
    }
  };

  return (
    <Dialog
      dialogKey={Dialogs.ClientDataPublish}
      maxWidth="sm"
      disableClose={isPublishing}
      onClosed={saveBranchDescription}
    >
      <ClientDataPublishDialogStripes isPublishing={isPublishing} />
      <DialogContent>
        <Typography gutterBottom>
          <Trans
            i18nKey={I18nKeys.ClientDataPublishDialogText as string}
            values={{
              branch: clientDataBranch ? clientDataBranch.charAt(0).toUpperCase() + clientDataBranch.slice(1) : '',
              authors: displayAuthorsAsNumber
                ? t(I18nKeys.ClientDataRevertBranchDialogTextAuthorsMultiple, { count: authors.length })
                : t(I18nKeys.ClientDataRevertBranchDialogTextAuthors, {
                    authors: localizedAuthors,
                    count: authors.length,
                  }),
              changedTables: displayTablesAsNumber
                ? t(I18nKeys.ClientDataRevertBranchDialogTextChangedTablesMultiple, { count: changedTables.length })
                : t(I18nKeys.ClientDataRevertBranchDialogTextChangedTables, {
                    changedTables: tableLabels,
                    count: changedTables.length,
                  }),
              metadataChanges: metadataChanges ? t(I18nKeys.ClientDataRevertBranchDialogTextMetadataChanges) : '',
            }}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <Typography gutterBottom component="div">
          {clientPublishedVersions && (
            <ClientDataSuppliersVersionBumpList clientPublishedVersions={clientPublishedVersions} />
          )}
        </Typography>
        <Typography gutterBottom>
          <Trans
            i18nKey={
              (environment === Environment.Production
                ? I18nKeys.ClientDataPublishDialogChecklistText
                : I18nKeys.ClientDataPublishDialogNotProductionText) as string
            }
          />
        </Typography>
        <ClientDataPublishForm
          initialValues={{
            [ClientDataPublishFields.Description]: description,
            [ClientDataPublishFields.PublishVendors]: [],
            [ClientDataPublishFields.PreviewedChanges]: environment !== Environment.Production,
            [ClientDataPublishFields.CustomerIsAware]: environment !== Environment.Production,
            [ClientDataPublishFields.VerifiedQuotes]: environment !== Environment.Production,
          }}
          isPublishing={isPublishing}
          onSubmit={(formData: FormData) => {
            if (clientDataBranch) {
              publishClientData({
                dataType: clientDataType,
                branch: clientDataBranch,
                clientId,
                groupId,
                message: formData[ClientDataPublishFields.Description],
              })
                .unwrap()
                .then((data) => {
                  if (data.mainMerge.status === MergeStatus.Succeed) {
                    dispatch(setClientDataBranch(ClientDataBranch.Main));
                    dispatch(setVendorsToPublish(vendorsToPublish || []));
                  }
                  dispatch(setPublishMergeResult({ data, isSuccess: true, error: undefined }));
                })
                .catch((error) => {
                  dispatch(setPublishMergeResult({ data: undefined, isSuccess: false, error }));
                })
                .finally(() => {
                  dispatch(closeDialogFunc());
                  dispatch(openDialog({ dialog: Dialogs.ClientDataPublishResult }));
                });
            }
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isPublishing}
          className={classes.firstButton}
          color="primary"
          onClick={() => {
            dispatch(closeDialogFunc());
            dispatch(setChangeSummaryOpen(true));
          }}
        >
          {t(I18nKeys.ClientDataRevertBranchDialogChangesButton)}
        </Button>
        <Button
          disabled={isPublishing}
          onClick={(): void => {
            saveBranchDescription();
            dispatch(closeDialogFunc());
          }}
          color="primary"
          id="site-data-publish-dialog-cancel-button"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            dispatch(submit(Forms.ClientDataPublish));
          }}
          loading={isPublishing}
          disabled={!canSubmit || isPublishing}
          color="primary"
          id="site-data-publish-dialog-publish-button"
        >
          {t(I18nKeys.ClientDataPublishButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
