import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { IntegrationStatus } from '../constants/IntegrationStatus';

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/integration',
  }),
  endpoints: (builder) => ({
    /**
     * Gets payment integration status
     */
    getPaymentIntegration: builder.query<
      { status: IntegrationStatus; message?: string },
      {
        groupId: string;
        clientIds: string[];
      }
    >({
      query: ({ groupId, clientIds }) => ({
        url: `/payment`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId }),
          queryStringParameters: {
            clientIds: clientIds.join(','),
          },
        },
        displayToastOnError: false,
      }),
    }),

    /**
     * Post a request to start the process to create the payment integration
     */
    createPaymentIntegration: builder.query<
      { onboardingUrl?: string },
      {
        groupId: string;
        clientIds: string[];
      }
    >({
      query: ({ groupId, clientIds }) => ({
        url: `/payment`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: {
            clientIds,
          },
        },
      }),
    }),
  }),
});

export const { useGetPaymentIntegrationQuery, useCreatePaymentIntegrationQuery } = integrationApi;
