import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Skeleton } from '@mui/material';
import { GridData } from '../types/DataGrid';
import { ClientSearchResults } from './ClientSearchResults';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchGroups } from '../ducks/groups';
import { getClientSearchData } from '../utils/searchUtils';

interface Props {
  searchData: { [vendor: string]: GridData };
  refs: {
    search: React.RefObject<HTMLDivElement>;
    findAll: React.RefObject<HTMLDivElement>;
    rootGrid: React.RefObject<AgGridReact>;
  };
}

export const ClientDataSearchFindAll: React.FC<Props> = ({ searchData, refs }) => {
  const { clientId: mainClientId, search: { result: { ids: resultIds = [], loading = false } = {} } = {} } =
    useAppSelector((state) => state.clientData);
  const dispatch = useAppDispatch();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [expandedView, setExpandedView] = React.useState<string>('');
  const [uniqueClientIds, setUniqueClientIds] = React.useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [mainClientId]);

  useEffect(() => {
    setUniqueClientIds(Array.from(new Set(resultIds.map((id) => id.split(':')[0]).filter(Boolean))));
  }, [resultIds]);

  const getMaxTableHeight = (sectionCount = 0): string => {
    if (!refs.findAll.current) return '0px';
    // Set height of container to fill remaining space
    // Subtract top bar, table bar, status bar, each grid view accordion and padding
    return `calc(${refs.findAll.current?.style.maxHeight} - ${32 * sectionCount + 16}px)`;
  };

  return loading ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={`calc(${getMaxTableHeight()} * 0.7)`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} // Match filled TextField background
    />
  ) : (
    <div ref={containerRef}>
      {uniqueClientIds.map((clientId) => (
        <ClientSearchResults
          key={clientId}
          clientId={clientId}
          searchData={getClientSearchData(searchData, clientId)}
          expandedView={expandedView}
          setExpandedView={setExpandedView}
          getMaxTableHeight={() => {
            const tableCount = Object.keys(searchData).reduce(
              (count, id) => count + Object.keys(searchData[id]).length,
              0,
            );
            return getMaxTableHeight(tableCount);
          }}
          refs={refs}
        />
      ))}
    </div>
  );
};
