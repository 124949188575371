import React, { Fragment } from 'react';
import { Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { unknownUser } from '../types/User';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { CELL_METADATA_TABLE } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useGetClientPublishedVersionsQuery } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useGetClientName } from '../hooks/useGetClientName';
import { ClientDataBranchChip } from './ClientDataBranchChip';
import { unknownGroup } from '../constants/Group';

const useStyles = makeStyles((theme: Theme) => ({
  tooltipText: {
    lineHeight: theme.typography.body2.lineHeight,
    '&:last-child': {
      marginBottom: '0px',
    },
  },
}));

export const TableAppBarBranchLabel: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user = unknownUser, group: { groupId } = unknownGroup } = useAppSelector(
    (state: AppState) => state?.currentUser,
  );
  const { clientId, clientDataType, clientDataBranch } = useAppSelector((state: AppState) => state?.clientData);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const getClientName = useGetClientName();

  const { currentData: clientPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, groupId, dataType: clientDataType },
    {
      skip:
        !clientId ||
        !clientDataType ||
        clientDataType !== ClientDataType.Vendor ||
        clientDataBranch === ClientDataBranch.Main,
    },
  );

  const {
    authors = [],
    changedTables = [],
    description = '',
  } = activeBranches.find((metadata) => metadata.branchType === clientDataBranch) || {};

  const displayTablesAsNumber = changedTables.length > 3;
  const displayAuthorsAsNumber = authors.length > 3;
  const localizedAuthors = authors.map((authorName) =>
    authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
  );
  const tableLabels = changedTables
    .filter((table) => table !== CELL_METADATA_TABLE)
    .map((table) => compoundCaseToTitleCase(table));
  const metadataChanges = changedTables.includes(CELL_METADATA_TABLE);

  const toolTipLabel = (
    <>
      <Typography variant="caption" component="div" gutterBottom className={classes.tooltipText}>
        {changedTables.length > 0 ? (
          <>
            <Trans
              i18nKey={I18nKeys.ClientDataBranchToolTipSummary as string}
              values={{
                authors: displayAuthorsAsNumber
                  ? t(I18nKeys.ClientDataBranchToolTipSummaryAuthorsMultiple, { count: authors.length })
                  : t(I18nKeys.ClientDataBranchToolTipSummaryAuthors, {
                      authors: localizedAuthors,
                      count: authors.length,
                    }),
                changedTables: displayTablesAsNumber
                  ? t(I18nKeys.ClientDataBranchToolTipSummaryChangedTablesMultiple, {
                      count: tableLabels.length,
                    })
                  : t(I18nKeys.ClientDataBranchToolTipSummaryChangedTables, {
                      changedTables: tableLabels,
                      count: tableLabels.length,
                    }),
                metadataChanges: metadataChanges ? t(I18nKeys.ClientDataBranchToolTipSummaryMetadataChanges) : '',
              }}
              components={{ bold: <strong /> }}
            />
            {displayTablesAsNumber && (
              <Trans
                i18nKey={I18nKeys.ClientDataBranchToolTipSummarySeeChangeSummary as string}
                components={{ bold: <strong /> }}
              />
            )}
          </>
        ) : (
          t(I18nKeys.ClientDataBranchToolTipSummaryNoChanges)
        )}
      </Typography>
      {description && (
        <Typography
          variant="caption"
          key="branch-description-label"
          component="div"
          gutterBottom
          className={classes.tooltipText}
        >
          {description}
        </Typography>
      )}
      {clientPublishedVersions?.suppliers
        ?.filter((supplier) => supplier.versions.length > 0)
        .map((supplier) => (
          <Fragment key={supplier.key}>
            {supplier.versions.map((version) => (
              <Typography
                key={`${supplier.key}-${version.version}`}
                variant="caption"
                gutterBottom
                component="div"
                className={classes.tooltipText}
              >
                <strong>{`${getClientName(supplier.key)} version ${version.version}`}</strong> by {version.author}:{' '}
                {version.message}
              </Typography>
            ))}
          </Fragment>
        ))}
      {clientPublishedVersions?.system?.versions.map((version) => (
        <Typography
          key={`reference-${version.version}`}
          variant="caption"
          gutterBottom
          component="div"
          className={classes.tooltipText}
        >
          <strong>{`Reference Data version ${version.version}`}</strong> by {version.author}: {version.message}
        </Typography>
      ))}
    </>
  );

  return (
    <Tooltip title={clientDataBranch && clientDataBranch !== ClientDataBranch.Main ? toolTipLabel : ''}>
      <ClientDataBranchChip branch={clientDataBranch} />
    </Tooltip>
  );
};
