import { config } from '../config/config';
import { QueryParamsToPassBy } from '../types/QueryParamsToPassBy';

/**
 * Validates the given url string.
 * Simply checks for the occurrence of http/https and adds if it not.
 *
 * @param url
 */
const validateURL = (url: string): string =>
  url.startsWith('http:') || url.startsWith('https:') ? url : `http://${url}`;

/**
 * Creates a url to the configurator that includes locale configuration. Points to development or staging
 * when not a production deployment.
 *
 * @param configurator
 * @param vendor
 * @param environment must be 'localhost', 'development', 'staging', or 'production' - no value defaults to config.environment.STAGE
 */
export const getConfiguratorUrlWithLocale = (
  configurator: string,
  vendor: string,
  locale: string,
  productionURL: string,
  environment = config.environment.STAGE,
): string => {
  let configuratorUrl = '';
  if (environment === 'localhost') {
    configuratorUrl = `http://localhost:8081/${configurator}-${vendor}`;
  } else if (environment !== 'production') {
    // If the environment isn't actually production, point the configuratorUrl at staging or development
    configuratorUrl = `https://${environment}.${configurator}.com/${vendor}/`;
  } else if (!productionURL) {
    // If no productionURL is set, point the configuratorUrl at staging
    configuratorUrl = `https://staging.${configurator}.com/${vendor}/`;
  } else {
    configuratorUrl = validateURL(productionURL);
  }

  // If locale is set and there are no query params, add the locale as a query param
  if (locale && configuratorUrl.indexOf('?') === -1) {
    configuratorUrl = `${configuratorUrl}?lng=${locale}`;
    // If locale is set, the lng params does not exist, and there are query params, add the locale as a query param
  } else if (locale && configuratorUrl.indexOf('&lng=') === -1 && configuratorUrl.indexOf('?lng=') === -1) {
    configuratorUrl = `${configuratorUrl}&lng=${locale}`;
  }

  return configuratorUrl;
};

/**
 * Attempts to construct a url to a dealer site
 *
 * @param baseProductionUrl
 * @param dealerKey
 */
export const getDealerUrl = (baseProductionUrl: string, dealerKey: string): string => {
  let dealerURL = '';

  if (baseProductionUrl) {
    if (baseProductionUrl.indexOf('?') === -1) {
      dealerURL = `${baseProductionUrl}?dealer=${dealerKey}`;
    } else {
      dealerURL = `${baseProductionUrl}&dealer=${dealerKey}`;
    }
  }
  return dealerURL;
};

/**
 * Attempts to construct a url with query params
 *
 * @param baseUrl
 * @param queryParamsToPassBy
 */
export const getUrlWithQueryParams = (baseUrl: string, queryParamsToPassBy: QueryParamsToPassBy[]): string => {
  let url = validateURL(baseUrl);

  if (queryParamsToPassBy) {
    queryParamsToPassBy.forEach((queryParam) => {
      if (queryParam.value && !queryParam.hideInDesignLink) {
        if (url.indexOf('?') === -1) {
          url += `?${queryParam.queryParamKey}=${queryParam.value}`;
        } else {
          url += `&${queryParam.queryParamKey}=${queryParam.value}`;
        }
      }
    });
  }
  return url;
};

/**
 * Attempts to construct a url with the uuid
 *
 * @param baseUrl
 * @param uuid
 * @param queryParamsToPassBy
 */
export const getUrlWithUUID = (
  baseUrl: string,
  uuid?: string,
  queryParamsToPassBy: QueryParamsToPassBy[] = [],
): string => {
  let uuidURL = validateURL(baseUrl);

  if (baseUrl && uuid) {
    if (baseUrl.indexOf('?') === -1) {
      uuidURL += `?uuid=${uuid}`;
    } else {
      uuidURL += `&uuid=${uuid}`;
    }
  }
  return getUrlWithQueryParams(uuidURL, queryParamsToPassBy);
};
