import React from 'react';
import { usePath } from 'hookrouter';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Configurator as ConfiguratorType } from '../types/Configurator';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { AppState } from '../types/AppState';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedClientId, setSelectedTabId } from '../ducks/viewerSlice';

type Configurator = Pick<ConfiguratorType, 'clientId' | 'name' | 'vendorData'>;

interface Props {
  children?: JSX.Element;
  enabledOnProperty: string;
  isVendorProperty: boolean;
  configurators?: Configurator[];
  onTabChange?(clientId: string): void;
}

const findInitialConfig = (configs: Configurator[], id?: string): string | undefined => {
  // Is the current id already valid?
  if (id && configs.some(({ clientId = '' }) => id === clientId)) return id;

  const [{ clientId: selectedConfig }] = configs;
  return selectedConfig;
};

const filterConfigs = (configs: Configurator[], enabledOnProperty: string, isVendorProperty: boolean): Configurator[] =>
  configs.filter((c) => getEnabledOnProperty(c.vendorData, enabledOnProperty, isVendorProperty));

export const ConfiguratorAppBar: React.FC<Props> = ({
  children,
  configurators: configs = [],
  enabledOnProperty,
  isVendorProperty,
  onTabChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedTabId = '', selectedClientId = '' } = useAppSelector((state: AppState) => state?.viewer);

  const [filteredConfigs, setFilteredConfigs] = React.useState(
    filterConfigs(configs, enabledOnProperty, isVendorProperty),
  );
  const [currentConfig, setCurrentConfig] = React.useState(undefined as string | undefined);
  const selectedId = selectedTabId || selectedClientId;

  React.useEffect(() => {
    const newConfigs = filterConfigs(configs, enabledOnProperty, isVendorProperty);
    if (JSON.stringify(filteredConfigs) !== JSON.stringify(newConfigs)) setFilteredConfigs(newConfigs);
  }, [filteredConfigs, configs, enabledOnProperty, isVendorProperty]);

  React.useEffect(() => {
    const setSelectTabAndClientId = (clientId: string) => {
      dispatch(setSelectedTabId(clientId));
      dispatch(setSelectedClientId(clientId));
    };

    // Finding a selected config to start with
    if (!currentConfig && filteredConfigs.length > 0) {
      setCurrentConfig(findInitialConfig(filteredConfigs, selectedId));
    }

    if (currentConfig && (currentConfig !== selectedTabId || currentConfig !== selectedClientId)) {
      setSelectTabAndClientId(currentConfig);
      if (onTabChange) onTabChange(currentConfig);
    }
  }, [
    selectedTabId,
    selectedClientId,
    selectedId,
    onTabChange,
    filteredConfigs,
    currentConfig,
    setCurrentConfig,
    dispatch,
  ]);

  const path = usePath();

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;
  const showTabs = filteredConfigs.length > 1;

  return (
    <AppBar position="sticky" color="inherit">
      {showTabs && (
        <Tabs
          value={currentConfig || findInitialConfig(filteredConfigs, selectedId)}
          variant={tabsVariant}
          aria-label="configurator sales tools tab"
          indicatorColor="primary"
          textColor="inherit"
          onChange={(event: React.ChangeEvent<any>, newValue: string): void => {
            setCurrentConfig(newValue);
          }}
        >
          {filteredConfigs.map((configurator) => {
            const { clientId, name } = configurator;
            return (
              <Tab
                value={clientId}
                key={clientId}
                label={name}
                id={clientId}
                icon={clientId === 'siteText' ? <IdeaRoomOnlyIndicator /> : undefined}
                iconPosition="start"
              />
            );
          })}
        </Tabs>
      )}
      {children}
    </AppBar>
  );
};
