import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { ForgotPasswordState } from '../types/ForgotPasswordState';

// Action types

export enum ForgotPasswordActionTypes {
  SET_FORGOT_PASSWORD_STATE = 'SET_FORGOT_PASSWORD_STATE',
}

// Reducer

export const INITIAL_FORGOT_PASSWORD_STATE: ForgotPasswordState = {
  email: undefined,
};

export const forgotPasswordReducer: Reducer<ForgotPasswordState> = (
  state = INITIAL_FORGOT_PASSWORD_STATE,
  reducerAction,
) => {
  switch (reducerAction.type) {
    case ForgotPasswordActionTypes.SET_FORGOT_PASSWORD_STATE: {
      const {
        payload: { email },
      } = reducerAction;

      return { ...state, email };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const setForgotPasswordState = (forgotPasswordState: ForgotPasswordState): AnyAction =>
  action(ForgotPasswordActionTypes.SET_FORGOT_PASSWORD_STATE, forgotPasswordState);
