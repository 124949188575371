import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { SearchState } from '../types/SearchState';

export enum SearchActionTypes {
  RESET_SEARCH = 'RESET_SEARCH',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_SEARCH_HIDDEN = 'SET_SEARCH_HIDDEN',
}

export const INITIAL_SEARCH_STATE: SearchState = {
  searchTerm: '',
  hideSearch: false,
};

export const searchReducer: Reducer<SearchState> = (state = INITIAL_SEARCH_STATE, searchAction) => {
  switch (searchAction.type) {
    case SearchActionTypes.RESET_SEARCH: {
      return { ...state, ...INITIAL_SEARCH_STATE };
    }
    case SearchActionTypes.SET_SEARCH_TERM: {
      const {
        payload: { searchTerm },
      } = searchAction;
      return { ...state, searchTerm };
    }
    case SearchActionTypes.SET_SEARCH_HIDDEN: {
      const {
        payload: { hideSearch },
      } = searchAction;
      return { ...state, hideSearch };
    }
    default: {
      return state;
    }
  }
};

export const resetSearch = (): AnyAction => action(SearchActionTypes.RESET_SEARCH, {});

export const setSearchTerm = (searchTerm: string): AnyAction =>
  action(SearchActionTypes.SET_SEARCH_TERM, {
    searchTerm,
  });

export const setSearchHidden = (hideSearch: boolean): AnyAction =>
  action(SearchActionTypes.SET_SEARCH_HIDDEN, { hideSearch });
