import { IHeaderParams } from 'ag-grid-community';
import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { AppState } from '../types/AppState';
import { useAppSelector } from '../hooks';
import { SearchType } from '../constants/ClientData';
import { splitByMatches } from '../utils/searchUtils';
import { SearchResultPart } from '../types/ClientData';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { getCellRangeInfo } from '../utils/clientDataUtils';
import { DefaultColumnFieldNames } from '../constants/ClientDataColumn';

export const ClientDataSearchHeader: React.FC<IHeaderParams> = ({
  column,
  context = {},
  showColumnMenu,
}: IHeaderParams) => {
  const columnId = column.getColId();
  const columnName = compoundCaseToTitleCase(columnId);
  const {
    search: { value: searchValue = '', type: searchType = SearchType.AllTables, options: searchOptions = {} } = {},
  } = useAppSelector((state: AppState) => state?.clientData);

  const { highlightSearchResults = false, rootGridApi } = context;
  const { columns: selectedColumns = [] } = getCellRangeInfo(rootGridApi?.getCellRanges());
  const outsideSelection = searchType === SearchType.CurrentSelection && !selectedColumns.includes(columnId);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const parts: { text: string; highlight: boolean; key: string }[] = (
    (highlightSearchResults && searchValue && !outsideSelection
      ? splitByMatches(columnName, searchValue, searchOptions)
      : [
          {
            text: columnName,
            highlight: false,
          },
        ]) as SearchResultPart[]
  ).map((part, i) => ({ ...part, key: `${columnId}-${i}` }));

  return !([DefaultColumnFieldNames.GoToCellRange, DefaultColumnFieldNames.AddToTable] as string[]).includes(
    columnId,
  ) ? (
    <Grid container direction="row" justifyContent="space-between">
      <Grid alignSelf="center">
        {parts.map((part) => (
          <span
            key={part.key}
            style={part.highlight && highlightSearchResults ? { backgroundColor: '#FDFF80', padding: '3px 1px' } : {}}
          >
            {part.text}
          </span>
        ))}
      </Grid>
      <Grid alignSelf="center">
        <IconButton
          ref={buttonRef}
          disableRipple
          className="ag-header-icon ag-header-cell-menu-button"
          size="small"
          onClick={() => buttonRef.current && showColumnMenu(buttonRef.current)}
        >
          <Menu className="ag-icon ag-icon-menu" />
        </IconButton>
      </Grid>
    </Grid>
  ) : null;
};
