import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { SystemGroupOrderConfigurator } from '../types/SystemGroupOrderConfigurator';
import { SystemGroupOrderConfiguratorsState } from '../types/SystemGroupOrderConfiguratorsState';

export enum SystemGroupOrderConfiguratorsActionTypes {
  SET_SYSTEM_GROUP_ORDER_CONFIGURATORS = 'SET_SYSTEM_GROUP_ORDER_CONFIGURATORS',
}

export const INITIAL_SYSTEM_GROUP_ORDER_CONFIGURATORS_STATE: SystemGroupOrderConfiguratorsState = {
  systemGroupOrderConfigurators: [],
};

export const systemGroupOrderConfiguratorsReducer: Reducer<SystemGroupOrderConfiguratorsState> = (
  state = INITIAL_SYSTEM_GROUP_ORDER_CONFIGURATORS_STATE,
  systemGroupOrderConfiguratorsAction,
) => {
  switch (systemGroupOrderConfiguratorsAction.type) {
    case SystemGroupOrderConfiguratorsActionTypes.SET_SYSTEM_GROUP_ORDER_CONFIGURATORS: {
      const {
        payload: { systemGroupOrderConfigurators },
      } = systemGroupOrderConfiguratorsAction;
      return { ...state, systemGroupOrderConfigurators };
    }
    default: {
      return state;
    }
  }
};

export const setSystemGroupOrderConfigurators = (
  systemGroupOrderConfigurators: SystemGroupOrderConfigurator[],
): AnyAction =>
  action(SystemGroupOrderConfiguratorsActionTypes.SET_SYSTEM_GROUP_ORDER_CONFIGURATORS, {
    systemGroupOrderConfigurators,
  });
