import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & TextFieldProps;

export const RenderSelectField: React.FC<Props> = ({
  input,
  children,
  SelectProps,
  meta: { touched, error },
  ...customProps
}): JSX.Element => (
  <TextField
    /* eslint-disable react/jsx-props-no-spreading */
    {...customProps}
    {...input}
    helperText={touched && error}
    error={!!(touched && error)}
    select
    value={input.value}
    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => input.onChange(event.target.value)}
    onBlur={(event: React.FocusEvent<HTMLInputElement>): void => input.onBlur(event.target.value)}
    SelectProps={{
      displayEmpty: true,
      autoWidth: true,
      ...SelectProps,
      MenuProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        PaperProps: {
          style: {
            maxHeight: 500,
          },
        },
      },
    }}
    margin="normal"
  >
    {children}
  </TextField>
);
