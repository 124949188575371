import React from 'react';
import { FormHelperText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SUPPLIER_DATA_TEMPLATE_NONE_KEY } from '../constants/Configurator';

interface Props {
  option: { label: string; value: string; displayKey?: boolean };
  options: { label: string; value: string; displayKey?: boolean }[];
  onClick?: () => void;
}

export const ConfiguratorDialogSelectOption: React.FC<Props> = ({ option, options, onClick }) => {
  const { t } = useTranslation();

  const { label, value, displayKey } = option;
  const i = options.findIndex((o) => o.value === option.value);
  return (
    <MenuItem
      key={value}
      value={value}
      onClick={onClick}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...(value === SUPPLIER_DATA_TEMPLATE_NONE_KEY
          ? { borderBottom: '0.5px solid #E2E2E2', paddingBottom: '7px' }
          : {}),
        ...(i && options[i - 1].value === SUPPLIER_DATA_TEMPLATE_NONE_KEY
          ? { borderTop: '0.5px solid #E2E2E2', paddingTop: '7px' }
          : {}),
      }}
    >
      {t(label)}
      {displayKey && <FormHelperText style={{ margin: '0px', lineHeight: 1 }}>{value}</FormHelperText>}
    </MenuItem>
  );
};
