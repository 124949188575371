import {
  Box,
  CircularProgress,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps, Theme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { SortDirection } from '../constants/SortDirection';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { sortRows } from '../utils/sortUtils';
import { TableHeaderCell } from './TableHeaderCell';
import { SearchInput } from './SearchInput';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  rootFab: {
    flex: 1,
    paddingBottom: '70px',
  },
  saveButton: {
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
  tableCell: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export interface Header {
  i18nKey: string;
  numeric?: boolean;
  property: string;
  sortKey?: string;
  hidden?: boolean;
  CellComponent?: React.ComponentClass<{ row: any; value: any }> | React.FunctionComponent<{ row: any; value: any }>;
  valueFormatter?(property: string, row: any): string;
  cellStyle?: any;
}

interface Props {
  sx?: SxProps<Theme>;
  showSearch?: boolean;
  noFab?: boolean;
  headers: Header[];
  loading: boolean;
  rows: any[];
  rowsPerPage?: number;
  sortProperties?: SortProperty[];
  sortableProperties?: SortableProperty[];
  noDataMessage?: string;
  handleRowClick?(row: any): void;
  onSort?(sortProperties: SortProperty[]): void;
  getCellClass?(row: any, property: string): string;
  getHeaderClass?(property: string): string;
}

export const Table: React.FC<Props> = ({
  sx = undefined,
  showSearch = false,
  noFab = false,
  onSort,
  headers,
  loading,
  rows,
  rowsPerPage,
  sortableProperties = [],
  sortProperties = [],
  noDataMessage,
  handleRowClick,
  getCellClass,
  getHeaderClass,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [localSortProperties, setLocalSortProperties] = React.useState<SortProperty[]>(sortProperties);
  const [page, setPage] = React.useState(0);
  const tableRowsPerPage = rowsPerPage || 100;
  const [matchingRows, setMatchingRows] = React.useState<any[]>(rows);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const resetSearch = React.useCallback(() => setSearchValue(''), []);

  useEffect(() => {
    let displayedRows;
    if (searchValue.length < 2) {
      displayedRows = rows;
    } else {
      displayedRows = rows.filter((row: any) =>
        Object.keys(row).some((key) => stringToLowerIncludes(row[key], searchValue)),
      );
    }
    setMatchingRows(displayedRows);
  }, [searchValue, rows, setMatchingRows]);

  // When onSort is not provided the table is responsible for sorting the rows
  if (!onSort) {
    sortRows(rows, localSortProperties);
  }

  const handleSortChanged = (key: string, direction?: SortDirection, defaultValue?: string): void => {
    const newSortProperties = direction ? [{ key, direction, defaultValue }] : [];

    setLocalSortProperties(newSortProperties);
    if (onSort) {
      onSort(newSortProperties);
    }
  };

  useEffect(() => {
    // if there are less rows than the current page go back to the first page
    if (matchingRows.length < page * tableRowsPerPage) {
      setPage(0);
    }
  }, [matchingRows, page, tableRowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <Box sx={sx} className={noFab ? classes.root : classes.rootFab}>
      {showSearch && (
        <SearchInput
          searchTerm={searchValue}
          startAdornment={<SearchIcon />}
          onClearClick={resetSearch}
          onChange={setSearchValue}
        />
      )}
      <MaterialTable aria-labelledby="tableTitle">
        <TableHead>
          {!loading && (
            <TableRow style={{ height: '52px' }} key="header-row">
              {headers
                .filter((header: Header) => !header.hidden)
                .map((header: Header) => (
                  <TableHeaderCell
                    key={`header-${header.property}-${header.i18nKey}`}
                    header={header}
                    sortProperties={sortProperties}
                    sortableProperties={sortableProperties}
                    onSortChange={handleSortChanged}
                    getHeaderClass={getHeaderClass}
                  />
                ))}
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {loading && (
            <TableRow hover style={{ height: 200 }} key="loading-data-row">
              <TableCell colSpan={headers.length} style={{ borderBottom: 'none' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
                </div>
              </TableCell>
            </TableRow>
          )}

          {!loading && rows.length === 0 && (
            <TableRow hover style={{ height: '4rem' }} key="empty-row">
              <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                {t(noDataMessage || I18nKeys.TableNoData)}
              </TableCell>
            </TableRow>
          )}

          {!loading &&
            rows.length > 0 &&
            matchingRows
              .slice(page * tableRowsPerPage, page * tableRowsPerPage + tableRowsPerPage)
              .map((row, index) => (
                <TableRow
                  hover
                  style={{
                    cursor: handleRowClick ? 'pointer' : undefined,
                    height: '52px',
                  }}
                  tabIndex={index}
                  key={row.id || row.uuid || row[ClientDataFixedColumns.RowId] || row.email} // can't use index here or we won't update correctly when adding rows
                  onClick={handleRowClick ? (): void => handleRowClick(row) : undefined}
                >
                  {headers
                    .filter((header: Header) => !header.hidden)
                    .map(({ CellComponent, property, valueFormatter }: any, colIndex: number) => {
                      const formattedValue = valueFormatter ? valueFormatter(property, row) : row[property];
                      return (
                        <TableCell
                          style={headers[colIndex].cellStyle}
                          className={`${classes.tableCell} ${getCellClass ? getCellClass(row, property) : ''}`}
                          scope="row"
                          key={`row-${headers[colIndex].property}`}
                        >
                          {CellComponent ? <CellComponent row={row} value={formattedValue} /> : formattedValue}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
        </TableBody>
      </MaterialTable>
      {!loading && rows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[tableRowsPerPage]}
          component="div"
          count={matchingRows.length}
          rowsPerPage={tableRowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </Box>
  );
};
