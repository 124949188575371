import React, { MutableRefObject, Ref, useEffect, useRef } from 'react';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { loadingComponent, noOverlayComponent } from './Reports';
import { useReportRepo } from '../hooks/useReportRepo';
import { dateCellRenderer } from './DateCellRenderer';

const columnDefs: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    initialWidth: 450,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  { field: 'latest_committer', headerName: 'Last Committer', initialWidth: 200 },
  {
    field: 'latest_commit_date',
    headerName: 'Last Change Date',
    cellRenderer: dateCellRenderer,
    cellRendererParams: { tooltipField: 'latest_commit_message' },
    initialWidth: 225,
  },
];

export const ClientDataBranchReport = ({
  gridRef,
  defaultColDef,
}: {
  gridRef?: Ref<AgGridReact | null>;
  defaultColDef: ColDef<any, any>;
}) => {
  const gridRefInternal = (gridRef as MutableRefObject<AgGridReact | null>) || useRef<AgGridReact | null>(null);
  const { api } = gridRefInternal?.current || {};

  const hideOverlay = false;

  const { branchReportData, isInitializingBranchReportData, isLoadingBranchReportData } = useReportRepo({
    useBranchReport: true,
  });

  useEffect(() => {
    if (api) {
      if (isLoadingBranchReportData || isInitializingBranchReportData) {
        api.showLoadingOverlay();
      } else if (branchReportData.length === 0) {
        api.showNoRowsOverlay();
      } else {
        api.hideOverlay();
        // Updates coming from API need to manually refresh cells
        api.refreshCells();
      }
    }
  }, [branchReportData, isInitializingBranchReportData, isLoadingBranchReportData, api]);

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={branchReportData}
        animateRows
        tooltipShowDelay={0}
        tooltipHideDelay={6000}
        loadingOverlayComponent={hideOverlay ? noOverlayComponent : loadingComponent}
        statusBar={{
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
          ],
        }}
      />
    </div>
  );
};
