import { ColDef, Column, ValueFormatterParams } from 'ag-grid-community';
import { TextMatcherParams } from 'ag-grid-community/dist/lib/filter/provided/text/textFilter';
import { ClientDataTooltip } from '../components/ClientDataTooltip';
import { ClientDataGoToCellRange } from '../components/ClientDataGoToCellRange';
import { ClientDataAddToTable } from '../components/ClientDataAddToTable';
import { ClientDataTextColumnFilter } from '../components/ClientDataTextColumnFilter';
import { SelectAllCellsHeader } from '../components/SelectAllCellsHeader';
import { ClientDataBooleanColumnFilter } from '../components/ClientDataBooleanColumnFilter';
import { ClientDataImageCell } from '../components/ClientDataImageCell';
import { CellMetadataProperty, ColumnDataType } from './ClientData';
import { ClientDataFixedColumns } from './ClientDataFixedColumns';
import { getPropertyFromCellMetadata, hasCellMetadataProperty } from '../utils/clientDataUtils';
import { ClientDataBooleanCell } from '../components/ClientDataBooleanCell';
import {
  suppressGridKeyboardEvent,
  suppressGridKeyboardEventAutocompleteCell,
} from '../utils/keyboardShortcutSuppressionUtils';
import { ColumnPinned } from '../types/ClientData';
import { ClientDataGoToCellRangeNewTab } from '../components/ClientDataGoToCellRangeNewTab';
import { ClientDataTextEditor } from '../components/ClientDataTextEditor';
import { ClientDataLargeTextEditor } from '../components/ClientDataLargeTextEditor';
import { ClientDataFileCell } from '../components/ClientDataFileCell';
import { ClientDataLookupCellEditor } from '../components/ClientDataLookupCellEditor';
import { ClientDataLookupCell } from '../components/ClientDataLookupCell';
import { ClientDataEnumCellEditor } from '../components/ClientDataEnumCellEditor';

export const KEY_COLUMN = 'key';
export const REGION_KEY_COLUMN = 'regionKey';
export const LABEL_COLUMN = 'label';
export const PRICE_COLUMN_COLUMN = 'priceColumn';

export const defaultFixedColumn: ColDef = {
  editable: false,
  lockPosition: true,
  lockVisible: true,
  resizable: false,
  sortable: false,
  suppressAutoSize: true,
  suppressColumnsToolPanel: true,
  suppressFillHandle: true,
  suppressFiltersToolPanel: true,
  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressPaste: true,
  floatingFilter: false,
};

export const largeTextCellEditorParams: ColDef = {
  cellEditor: ClientDataLargeTextEditor,
  cellEditorPopup: true,
  cellEditorPopupPosition: 'over',
  cellEditorParams: {
    maxLength: 50000,
  },
};

export const defaultColumn: ColDef = {
  tooltipComponent: ClientDataTooltip,
  tooltipValueGetter: (params) => {
    const rowId = params.data[ClientDataFixedColumns.RowId];
    const columnId = (params.column as Column).getColId();
    const { cellMetadata } = params.context;

    if (!hasCellMetadataProperty(cellMetadata, rowId, columnId, CellMetadataProperty.Note)) return '';
    const note = getPropertyFromCellMetadata(cellMetadata, rowId, columnId, CellMetadataProperty.Note);
    return note;
  },
  resizable: true,
  sortable: true,
  cellEditor: ClientDataTextEditor,
  cellEditorSelector: ({ data, column }) => {
    const colId = column.getColId();
    const cellData = data[colId];

    // If the cell data contains new lines, use the large text cell editor
    if (typeof cellData === 'string' && cellData.includes('\n')) {
      return {
        component: largeTextCellEditorParams.cellEditor,
        params: largeTextCellEditorParams.cellEditorParams,
        popup: largeTextCellEditorParams.cellEditorPopup,
        popupPosition: largeTextCellEditorParams.cellEditorPopupPosition,
      };
    }

    // Use column type specific cell editor
    return undefined;
  },
};

export const defaultEditableColumn: ColDef = {
  ...defaultColumn,
  editable: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponent: ClientDataTextColumnFilter,
  filterValueGetter: (params) => {
    const colId = (params.column as Column).getColId();
    // Add a space for blank cells so they aren't ignored by textMatcher
    return params.data[colId] || ' ';
  },
  filterParams: {
    filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
    textMatcher: (params: TextMatcherParams) => {
      const {
        filterOption,
        filterText,
        data: dataToFilter,
        column: columnToFilter,
        context: { filterExceptionIds: exceptions = [] },
      } = params;
      const colId = columnToFilter.getColId();
      const [value, filter] = [dataToFilter[colId], filterText].map((val) => `${val}`.toLowerCase());

      // Don't filter out this row if the row is in the exceptions list
      if (exceptions.includes(dataToFilter[ClientDataFixedColumns.RowId])) return true;

      switch (filterOption) {
        case 'equals':
          return value === filter;
        case 'notEqual':
          return value !== filter;
        case 'contains':
          return value.includes(filter);
        case 'notContains':
          return !value.includes(filter);
        case 'startsWith':
          return value.startsWith(filter);
        case 'endsWith':
          return value.endsWith(filter);
        default:
          return true;
      }
    },
  },
  suppressKeyboardEvent: suppressGridKeyboardEvent,
};

export const booleanColumnParams: ColDef = {
  ...defaultEditableColumn,
  cellRenderer: ClientDataBooleanCell,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: ['0', '1'],
    valueFormatter: (params: ValueFormatterParams) => (params.value === '1' ? 'True' : 'False'),
    suppressSorting: true,
    suppressMiniFilter: true,
  },
  floatingFilter: true,
  floatingFilterComponent: ClientDataBooleanColumnFilter,
};

export const imageColumnParams: ColDef = {
  ...defaultEditableColumn,
  ...largeTextCellEditorParams,
  cellRendererSelector: (params) => {
    // This is a workaround for the style tab in sheds which defines the column type in a cell rather than having its own
    // icon column
    if (!('value1' in params.data) || params.data.property === 'icon') {
      return {
        component: ClientDataImageCell,
      };
    }
    return undefined;
  },
};

export const kmlFileColumnParams: ColDef = {
  ...defaultEditableColumn,
  ...largeTextCellEditorParams,
  cellRendererParams: {
    bucketPath: 'kml-maps',
    fileType: 'kml',
    accept: { 'application/vnd.google-earth.kml+xml': ['.kml'] },
  },
  cellRendererSelector: () => ({
    component: ClientDataFileCell,
  }),
};

export const fileColumnParams: ColDef = {
  ...defaultEditableColumn,
  ...largeTextCellEditorParams,
  cellRendererParams: {
    bucketPath: 'files',
    appendFilename: true,
  },
  cellRendererSelector: () => ({
    component: ClientDataFileCell,
  }),
};

export const lookupColumnParams: ColDef = {
  ...defaultEditableColumn,
  cellEditor: ClientDataLookupCellEditor,
  cellEditorPopup: true,
  cellRenderer: ClientDataLookupCell,
  suppressKeyboardEvent: suppressGridKeyboardEventAutocompleteCell,
};

export const enumColumnParams: ColDef = {
  ...defaultEditableColumn,
  cellEditor: ClientDataEnumCellEditor,
  cellEditorPopup: true,
  cellRenderer: ClientDataLookupCell,
  suppressKeyboardEvent: suppressGridKeyboardEventAutocompleteCell,
};

export enum DefaultColumnFieldNames {
  Index = 'index',
  GoToCellRange = 'goToCellRange',
  AddToTable = 'addToTable',
}

export const fixedColumnWidth = 34;
export const defaultColumnWidth = 200;
export const minAutoColumnWidth = 80;
export const maxAutoColumnWidth = 300;
export const indexColumnWidth = 44;

export const getIndexColumnDef = (props: { floatingFilter?: boolean }): ColDef => {
  const { floatingFilter = false } = props;
  return {
    ...defaultFixedColumn,
    cellClass: 'ag-grid-index-column',
    floatingFilter,
    floatingFilterComponent: floatingFilter ? SelectAllCellsHeader : undefined,
    headerComponent: floatingFilter ? undefined : SelectAllCellsHeader,
    pinned: 'left' as ColumnPinned,
    headerName: '',
    field: DefaultColumnFieldNames.Index,
    colId: DefaultColumnFieldNames.Index,
    width: indexColumnWidth,
    resizable: true,
    suppressAutoSize: true,
    suppressSizeToFit: true,
    rowDrag: (params) => !params.data?.rowIsReadOnly,
  };
};

export const goToCellRangeColumn: ColDef = {
  pinned: 'right' as ColumnPinned,
  headerName: '',
  initialWidth: fixedColumnWidth,
  field: DefaultColumnFieldNames.GoToCellRange,
  colId: DefaultColumnFieldNames.GoToCellRange,
  ...defaultFixedColumn,
  cellRenderer: ClientDataGoToCellRange,
};

export const goToCellRangeColumnInNewTab: ColDef = {
  pinned: 'right' as ColumnPinned,
  headerName: '',
  initialWidth: fixedColumnWidth,
  field: DefaultColumnFieldNames.GoToCellRange,
  colId: DefaultColumnFieldNames.GoToCellRange,
  ...defaultFixedColumn,
  cellRenderer: ClientDataGoToCellRangeNewTab,
};

export const addToTableColumn: ColDef = {
  pinned: 'right' as ColumnPinned,
  headerName: '',
  initialWidth: fixedColumnWidth,
  field: DefaultColumnFieldNames.AddToTable,
  colId: DefaultColumnFieldNames.AddToTable,
  ...defaultFixedColumn,
  cellRenderer: ClientDataAddToTable,
};

export const columnHeights = {
  [ColumnDataType.StyleIcon]: 65,
  [ColumnDataType.OptionIcon]: 65,
  [ColumnDataType.Image]: 65,
  [ColumnDataType.Text]: 28,
};

export const columnTypes: Partial<Record<ColumnDataType, ColDef>> = {
  [ColumnDataType.Boolean]: booleanColumnParams,
  [ColumnDataType.Enum]: enumColumnParams,
  [ColumnDataType.Expression]: largeTextCellEditorParams,
  [ColumnDataType.File]: fileColumnParams,
  [ColumnDataType.Html]: largeTextCellEditorParams,
  [ColumnDataType.Image]: imageColumnParams,
  [ColumnDataType.Json]: largeTextCellEditorParams,
  [ColumnDataType.KMLMap]: kmlFileColumnParams,
  [ColumnDataType.Lookup]: lookupColumnParams,
  [ColumnDataType.OptionIcon]: imageColumnParams,
  [ColumnDataType.StyleIcon]: imageColumnParams,
};
