export interface Dealer {
  clientId: string;
  name: string;
  id: number | undefined;
  key: string;
  city: string;
  state: string;
  zip: string;
  logoUrl: string;
  // Redirect when clicking on logo
  homeLinkUrl: string;
  // The url of the dealer's configurator
  dealerURL: string;
  phoneNumber: string;
  emailAddress: string;
  quoteEmailReplyToAddress: string;
  quoteEmailCopyAddress: string;
  // can be imported as string but normally is string[]
  depositPrice: string | string[];
  depositPercent: string | string[];
  // generated from depositPrice/depositPercent
  depositAmount: string;
  // a json chunk
  integration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  contactBarCustomHtml: string;
  emailLogoUrl: string;
  integrationsKey: string;
}

export const unknownDealer: Dealer = {
  clientId: '',
  name: 'Not Assigned',
  id: undefined,
  key: '',
  city: '',
  state: '',
  zip: '',
  logoUrl: '',
  homeLinkUrl: '',
  dealerURL: '',
  phoneNumber: '',
  emailAddress: '',
  quoteEmailReplyToAddress: '',
  quoteEmailCopyAddress: '',
  depositPrice: '',
  depositPercent: '',
  depositAmount: '',
  integration: {},
  contactBarCustomHtml: '',
  emailLogoUrl: '',
  integrationsKey: '',
};
