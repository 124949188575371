import { DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { submit } from 'redux-form';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LoadingButton } from './LoadingButton';
import { FormData, PricingComponentEditForm } from './PricingComponentEditForm';
import { Forms } from '../constants/Forms';
import { getComponentPricingFormInitialValues, onComponentEditSubmit } from '../utils/pricingClientUpdateUtils';
import { AppState } from '../types/AppState';
import { setSelectedComponentId } from '../ducks/pricingSlice';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { hasDuplicateLabels } from '../utils/pricingUtils';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { ComponentCategoryKey } from '../constants/ClientUpdateCategoryKey';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
});

export const PricingComponentEditDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const clientId = useAppSelector(
    ({ viewer: { selectedTabId, selectedClientId }, clientData: { clientId: clientDataId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataId || selectedTabId || selectedClientId || ''),
  );
  const {
    pricing: {
      component: { selectedCategoryKey: categoryKey, selectedComponentId = '' },
    },
  } = useAppSelector((state: AppState) => state);
  const [componentId, setComponentId] = useState(selectedComponentId);

  const { componentCategoryItemsWithConditions = [], isLoadingComponentCategoryItemsWithConditions } =
    useClientUpdatePricingRepo({
      useComponentCategoryItemsWithConditions: true,
    });

  useEffect(() => {
    setComponentId(selectedComponentId);
  }, [selectedComponentId]);

  if (!componentId) return null;

  const initialValues = getComponentPricingFormInitialValues(
    componentId,
    categoryKey as ComponentCategoryKey,
    componentCategoryItemsWithConditions,
  );

  const closeHandler = (): void => {
    dispatch(setSelectedComponentId(undefined));
    dispatch(closeDialogFunc());
  };

  if (!initialValues) return null;
  return (
    <Dialog dialogKey={Dialogs.PricingComponentEdit} maxWidth="sm" onClosed={closeHandler}>
      <DialogContent>
        <Typography fontWeight={600} gutterBottom>
          {t(I18nKeys.PricingComponentEditDialogTitle)}
        </Typography>
        <PricingComponentEditForm
          formFields={Object.keys(initialValues) as (keyof FormData)[]}
          initialValues={initialValues}
          onSubmit={(formData: FormData) => {
            onComponentEditSubmit(
              clientId,
              categoryKey as ComponentCategoryKey,
              formData,
              componentCategoryItemsWithConditions,
              dispatch,
            );
            closeHandler();
          }}
          showKeyAsHelperText={hasDuplicateLabels(
            initialValues,
            categoryKey as ComponentCategoryKey,
            componentCategoryItemsWithConditions,
          )}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={closeHandler} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            dispatch(submit(Forms.PricingComponentEdit));
          }}
          disabled={isLoadingComponentCategoryItemsWithConditions}
          loading={false}
          color="primary"
        >
          {t(I18nKeys.DialogSaveButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
