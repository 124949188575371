import { Chip, Divider, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Permission } from '@idearoom/types';
import { InputField } from './redux-form/InputField';
import { RenderSelectField } from './RenderSelectField';
import { BulkUserDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { getPermissionLabel } from '../constants/Permissions';
import { required } from '../utils/reduxFormUtils';
import { AppState } from '../types/AppState';
import { Dealer } from '../types/Dealer';
import { isCurrentUserGroupAdmin, isCurrentUserGroupSuperUser } from '../utils/userUtils';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: '15px',
    width: '100%',
  },
});

const getDealerLabel = (availableDealers: Dealer[], dealerKey: string): string => {
  const dealer = availableDealers.find((availableDealer) => availableDealer.key === dealerKey);
  return dealer ? dealer.name : dealerKey;
};

export interface FormData {
  [BulkUserDialogFormFields.Emails]: string[];
  [BulkUserDialogFormFields.Permissions]: string[];
}

interface FormDispatchProps {
  onSubmit(data: FormData): Promise<AnyAction>;
}

interface StateProps {
  newMember: boolean;
  availableDealers: Dealer[];
  adminUser: boolean;
  superUser: boolean;
}

type FormProps = FormDispatchProps & StateProps & InjectedFormProps<FormData>;

const BulkUserFormComponent: React.FC<FormProps> = ({
  newMember,
  availableDealers,
  adminUser,
  superUser,
  error,
  handleSubmit,
  onSubmit,
}: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={!newMember}
        autoFocus={newMember}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        validate={required}
        label={`${t(I18nKeys.BulkUserFormUsernamesLabel)}*`}
        name={BulkUserDialogFormFields.Emails}
      />

      <Field
        autoFocus={!newMember}
        autoComplete="off"
        multiple
        className={classes.field}
        component={RenderSelectField}
        validate={required}
        label={`${t(I18nKeys.FieldPermissions)}*`}
        name={BulkUserDialogFormFields.Permissions}
        SelectProps={{
          displayEmpty: true,
          multiple: true,
          renderValue: (selectedValues: unknown): JSX.Element => {
            const selectedPermissions = selectedValues as Permission[];

            if (selectedPermissions.length > 0) {
              selectedPermissions.sort();
              return (
                <div>
                  {(selectedValues as Permission[]).map((selectedValue) => (
                    <Chip style={{ margin: '0px 2px' }} key={selectedValue} label={getPermissionLabel(selectedValue)} />
                  ))}
                </div>
              );
            }

            return <div style={{ height: '18px' }} />;
          },
        }}
      >
        {superUser && (
          <MenuItem key="super-user-permission" value={Permission.SuperUser}>
            {getPermissionLabel(Permission.SuperUser)}
          </MenuItem>
        )}

        {adminUser && (
          <MenuItem key="admin-permission" value={Permission.Admin}>
            {getPermissionLabel(Permission.Admin)}
          </MenuItem>
        )}

        <MenuItem key="sales-tools-permission" value={Permission.Manager}>
          {getPermissionLabel(Permission.Manager)}
        </MenuItem>

        <MenuItem key="sales-view-permission" value={Permission.Sales}>
          {getPermissionLabel(Permission.Sales)}
        </MenuItem>
      </Field>

      {availableDealers.length > 0 && (
        <Field
          autoFocus={!newMember}
          autoComplete="off"
          className={classes.field}
          component={RenderSelectField}
          label={t(I18nKeys.BulkUserFormDealerLabel)}
          name={BulkUserDialogFormFields.Dealers}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selectedValues: unknown): JSX.Element => {
              const selectedDealer = selectedValues as string;

              if (selectedDealer) {
                return (
                  <div style={{ margin: '0px 2px' }} key={selectedDealer}>
                    {getDealerLabel(availableDealers, selectedDealer)}
                  </div>
                );
              }

              return <div style={{ height: '18px' }} />;
            },
          }}
        >
          {(adminUser || superUser) && (
            <>
              <MenuItem key="" value="">
                {t(I18nKeys.BulkUserFormAllDealersLabel)}
              </MenuItem>
              <Divider />
            </>
          )}
          {availableDealers.map((dealer) => (
            <MenuItem key={dealer.key} value={dealer.key}>
              {dealer.name}
            </MenuItem>
          ))}
        </Field>
      )}

      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.BulkUser}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = ({
  group: { newMember, group },
  currentUser: { user, group: usersGroup, availableDealers = [] },
}: AppState): StateProps => {
  const adminUser = isCurrentUserGroupAdmin(user, usersGroup, group);
  const superUser = isCurrentUserGroupSuperUser(user, usersGroup, group);

  return {
    newMember,
    availableDealers,
    adminUser,
    superUser,
  };
};

export const BulkUserForm = reduxForm<FormData>({ form: Forms.BulkUser, enableReinitialize: true })(
  connect(mapStateToProps, mapDispatchToProps)(BulkUserFormComponent),
);
