import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface StyleProps {
  sizeFactor: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  icon: {
    width: (props) => `${80 * props.sizeFactor}px`,
    height: (props) => `${40 * props.sizeFactor}px`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}));

interface Props {
  url: string;
  size?: number;
}

export const SceneEnvironmentIconPreview: React.FC<Props> = ({ url, size = 1 }) => {
  const classes = useStyles({ sizeFactor: size });
  return <div className={classes.icon} style={{ backgroundImage: `url('${url}')` }} />;
};
