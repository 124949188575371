import rgba from 'color-rgba';

/**
 * Takes a hex color and an alpha value and returns the color with the alpha value
 *
 * @param color
 * @param alpha
 * @returns
 */
export const addTransparency = (color: string, alpha: number): string => {
  const [r, g, b, a] = rgba(color) || [];
  if (r !== undefined && g !== undefined && b !== undefined) {
    let newAlpha = (a || 0) - alpha;
    if (newAlpha < 0) newAlpha = 0;
    if (newAlpha > 1) newAlpha = 1;
    return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
  }
  return color;
};

/**
 * Takes a hex color and returns a boolean indicating if the color is light
 *
 * @param hex
 * @returns
 */
export const isColorLight = (hex: string): boolean => {
  // Convert hex to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // A common threshold for determining if the color is light is 0.5
  return luminance > 0.5;
};
