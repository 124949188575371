import { SceneEnvironment } from '../types/SceneEnvironment';

export const unknownSceneEnvironment: SceneEnvironment = {
  key: '',
  label: '',
  fileUrl: '',
  previewUrl: '',
  maxCameraDistanceMultiplier: 1,
  maxDiagonalBuildingLength: 100,
  isNew: true,
};

export enum SceneEnvironmentCacheTagType {
  SceneEnvironments = 'SceneEnvironments',
}
