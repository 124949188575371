import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface OwnProps {
  loading: boolean;
}

type Props = OwnProps & ButtonProps;

export const LoadingButton: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { loading = false, children } = props;

  const buttonProps: Partial<Props> = { ...props };

  delete buttonProps.classes;
  delete buttonProps.loading;
  delete buttonProps.children;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={loading} {...buttonProps}>
      {children}
      {loading && <CircularProgress size={24} className={classes.progress} color="primary" />}
    </Button>
  );
};
