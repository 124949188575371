import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { SearchInputEndAdornment } from './SearchInputEndAdornment';

interface Props {
  searchTerm: string;
  startAdornment?: React.ReactNode;
  autoFocus?: boolean;
  autoComplete?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  size?: 'small' | 'medium' | undefined;
  margin?: 'dense' | 'normal' | undefined;
  placeholder?: I18nKeys;
  displayCloseButton?: boolean;
  resultsDisplay?: {
    count: number;
    index?: number;
    loading?: boolean;
    label?: string;
  };
  onStartAdornmentClick?(): void;
  onClearClick?(): void;
  onChange(newValue: string): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
  classes?: {
    searchField?: string;
    searchInput?: string;
  } & Record<string, string>;
}

export const SearchInput = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      searchTerm,
      startAdornment,
      autoFocus,
      autoComplete,
      variant,
      size,
      margin,
      placeholder,
      displayCloseButton,
      resultsDisplay,
      onStartAdornmentClick,
      onClearClick,
      onChange,
      onKeyDown,
      classes = {},
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <TextField
        fullWidth
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        className={classes.searchField}
        variant={variant}
        size={size}
        margin={margin}
        placeholder={t(placeholder || I18nKeys.SearchInputPlaceholder)}
        value={searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChange(event.target.value)}
        onKeyDown={onKeyDown}
        sx={{
          '& > div': {
            height: '100%',
            padding: '0px 0px 0px 12px',
          },
          input: {
            color: 'text.secondary',
            padding: '0px',
            '&::placeholder': {
              opacity: 1,
            },
          },
        }}
        slotProps={{
          input: {
            inputRef: ref,
            className: classes.searchInput,
            startAdornment: startAdornment && (
              <IconButton
                disabled={onStartAdornmentClick === undefined}
                onClick={(): void => {
                  if (onStartAdornmentClick) {
                    onStartAdornmentClick();
                  }
                }}
                sx={{
                  '& svg': {
                    color: 'text.secondary',
                  },
                  padding: '12px 12px 12px 0px',
                }}
                size="large"
              >
                {startAdornment}
              </IconButton>
            ),
            endAdornment: (
              <SearchInputEndAdornment
                onClearClick={onClearClick}
                searchTerm={searchTerm}
                displayCloseButton={displayCloseButton}
                resultsDisplay={resultsDisplay}
              />
            ),
          },
        }}
      />
    );
  },
);
