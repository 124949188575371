import { I18nKeys } from './I18nKeys';
import { EmailFormat, SendEmailTo } from './VendorData';

export const emailFormatOptions = [
  {
    key: EmailFormat.Summary,
    label: I18nKeys.SitesEmailFormatSummary,
  },
  {
    key: EmailFormat.Invoice,
    label: I18nKeys.SitesEmailFormatInvoice,
  },
];

export const sendEmailsToOptions = [
  {
    key: SendEmailTo.None,
    label: I18nKeys.SitesSendEmailToNone,
  },
  {
    key: SendEmailTo.Default,
    label: I18nKeys.SitesSendEmailToDefault,
  },
  {
    key: SendEmailTo.SupplierAndDealer,
    label: I18nKeys.SitesSendEmailToSupplierAndDealer,
  },
  {
    key: SendEmailTo.DealerAndBuyerOnly,
    label: I18nKeys.SitesSendEmailToDealerAndBuyerOnly,
  },
];
