import React, { useEffect, useState } from 'react';
import { v1 as uuidV1 } from 'uuid';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector } from 'redux-form';
import { useAppDispatch, useAppSelector } from '../hooks';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { FormData, PricingBaseAddSizeForm } from './PricingBaseAddSizeForm';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingBaseAddSizeFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { addPricingBaseRow } from '../ducks/pricingSlice';
import { getPricingSheetDimensions } from '../utils/pricingSheetUtils';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheetDimension, PricingTab } from '../constants/Pricing';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  attributeHeader: {
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  list: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  listItem: {
    paddingTop: '0px',
    marginTop: '0px',
  },
}));

export const PricingBaseAddSizeDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pricingBaseSheets } = usePricingRepo({
    usePricingSheetBase: true,
  });

  const {
    viewer: { selectedPricingTabId: pricingTab = '' },
    pricing: {
      base: { selectedPricingSheetId },
    },
    dialog: { key },
  } = useAppSelector((state) => state);
  const [rowId, setRowId] = useState(uuidV1());

  useEffect(() => {
    if (key === Dialogs.PricingBaseAddSize) {
      setRowId(uuidV1());
    }
  }, [key, setRowId]);

  const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
  const { x, y } = getPricingSheetDimensions(pricingTab as PricingTab, GridViewType.Grid) as {
    x: PricingSheetDimension.Width | PricingSheetDimension.Length;
    y: PricingSheetDimension.Length | PricingSheetDimension.Height;
  };

  let initialYDimension = 1;
  let initialXDimension = Number.MIN_SAFE_INTEGER;
  let incrementDiff = 0;

  selectedPricingSheet?.prices?.forEach((p) => {
    if (p[y] === undefined || p[x] === undefined) return;
    const [xValue, yValue] = [p[x], p[y]] as [number, number];

    if (yValue > initialYDimension) {
      if (xValue === initialXDimension) {
        incrementDiff = yValue - initialYDimension;
      }
      initialYDimension = yValue;
    }
    if (xValue > initialXDimension) {
      initialXDimension = xValue;
      initialYDimension = yValue;
    }
  });

  const initialValues = {
    [PricingBaseAddSizeFormFields.Width]: initialXDimension,
    [PricingBaseAddSizeFormFields.Length]: initialYDimension + incrementDiff,
  } as FormData;

  const currentWidth = useAppSelector((state: AppState) =>
    formValueSelector(Forms.PricingBaseAddSize)(state, PricingBaseAddSizeFormFields.Width),
  );

  const currentLength = useAppSelector((state: AppState) =>
    formValueSelector(Forms.PricingBaseAddSize)(state, PricingBaseAddSizeFormFields.Length),
  );
  let hasPriceWithSize = false;
  if (selectedPricingSheet) {
    hasPriceWithSize = !!selectedPricingSheet?.prices?.some(
      (p) => p.width === currentWidth && p.length === currentLength && p.rowId !== rowId,
    );
  }
  const hasPriceWithZero = currentWidth === 0 || currentLength === 0;

  const onSave = () => {
    if (currentWidth && currentLength && !hasPriceWithSize && selectedPricingSheet) {
      dispatch(
        addPricingBaseRow({
          rows: [{ rowId, width: currentWidth, length: currentLength }],
          selectedPricingSheet,
        }),
      );
      dispatch(closeDialogFunc());
    }
  };

  return (
    <Dialog dialogKey={Dialogs.PricingBaseAddSize}>
      <DialogTitle className={classes.dialogTitle}>{t(I18nKeys.PricingBaseAddSizeDialogTitle)}</DialogTitle>
      <DialogContent>
        <PricingBaseAddSizeForm
          initialValues={initialValues}
          hasPriceWithSize={hasPriceWithSize}
          hasPriceWithZero={hasPriceWithZero}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSave} color="primary" disabled={hasPriceWithSize || hasPriceWithZero}>
          {t(I18nKeys.DialogTypeAdd)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
