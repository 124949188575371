import React, { useEffect } from 'react';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheetView } from './PricingSheetView';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedSizeBasedPricingSheetId } from '../ducks/pricingSlice';

export const PricingSizeBasedPricingSheets: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    pricing: {
      sizeBased: { selectedPricingSheetId: pricingSheetId },
    },
  } = useAppSelector((state) => state);

  const {
    clientUpdateRegions: regions = [],
    isLoadingClientUpdateRegions,
    sizeBasedCategoryPricingSheets: pricingSheets = [],
    isFetchingSizeBasedCategoryPricingSheets,
    isLoadingSizeBasedCategoryPricingSheets,
    errorLoadingSizeBasedCategoryPricingSheets,
    sizeBasedCategoryPricingSheetPrices: selectedPricingSheet,
    isFetchingSizeBasedCategoryPricingSheetPrices,
    isLoadingSizeBasedCategoryPricingSheetPrices,
    errorLoadingSizeBasedCategoryPricingSheetPrices,
    cellMetadata = [],
    isLoadingCellMetadata,
    cellMetadataDiff = [],
    clientUpdateDiffs = [],
  } = useClientUpdatePricingRepo({
    useClientUpdateRegions: true,
    useSizeBasedCategoryPricingSheets: true,
    useSizeBasedCategoryPricingSheetsPrices: true,
    useCellMetadata: true,
    useCellMetadataDiff: true,
    useClientUpdateDiffs: true,
  });

  useEffect(() => {
    if (!pricingSheetId && pricingSheets.length && !isFetchingSizeBasedCategoryPricingSheets)
      dispatch(setSelectedSizeBasedPricingSheetId(pricingSheets[0].id));
  }, [pricingSheetId, pricingSheets, isFetchingSizeBasedCategoryPricingSheets, dispatch]);

  return (
    <PricingSheetView
      gridViewType={GridViewType.Grid}
      selectedPricingSheetId={pricingSheetId}
      selectedPricingSheet={selectedPricingSheet}
      pricingSheets={pricingSheets}
      errorLoadingPricingSheets={
        errorLoadingSizeBasedCategoryPricingSheets || errorLoadingSizeBasedCategoryPricingSheetPrices
      }
      isFetchingPricingSheets={
        isFetchingSizeBasedCategoryPricingSheets || isFetchingSizeBasedCategoryPricingSheetPrices
      }
      isLoadingPricingSheets={
        isLoadingClientUpdateRegions || isLoadingSizeBasedCategoryPricingSheets || isLoadingCellMetadata
      }
      isLoadingPricingSheetPrices={isLoadingSizeBasedCategoryPricingSheetPrices}
      regions={regions}
      cellMetadata={cellMetadata}
      cellMetadataDiff={cellMetadataDiff}
      pricingDiffs={clientUpdateDiffs}
    />
  );
};
