/**
 * Undo Manager
 * Handles undo a redo of actions performed in the application.
 * Provides a stack for each clientDataTableId.
 *
 * Configured with options:
 * - limit: The maximum number of commands that can be undone. Default is unlimited.
 *
 * @class UndoManager
 * @classdesc Undo Manager
 */

interface Command {
  undo: () => void;
  redo: () => void;
}

interface UndoManagerOptions {
  limit?: number;
  debug?: boolean;
}

enum UndoManagerActions {
  Undo = 'undo',
  Redo = 'redo',
}

const DEFAULT_INDEX = -1;

export class UndoManager {
  private stacks: { [clientDataTableId: string]: Command[] } = {};

  private indexes: { [clientDataTableId: string]: number } = {};

  private limit = 0;

  private isExecuting = false;

  private debug = false;

  constructor(options?: UndoManagerOptions) {
    if (options?.limit) {
      this.limit = options.limit;
    }
    if (options?.debug) {
      this.debug = options.debug;
    }
  }

  public hasUndo(clientDataTableId: string): boolean {
    return this.getIndex(clientDataTableId) !== -1;
  }

  public hasRedo(clientDataTableId: string): boolean {
    return this.getIndex(clientDataTableId) < this.stacks[clientDataTableId].length - 1;
  }

  public getCommands(clientDataTableId: string): Command[] {
    return this.stacks[clientDataTableId] || [];
  }

  public getIndex(clientDataTableId: string): number {
    return this.indexes[clientDataTableId] === undefined ? DEFAULT_INDEX : this.indexes[clientDataTableId];
  }

  public setLimit(l: number): void {
    this.limit = l;
  }

  /**
   * Increments the index for the given clientDataTableId
   *
   * @param clientDataTableId
   */
  private incrementIndex(clientDataTableId: string): void {
    if (this.indexes[clientDataTableId] !== undefined) {
      this.indexes[clientDataTableId] += 1;
    } else {
      this.indexes[clientDataTableId] = 0;
    }
  }

  /**
   * Decrements the index for the given clientDataTableId
   *
   * @param clientDataTableId
   */
  private decrementIndex(clientDataTableId: string): void {
    if (this.indexes[clientDataTableId] !== undefined) {
      this.indexes[clientDataTableId] -= 1;
    } else {
      this.indexes[clientDataTableId] = 0;
    }
  }

  /**
   * Add a command to the undo manager
   * If the number of commands exceeds the limit, the oldest command is removed
   * To add and execute a command, use the execute parameter
   *
   * @param command
   * @param clientDataTableId
   * @param execute
   * @returns
   */
  public add(command: Command, clientDataTableId: string, execute = false): void {
    if (this.isExecuting) {
      this.warning('Execution in progress');
      return;
    }

    if (execute) {
      command.redo();
    }

    if (!this.stacks[clientDataTableId]) {
      this.stacks[clientDataTableId] = [];
      this.indexes[clientDataTableId] = DEFAULT_INDEX;
    }

    if (this.indexes[clientDataTableId] === this.stacks[clientDataTableId].length - 1) {
      this.stacks[clientDataTableId].push(command);
    } else {
      this.stacks[clientDataTableId] = this.stacks[clientDataTableId].slice(0, this.indexes[clientDataTableId] + 1);
      this.stacks[clientDataTableId].push(command);
    }
    this.incrementIndex(clientDataTableId);

    if (this.limit && this.stacks[clientDataTableId].length > this.limit) {
      this.stacks[clientDataTableId].shift();
      this.decrementIndex(clientDataTableId);
    }
    this.print('add', clientDataTableId);
  }

  /**
   * Undo the last command
   *
   * @param clientDataTableId
   * @returns
   * @memberof UndoManager
   * @throws {Error} If there is no command to undo
   * @throws {Error} If the undo command throws an error
   * @throws {Error} If the undo command is not a function
   */
  public undo(clientDataTableId: string): void {
    if (this.isExecuting || this.getIndex(clientDataTableId) < 0) {
      this.warning(
        this.isExecuting
          ? 'Execution in progress'
          : `No redo commands, current index: ${this.getIndex(clientDataTableId)}`,
      );
      return;
    }

    try {
      const command = this.stacks[clientDataTableId][this.getIndex(clientDataTableId)];
      if (!command) {
        this.warning(`Invalid command at index ${this.getIndex(clientDataTableId)}: ${command}`);
        return;
      }
      this.execute(command, UndoManagerActions.Undo);
    } catch (e) {
      this.isExecuting = false;
      throw new Error(`Undo command threw an error: ${e}`);
    }

    this.decrementIndex(clientDataTableId);
    this.print(UndoManagerActions.Undo, clientDataTableId);
  }

  /**
   * Redo the last command
   *
   * @param clientDataTableId
   * @returns
   * @memberof UndoManager
   * @throws {Error} If there is no command to redo
   * @throws {Error} If the redo command throws an error
   * @throws {Error} If the redo command is not a function
   */
  public redo(clientDataTableId: string): void {
    if (this.isExecuting || this.getIndex(clientDataTableId) >= (this.stacks[clientDataTableId] || []).length - 1) {
      this.warning(
        this.isExecuting
          ? 'Execution in progress'
          : `No redo commands, current index: ${this.getIndex(clientDataTableId)}`,
      );
      return;
    }

    try {
      const command = this.stacks[clientDataTableId][this.getIndex(clientDataTableId) + 1];
      if (!command) {
        this.warning(`Invalid command at index ${this.getIndex(clientDataTableId)}: ${command}`);
        return;
      }
      this.execute(command, UndoManagerActions.Redo);
    } catch (e) {
      this.isExecuting = false;
      throw new Error(`Redo command threw an error: ${e}`);
    }

    this.incrementIndex(clientDataTableId);
    this.print(UndoManagerActions.Redo, clientDataTableId);
  }

  /**
   * Clear the undo manager
   *
   * @memberof UndoManager
   * @returns
   * @throws {Error} If the clear command throws an error
   * @throws {Error} If the clear command is not a function
   * @throws {Error} If the clear command does not return a boolean
   */
  public clear(clientDataTableId: string): void {
    if (this.isExecuting) {
      this.warning('Execution in progress');
      return;
    }

    this.stacks[clientDataTableId] = [];
    this.indexes[clientDataTableId] = DEFAULT_INDEX;
    this.print('clear', clientDataTableId);
  }

  /**
   * Execute a command
   *
   * @param command
   * @param execute
   * @returns
   * @memberof UndoManager
   * @throws {Error} If the command is not a function
   * @throws {Error} If the command throws an error
   */
  private execute(command: Command, action: UndoManagerActions): void {
    if (!command || typeof command[action] !== 'function') {
      throw new Error(`Invalid command action: ${action}`);
    }
    this.isExecuting = true;
    command[action]();
    this.isExecuting = false;
  }

  private print(action: UndoManagerActions | string, clientDataTableId: string): void {
    if (!this.debug) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(`UndoManager - after ${action}:`, {
      index: this.getIndex(clientDataTableId),
      commands: this.stacks[clientDataTableId].map((c, i) => ({
        clientDataTableId,
        index: i,
        undo: c.undo?.toString(),
        redo: c.redo?.toString(),
      })),
    });
  }

  private warning(message: string): void {
    if (!this.debug) {
      return;
    }
    // eslint-disable-next-line no-console
    console.warn(`UndoManager - ${message}`);
  }
}

export const undoManager = new UndoManager();
