import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';

export const sesApi = createApi({
  reducerPath: 'sesApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/ses',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of all records in the suppression list
     *
     * @return {Promise}
     */
    getSuppressionList: builder.query<
      {
        emailAddress: string;
        reason: string;
        lastUpdate: Date;
      }[],
      void
    >({
      query: () => ({
        url: `/suppression-list`,
        method: 'get',
      }),
    }),

    /**
     * Remove an email address from the suppression list
     *
     * @param emailAddress
     * @return {Promise}
     */
    removeFromSuppressionList: builder.mutation<void, { emailAddress: string }>({
      query: ({ emailAddress }) => ({
        url: `/suppression-list/${emailAddress}`,
        method: 'post',
      }),
    }),
  }),
});

export const { useGetSuppressionListQuery, useRemoveFromSuppressionListMutation } = sesApi;
