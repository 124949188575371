import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientPublishedVersions } from '../constants/ClientData';
import { useGetClientName } from '../hooks/useGetClientName';

const useStyles = makeStyles({
  accordion: {
    width: '100%',
    border: 'none',
    '&:before': {
      height: '0px',
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    backgroundColor: 'white',
    border: 'none',
    padding: '0px',
    minHeight: '0px',
    '& .MuiAccordionSummary-content': {
      margin: '0px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  accordionDetails: {
    padding: '0px',
    paddingLeft: '24px',
  },
});

export const ClientDataSuppliersVersionBumpListDetails: React.FC<{
  clientPublishedVersions: ClientPublishedVersions;
}> = ({ clientPublishedVersions }) => {
  const classes = useStyles();
  const getClientName = useGetClientName();

  return (
    <div>
      {clientPublishedVersions?.suppliers
        ?.filter((supplier) => supplier.published?.structureVersion !== supplier.latestVersion)
        .map((supplier) => {
          const isVendorSupplierPublished = !!supplier?.published;
          return (
            <Accordion
              disableGutters
              square
              variant="outlined"
              key={supplier.key}
              classes={{ root: classes.accordion }}
            >
              <AccordionSummary classes={{ root: classes.accordionSummary }} expandIcon={<ChevronRightIcon />}>
                <Typography>
                  <Trans
                    i18nKey={
                      (isVendorSupplierPublished
                        ? I18nKeys.ClientDataPublishUpdatesSupplierVersionBump
                        : I18nKeys.ClientDataPublishUpdatesSupplierNotPublishedVersionBump) as string
                    }
                    values={{
                      supplier: getClientName(supplier.key),
                      currentVersion: supplier?.latestVersion || 0,
                      publishedVersion: supplier?.published?.structureVersion || 0,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {supplier.versions.map((version) => (
                  <Typography key={`${supplier.key}-${version.version}`} gutterBottom component="div">
                    - <strong>{version.version}</strong> by {version.author}: {version.message}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      {clientPublishedVersions?.system?.latestVersion !== clientPublishedVersions?.system?.publishedVersion && (
        <Accordion disableGutters square variant="outlined" classes={{ root: classes.accordion }}>
          <AccordionSummary classes={{ root: classes.accordionSummary }} expandIcon={<ChevronRightIcon />}>
            <Typography>
              <Trans
                i18nKey={I18nKeys.ClientDataPublishUpdatesReferenceVersionBump as string}
                values={{
                  currentVersion: clientPublishedVersions?.system?.latestVersion || 0,
                  publishedVersion: clientPublishedVersions?.system?.publishedVersion || 0,
                }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {clientPublishedVersions?.system?.versions.map((version) => (
              <Typography key={`reference-${version.version}`} gutterBottom component="div">
                - <strong>{version.version}</strong> by {version.author}: {version.message}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
