import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { ConfirmationState } from '../types/ConfirmationState';
import { closeDialog } from './dialogSlice';

// Action types

export enum ConfirmationActionTypes {
  REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION',
  CONFIRM = 'CONFIRM',
  // Cancellation is handled by the Dialog itself
}

const defaultConfirmationValues: any = {
  confirmationDescription: I18nKeys.ConfirmationDialogDefaultDescription,
  confirmationButtonText: I18nKeys.ConfirmationDialogConfirmButton,
  cancelButtonText: I18nKeys.DialogCancelButton,
  keepDialogOpen: false,
  id: 'generic',
};

// Reducer

export const INITIAL_CONFIRMATION_STATE: ConfirmationState = {
  pendingConfirmationActions: [closeDialog()],
  pendingConfirmationFunctions: [],
  confirmationTitle: i18n.t(defaultConfirmationValues.confirmationTitle),
  confirmationDescription: i18n.t(defaultConfirmationValues.confirmationDescription),
  confirmationButtonText: i18n.t(defaultConfirmationValues.confirmationButtonText),
  cancelButtonText: i18n.t(defaultConfirmationValues.cancelButtonText),
  keepDialogOpen: defaultConfirmationValues.keepDialogOpen,
  id: defaultConfirmationValues.id,
};

export const confirmationReducer: Reducer<ConfirmationState> = (
  state = INITIAL_CONFIRMATION_STATE,
  confirmationAction,
) => {
  switch (confirmationAction.type) {
    case ConfirmationActionTypes.REQUEST_CONFIRMATION: {
      const {
        payload: {
          pendingConfirmationActions,
          pendingConfirmationFunctions,
          confirmationTitle,
          confirmationDescription,
          confirmationButtonText,
          cancelButtonText,
          keepDialogOpen,
          id,
        },
      } = confirmationAction;

      return {
        ...state,
        pendingConfirmationActions,
        pendingConfirmationFunctions,
        confirmationTitle: confirmationTitle || i18n.t(defaultConfirmationValues.confirmationTitle),
        confirmationDescription: confirmationDescription || i18n.t(defaultConfirmationValues.confirmationDescription),
        confirmationButtonText: confirmationButtonText || i18n.t(defaultConfirmationValues.confirmationButtonText),
        cancelButtonText: cancelButtonText || i18n.t(defaultConfirmationValues.cancelButtonText),
        keepDialogOpen: keepDialogOpen || defaultConfirmationValues.keepDialogOpen,
        id: id || defaultConfirmationValues.id,
      };
    }
    default:
      return state;
  }
};

// Action creators

export const openConfirmationDialog = (
  pendingConfirmationActions: AnyAction[],
  pendingConfirmationFunctions: Function[],
  confirmationTitle: string | undefined,
  confirmationDescription: string,
  confirmationButtonText?: string,
  cancelButtonText?: string,
  keepDialogOpen?: boolean,
  id?: string,
): AnyAction =>
  action(ConfirmationActionTypes.REQUEST_CONFIRMATION, {
    pendingConfirmationActions,
    pendingConfirmationFunctions,
    confirmationTitle,
    confirmationDescription,
    confirmationButtonText,
    cancelButtonText,
    keepDialogOpen,
    id,
  });
