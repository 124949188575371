import { DepositAmount } from '../types/DepositAmount';

export const getAsFloat = (value: string | number): number => {
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
};

export const getDepositAmountExpression = (depositAmounts: DepositAmount[]): string => {
  if (!depositAmounts) return '';
  const [firstDepositAmount] = depositAmounts;

  if (depositAmounts.length === 1) {
    if (firstDepositAmount.price) {
      return `subtotalPrice >= ${getAsFloat(firstDepositAmount.price)} ? ${getAsFloat(firstDepositAmount.percent)} : 0`;
    }

    return firstDepositAmount.percent.toString();
  }
  if (depositAmounts.length > 1) {
    // reverse a copy so the UI doesn't update
    const reversedDepositAmounts = [...depositAmounts].reverse();

    const depositAmountExpression = reversedDepositAmounts.reduce<string[]>((result, depositAmount) => {
      const price = getAsFloat(depositAmount.price);
      const percent = getAsFloat(depositAmount.percent);
      if (price !== undefined && percent !== undefined) {
        result.push(`subtotalPrice >= ${price} ? ${percent}`);
      }

      return result;
    }, []);

    return `${depositAmountExpression.join(' : ')} : 0`;
  }
  return '';
};
