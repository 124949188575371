import React from 'react';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Region } from '../types/Region';
import { I18nKeys } from '../constants/I18nKeys';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { PricingBaseHiddenPriceHeader } from './PricingBaseHiddenPriceHeader';
import { PricingSheet } from '../types/PricingSheet';
import { getPricingSheetDefaultLabel } from '../utils/pricingSheetUtils';

const useStyles = makeStyles(() => ({
  pricingSheetLabel: {
    fontSize: '20px',
    paddingBottom: '4px',
    fontWeight: 'bold',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover + $hiddenUntilHover': {
      display: 'flex',
    },
  },
  attributesLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: '12px',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  hiddenUntilHover: {
    display: 'none',
    '&:hover': {
      display: 'flex',
    },
  },
  hoverContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topTextContainer: {
    flex: 1,
    overflow: 'hidden',
  },
}));

type Props = {
  selectedPricingSheetId?: string;
  pricingSheets: PricingSheet[];
  regions: Region[];
};

export const PricingSheetHeader: React.FC<Props> = ({ selectedPricingSheetId, pricingSheets, regions }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { selectedPricingTabId: pricingTab = '' } = useAppSelector((state) => state?.viewer);

  const sheet = pricingSheets.find(({ id }) => id === selectedPricingSheetId);
  const sheetName = sheet?.priceSetLabel || getPricingSheetDefaultLabel(sheet, pricingTab);
  const attributesLabel =
    sheet?.attributes?.reduce(
      (label, attr) => `${!label || !attr.label ? label : `${label}, `}${attr.label || ''}`,
      '',
    ) || '';

  return (
    <div className={classes.topTextContainer}>
      {selectedPricingSheetId && (
        <div className={classes.hoverContainer}>
          <Typography
            className={classes.pricingSheetLabel}
            onClick={() => {
              dispatch(openDialog({ dialog: Dialogs.PricingSheetName }));
            }}
            noWrap
          >
            {sheetName || t(I18nKeys.PricingBaseAccordionPricingSheetTitle, { pricingSheetId: selectedPricingSheetId })}
          </Typography>

          <Tooltip title={t(I18nKeys.PricingBaseAccordionEditSheetTitleDialogTitle)} style={{ height: '20px' }} arrow>
            <IconButton
              onClick={() => {
                dispatch(openDialog({ dialog: Dialogs.PricingSheetName }));
              }}
              className={classes.hiddenUntilHover}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}{' '}
      {/* According to design, no attribute label is shown if sheet is named */}
      {attributesLabel && !sheetName && (
        <Typography className={classes.attributesLabel} variant="body2" noWrap>
          {attributesLabel}
        </Typography>
      )}
      <PricingBaseHiddenPriceHeader regions={regions} />
    </div>
  );
};
