export enum ClientDataFixedColumns {
  Enabled = 'enabled',
  Order = 'order',
  Product = 'product',
  RowId = 'rowId',
  SupplierKey = 'supplierKey',
  SourceSheet = 'sourceSheet',
  VendorKey = 'vendorKey',
  ModifyTimestamp = 'modifyTimestamp',
  Index = 'index', // Auto-generated column
}
