import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { NotificationState } from '../types/NotificationState';

// Action types

export enum NotificationActionTypes {
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
  OPEN_NOTIFICATION = 'OPEN_NOTIFICATION',
}

// Reducer

export const INITIAL_NOTIFICATION_STATE: NotificationState = {
  notificationTitle: undefined,
  notificationContent: undefined,
};

export const notificationReducer: Reducer<NotificationState> = (
  state = INITIAL_NOTIFICATION_STATE,
  notificationAction,
) => {
  switch (notificationAction.type) {
    case NotificationActionTypes.CLOSE_NOTIFICATION: {
      return { notificationTitle: undefined, notificationContent: undefined };
    }

    case NotificationActionTypes.OPEN_NOTIFICATION: {
      const {
        payload: { notificationTitle, notificationContent },
      } = notificationAction;

      return {
        ...state,
        notificationTitle,
        notificationContent,
      };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const openNotificationDialog = (notificationTitle: string, notificationContent: string): AnyAction =>
  action(NotificationActionTypes.OPEN_NOTIFICATION, { notificationTitle, notificationContent });

export const closeNotificationDialog = (): AnyAction => action(NotificationActionTypes.CLOSE_NOTIFICATION);
