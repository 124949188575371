import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { navigate } from 'hookrouter';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { AppRoutes, AppRoutesWithConfirmation } from '../constants/AppRoutes';
import {
  setSelectedClientId as setSelectedClientIdFunc,
  resetClientIds as resetClientIdsFunc,
} from '../ducks/viewerSlice';
import { fetchGroups } from '../ducks/groups';
import { openSalesViewWhatsNew } from '../ducks/settings';
import { AppState } from '../types/AppState';
import { GroupData } from '../types/Group';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { SearchInput } from './SearchInput';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { Language } from '../constants/Languages';
import { onTableMenuKeyboardShortcut } from '../utils/keyboardShortcutHandlerUtils';
import { AnchorMenu } from './AnchorMenu';
import { fuzzyMatchIncludes } from '../utils/stringUtils';
import { getUnsavedChangesMessage } from '../utils/viewerUtils';
import { changeGroup, signOut as signOutFunc } from '../ducks/currentUserSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: { textTransform: 'none', width: '100%', display: 'flex', color: theme.palette.text.primary },
  buttonText: { flex: 1, marginLeft: '8px' },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: '42px',
    height: '42px',
  },
  user: {
    padding: '5px 16px',
  },
  groupsList: {
    maxHeight: '500px',
    overflow: 'overlay',
  },
  search: {
    padding: '16px',
  },
}));

interface StateProps {
  email?: string;
  name?: string;
  userInitials?: string;
  groupId?: string;
  groupName?: string;
  groups?: GroupData[];
  defaultClientId: string;
  languages: Language[];
}

interface DispatchProps {
  openSalesViewWhatsNewDialog(): void;
  openLanguageDialog(): void;
  dispatchAll(actions: AnyAction[]): void;
  confirmUnsavedChanges(actions: AnyAction[], functions: Function[], path: AppRoutesWithConfirmation): void;
  setSelectedClientId(clientId: string): void;
  resetClientIds(): void;
}

type Props = StateProps & DispatchProps;

const UserMenuItemComponent: React.FC<Props> = ({
  email,
  name,
  confirmUnsavedChanges,
  openSalesViewWhatsNewDialog,
  openLanguageDialog,
  dispatchAll,
  userInitials,
  groupId,
  groupName,
  groups = [],
  defaultClientId,
  setSelectedClientId,
  resetClientIds,
  languages,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [matchingGroups, setMatchingGroups] = React.useState<GroupData[]>([]);
  const [matchingGroupOptions, setMatchingGroupOptions] = React.useState<{ key: string; value: string }[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const resetSearch = React.useCallback(() => setSearchValue(''), []);
  const [currentLanguage, setCurrentLanguage] = React.useState<Language | undefined>(undefined);

  const [checkedIndex, setCheckedIndex] = React.useState<number>(0);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (groupId) {
      setCheckedIndex(matchingGroupOptions.findIndex((group) => group.key === groupId));
    }
  }, [groupId, groups, matchingGroups]);

  React.useEffect(() => {
    let displayedGroups;
    if (searchValue.length < 2) {
      displayedGroups = groups;
    } else {
      displayedGroups = groups.filter((group) => {
        const { groupName: filterName, groupId: filterId, configurators = [] } = group;
        return [filterName, filterId, ...configurators.map(({ vendor = '' }) => vendor)].some(
          (v) => v && fuzzyMatchIncludes(v, searchValue),
        );
      });
    }

    setMatchingGroups(displayedGroups);
    setMatchingGroupOptions(
      displayedGroups.map((group) => ({ key: group.groupId || '', value: group.groupName || '' })),
    );
    setSelectedIndex(0);
  }, [searchValue, groups, setMatchingGroups]);

  React.useEffect(() => {
    setCurrentLanguage(languages.find((lng: Language) => lng.key === i18n.language));
  }, [languages]);

  const getChildNodeById = (parentEl: HTMLElement | null, id: string): HTMLElement | undefined =>
    Array.from(parentEl?.children || []).find((node) => node?.id === id) as HTMLElement | undefined;

  React.useEffect(() => {
    if (anchorEl) searchRef.current?.focus();
  }, [anchorEl]);

  const menuListRef = React.useRef<HTMLUListElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    resetSearch();
  };

  const focusMenuItem = (index: number) => {
    setSelectedIndex(index);
  };

  const clickMenuItem = () => {
    const id = matchingGroups[selectedIndex]?.groupId;
    if (id) {
      const child = getChildNodeById(menuListRef?.current as HTMLUListElement, id);
      child?.click();
    }
  };

  const handleGroupMenuItemClick = (selectedGroupId: string): void => {
    handleClose();
    const actions = [changeGroup(selectedGroupId), fetchGroups()];
    resetClientIds();
    dispatchAll(actions);
  };

  const handleProfileClicked = (): void => {
    setSelectedClientId(defaultClientId);
    navigate(AppRoutes.Profile);
  };

  const handleSignOutClicked = (): void => {
    const actions = [signOutFunc()];
    resetClientIds();
    dispatchAll(actions);
  };

  return (
    <div className={classes.root}>
      <Button
        id="userMenu"
        className={classes.button}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Avatar className={classes.avatar}>{userInitials}</Avatar>
        <Typography color="textPrimary" component="div" className={classes.buttonText}>
          <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h6.fontSize" lineHeight={1.2}>
            {name || email}
          </Box>
          <Box textAlign="left" fontWeight="fontWeightLight">
            {groupName}
          </Box>
        </Typography>
      </Button>
      <AnchorMenu
        menuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        maxMenuHeight="500px"
        menuAnchorEl={anchorEl}
        listRef={menuListRef}
        onClose={handleClose}
        options={matchingGroupOptions}
        checkedOptions={[matchingGroupOptions[checkedIndex]?.key]}
        selectedOptions={[matchingGroupOptions[selectedIndex]?.key]}
        checkedIcon={<CheckIcon />}
        onClick={(id): void => handleGroupMenuItemClick(id)}
        onKeyDown={(i: number) => (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) =>
          onTableMenuKeyboardShortcut(e, { currentIndex: i, focusMenuItem, clickMenuItem })}
        searchInput={
          groups &&
          groups.length > 10 && (
            <MenuItem
              key="group-search"
              className={classes.search}
              onKeyDown={(e): void => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            >
              <SearchInput
                ref={searchRef}
                autoComplete="off"
                searchTerm={searchValue}
                startAdornment={<SearchIcon />}
                onClearClick={resetSearch}
                onChange={setSearchValue}
                size="small"
                onKeyDown={(e) =>
                  onTableMenuKeyboardShortcut(e, { currentIndex: selectedIndex, focusMenuItem, clickMenuItem })
                }
              />
            </MenuItem>
          )
        }
        pinnedBottomOptions={[
          ...(groups && groups.length > 1 ? [<Divider key="menu-divider-1" />] : []),
          <MenuItem key={I18nKeys.UserMenuItemMyProfile} onClick={handleProfileClicked}>
            <ListItemText primary={t(I18nKeys.UserMenuItemMyProfile)} />
          </MenuItem>,
          <MenuItem
            key={I18nKeys.WhatsNew}
            onClick={(): void => {
              openSalesViewWhatsNewDialog();
              handleClose();
            }}
          >
            <ListItemText primary={t(I18nKeys.WhatsNew)} />
          </MenuItem>,
          ...(languages.filter((lng: Language) => !lng.hidden).length > 1
            ? [
                <MenuItem key="select-language" onClick={openLanguageDialog}>
                  <ListItemText
                    primary={(currentLanguage && currentLanguage.label) || t(I18nKeys.UserMenuItemLanguage)}
                  />
                </MenuItem>,
              ]
            : []),
          <Divider key="menu-divider-2" />,
          <MenuItem key={I18nKeys.UserMenuItemLogOut}>
            <ListItemText onClick={handleSignOutClicked} primary={t(I18nKeys.UserMenuItemLogOut)} />
          </MenuItem>,
        ]}
      />
    </div>
  );
};

const mapStateToProps = ({
  currentUser: { user, group, groups },
  viewer: { defaultClientId = '', languages = [] },
}: AppState): StateProps => {
  let email;
  let firstName;
  let name;
  let userInitials;
  let groupName;
  let groupId;

  if (user) {
    ({ email, firstName = '', name } = user);

    const { lastName = '' } = user;
    userInitials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }

  if (group) {
    ({ groupId, groupName } = group);
  }

  return {
    email,
    groupId,
    groupName,
    groups,
    name,
    userInitials,
    defaultClientId,
    languages,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  confirmUnsavedChanges: (actions: AnyAction[], functions: Function[], path: AppRoutesWithConfirmation): void => {
    dispatch(
      openConfirmationDialog(
        actions,
        functions,
        i18n.t(I18nKeys.UnsavedChangesConfirmationTitle),
        getUnsavedChangesMessage(path, i18n.t),
        i18n.t(I18nKeys.UnsavedChangesConfirmationConfirmButton),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  },
  dispatchAll: (actions: AnyAction[]): void => {
    actions.forEach((action) => dispatch(action));
  },
  openSalesViewWhatsNewDialog: (): AnyAction => dispatch(openSalesViewWhatsNew()),
  openLanguageDialog: (): AnyAction => dispatch(openDialog({ dialog: Dialogs.Language })),
  resetClientIds: (): AnyAction => dispatch(resetClientIdsFunc()),
  setSelectedClientId: (clientId: string): AnyAction => dispatch(setSelectedClientIdFunc(clientId)),
});

export const UserMenuItem = connect(mapStateToProps, mapDispatchToProps)(UserMenuItemComponent);
