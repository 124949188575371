/* eslint-disable no-console */
/* eslint-disable no-dupe-class-members */
import { gtagPrepareArguments } from './gtagPrepareArguments';
import { gtag } from './gtag';
import { StandardEventProps } from './AnalyticsTypes';

/**
 * Class for managing interactions with Google Analytics. Designed to help make interactions simpler and provide
 *   parameter hints and structure.
 *
 * Google Analytics script is fetched in gtag so no need to import in this class.
 *
 * The class is not exported because we only want one instance of this class - import googleAnalyticsInstance instead.
 *
 * Usage:
 *   import { googleAnalyticsInstance } from './googleAnalyticsInstance';.
 *   googleAnalyticsInstance.initializeStandardExtraProps(...);
 *   googleAnalyticsInstance.sendStandardEvent(...);
 */
class GoogleAnalytics {
  standardEventExtraProps?: {
    environmentKey: string;
    productKey: string;
    username: string;
    vendorKey: string;
  };

  constructor() {
    this.standardEventExtraProps = undefined;

    this.sendUnstructuredEvent('js', new Date());
  }

  /**
   * Define some properties (custom dimensions) that will be sent with every Google Analytics event.
   *
   * This should be called before sendStandardEvent to ensure that all of these properties are sent with every event.
   */
  initializeStandardExtraProps(environmentKey: string, productKey: string, username: string, groupId: string): void {
    if (!environmentKey) {
      const message = 'GoogleAnalytics initializeStandardExtraProps - "environmentKey" argument is missing.';
      console.error(message);
    }
    if (!productKey) {
      const message = 'GoogleAnalytics initializeStandardExtraProps - "productKey" argument is missing.';
      console.error(message);
    }
    if (!username) {
      const message = 'GoogleAnalytics initializeStandardExtraProps - "username" argument is missing.';
      console.error(message);
    }
    if (!groupId) {
      const message = 'GoogleAnalytics initializeStandardExtraProps - "groupId" argument is missing.';
      console.error(message);
    }

    this.standardEventExtraProps = {
      environmentKey,
      productKey,
      username,
      vendorKey: groupId,
    };
  }

  /**
   * Call this to associate a Google Analytics or Google Ads property with this instance. Any event sent after this
   *   point will be sent to all properties associated with addTrackerId for the duration of the session on the webpage.
   */
  addTrackerId(trackerId: string): void {
    this.sendUnstructuredEvent('config', trackerId, {
      // Using cookie_flags: 'SameSite=None; Secure' so that embedded configurators will be able to
      // use gtag.js. The default for modern browsers changed from 'SameSite=None' to 'SameSite=Lax'
      // to protect against CSRF attacks. Using 'SameSite=None; Secure' is considered OK however.
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
      cookie_flags: 'SameSite=None; Secure', // eslint-disable-line
      // debug_mode: true, // eslint-disable-line
    });
  }

  // Type declaration for all usages of sendUnstructuredEvent (basically gtag function)
  public sendUnstructuredEvent(command: 'config', targetId: string, config?: any): void;

  public sendUnstructuredEvent(command: 'set', config: any): void;

  public sendUnstructuredEvent(command: 'js', config: Date): void;

  public sendUnstructuredEvent(command: 'event', eventName: string, eventProps?: any): void;

  // Event that does not necessarily play by rules of "StandardEventProps"
  sendUnstructuredEvent(): void {
    // Similar to using "arguments" as is, but allows us to attach extra dimensions consistently.
    // eslint-disable-next-line prefer-rest-params
    const preparedArguments = gtagPrepareArguments(arguments, this.standardEventExtraProps);
    gtag(...preparedArguments);
  }

  /**
   * Send a Google Analytics event that plays by these rules:
   * - eventKey provided
   * - eventProps included and contains eventCategory and eventDescription.
   *
   * If these conditions are not met, the event will still be sent
   */
  sendStandardEvent(eventKey: string, eventProps: StandardEventProps): void {
    if (!eventKey) {
      const message = `GoogleAnalytics sendStandardEvent - eventKey is missing.`;
      console.error(message);
    }
    if (!eventProps) {
      const message = `GoogleAnalytics sendStandardEvent - eventProps is missing.`;
      console.error(message);
    }
    if (eventProps && !eventProps.eventCategory) {
      const message = `GoogleAnalytics sendStandardEvent - eventProps.eventCategory is missing.`;
      console.error(message);
    }
    if (eventProps && !eventProps.eventDescription) {
      const message = `GoogleAnalytics sendStandardEvent - eventProps.eventDescription is missing.`;
      console.error(message);
    }

    this.sendUnstructuredEvent('event', eventKey, eventProps);
  }
}

export const googleAnalyticsInstance = new GoogleAnalytics();
