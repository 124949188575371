import React from 'react';
import { makeStyles } from '@mui/styles';
import newIcon from '../images/new.svg';
import waitingIcon from '../images/waiting.svg';
import warningIcon from '../images/warning.svg';

const useStyles = makeStyles({
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
});

export enum MenuIconKey {
  NEW = 'new',
  ATTENTION = 'attention',
  WARNING = 'warning',
}

export const MenuIcon: React.FC<{ iconKey?: MenuIconKey }> = ({ iconKey }) => {
  const classes = useStyles();

  switch (iconKey) {
    case MenuIconKey.NEW:
      return <img alt="New" className={classes.icon} src={newIcon} />;
    case MenuIconKey.ATTENTION:
      return <img alt="Attention" className={classes.icon} src={waitingIcon} />;
    case MenuIconKey.WARNING:
      return <img alt="Warning" className={classes.icon} src={warningIcon} />;
    default:
      return <div />;
  }
};
