import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { getDateAsText } from '../utils/dateUtils';
import { WhatsNew } from '../types/WhatsNew';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  whatsNewCards: {
    flex: 1,
  },
  whatsNewCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  whatsNewCardHeader: { paddingBottom: '0' },
  whatsNewCardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  whatsNewCardContentLine: {
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  closeDialog(whatsNew: WhatsNew): void;
  loading: boolean;
  whatsNewRecords: WhatsNew[];
};

export const WhatsNewPreview = ({ loading, whatsNewRecords, closeDialog }: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <DialogTitle>What&apos;s New</DialogTitle>
      {loading && (
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
          </div>
        </DialogContent>
      )}
      {!loading && (
        <DialogContent>
          <div className={classes.whatsNewCards}>
            {whatsNewRecords.map((record) => (
              <Card className={classes.whatsNewCard} key={record.id}>
                <CardHeader
                  className={classes.whatsNewCardHeader}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={`${record.title}: ${getDateAsText(record.date)}`}
                />
                <CardContent className={classes.whatsNewCardContent}>
                  <Box
                    className={classes.whatsNewCardContentLine}
                    dangerouslySetInnerHTML={{
                      __html: record.message,
                    }}
                  />
                </CardContent>
              </Card>
            ))}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={(): void => closeDialog(whatsNewRecords[0])} color="primary">
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </>
  );
};
