import {
  Grid2 as Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import { AppState } from '../types/AppState';
import { useAppSelector, useAppDispatch } from '../hooks';
import { SearchType, SearchOptions, defaultSearchOptions, KeyboardKeys } from '../constants/ClientData';
import { capitalizeFirstLetter, compoundCaseToKebabCase } from '../utils/stringUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { GridData } from '../types/DataGrid';
import { getClientSearchData, getMaxFindAllSectionHeight, replaceValues } from '../utils/searchUtils';
import { ClientDataSearchActions } from './ClientDataSearchActions';
import { ClientDataSearchFindAll } from './ClientDataSearchFindAll';
import {
  setSearchType,
  setSearchValue as setSearchValueFunc,
  setReplaceValue as setReplaceValueFunc,
  setSearchOptions,
  replaceValues as replaceValuesAction,
  fetchSearchResults as fetchSearchResultsFunc,
  getSearchResultIndex as getSearchResultIndexFunc,
  setSearchResultIds,
  setReplacedResultIds,
} from '../ducks/clientDataSlice';
import { ClientDataSearchButtons } from './ClientDataSearchButtons';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { IDEAROOM, getVendorFromClientId, mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import replaceLeadingIconSrc from '../images/replaceLeadingIcon.svg';
import { SearchInput } from './SearchInput';

const useStyles = makeStyles(() => ({
  searchContainer: {
    padding: '8px',
  },
  searchItem: {
    padding: '8px',
  },
  searchField: {
    height: '56px',
  },
  searchTypeSelect: {
    '& .MuiInputBase-input': {
      paddingTop: '16.5px',
      paddingBottom: '16.5px',
    },
  },
  selectItem: {
    padding: '8px 16px',
  },
  selectItemSectionEnd: {
    padding: '8px 16px 10px 16px',
    borderBottom: '0.5px solid #D8D8D8',
  },
  selectItemSectionStart: {
    padding: '10px 16px 8px 16px',
    borderTop: '0.5px solid #D8D8D8',
  },
  searchOptionsCheckbox: {
    '& > .MuiCheckbox-root': {
      padding: '0px 9px',
    },
  },
}));

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

export const ClientDataSearch: React.FC<Props> = ({ gridRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientId,
    clientDataType,
    clientDataBranch,
    selectedTable = '',
    search: {
      value: initialSearchValue = '',
      type: searchType = SearchType.AllTables,
      options: searchOptions = defaultSearchOptions,
      open = false,
      result: { loading = false, ids: resultIds = [], index: resultIndex = undefined } = {},
    } = {},
  } = useAppSelector((state: AppState) => state?.clientData);
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const clientDataId = mapClientAndDataTypeAndTableToUndoStackId(selectedViewerId, clientDataType, selectedTable);
  const { clientTables, cellMetadata } = useClientDataRepo({
    useClientTables: true,
    useCellMetadata: true,
  });
  const selectedTableName =
    clientTables.find((table) => table.formattedTableName === selectedTable)?.label || selectedTable;
  const { api: gridApi, columnApi: gridColApi } = gridRef?.current || {};

  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [replaceValue, setReplaceValue] = useState('');
  const [searchData, setSearchData] = useState<{ [vendor: string]: GridData }>({});

  // Ensure that search data is always indexed by vendor for consistency
  const setSearchDataFunc = (data: { [id: string]: GridData }) => {
    setSearchData(
      Object.entries(data).reduce(
        (vendorSearchData, [id, clientSearchData]) => ({
          ...vendorSearchData,
          [getVendorFromClientId(id)]: clientSearchData,
        }),
        {} as { [vendor: string]: GridData },
      ),
    );
  };

  const replaceParams = {
    searchData: getClientSearchData(searchData, clientId),
    searchValue,
    replaceValue,
    searchOptions,
    onSearchComplete: setSearchDataFunc,
    cellMetadata,
    selectedTable,
    dispatch,
    gridApi,
    gridColApi,
  };

  const resetSearchOptions = () => dispatch(setSearchOptions(defaultSearchOptions));
  const resetSearchData = () => {
    setSearchData({});
    dispatch(setSearchResultIds(undefined));
    dispatch(setReplacedResultIds([]));
  };

  const fetchSearchResults = () =>
    dispatch(fetchSearchResultsFunc({ onSearchComplete: setSearchDataFunc, gridApi, gridColApi, resetSearch: true }));

  const searchHandler = (searchString: string) => {
    setSearchValue(searchString);
    dispatch(setSearchValueFunc(searchString));
    fetchSearchResults();
  };
  const debouncedSearchHandler = useMemo(() => debounce(searchHandler, 300), [setSearchValue]);

  const replaceHandler = (replaceString: string) => {
    setReplaceValue(replaceString);
    dispatch(setReplaceValueFunc(replaceString));
  };
  const debouncedReplaceHandler = useMemo(() => debounce(replaceHandler, 300), [setReplaceValue]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(
    () => () => {
      debouncedSearchHandler.cancel();
      debouncedReplaceHandler.cancel();
    },
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const rangeSelectionChangedRef = useRef(() => {});
  // Refetches when range changes
  useEffect(() => {
    gridApi?.removeEventListener('rangeSelectionChanged', rangeSelectionChangedRef.current);
    const listener = () => {
      if (searchType === SearchType.CurrentSelection) {
        fetchSearchResults();
      }
    };
    rangeSelectionChangedRef.current = listener;
    gridApi?.addEventListener('rangeSelectionChanged', listener);
  }, [searchType]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const cellValueChangedRef = useRef(() => {});
  useEffect(() => {
    gridApi?.removeEventListener('cellValueChanged', cellValueChangedRef.current);
    const listener = () => {
      if (!open && searchValue) {
        // Reset the search after a cell value changes if the search is closed
        dispatch(setSearchValueFunc(''));
        dispatch(setSearchType(SearchType.CurrentTable));
        resetSearchData();
        resetSearchOptions();
        // Fetch the search results after a cell value changes if the search is open
      } else if (open && searchValue) fetchSearchResults();
    };
    cellValueChangedRef.current = listener;
    gridApi?.addEventListener('cellValueChanged', listener);
  }, [open, searchValue]);

  // Refetches when table changes
  useEffect(() => {
    if (!selectedTable) return;
    if (
      [SearchType.AllVendorsCurrentTable, SearchType.CurrentSelection, SearchType.CurrentTable].includes(searchType)
    ) {
      fetchSearchResults();
    }
  }, [selectedTable]);

  // Refetches when client or branch changes
  useEffect(() => {
    if (!clientId || !clientDataType || !clientDataBranch) return;
    if (![SearchType.AllVendorsAllTables].includes(searchType)) {
      fetchSearchResults();
    }
  }, [clientId, clientDataType, clientDataBranch, searchOptions[SearchOptions.MatchCase]]);

  const onSearchTypeChange = (e: SelectChangeEvent<string>) => {
    const selectedSearchType = e.target.value as SearchType;
    dispatch(setSearchType(selectedSearchType));
    resetSearchData();
    resetSearchOptions();
    if ([SearchType.AllVendorsCurrentTable, SearchType.AllVendorsAllTables].includes(selectedSearchType)) {
      dispatch(setSearchOptions({ ...defaultSearchOptions, findAll: true }));
    }
    if (searchValue) debouncedSearchHandler(searchValue);
  };

  const disableIndices =
    !resultIds.length ||
    loading ||
    [SearchType.AllVendorsCurrentTable, SearchType.AllVendorsAllTables].includes(searchType);
  const getSearchResultIndex = (increment: number, onComplete?: () => void) => {
    if (disableIndices) return;
    dispatch(getSearchResultIndexFunc({ increment, gridApi, gridColApi, onComplete }));
  };

  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Move to next result with (ctrl || cmd) + g or enter
    if (
      ((event.ctrlKey || event.metaKey) && !event.shiftKey && event.key === 'g') ||
      event.key === KeyboardKeys.Enter
    ) {
      event.preventDefault();
      getSearchResultIndex(1, () => searchInputRef.current?.focus());
    }
    // Move to previous result with (ctrl || cmd) + shift + g
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'g') {
      event.preventDefault();
      getSearchResultIndex(-1, () => searchInputRef.current?.focus());
    }
  };

  const searchRootRef = useRef<HTMLDivElement>(null);
  const findAllSectionRef = useRef<HTMLDivElement>(null);

  return (
    <Paper ref={searchRootRef} className={classes.searchContainer}>
      <Grid container>
        <Grid size="grow">
          <Grid container size="grow">
            <Grid size="grow" className={classes.searchItem}>
              <SearchInput
                ref={searchInputRef}
                classes={classes}
                searchTerm={searchValue}
                startAdornment={<Search />}
                autoFocus
                variant="filled"
                placeholder={I18nKeys.ClientDataSearchPlaceholder}
                displayCloseButton={false}
                resultsDisplay={{ count: resultIds.length, index: resultIndex, loading }}
                onChange={(val) => {
                  setSearchValue(val);
                  debouncedSearchHandler(val);
                }}
                onKeyDown={onInputKeyDown}
              />
            </Grid>
            <Grid size={{ xs: 6 }} className={classes.searchItem}>
              <FormControl fullWidth>
                <Select
                  variant="filled"
                  value={searchType}
                  className={classes.searchTypeSelect}
                  onChange={onSearchTypeChange}
                >
                  {Object.entries(SearchType)
                    // TODO: Remove this filter when sc-28019 is implemented
                    .filter((type) => {
                      const [_, val] = type;
                      if (
                        val === SearchType.AllVendorsAllTables ||
                        (clientId.startsWith(IDEAROOM) && val === SearchType.AllVendorsCurrentTable)
                      )
                        return false;
                      return true;
                    })
                    .map(([key, val], i, arr) => (
                      <MenuItem
                        className={
                          (val === SearchType.AllTables && i !== arr.length - 1 && classes.selectItemSectionEnd) ||
                          (val === SearchType.AllVendorsCurrentTable && classes.selectItemSectionStart) ||
                          classes.selectItem
                        }
                        value={val}
                        key={val}
                        id={val}
                      >
                        {t(`search-type-${compoundCaseToKebabCase(key)}`, {
                          dataset: capitalizeFirstLetter(clientDataType),
                          sheet: selectedTableName,
                        })}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {searchOptions[SearchOptions.ShowReplace] && (
            <Grid size={{ xs: 12 }} className={classes.searchItem}>
              <SearchInput
                classes={classes}
                variant="filled"
                placeholder={I18nKeys.ClientDataSearchReplacePlaceholder}
                searchTerm={replaceValue}
                onChange={(val) => {
                  setReplaceValue(val);
                  debouncedReplaceHandler(val);
                }}
                startAdornment={<img src={replaceLeadingIconSrc} alt="Replace" />}
                onClearClick={() => {
                  dispatch(setSearchOptions({ ...searchOptions, [SearchOptions.ShowReplace]: false }));
                }}
              />
            </Grid>
          )}
        </Grid>
        <ClientDataSearchActions disableIndices={disableIndices} getSearchResultIndex={getSearchResultIndex} />
      </Grid>
      {searchOptions[SearchOptions.FindAll] && (
        <Grid
          size={{ xs: 12 }}
          ref={findAllSectionRef}
          style={{
            overflowY: 'auto',
            maxHeight: getMaxFindAllSectionHeight({ searchRootRef, findAllSectionRef }),
            ...(loading || resultIds.length ? { margin: '8px' } : {}),
          }}
        >
          <ClientDataSearchFindAll
            searchData={searchData}
            refs={{
              search: searchRootRef,
              findAll: findAllSectionRef,
              rootGrid: gridRef,
            }}
          />
        </Grid>
      )}
      <Grid container style={{ padding: '2px 8px 0px 25px' }}>
        <Grid size="grow">
          {[SearchOptions.SearchFormulas, SearchOptions.MatchCase].map((searchOption) => (
            <FormControl key={searchOption} fullWidth>
              <FormControlLabel
                className={classes.searchOptionsCheckbox}
                style={{
                  padding: '3px 0px',
                }}
                control={
                  <Checkbox
                    checked={searchOptions[searchOption] || false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(setSearchOptions({ [searchOption]: e.target.checked }))
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={t(`client-data-${compoundCaseToKebabCase(searchOption)}` as I18nKeys)}
              />
            </FormControl>
          ))}
        </Grid>
        <ClientDataSearchButtons
          onReplace={async (replaceAll: boolean) => {
            const onReplace = (resultIdsToReplace: string[]): void =>
              replaceValues(clientDataId, replaceParams, resultIdsToReplace, replaceAll);
            dispatch(replaceValuesAction({ params: replaceParams, onReplace, replaceAll }));
          }}
        />
      </Grid>
    </Paper>
  );
};
