import { Permission } from '@idearoom/types';

// Filter to unique permissions
export const filterUserPermissions = (permissions: string[] | undefined): string[] =>
  permissions && permissions.length > 0
    ? permissions.filter((value, index, self) => self.indexOf(value) === index)
    : [];

export const hasAdminPermissions = (permissions: string[] | undefined): boolean =>
  (permissions && (permissions.includes(Permission.Admin) || permissions.includes(Permission.SuperUser))) || false;
