import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Divider,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { ColorResult, GithubPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import {
  BlueMuted,
  GrayMuted,
  GreenMuted,
  OrderStatusChips,
  OrderStatusId,
  Red,
  RedMuted,
} from '../constants/OrderStatus';
import { i18n } from '../i18n';
import { OrderStatus } from '../types/OrderStatus';
import { getOrderStatusChipFromBackgroundColor } from '../utils/orderStatusUtils';

interface Props {
  readonly: boolean;
  status: OrderStatus;
  onOrderStatusChange(orderStatus: OrderStatus): void;
}

export const newLead: OrderStatus = {
  id: OrderStatusId.NewLead,
  i18nKey: I18nKeys.OrderStatusNewLead,
  chip: GrayMuted,
};
export const qualified: OrderStatus = {
  id: OrderStatusId.Qualified,
  i18nKey: I18nKeys.OrderStatusQualified,
  chip: BlueMuted,
};
const closedWon: OrderStatus = {
  id: OrderStatusId.ClosedWon,
  i18nKey: I18nKeys.OrderStatusClosedWon,
  chip: GreenMuted,
};
const closedLost: OrderStatus = {
  id: OrderStatusId.ClosedLost,
  i18nKey: I18nKeys.OrderStatusClosedLost,
  chip: RedMuted,
};
const notALead: OrderStatus = {
  id: OrderStatusId.NotALead,
  i18nKey: I18nKeys.OrderStatusNotALead,
  chip: Red,
};
export const orderStatusOptions: OrderStatus[] = [newLead, qualified, closedWon, closedLost, notALead];

export const OrderStatusSelector: React.FC<Props> = ({ readonly, status = newLead, onOrderStatusChange }: Props) => {
  const { t } = useTranslation();
  const [chipLabel, setChipLabel] = React.useState(status.i18nKey ? i18n.t(status.i18nKey) : status.name || '');
  const [chipBackgroundColor, setChipBackgroundColor] = React.useState(status.chip.backgroundColor);
  const [chipFontColor, setChipFontColor] = React.useState(status.chip.fontColor);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();
  const [customStatusOpen, setCustomStatusOpen] = React.useState(false);
  const [customStatusName, setCustomStatusName] = React.useState('');
  const [customStatusColor, setCustomStatusColor] = React.useState(GrayMuted.backgroundColor);
  const [customStatusColorPickerDisplay, setCustomStatusColorPickerDisplay] = React.useState('none');

  const handleChipClicked = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setMenuAnchorEl(anchorElRef.current);
    setMenuOpen(!menuOpen);
  };

  const handleClose = (event: Event): void => {
    event.stopPropagation();
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (orderStatus: OrderStatus): void => {
    if (!readonly) {
      onOrderStatusChange(orderStatus);
      setChipLabel(orderStatus.i18nKey ? i18n.t(orderStatus.i18nKey) : orderStatus.name || '');
      setChipFontColor(orderStatus.chip.fontColor);
      setChipBackgroundColor(orderStatus.chip.backgroundColor);
    }
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const handleCustomMenuItemClick = (): void => {
    setCustomStatusOpen(true);
    setMenuOpen(false);
  };

  const handleCustomStatusChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCustomStatusName(event.target.value as string);
  };

  const handleAddCustomStatus = (): void => {
    setCustomStatusOpen(false);
    const chip = getOrderStatusChipFromBackgroundColor(customStatusColor);
    if (!readonly) {
      onOrderStatusChange({
        id: customStatusName.replace(/\s/g, ''),
        name: customStatusName,
        chip,
      });
      setChipLabel(customStatusName);
      setChipFontColor(chip.fontColor);
      setChipBackgroundColor(chip.backgroundColor);
    }
  };

  const handleCustomStatusClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setCustomStatusColorPickerDisplay('none');
  };

  const handleCancelCustomStatus = (): void => {
    setCustomStatusOpen(false);
  };

  const handleCustomStatusColorClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (customStatusColorPickerDisplay === 'none') {
      setCustomStatusColorPickerDisplay('block');
    } else {
      setCustomStatusColorPickerDisplay('none');
    }
  };

  const handleOnChangeComplete = (color: ColorResult): void => {
    setCustomStatusColor(color.hex);
    setCustomStatusColorPickerDisplay('none');
  };

  return (
    <>
      <Chip
        deleteIcon={<ArrowDropDownIcon style={{ color: chipFontColor }} />}
        clickable
        onDelete={handleChipClicked}
        onClick={handleChipClicked}
        label={chipLabel}
        style={{ backgroundColor: chipBackgroundColor, color: chipFontColor }}
        ref={anchorElRef}
      />
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleClose}>
        {orderStatusOptions.map((orderStatus) => (
          <div>
            {orderStatus.id === OrderStatusId.NotALead && (
              <div>
                <MenuItem
                  onClick={(event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();
                    handleCustomMenuItemClick();
                  }}
                >
                  {t(I18nKeys.OrderStatusAddCustomStatus)}
                </MenuItem>
                <Divider />
              </div>
            )}
            <MenuItem
              onClick={(event: React.MouseEvent<HTMLElement>): void => {
                event.stopPropagation();
                handleMenuItemClick(orderStatus);
              }}
            >
              {orderStatus.i18nKey ? t(orderStatus.i18nKey) : orderStatus.name || ''}
            </MenuItem>
          </div>
        ))}
      </Menu>
      <Dialog open={customStatusOpen} onClick={handleCustomStatusClick}>
        <DialogTitle>{t(I18nKeys.OrderStatusDialogTitle)}</DialogTitle>
        <DialogContent>
          {t(I18nKeys.OrderStatusDialogMessage)}
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <TextField
              variant="standard"
              autoFocus
              margin="dense"
              id="status"
              label={t(I18nKeys.OrderStatusField)}
              type="text"
              value={customStatusName}
              onChange={handleCustomStatusChange}
              style={{ width: '300px' }}
            />
            <IconButton
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                marginBottom: '8px',
                marginLeft: '8px',
                backgroundColor: customStatusColor,
                cursor: 'pointer',
              }}
              onClick={handleCustomStatusColorClick}
              size="large"
            />
            <div style={{ position: 'absolute', display: customStatusColorPickerDisplay, right: '14px', top: '50px' }}>
              <GithubPicker
                triangle="hide"
                width="163px"
                onChangeComplete={handleOnChangeComplete}
                colors={OrderStatusChips.map((chip) => chip.backgroundColor)}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCustomStatus} color="primary">
            {t(I18nKeys.DialogCancelButton)}
          </Button>
          <Button onClick={handleAddCustomStatus} color="primary" disabled={customStatusName.length === 0}>
            {t(I18nKeys.DialogApplyButton)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
