import React from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { DataGridAutocompleteCellEditor } from './DataGridAutocompleteCellEditor';

export const ClientDataEnumCellEditor = React.forwardRef<ICellEditorReactComp, ICellEditorParams>(
  (props: ICellEditorParams, ref) => {
    const { colDef } = props;
    const { tableMetadata } = useClientDataRepo({ useTableMetadata: true });

    const columnMetadata = tableMetadata?.metadata[colDef.field || ''];
    const { enumType, lenient = false, multiple = false } = columnMetadata || {};
    if (columnMetadata && !enumType) {
      // eslint-disable-next-line no-console
      console.error(`${colDef.field} column is using enumCellEditor but is missing enumType from metadata.`);
    }
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const options = enumType ? Object.values<string>(require('@idearoom/types')[enumType] || {}) : [];
    options.sort();

    return (
      <DataGridAutocompleteCellEditor
        cellEditorParams={props}
        ref={ref}
        options={options}
        loading={false}
        freeSolo={lenient}
        multiple={multiple}
      />
    );
  },
);
