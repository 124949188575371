/* eslint-disable react/no-danger */
import { Chip, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DoneIcon from '@mui/icons-material/Done';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:active, &:hover, &:active:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  doneIcon: {
    color: 'white',
  },
}));

interface Props {
  chip: { label: string; value: string; tooltip?: string };
  selected: boolean;
  handleChipSelection(value: string, selected: boolean): void;
}

const PricingCalculationTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const PricingSurchargeSelectableChip: React.FC<Props> = ({ chip, selected, handleChipSelection }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {chip.tooltip ? (
        <PricingCalculationTooltip
          key={`${chip.value}-tooltip`}
          placement="top"
          title={<div dangerouslySetInnerHTML={{ __html: t(chip.tooltip) }} />}
          leaveDelay={100}
        >
          <Chip
            key={`${chip.value}-chip`}
            className={selected ? classes.selectedChip : ''}
            clickable
            onClick={(): void => handleChipSelection(chip.value, selected)}
            icon={selected ? <DoneIcon className={classes.doneIcon} /> : undefined}
            label={t(chip.label)}
          />
        </PricingCalculationTooltip>
      ) : (
        <Chip
          key={`${chip.value}-chip`}
          className={selected ? classes.selectedChip : ''}
          clickable
          onClick={(): void => handleChipSelection(chip.value, selected)}
          icon={selected ? <DoneIcon className={classes.doneIcon} /> : undefined}
          label={t(chip.label)}
        />
      )}
    </>
  );
};
