import { SuppressKeyboardEventParams } from 'ag-grid-community';

import { KeyboardKeys } from '../constants/ClientData';

export const suppressPricingGridKeyboardEvent = ({ event: { key } }: SuppressKeyboardEventParams) => {
  if (([KeyboardKeys.Backspace, KeyboardKeys.Delete] as string[]).includes(key)) return true;

  return false;
};

export const suppressGridKeyboardEvent = ({
  event: { key, metaKey, ctrlKey, shiftKey, altKey },
  api,
}: SuppressKeyboardEventParams) => {
  if (([KeyboardKeys.Backspace, KeyboardKeys.Delete] as string[]).includes(key)) return true;

  const isEditing = api.getEditingCells()?.length > 0;
  if (
    !isEditing &&
    (metaKey || ctrlKey || shiftKey) &&
    ([KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right] as string[]).includes(key)
  ) {
    return true;
  }
  // Do not close editor when adding a new line
  if (key === KeyboardKeys.Enter && ((isEditing && (ctrlKey || altKey || shiftKey)) || metaKey)) return true;

  return false;
};

export const suppressGridKeyboardEventAutocompleteCell = (params: SuppressKeyboardEventParams) => {
  if (suppressGridKeyboardEvent(params)) {
    return true;
  }
  const {
    event: { key },
    editing,
  } = params;

  // Enter/Escape keys are going to be handled by DataGridAutocompleteCellEditor.tsx
  if ((key === KeyboardKeys.Enter || key === KeyboardKeys.Escape) && editing) return true;

  return false;
};
