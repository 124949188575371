/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & TextFieldProps & { hideHelperText: boolean; errorWithoutTouch: boolean };

export const InputField: React.FC<Props> = (props: Props) => {
  const {
    rows,
    multiline,
    input,
    hideHelperText,
    errorWithoutTouch,
    meta: { touched, error },
  } = props;

  return (
    <TextField
      variant="filled"
      rows={rows}
      multiline={multiline}
      error={!!((touched || errorWithoutTouch) && error)}
      helperText={(touched || errorWithoutTouch) && !hideHelperText && error}
      {...props}
      {...input}
    />
  );
};
