import { DoltDiffType } from '../../constants/ClientData';
import { ClientDataFixedColumns } from '../../constants/ClientDataFixedColumns';
import { CellMetadata, ClientDataTableRowDiff } from '../../types/ClientData';
import { ComponentCategoryItem, ConditionalPrice } from '../../types/PricingClientUpdate';
import { TableData } from '../../types/DataGrid';
import { getRowDiffFromColumn, getRowDiffToColumn } from '../../utils/clientDataUtils';

export const getUpdatedTableDataDiff = (
  tableDiff: ClientDataTableRowDiff[],
  oldRow: TableData | CellMetadata | ComponentCategoryItem | ConditionalPrice | null,
  newRow: TableData | CellMetadata | ComponentCategoryItem | ConditionalPrice | null,
  column?: string,
): ClientDataTableRowDiff[] => {
  if (!oldRow && !newRow) return tableDiff;

  const rowId = ((oldRow || newRow) as TableData)[ClientDataFixedColumns.RowId];

  const diffType =
    (oldRow && newRow && DoltDiffType.Modified) || (oldRow && DoltDiffType.Removed) || DoltDiffType.Added;

  let updatedTableDiff = tableDiff;
  // Updates the Table Diff
  let tableRowDiff =
    updatedTableDiff.find((rowDiff) => rowDiff[ClientDataFixedColumns.RowId] === rowId) ||
    ({
      [ClientDataFixedColumns.RowId]: rowId,
      diffType,
    } as ClientDataTableRowDiff);

  const diffColumns = column
    ? [column]
    : Array.from(new Set([...(oldRow ? Object.keys(oldRow) : []), ...(newRow ? Object.keys(newRow) : [])])).filter(
        (columnName) => columnName !== ClientDataFixedColumns.RowId,
      );
  diffColumns.forEach((columnName) => {
    const oldColumnValue = oldRow ? (oldRow as TableData)[columnName] || null : null;
    const newColumnValue = newRow ? (newRow as TableData)[columnName] || null : null;
    const columnDiffFrom =
      getRowDiffFromColumn(columnName) in tableRowDiff
        ? tableRowDiff[getRowDiffFromColumn(columnName)]
        : oldColumnValue;
    tableRowDiff = {
      ...tableRowDiff,
      [getRowDiffFromColumn(columnName)]: columnDiffFrom,
      [getRowDiffToColumn(columnName)]: newColumnValue,
    };
  });

  if (tableRowDiff.diffType === DoltDiffType.Removed && diffType === DoltDiffType.Added) {
    if (
      diffColumns.every(
        (columnName) => tableRowDiff[getRowDiffFromColumn(columnName)] === tableRowDiff[getRowDiffToColumn(columnName)],
      )
    ) {
      return updatedTableDiff.filter((rowDiff) => rowDiff[ClientDataFixedColumns.RowId] !== rowId);
    }
    tableRowDiff.diffType = DoltDiffType.Modified;
  }

  if (tableRowDiff.diffType === DoltDiffType.Modified && diffType === DoltDiffType.Removed) {
    tableRowDiff.diffType = DoltDiffType.Removed;
  }

  updatedTableDiff = [
    ...updatedTableDiff.filter((rowDiff) => rowDiff[ClientDataFixedColumns.RowId] !== rowId),
    tableRowDiff,
  ];

  return updatedTableDiff;
};
