import { ClientDataBranch } from '../../constants/ClientDataBranch';
import { ClientDataBranchMetadata, ClientDataTableRowDiff } from '../../types/ClientData';
import { getRowDiffColumnToFromValues } from '../../utils/clientDataUtils';

export const getUpdatedActiveBranches = (
  activeBranches: ClientDataBranchMetadata[],
  currentBranch: ClientDataBranch | undefined,
  tableDiff: ClientDataTableRowDiff[],
  tableColumns: string[] = [],
  table: string,
  author?: string,
): ClientDataBranchMetadata[] => {
  let updatedActiveBranches = activeBranches;

  // Updates the Branch changedTables and authors
  const keepChangedTable = tableColumns.some((columnName) =>
    tableDiff.some((rowDiff) => {
      const diff = getRowDiffColumnToFromValues(rowDiff, columnName);
      return diff && diff.from !== diff.to;
    }),
  );
  let branchMetadata = updatedActiveBranches.find((branch) => branch.branchType === currentBranch);
  if (branchMetadata) {
    let changedTables = branchMetadata.changedTables || [];
    if (keepChangedTable && !changedTables.includes(table)) {
      changedTables = [...changedTables, table];
    } else if (!keepChangedTable) {
      changedTables = changedTables.filter((tableName) => tableName !== table);
    }

    let authors = branchMetadata.authors || [];
    if (author && !authors.includes(author)) {
      authors = [...authors, author];
    }

    branchMetadata = {
      ...branchMetadata,
      authors,
      changedTables,
    };
    updatedActiveBranches = [
      ...updatedActiveBranches.filter((branch) => branch.branchType !== currentBranch),
      branchMetadata,
    ];
  }

  return updatedActiveBranches;
};
