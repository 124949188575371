import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { Group } from '../types/Group';
import { GroupsState } from '../types/GroupsState';

// Action types

export enum GroupsActionTypes {
  FETCH_GROUPS = 'FETCH_GROUPS',
  FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR',
  FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS',
  REMOVE_GROUP = 'REMOVE_GROUP',
  REMOVE_GROUP_ERROR = 'REMOVE_GROUP_ERROR',
  REMOVE_GROUP_SUCCESS = 'REMOVE_GROUP_SUCCESS',
}

// Reducer

export const INITIAL_GROUPS_STATE: GroupsState = {
  loading: false,
  groups: [],
};

export const groupsReducer: Reducer<GroupsState> = (state = INITIAL_GROUPS_STATE, reducerAction) => {
  switch (reducerAction.type) {
    case GroupsActionTypes.FETCH_GROUPS: {
      return { ...state, groups: [], loading: true };
    }

    case GroupsActionTypes.FETCH_GROUPS_ERROR: {
      return { ...state, groups: [], loading: false };
    }

    case GroupsActionTypes.FETCH_GROUPS_SUCCESS: {
      const {
        payload: { groups },
      } = reducerAction;

      return { ...state, groups, loading: false };
    }

    case GroupsActionTypes.REMOVE_GROUP: {
      const {
        payload: { group },
      } = reducerAction;

      let { groups } = state;
      const groupIndex = groups.findIndex((existingGroup) => existingGroup.groupId === group.groupId);

      groups = [...groups];
      groups[groupIndex] = { ...group, updating: true };

      return { ...state, groups };
    }

    case GroupsActionTypes.REMOVE_GROUP_ERROR: {
      const {
        payload: { group },
      } = reducerAction;

      let { groups } = state;
      const groupIndex = groups.findIndex((existingGroup) => existingGroup.groupId === group.groupId);

      groups = [...groups];
      groups[groupIndex] = { ...group, updating: false };

      return { ...state, groups };
    }

    case GroupsActionTypes.REMOVE_GROUP_SUCCESS: {
      const {
        payload: { group },
      } = reducerAction;

      return {
        ...state,
        groups: state.groups.filter((existingGroup) => existingGroup.groupId !== group.groupId),
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const fetchGroups = (): AnyAction => action(GroupsActionTypes.FETCH_GROUPS);

export const fetchGroupsError = (): AnyAction => action(GroupsActionTypes.FETCH_GROUPS_ERROR);

export const fetchGroupsSuccess = (groups: Group[]): AnyAction =>
  action(GroupsActionTypes.FETCH_GROUPS_SUCCESS, { groups });

export const removeGroup = (group: Group): AnyAction => action(GroupsActionTypes.REMOVE_GROUP, { group });

export const removeGroupError = (group: Group): AnyAction => action(GroupsActionTypes.REMOVE_GROUP_ERROR, { group });

export const removeGroupSuccess = (group: Group): AnyAction =>
  action(GroupsActionTypes.REMOVE_GROUP_SUCCESS, { group });
