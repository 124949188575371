import React from 'react';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

interface Props {
  className?: string;
  shrink?: boolean;
  htmlFor?: string;
  i18nKey?: string;
  text?: string;
  ideaRoomOnly?: boolean;
}

export const SiteInputLabel: React.FC<Props> = ({
  className,
  shrink = false,
  htmlFor,
  i18nKey,
  text,
  ideaRoomOnly = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <InputLabel shrink={shrink} className={className} htmlFor={htmlFor}>
      {ideaRoomOnly && <IdeaRoomOnlyIndicator />}
      {i18nKey && t(i18nKey)}
      {text && text}
    </InputLabel>
  );
};
