import { PricingSheetDimensions } from '../types/Pricing';

export enum PricingTab {
  Base = 'base',
  Component = 'component',
  SizeBased = 'size-based',
  Surcharge = 'surcharge',
}

export enum PricingSheetDimension {
  Width = 'width',
  Length = 'length',
  Height = 'height',
  Region = 'region',
}

export enum DefaultPricingColumnFieldNames {
  RowSpanLabel = 'rowSpanLabel',
}

export const pricingBaseGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.Length,
};

export const pricingBaseListView: PricingSheetDimensions = {
  x: PricingSheetDimension.Region,
  y: PricingSheetDimension.Length,
};

export const pricingSizeBasedGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Length,
  y: PricingSheetDimension.Height,
};
