import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Grid2 as Grid } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IdeaRoomCode, CustomTheme, Theme, Language } from '@idearoom/ir-code';
import { PreferencesFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { LoadingButton } from './LoadingButton';
import { RenderSelectField } from './RenderSelectField';
import { kebabCaseToTitleCase } from '../utils/stringUtils';
import { CheckboxField } from './redux-form/CheckboxField';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles(() => ({
  textContainer: {
    alignSelf: 'center',
    height: '72px',
  },
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  field: {
    width: '100%',
    marginBottom: '2em',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  form: {
    width: '100%',
  },
}));

interface FormData {
  [PreferencesFormFields.Theme]: string;
  [PreferencesFormFields.MiniMap]: boolean;
}

type Props = InjectedFormProps<FormData>;

const PreferencesFormComponent: React.FC<Props> = ({
  error,
  handleSubmit,
  submitting,
  initialValues: {
    [PreferencesFormFields.Theme]: initialTheme = 'vs-dark',
    [PreferencesFormFields.MiniMap]: initialMiniMap = false,
  } = {},
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const defaultPreviewValue = t(I18nKeys.ProfilePreferencesThemePreviewValue).split('\\n').join('\n');

  const [previewTheme, setPreviewTheme] = React.useState(initialTheme as Theme | undefined);
  const [previewValue, setPreviewValue] = React.useState<string>(defaultPreviewValue);
  const [previewMiniMap, setPreviewMiniMap] = React.useState<boolean>(initialMiniMap);

  const onSubmit = (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Preferences}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    });

  const themes: { key: Theme; label: string }[] = [
    {
      key: 'light',
      label: 'Light',
    },
    {
      key: 'vs-dark',
      label: 'Dark',
    },
    ...(Object.values(CustomTheme) as Theme[]).map((theme) => ({
      key: theme,
      label: kebabCaseToTitleCase(theme),
    })),
  ];

  return (
    <Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 6 }}>
            <Field
              className={classes.field}
              style={{ marginTop: '0px', marginBottom: '5px' }}
              variant="outlined"
              name={PreferencesFormFields.Theme}
              component={RenderSelectField}
              label={t(I18nKeys.ProfilePreferencesTheme)}
              value={previewTheme}
              onChange={(key: React.ChangeEvent<{ value: unknown }>): void => {
                setPreviewTheme(key as any);
              }}
            >
              {themes.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Field>
            {!!error && <Typography className={classes.error}>{error}</Typography>}
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Field
              id={PreferencesFormFields.MiniMap}
              name={PreferencesFormFields.MiniMap}
              component={CheckboxField}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setPreviewMiniMap(event.target.checked);
              }}
            />
            <Typography component="span">
              <>{t(I18nKeys.ProfilePreferencesMinimap)}</>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }} style={{ height: '200px', marginBottom: '1.5%' }}>
            <IdeaRoomCode
              width="100%"
              height="100%"
              value={previewValue}
              language={Language.JavaScript}
              theme={previewTheme}
              options={{
                minimap: {
                  enabled: previewMiniMap,
                },
              }}
              onChange={(editorValue?: string) => setPreviewValue(editorValue || '')}
            />
          </Grid>
        </Grid>
        <Grid alignSelf="end" size={{ xs: 12 }}>
          <LoadingButton
            key="create-group-btn"
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            loading={submitting}
            disabled={submitting}
          >
            {t(I18nKeys.ProfileUpdatePreferences)}
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export const PreferencesForm = reduxForm<FormData>({ form: Forms.Preferences })(PreferencesFormComponent);
