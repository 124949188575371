import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { ConfiguratorAppBar } from './ConfiguratorAppBar';
import { unknownGroup } from '../constants/Group';
import { isCarportView } from '../utils/clientIdUtils';
import { Dashboard } from './Dashboard';

const CARPORTS_DASHBOARD_ID = '58';
const SHEDS_DASHBOARD_ID = '56';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

export const Usage: React.FC = () => {
  const classes = useStyles();
  const clientId = useAppSelector((state: AppState) => state?.viewer.selectedClientId) || '';
  const { group: { configurators: configs = [] } = unknownGroup } = useAppSelector(
    (state: AppState) => state?.currentUser,
  );

  return (
    <div className={classes.root}>
      <ConfiguratorAppBar enabledOnProperty="clientId" isVendorProperty={false} configurators={configs} />
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Dashboard
          clientId={clientId}
          dashboardId={isCarportView(clientId) ? CARPORTS_DASHBOARD_ID : SHEDS_DASHBOARD_ID}
        />
      </Grid>
    </div>
  );
};
