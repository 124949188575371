import { unknownUser, User } from './User';

export interface GroupMember extends User {
  readonly updating: boolean;
}

export const unknownGroupMember: GroupMember = {
  ...unknownUser,
  updating: false,
};

export interface BulkMemberResult {
  successfulUsers: GroupMember[];
  errors: { username: string; error: string }[];
}
