import React from 'react';
import { Box, SpeedDial, SpeedDialAction, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  fabRoot: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
    '& button': {
      borderRadius: '24px',
      height: '48px',
      minWidth: '48px',
      width: 'auto',
      padding: '0 16px',
    },
  },
  fabText: {
    padding: theme.spacing(1),
  },
  fabTooltip: {
    whiteSpace: 'nowrap',
  },
  fabButton: {
    backgroundColor: '#80848E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#5C5E65',
    },
  },
}));

type FabMenuAction = {
  icon: React.ReactNode;
  i18nKey: string;
  onClick: () => void;
};

interface Props {
  actions: FabMenuAction[];
  icon: React.ReactNode;
  label: I18nKeys;
  className?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
}

export const FabMenu: React.FC<Props> = ({ actions, icon, label, className, direction }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  return (
    <Box className={className}>
      <SpeedDial
        ariaLabel="Fab menu"
        icon={
          <>
            {icon}
            <Typography className={classes.fabText}>{t(label)}</Typography>
          </>
        }
        direction={direction}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        className={classes.fabRoot}
      >
        {actions.map((action) => (
          <SpeedDialAction
            FabProps={{ color: 'primary' }}
            key={action.i18nKey}
            icon={action.icon}
            tooltipTitle={t(action.i18nKey)}
            tooltipPlacement="right"
            onClick={() => {
              setOpen(false);
              action.onClick();
            }}
            classes={{ staticTooltipLabel: classes.fabTooltip, fab: classes.fabButton }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};
