import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Container, IconButton, Theme, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment';
import {
  CellFocusedEvent,
  ColumnEvent,
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
  ProcessCellForExportParams,
  SendToClipboardParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-community';
import { diffWords } from 'diff';
import { AgGridReact } from 'ag-grid-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useAppDispatch, useAppSelector } from '../hooks';
import { clientDataApi, useGetClientDataBranchChangesSummaryQuery } from '../services/clientDataApi';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { DataGridAccordion } from './DataGridAccordion';
import { DataGrid } from './DataGrid';
import { defaultFixedColumn, getIndexColumnDef, DefaultColumnFieldNames } from '../constants/ClientDataColumn';
import { ClientDataTableChanges, ColumnChangedParams } from '../types/ClientData';
import {
  CELL_METADATA_TABLE,
  CHANGE_SUMMARY_TABLE,
  ColumnEventSource,
  ColumnEventType,
  DoltDiffType,
} from '../constants/ClientData';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { I18nKeys } from '../constants/I18nKeys';
import { unknownUser } from '../types/User';
import { ClientDataChangesSummaryDiffCell } from './ClientDataChangesSummaryDiffCell';
import { ClientDataChangesSummaryDiffCellEditor } from './ClientDataChangesSummaryDiffCellEditor';
import { TableData } from '../types/DataGrid';
import { setChangeSummaryOpen } from '../ducks/clientDataSlice';
import { Loading } from './Loading';
import {
  cantEditBranchData,
  getRowsRevertData,
  getTableDataWithRemovedRows,
  revertRangeChanges,
  copyToClipboard,
  getCellMetadataFormulasDiffMap,
} from '../utils/clientDataUtils';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import revertIcon from '../images/revertIcon.svg';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { ClientDataChangesSummaryDiffRevertCell } from './ClientDataChangesSummaryDiffRevertCell';
import { UserPreference } from '../constants/User';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { saveUserPreferences } from '../ducks/currentUserSlice';

enum ChangeSummaryGridColumn {
  Order = 'order',
  Change = 'change',
  From = 'from',
  To = 'to',
  Authors = 'authors',
  LastChange = 'lastChange',
}

const indexColumnWidth = 50;

const useStyles = makeStyles<Theme>((theme) => ({
  panelTopBar: {
    display: 'flex',
    marginBottom: '4px',
  },
  title: {
    color: theme.palette.primary.main,
    flexGrow: 1,
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    marginRight: '20px',
  },
  titleDescription: {
    color: theme.palette.grey[500],
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root: {
    padding: '14px',
    paddingTop: '8px',
    paddingBottom: '0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridsContainer: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: { height: '100%' },
  noChangesDescriptionPanel: { display: 'flex', justifyContent: 'center', height: '100%' },
  noChangesDescriptionTypography: { alignSelf: 'center' },
}));

interface Props {
  onChangeFocused: (table: string, change: TableData & { diffType: DoltDiffType }) => void;
}

const fromToValueFormatter = (params: ValueFormatterParams) => {
  const { data, value, colDef } = params;
  if (data.diffType === DoltDiffType.Modified) {
    const diff = diffWords(`${data[ChangeSummaryGridColumn.From] || ''}`, `${data[ChangeSummaryGridColumn.To] || ''}`);
    if (colDef.colId === ChangeSummaryGridColumn.To) {
      return diff.filter((part) => part.added || !part.removed);
    }
    return diff.filter((part) => part.removed || !part.added);
  }
  return value;
};

export const ClientDataChangesSummaryPanel: React.FC<Props> = ({ onChangeFocused }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    clientDataBranch = ClientDataBranch.Main,
    clientDataType,
    clientId,
    selectedTable,
  } = useAppSelector((state) => state?.clientData);
  const {
    user = unknownUser,
    group: { groupId } = unknownGroup,
    preferences: { [UserPreference.ClientDataPreferences]: clientDataPreferences = {} } = {},
  } = useAppSelector((state: AppState) => state?.currentUser);
  const { [CHANGE_SUMMARY_TABLE]: changeSummaryTablePreferences = {} } = clientDataPreferences;

  const columnChangedParams: ColumnChangedParams = {
    tablePreferences: changeSummaryTablePreferences,
  };

  const [expandedTables, setExpandedTables] = useState<string[]>([]);
  const [columnsWidths, setColumnsWidths] = useState<{ [columnId: string]: number }>(
    Object.values(ChangeSummaryGridColumn).reduce(
      (acc, columnId) => ({
        ...acc,
        [columnId]: changeSummaryTablePreferences[columnId]?.width || 200,
      }),
      {},
    ),
  );
  const gridRefs = useRef<(AgGridReact | null)[]>([]);

  const { activeBranches } = useClientDataRepo({
    useBranches: true,
  });
  const { description: branchDescription = '' } =
    activeBranches.find((metadata) => metadata.branchType === clientDataBranch) || {};
  const isGridReadOnly = cantEditBranchData(clientDataBranch, activeBranches);

  useEffect(() => {
    setExpandedTables([selectedTable]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentData = [], isLoading } = useGetClientDataBranchChangesSummaryQuery(
    { dataType: clientDataType, clientId, groupId, branch: clientDataBranch },
    {
      skip: !clientId || clientDataBranch === ClientDataBranch.Main,
      refetchOnMountOrArgChange: true,
    },
  );

  const selectedTableColumns = useMemo(
    () => [
      {
        ...getIndexColumnDef({}),
        width: indexColumnWidth,
        field: 'order',
        colId: 'order',
        rowDrag: false,
      },
      {
        ...defaultFixedColumn,
        headerName: t(I18nKeys.ClientDataChangesSummaryChange),
        field: ChangeSummaryGridColumn.Change,
        colId: ChangeSummaryGridColumn.Change,
        resizable: true,
        maxWidth: 250,
        width: columnsWidths[ChangeSummaryGridColumn.Change],
      },
      {
        ...defaultFixedColumn,
        headerName: t(I18nKeys.ClientDataChangesSummaryPublished),
        field: ChangeSummaryGridColumn.From,
        colId: ChangeSummaryGridColumn.From,
        resizable: true,
        editable: true,
        cellEditorPopup: true,
        cellEditor: ClientDataChangesSummaryDiffCellEditor,
        width: columnsWidths[ChangeSummaryGridColumn.From],
        cellRenderer: ClientDataChangesSummaryDiffCell,
        valueFormatter: fromToValueFormatter,
      },
      {
        ...defaultFixedColumn,
        headerName: compoundCaseToTitleCase(clientDataBranch),
        field: ChangeSummaryGridColumn.To,
        colId: ChangeSummaryGridColumn.To,
        resizable: true,
        editable: true,
        cellEditorPopup: true,
        cellEditor: ClientDataChangesSummaryDiffCellEditor,
        width: columnsWidths[ChangeSummaryGridColumn.To],
        cellRenderer: ClientDataChangesSummaryDiffRevertCell,
        valueFormatter: fromToValueFormatter,
      },
      {
        ...defaultFixedColumn,
        headerName: t(I18nKeys.ClientDataChangesSummaryChangedBy),
        field: ChangeSummaryGridColumn.Authors,
        colId: ChangeSummaryGridColumn.Authors,
        resizable: true,
        editable: true,
        cellEditorPopup: true,
        cellEditor: ClientDataChangesSummaryDiffCellEditor,
        maxWidth: 170,
        width: columnsWidths[ChangeSummaryGridColumn.Authors],
      },
      {
        ...defaultFixedColumn,
        headerName: t(I18nKeys.ClientDataChangesSummaryLastChange),
        field: ChangeSummaryGridColumn.LastChange,
        colId: ChangeSummaryGridColumn.LastChange,
        maxWidth: 170,
        width: columnsWidths[ChangeSummaryGridColumn.LastChange],
      },
    ],
    [clientDataBranch, t, columnsWidths],
  );

  const closePanel = () => {
    dispatch(setChangeSummaryOpen(false));
  };

  useEffect(() => {
    if (clientDataBranch === ClientDataBranch.Main) {
      closePanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDataBranch]);

  const onColumnResized = (event: ColumnEvent | ColumnResizedEvent, params: ColumnChangedParams) => {
    const { type, source, columnApi } = event;
    const { tablePreferences = {} } = params;

    switch (type) {
      case ColumnEventType.Resized:
        if (source === ColumnEventSource.UiColumnResized) {
          const { finished } = event as ColumnResizedEvent;
          if (!finished) {
            break;
          }
          const prefs = { ...tablePreferences };
          const newColumnWidths = { ...columnsWidths };
          columnApi.getColumns()?.forEach((col) => {
            const columnId = col.getColId();
            const width = col.getActualWidth();
            if (columnId && width) {
              newColumnWidths[columnId] = width;
              prefs[columnId] = { ...prefs[columnId], width };
            }
          });
          setColumnsWidths(newColumnWidths);
          dispatch(
            saveUserPreferences({
              userPreference: UserPreference.ClientDataPreferences,
              preferences: {
                ...clientDataPreferences,
                [CHANGE_SUMMARY_TABLE]: prefs,
              },
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const onCellFocused = (event: CellFocusedEvent) => {
    const { selectedTable: table } = event.context;
    const { rowIndex, api } = event;
    const row = api.getDisplayedRowAtIndex(rowIndex || 0);
    if (row) {
      const { data: rowData } = row;

      // Remove selections from other change summary tables
      gridRefs.current.forEach((grid) => {
        if (grid?.api !== api) {
          grid?.api.deselectAll();
        }
      });

      onChangeFocused(table, rowData);
    }
  };

  const revertChange = async (tableName: string, nodes: IRowNode[]) => {
    const menuClientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, tableName);
    const [tableData, tableDataDiff, tableMetadata, cellMetadata, cellMetadataDiff] = await Promise.all([
      dispatch(
        clientDataApi.endpoints.getClientDataTableData.initiate(
          { dataType: clientDataType, branch: clientDataBranch, clientId, groupId, table: tableName },
          { subscribe: false },
        ),
      ).unwrap(),
      dispatch(
        clientDataApi.endpoints.getClientDataBranchTableDiff.initiate(
          { dataType: clientDataType, branch: clientDataBranch, clientId, groupId, table: tableName },
          { subscribe: false },
        ),
      ).unwrap(),
      dispatch(
        clientDataApi.endpoints.getClientDataTableMetadata.initiate(
          { dataType: clientDataType, branch: clientDataBranch, clientId, groupId, table: tableName },
          { subscribe: false },
        ),
      ).unwrap(),
      dispatch(
        clientDataApi.endpoints.getClientDataCellMetadata.initiate(
          { dataType: clientDataType, branch: clientDataBranch, clientId, table: tableName, groupId },
          { subscribe: false },
        ),
      ).unwrap(),
      dispatch(
        clientDataApi.endpoints.getClientDataBranchTableDiff.initiate(
          { dataType: clientDataType, branch: clientDataBranch, clientId, groupId, table: CELL_METADATA_TABLE },
          { subscribe: false },
        ),
      ).unwrap(),
    ]);

    const rows: { rowId: string; columns: string[] }[] = [];
    nodes.forEach((row) => {
      if (row.data.originalChange) {
        const { originalChange: change } = row.data as {
          originalChange: ClientDataTableChanges['changes'][number];
        };

        if (change.columnId && change.diffType === DoltDiffType.Modified) {
          const existingRowEntry = rows.find((r) => r.rowId === change.rowId);
          if (existingRowEntry) {
            existingRowEntry.columns.push(change.columnId);
          } else {
            rows.push({ rowId: change.rowId, columns: [change.columnId] });
          }
        } else {
          rows.push({ rowId: change.rowId, columns: [DefaultColumnFieldNames.Index] });
        }
      }
    });

    const revertData = getRowsRevertData(rows, tableDataDiff, getCellMetadataFormulasDiffMap(cellMetadataDiff));

    revertRangeChanges(
      menuClientDataTableId,
      tableName,
      getTableDataWithRemovedRows(tableData, tableDataDiff),
      tableMetadata,
      dispatch,
      revertData,
      false,
      true,
      cellMetadata,
    );
  };

  const getContextMenuItems =
    (tableName: string) =>
    ({ api, node }: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (node) {
        if (!node.isSelected()) {
          api.deselectAll();
          node.setSelected(true);
        }
      } else {
        api.deselectAll();
      }
      const selectedNodes = api.getSelectedNodes();

      const revertToPublishedItem = {
        name: `Revert to Published Data`,
        action: async () => revertChange(tableName, selectedNodes),
        icon: renderToStaticMarkup(<img alt="Note" src={revertIcon} className="ag-icon" />),
        disabled: isGridReadOnly,
      };

      const result: (string | MenuItemDef)[] = [
        'copy',
        'copyWithHeaders',
        'separator',
        ...(selectedNodes.length > 0 ? [revertToPublishedItem, 'separator'] : []),
        'export',
      ];
      return result;
    };

  const processCellForClipboard = useCallback((params: ProcessCellForExportParams): string => {
    const { value } = params;
    if (Array.isArray(value)) return value.join('');
    return value;
  }, []);

  const sendToClipboard = useCallback((params: SendToClipboardParams) => {
    const copiedData = copyToClipboard(params);
    navigator.clipboard.writeText(copiedData);
  }, []);

  const formattedDataChanges = useMemo(() => {
    const removedRowsOrderIncrement: { [orderId: string]: number } = {};
    const getRowOrder = (table: string, change: ClientDataTableChanges['changes'][number]) => {
      if (change.diffType === DoltDiffType.Removed) {
        const newOrderIncrement = (removedRowsOrderIncrement[table + change.order] || 0) + 1;
        removedRowsOrderIncrement[table + change.order] = newOrderIncrement;
        return change.order + newOrderIncrement / 10;
      }
      return change.order;
    };

    return currentData.map((tableChanges) => ({
      table: tableChanges.table,
      changes: tableChanges.changes
        .map((change) => {
          const fromValue = change.diffType === DoltDiffType.Removed ? change.key || '' : change.from || '-';
          const toValue = change.diffType === DoltDiffType.Added ? change.key || '' : change.to || '-';

          return {
            [ClientDataFixedColumns.RowId]: `${change.rowId}-${change.column || ''}`,
            dataRowId: change.rowId,
            order: getRowOrder(tableChanges.table, change),
            [ChangeSummaryGridColumn.Change]:
              change.diffType === DoltDiffType.Modified
                ? compoundCaseToTitleCase(change.column) || ''
                : `(Row ${compoundCaseToTitleCase(change.diffType)})`,
            [ChangeSummaryGridColumn.From]: fromValue,
            [ChangeSummaryGridColumn.To]: toValue,
            [ChangeSummaryGridColumn.Authors]: change.authors
              .map((authorName) => (authorName === user.name ? 'You' : authorName))
              .join(', '),
            [ChangeSummaryGridColumn.LastChange]: moment(change.date).calendar({
              lastWeek: 'MMM D, h:mm A',
              sameElse: moment().isSame(change.date, 'year') ? 'MMM D, h:mm A' : 'MMM D, YYYY, h:mm A',
            }),
            diffType: change.diffType,
            colId: change.columnId,
            originalChange: change,
          };
        })
        .sort((a, b) => a.order - b.order || a.change.localeCompare(b.change)),
    }));
  }, [user.name, currentData]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <div className={classes.panelTopBar}>
        <Typography className={classes.title} variant="subtitle1">
          <Trans
            i18nKey={I18nKeys.ClientDataChangesSummaryTitle as string}
            values={{ changes: formattedDataChanges.reduce((acc, change) => acc + change.changes.length, 0) }}
          />
        </Typography>
        <Typography className={classes.titleDescription} variant="subtitle1">
          {branchDescription}
        </Typography>
        <IconButton onClick={closePanel} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.gridsContainer}>
        {!isLoading && formattedDataChanges.length === 0 && (
          <div className={classes.noChangesDescriptionPanel}>
            <Typography className={classes.noChangesDescriptionTypography}>There are no changes</Typography>
          </div>
        )}
        {isLoading && <Loading />}
        {formattedDataChanges.map((tableChanges, index) => (
          <DataGridAccordion
            minHeight={200}
            fullSize
            hideExpand={false}
            key={tableChanges.table}
            expanded={expandedTables.includes(tableChanges.table)}
            handleExpand={() =>
              setExpandedTables(
                expandedTables.includes(tableChanges.table)
                  ? expandedTables.filter((table) => table !== tableChanges.table)
                  : [...expandedTables, tableChanges.table],
              )
            }
            title={compoundCaseToTitleCase(tableChanges.table)}
            resultCount={tableChanges.changes.length}
          >
            <div className={classes.gridContainer}>
              <DataGrid
                readOnly
                context={{
                  revertChange: (node: RowNode) => revertChange(tableChanges.table, [node]),
                }}
                gridRef={(el) => {
                  gridRefs.current[index] = el;
                }}
                enableCellChangeFlash={false}
                data={tableChanges.changes}
                isLoading={isLoading}
                getContextMenuItems={getContextMenuItems(tableChanges.table)}
                selectedTable={tableChanges.table}
                selectedTableColumns={selectedTableColumns}
                rowSelection="multiple"
                suppressRowClickSelection={false}
                defaultColDefinition={{}}
                enableRangeSelection={false}
                suppressCellFocus
                onFirstDataRendered={(params) => {
                  if (Object.keys(columnsWidths).length === 0) params.api.sizeColumnsToFit();
                }}
                onColumnResized={(event: ColumnEvent) => onColumnResized(event, columnChangedParams)}
                onCellFocused={onCellFocused}
                processCellForClipboard={processCellForClipboard}
                sendToClipboard={sendToClipboard}
              />
            </div>
          </DataGridAccordion>
        ))}
      </div>
    </Container>
  );
};
