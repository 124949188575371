import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Permission } from '@idearoom/types';
import { AppState } from '../types/AppState';
import { FilterSelectionDialog } from './FilterSelectionDialog';
import { CustomFilterType, SelectedFilterValues, GroupFilter } from '../types/CustomFilter';
import { Dealer, unknownDealer } from '../types/Dealer';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { availablePermissions as allAvailablePermissions } from '../constants/Permissions';
import { isCurrentUserAdmin, isCurrentUserSuperUser } from '../utils/userUtils';
import { FilterType, TableFilterType } from '../constants/Viewer';
import { getFilteredMembers } from '../ducks/group';
import { getFilterMessage } from '../utils/viewerUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { clearFilters, fetchGroupFilters, setFilterDialog } from '../ducks/viewerSlice';

interface OwnProps {
  count: number;
}

interface StateProps {
  groupId: string | undefined;
  groupFilters: GroupFilter[];
  availableDealers: Dealer[];
  availablePermissions: SelectedFilterValues[];
}

interface DispatchProps {
  loadFilters(): void;
  handleOpenFilterDialog(groupId: string | undefined, filterType: string, availableValues: CustomFilterType): void;
  handleClearFilters(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const GroupMemberFilterMenuComponent: React.FC<Props> = ({
  groupId,
  groupFilters,
  availableDealers,
  availablePermissions,
  count,
  loadFilters,
  handleOpenFilterDialog,
  handleClearFilters,
}: Props) => {
  const { t } = useTranslation();
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const [filterMessage, setFilterMessage] = React.useState('');
  const anchorElRef = React.createRef<HTMLDivElement>();

  // Get filters from localStorage if available
  // Set the custom filter message based on the filter type and number of filters
  // Sets the message in the format of 'for n filterType(s)'
  React.useEffect(() => {
    if (groupId && (!groupFilters || !groupFilters.find((groupFilter) => groupFilter.groupId === groupId))) {
      loadFilters();
    }
    setFilterMessage(getFilterMessage(groupId, groupFilters, TableFilterType.User));
  }, [groupId, groupFilters, loadFilters]);

  const handleFilterMenuClick = (): void => setFilterMenuAnchorEl(anchorElRef.current);
  const handleFilterMenuClose = (): void => setFilterMenuAnchorEl(null);

  const handleFilterMenuItemClick = (filter: FilterType): void => {
    switch (filter) {
      case FilterType.Dealer:
        handleOpenFilterDialog(groupId, FilterType.Dealer, availableDealers);
        break;
      case FilterType.Permission:
        handleOpenFilterDialog(groupId, FilterType.Permission, availablePermissions);
        break;
      default:
    }
    handleFilterMenuClose();
  };

  const handleClearFilterMenuItemClick = (): void => {
    handleClearFilters();
    handleFilterMenuClose();
  };

  const transparentFilterIconColor = (filterMenuItem: FilterType): string | undefined => {
    const filtersForGroup = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
    const filtersForTable = filtersForGroup
      ? filtersForGroup.tableFilters.find((tableFilter) => tableFilter.tableFilterType === TableFilterType.User)
      : undefined;
    const filtersForMenuItem = filtersForTable
      ? filtersForTable.filters.find((filter) => filter.filterType === filterMenuItem)
      : undefined;
    return filtersForMenuItem ? undefined : 'transparent';
  };

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleFilterMenuClick} size="large">
          <FilterListIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={filterMenuAnchorEl}
          open={Boolean(filterMenuAnchorEl)}
          onClose={handleFilterMenuClose}
        >
          {availableDealers.length > 1 && (
            <MenuItem
              style={{ paddingRight: '40px' }}
              onClick={(): void => handleFilterMenuItemClick(FilterType.Dealer)}
            >
              <ListItemIcon style={{ color: transparentFilterIconColor(FilterType.Dealer) }}>
                <CheckIcon />
              </ListItemIcon>
              <Typography>{t(I18nKeys.FilterByDealer)}</Typography>
            </MenuItem>
          )}
          <MenuItem
            style={{ paddingRight: '40px' }}
            onClick={(): void => handleFilterMenuItemClick(FilterType.Permission)}
          >
            <ListItemIcon style={{ color: transparentFilterIconColor(FilterType.Permission) }}>
              <CheckIcon />
            </ListItemIcon>
            <Typography>{t(I18nKeys.GroupMemberFilterMenuPermissionOption)}</Typography>
          </MenuItem>
          <MenuItem style={{ paddingRight: '40px' }} onClick={(): void => handleClearFilterMenuItemClick()}>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <Typography>{t(I18nKeys.GroupMemberFilterMenuClearButton)}</Typography>
          </MenuItem>
        </Menu>
        <Typography color="textSecondary" variant="body2">
          {t(I18nKeys.GroupMemberFilterMessage, { count, filterMessage })}
        </Typography>
        <FilterSelectionDialog onApply={getFilteredMembers} />
      </Grid>
      <Grid>
        <div style={{ position: 'relative', bottom: '-20px' }} ref={anchorElRef} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  currentUser: { user, availableDealers = [], group },
  viewer: { groupFilters },
}: AppState): StateProps => {
  const isAdmin = isCurrentUserAdmin(user);
  const isSuperUser = isCurrentUserSuperUser(user);
  return {
    groupId: group ? group.groupId : undefined,
    groupFilters,
    availableDealers: [unknownDealer, ...availableDealers],
    availablePermissions: allAvailablePermissions.filter(
      (permission: SelectedFilterValues) =>
        !((permission.key as Permission) === Permission.SuperUser && !isSuperUser) ||
        !((permission.key as Permission) === Permission.Admin && !isAdmin),
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadFilters: (): void => {
    dispatch(fetchGroupFilters());
    dispatch(getFilteredMembers());
  },
  handleOpenFilterDialog: (
    groupId: string | undefined,
    filterType: string,
    availableFilterValues: CustomFilterType,
  ): void => {
    dispatch(setFilterDialog({ groupId, tableFilterType: TableFilterType.User, filterType, availableFilterValues }));
    dispatch(openDialog({ dialog: Dialogs.FilterSelection }));
  },
  handleClearFilters: (): void => {
    dispatch(clearFilters(TableFilterType.User));
    dispatch(getFilteredMembers());
  },
});

export const GroupMemberFilterMenu = connect(mapStateToProps, mapDispatchToProps)(GroupMemberFilterMenuComponent);
