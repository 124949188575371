/**
 * Tests if a.toLowerCase() includes b.toLowerCase()
 *
 * @param a
 * @param b
 * @returns
 */
export const stringToLowerIncludes = (a?: string, b?: string): boolean =>
  a !== undefined && a !== null && b !== undefined && b !== null && a.toLowerCase().includes(b.toLowerCase());

/**
 * Tests if a includes b ignoring case and whitespace
 *
 * @param a
 * @param b
 * @returns
 */
export const fuzzyMatchIncludes = (a?: string, b?: string): boolean =>
  a !== undefined &&
  a !== null &&
  b !== undefined &&
  b !== null &&
  a.toLowerCase().replace(/\s/g, '').includes(b.toLowerCase().replace(/\s/g, ''));

/**
 * Capitalizes the first letter of a string
 *
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Convert kebab-case string to Title Case
 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const kebabCaseToTitleCase = (string?: string): string =>
  string
    ? string
        .replace(/([_])/g, ' ')
        .replace(/([/])/g, ' ')
        .replace(/([.])/g, ' ')
        .replace(/([|])/g, ' ')
        .replace(/([:])/g, ' ')
        .trim()
        .split('-')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    : '';

/**
 * Convert snake_case or SCREAMING_SNAKE_CASE string to camelCase

 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const snakeCaseToCamelCase = (string?: string): string =>
  string
    ? string
        .replace(/([_])/g, ' ')
        .replace(/([/])/g, ' ')
        .replace(/([.])/g, ' ')
        .replace(/([|])/g, ' ')
        .replace(/([:])/g, ' ')
        .trim()
        .toLowerCase()
        .split(' ')
        .map((word, index) => (index === 0 ? word : capitalizeFirstLetter(word)))
        .join('')
    : '';

/**
 * Convert camelCase string to SCREAMING_SNAKE_CASE
 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const camelCaseToScreamingSnakeCase = (string?: string): string =>
  string
    ? string
        .replace(/([A-Z])/g, '_$1')
        .replace(/([_])/g, '_')
        .replace(/([/])/g, '_')
        .replace(/([.])/g, '_')
        .replace(/([|])/g, '_')
        .replace(/([:])/g, '_')
        .toUpperCase()
    : '';

/**
 * Convert PascalCase or camelCase string to Title Case
 * Handles numbers and special characters as a single word
 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const compoundCaseToTitleCase = (string?: string): string =>
  string
    ? string
        .replace(/([A-Z])/g, ' $1')
        .replace(/([0-9]+)/g, ' $1')
        .replace(/([_])/g, ' ')
        .replace(/([/])/g, ' ')
        .replace(/([.])/g, ' ')
        .replace(/([|])/g, ' ')
        .replace(/([:])/g, ' ')
        .replace(/([ ]{2,})/g, ' ')
        .toLowerCase()
        .trim()
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    : '';

/**
 * Convert PascalCase or camelCase string to kebab-case
 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const compoundCaseToKebabCase = (string?: string): string =>
  string
    ? string
        .replace(/([A-Z])/g, ' $1')
        .replace(/([0-9]+)/g, ' $1')
        .replace(/([_])/g, ' ')
        .replace(/([/])/g, ' ')
        .replace(/([.])/g, ' ')
        .replace(/([|])/g, ' ')
        .replace(/([:])/g, ' ')
        .replace(/([ ]{2,})/g, ' ')
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')
    : '';

/**
 * Convert PascalCase or camelCase string to capitalized SNAKE_CASE
 * Handles undefined, null, and empty string
 *
 * @param string
 * @returns {string}
 */
export const compoundCaseToCapitalSnakeCase = (string?: string): string =>
  string
    ? string
        .replace(/([A-Z])/g, ' $1')
        .replace(/([0-9]+)/g, ' $1')
        .replace(/([_])/g, ' ')
        .replace(/([/])/g, ' ')
        .replace(/([.])/g, ' ')
        .replace(/([|])/g, ' ')
        .replace(/([:])/g, ' ')
        .replace(/([ ]{2,})/g, ' ')
        .toUpperCase()
        .trim()
        .split(' ')
        .join('_')
    : '';

/**
 * Convert Title Case string to kebab-case
 *
 * @param string
 * @returns {string}
 */
export const titleCaseToKebabCase = (string?: string): string =>
  string
    ? string
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
    : '';

/**
 * Takes a string and ensures there is a '-' every 4th character. The goal is to
 * make our long versionId strings easier to read in chunks.
 *
 * Ex. 123456789098-7 -> 1234-5678-9098-7
 *
 * @param input
 */
export const hyphenateString = (input: string): string =>
  input ? (input.replace('-', '').match(/.{1,4}/g) || [input]).join('-') : '';

/**
 * Takes an array of strings concatenates them into a comma seperated list with "and" before the end
 *
 * @param strings
 */
export const concatenateList = (strings: string[]): string => {
  if (!strings || strings.length < 1) {
    return '';
  }

  const validStrings = strings.filter((string) => string);

  let result = '';
  validStrings.forEach((string, index) => {
    result += string;
    if (index !== validStrings.length - 1) {
      if (validStrings.length > 2 && index === validStrings.length - 2) {
        result += ', and ';
      } else if (validStrings.length === 2 && index === 0) {
        result += ' and ';
      } else {
        result += ', ';
      }
    }
  });
  return result;
};

/**
 * Takes a string and pluralizes it
 *
 * @param text
 */
export const pluralizeString = (text: string): string => {
  if (!text || text.length < 2 || text.endsWith('ing')) {
    return text;
  }
  if (text.endsWith('y')) {
    return `${text.substring(0, text.length - 1)}ies`;
  }
  if (text.endsWith('s')) {
    return `${text}es`;
  }
  return `${text}s`;
};

/**
 * Takes an input string and replaces the delimited values `$n` with the list of input strings [1-n]. The goal is to
 * make our long versionId strings easier to read in chunks.
 *
 * Ex. `Replace the $1 with $2` -> `Replace the delimiters with values`
 *
 * @param inputString string
 * @param replacementStrings string[]
 * @returns string with delimiters replaced
 */
export const replaceString = (inputString: string, replacementStrings: (string | undefined)[]): string => {
  let replacedString = inputString;
  replacementStrings.forEach((replacement, i) => {
    replacedString = replacedString.replace(`$${i + 1}`, replacement || '');
  });
  return replacedString;
};
