export enum ComponentCategoryKey {
  Cupola = 'cupola',
  Door = 'door',
  Dormer = 'dormer',
  Flooring = 'flooring',
  FlowerBox = 'flower-box',
  Frameout = 'frameout',
  FrameoutCorner = 'frameout-corner',
  GarageDoor = 'garage-door',
  NestingBox = 'nesting-box',
  Vent = 'vent',
  Ramp = 'ramp',
  Shelf = 'shelf',
  Shutter = 'shutter',
  Siding = 'siding',
  Window = 'window',
  WindowTransom = 'window-transom',
  Workbench = 'workbench',
}

export enum SizeBasedCategoryKey {
  EndWalls = 'end-walls',
  Insulation = 'insulation',
  LegHeight = 'leg-height',
  Panels = 'panels',
  SideWalls = 'side-walls',
  Roof = 'roof',
}
