import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Fab,
  Typography,
  ListItem,
  Hidden,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../constants/AppRoutes';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { SystemGroups } from '../constants/SystemGroups';
import { openDialog, setForm } from '../ducks/dialogSlice';
import { fetchGroups } from '../ducks/groups';
import { AppState } from '../types/AppState';
import { Group } from '../types/Group';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { GroupMenu } from './GroupMenu';
import { Table } from './Table';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { FabMenu } from './FabMenu';
import { ConfiguratorFormType } from '../constants/Configurator';
import { isIdeaRoomUser } from '../utils/userUtils';
import { config } from '../config/config';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
  smallGroupList: {
    flex: 1,
    paddingBottom: '70px',
  },
  smallGroupItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}));

const GroupNameCell: React.FC<any> = ({ row, row: { groupName, groupId }, value }: any) => (
  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
    <Box style={{ flex: 1, cursor: 'pointer' }} onClick={(): void => navigate(`${AppRoutes.Groups}/${groupId}`)}>
      {groupName}
    </Box>
    {value !== SystemGroups.IdeaRoom && (
      <div>
        {row.updating && <CircularProgress size={24} color="primary" />}
        {!row.updating && <GroupMenu group={row} />}
      </div>
    )}
  </div>
);

export const Groups: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    groups: { groups, loading },
    search: { searchTerm },
    currentUser: { user },
  } = useAppSelector((state: AppState) => state || {});

  const [filteredGroups, setFilteredGroups] = useState<Group[]>(groups);

  // Effect that filters groups by searchTerm anytime either groups or searchTerm change
  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredGroups(groups);
      return;
    }
    const tests = [(group: Group): boolean => stringToLowerIncludes(group.groupName, searchTerm)];

    setFilteredGroups(groups.filter((group) => tests.some((test) => test(group))));
  }, [groups, searchTerm]);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [fetchGroups]);

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Table
          headers={[{ i18nKey: I18nKeys.TableHeaderGroupName, property: 'groupName', CellComponent: GroupNameCell }]}
          sortableProperties={[{ i18nKey: I18nKeys.TableHeaderGroupName, property: 'groupName' }]}
          rows={filteredGroups}
          loading={loading}
        />
      </Hidden>
      <Hidden smUp>
        <List className={classes.smallGroupList}>
          {filteredGroups.map((group) => (
            <React.Fragment key={group.groupId}>
              <ListItemButton
                className={classes.smallGroupItem}
                onClick={(): void => navigate(`${AppRoutes.Groups}/${group.groupId}`)}
              >
                <ListItemText primary={group.groupName} />
                {group.groupId !== SystemGroups.IdeaRoom && (
                  <ListItem secondaryAction>
                    <GroupMenu group={group} />
                  </ListItem>
                )}
              </ListItemButton>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Hidden>
      {config.environment.STAGE === 'production' && isIdeaRoomUser(user) ? (
        <FabMenu
          icon={<AddIcon />}
          label={I18nKeys.AddButton}
          actions={[
            {
              icon: <AddBusinessIcon />,
              i18nKey: I18nKeys.ConfiguratorDialogFabCreateGroupWithDefault,
              onClick: () => {
                dispatch(setForm(ConfiguratorFormType.AddGroupWithDefault));
                dispatch(openDialog({ dialog: Dialogs.Configurator }));
              },
            },
            {
              icon: <AddIcon />,
              i18nKey: I18nKeys.ConfiguratorDialogFabCreateGroup,
              onClick: () => {
                dispatch(setForm(ConfiguratorFormType.AddGroup));
                dispatch(openDialog({ dialog: Dialogs.Configurator }));
              },
            },
          ]}
          className={classes.fab}
        />
      ) : (
        <Fab
          color="primary"
          className={classes.fab}
          variant="extended"
          onClick={() => {
            dispatch(setForm(ConfiguratorFormType.AddGroup));
            dispatch(openDialog({ dialog: Dialogs.Configurator }));
          }}
          aria-label="add configurator"
        >
          <AddIcon />
          <Typography className={classes.fabText}>{t(I18nKeys.AddButton)}</Typography>
        </Fab>
      )}
    </Box>
  );
};
