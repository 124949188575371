import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { OptionType, PricingSurchargeCategory } from '@idearoom/types/lib/types/src';
import { AppState } from '../types/AppState';
import {
  setPricingBaseDataBranch,
  updatePricingMetadata,
  updatePricingComponentRows,
  updateSurchargeProperty,
  setPricingComponentDataBranch,
  updatePricingSheetRows,
  setPricingSizeBasedDataBranch,
  addPricingBaseRow,
  removePricingBaseRows,
} from '../ducks/pricingSlice';
import { GridData, TableData } from '../types/DataGrid';
import { CellMetadata } from '../types/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import {
  UpdateClientDataMetadata,
  saveClientDataComplete,
  saveClientDataStart,
  setCreatingBranch,
  setCreatingBranchComplete,
  updateClientData,
} from '../ducks/clientDataSlice';
import { COMMIT_PRICING_BASE_SAVE_PREFIX, COMMIT_SAVE_PREFIX, PRICING_SURCHARGE_TABLE } from '../constants/ClientData';
import { ClientDataType } from '../constants/ClientDataType';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { getUpdatedCellsMetadata } from '../utils/metadataUtils';
import { pricingApi } from '../services/pricingApi';
import {
  addPricingCategory,
  addPricingConditionToAllRules,
  addPricingRule,
  findSelectedSurcharge,
  formatSurchargeCalculation,
  parseClientSurcharges,
  reconcileSurchargeCalculations,
  removePricingCategory,
  removePricingConditionFromAllRules,
  updatePricingRuleCondition,
} from '../utils/pricingUtils';
import { isCarportView, mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { addDispatchCommandToUndo } from '../utils/undoManagerUtils';
import {
  PRICING_SURCHARGE_COLUMN,
  SurchargeRuleProperty,
  SurchargeUpdateProperty,
} from '../constants/PricingSurcharge';
import { KEY_COLUMN } from '../constants/ClientDataColumn';
import { PricingTab } from '../constants/Pricing';
import { getPricingSheetTable } from '../utils/pricingSheetUtils';
import { openDialog } from '../ducks/dialogSlice';
import { openNotificationDialog } from '../ducks/notification';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { FetchError } from '../types/API';

export const pricingListener = createListenerMiddleware<AppState>();

const getTableName = (clientId: string) => (clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase');

/**
 * Updates/Delete rows from the database
 *
 * @returns
 */
const updateDataEffect = async (
  data: GridData,
  metadata: {
    [table: string]: CellMetadata[];
  } | null,
  isDelete: boolean,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
  branch: ClientDataBranch,
  newBranch = false,
) => {
  try {
    dispatch(saveClientDataStart());

    const { clientData, currentUser } = state;
    const { clientId } = clientData;
    const { user, group: { groupId } = unknownGroup } = currentUser;

    if (isDelete) {
      await dispatch(
        clientDataApi.endpoints.deleteClientData.initiate({
          clientId,
          dataType: ClientDataType.Supplier,
          branch,
          data,
          message: `${COMMIT_PRICING_BASE_SAVE_PREFIX} ${clientId}`,
          newBranch,
          user,
          groupId,
        }),
      );
    } else {
      await dispatch(
        clientDataApi.endpoints.updateClientData.initiate({
          clientId,
          groupId,
          dataType: ClientDataType.Supplier,
          branch,
          data,
          metadata,
          message: `${COMMIT_PRICING_BASE_SAVE_PREFIX} ${clientId}`,
          newBranch,
          user,
        }),
      );
    }
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

pricingListener.startListening({
  actionCreator: updatePricingSheetRows,
  effect: async (action, { dispatch, getState }) => {
    const { payload: rows } = action;
    const state = getState();
    const {
      viewer: { selectedPricingTabId },
      clientData: { clientId },
      pricing: {
        base: { pricingDataBranch: basePricingDataBranch },
        sizeBased: { pricingDataBranch: sizeBasedPricingDataBranch, selectedCategoryKey: categoryKey },
      },
    } = state;
    let selectedBranch = selectedPricingTabId === PricingTab.Base ? basePricingDataBranch : sizeBasedPricingDataBranch;

    let newBranch = false;
    if (!selectedBranch || selectedBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch =
        selectedPricingTabId === PricingTab.Base ? ClientDataBranch.Pricing : ClientDataBranch.PricingSizeBased;
      newBranch = true;
    }

    try {
      const table = getPricingSheetTable(clientId, selectedPricingTabId, categoryKey);
      if (!table) return;

      const tablesData: { [table: string]: TableData[] } = {};
      const tablesCellMetadata: { [table: string]: CellMetadata[] } = {};
      rows.forEach(({ rowData, column, value }) => {
        tablesData[table] = tablesData[table] || [];
        const { [ClientDataFixedColumns.RowId]: rowId } = rowData;

        const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);

        const updatedRow = existingRow || { ...rowData };
        updatedRow[column] = value;
        if (!existingRow) {
          tablesData[table] = [...tablesData[table], updatedRow];
        }
      });

      await updateDataEffect(tablesData, tablesCellMetadata, false, dispatch, state, selectedBranch, newBranch).then(
        () => {
          dispatch(
            selectedPricingTabId === PricingTab.Base
              ? setPricingBaseDataBranch(selectedBranch)
              : setPricingSizeBasedDataBranch(selectedBranch),
          );
        },
      );
    } finally {
      dispatch(setCreatingBranchComplete());
      dispatch(saveClientDataComplete());
    }
  },
});

pricingListener.startListening({
  actionCreator: updatePricingComponentRows,
  effect: async (action, { dispatch, getState }) => {
    const { payload: rows } = action;
    const state = getState();
    const {
      pricing: {
        component: { pricingDataBranch },
      },
    } = state;

    let selectedBranch = pricingDataBranch || ClientDataBranch.ClientUpdate;
    let newBranch = false;
    if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch = ClientDataBranch.ClientUpdate;
      newBranch = true;
    }

    const tablesData: { [table: string]: TableData[] } = {};
    const tablesCellMetadata: { [table: string]: CellMetadata[] } = {};

    try {
      rows.forEach(({ table, rowData, column, value }) => {
        tablesData[table] = tablesData[table] || [];
        const { [ClientDataFixedColumns.RowId]: rowId } = rowData;

        const existingRow = tablesData[table].find((r) => r[ClientDataFixedColumns.RowId] === rowId);

        const updatedRow = existingRow || { ...rowData };
        updatedRow[column] = value;
        if (!existingRow) {
          tablesData[table] = [...tablesData[table], updatedRow];
        }
      });

      await updateDataEffect(tablesData, tablesCellMetadata, false, dispatch, state, selectedBranch, newBranch).then(
        () => {
          dispatch(setPricingComponentDataBranch(selectedBranch));
        },
      );
    } finally {
      dispatch(setCreatingBranchComplete());
      dispatch(saveClientDataComplete());
    }
  },
});

pricingListener.startListening({
  actionCreator: updateSurchargeProperty,
  effect: async (action, { dispatch, getState }) => {
    const { payload: updates } = action;
    const state = getState();
    const {
      pricing: {
        surcharge: { supplierKey = '' },
      },
      clientData: { clientId, clientDataType },
      currentUser: { group: { groupId } = unknownGroup },
    } = state;
    const isCarportViewClient = isCarportView(clientId);

    let { data: tableData = [] } = clientDataApi.endpoints.getClientDataTableData.select({
      dataType: ClientDataType.Vendor,
      clientId,
      groupId,
      branch: ClientDataBranch.PricingSurcharge,
      table: PRICING_SURCHARGE_TABLE,
    })(state);

    let mainTableData: TableData[] | undefined = [];
    if (!tableData || !tableData.length) {
      // If no table data is found, try to default to the main table data
      ({ data: mainTableData = [] } = clientDataApi.endpoints.getClientDataTableData.select({
        dataType: ClientDataType.Vendor,
        clientId,
        groupId,
        branch: ClientDataBranch.Main,
        table: PRICING_SURCHARGE_TABLE,
      })(state));
      tableData = mainTableData;
    }
    const clientSurcharges = parseClientSurcharges(tableData);

    const updatedSurcharge = updates.reduce(
      (acc, { property, value }) => {
        if (property === SurchargeUpdateProperty.Rules) {
          const { id: ruleId, property: rulesProperty, isDelete, isNew, value: ruleValue } = value as any;

          // Add a new rule
          if (isNew) return addPricingRule(acc);
          // Delete a rule by ID
          if (isDelete && ruleId !== undefined) {
            return {
              ...acc,
              rules: acc.rules.filter((rule) => rule.id !== ruleId),
            };
          }

          if (rulesProperty === SurchargeRuleProperty.Calculation && ruleId !== undefined) {
            const { property: calculationProperty, value: calculationValue } = ruleValue as any;
            return {
              ...acc,
              rules: [
                ...acc.rules.map((rule) => {
                  if (rule.id === ruleId) {
                    return {
                      ...rule,
                      calculation: {
                        ...rule.calculation,
                        [calculationProperty]: calculationValue,
                      },
                    };
                  }
                  return rule;
                }),
              ],
            };
          }

          if (rulesProperty === SurchargeRuleProperty.Conditions) {
            const {
              type: conditionType,
              value: conditionValue,
              isNew: newCondition,
              isDelete: deleteCondition,
            } = ruleValue as any;

            if (newCondition) return addPricingConditionToAllRules(acc, conditionType);
            if (deleteCondition) return removePricingConditionFromAllRules(acc, conditionType);
            if (ruleId !== undefined) return updatePricingRuleCondition(acc, ruleId, conditionType, conditionValue);
          }
        }

        if (!property || value === undefined) return acc;

        if (property === SurchargeUpdateProperty.Calculation) {
          const { property: calculationProperty, value: calculationValue } = value;
          return {
            ...acc,
            calculation: {
              ...acc.calculation,
              [calculationProperty]: calculationValue,
            },
          };
        }

        if (property === SurchargeUpdateProperty.Categories) {
          const category = value as PricingSurchargeCategory;
          // IFC has multiple categories, while IFS has only one
          if (isCarportViewClient) {
            const { categories } = acc;
            return categories.includes(category)
              ? removePricingCategory(acc, category)
              : addPricingCategory(acc, category);
          }
          return addPricingCategory({ ...acc, [SurchargeUpdateProperty.Categories]: [] }, category);
        }

        return { ...acc, [property]: value };
      },
      { ...findSelectedSurcharge(tableData, clientId, supplierKey) },
    );

    const formattedSurcharge = formatSurchargeCalculation(updatedSurcharge, supplierKey);
    const updatedSurcharges = reconcileSurchargeCalculations(clientId, clientSurcharges, formattedSurcharge);
    const updateValue = JSON.stringify(updatedSurcharges);

    const [rowData] = tableData;
    const oldData = {
      table: PRICING_SURCHARGE_TABLE,
      rowData,
      column: PRICING_SURCHARGE_COLUMN,
      value: rowData ? rowData[PRICING_SURCHARGE_COLUMN] : null,
      formula: undefined,
    };
    const newData = {
      ...oldData,
      value: updateValue,
      rowData: { ...rowData, [KEY_COLUMN]: clientId, [PRICING_SURCHARGE_COLUMN]: updateValue },
    };

    const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
      clientId,
      clientDataType,
      PRICING_SURCHARGE_TABLE,
    );

    addDispatchCommandToUndo(
      dispatch,
      [updateClientData({ rows: [oldData], branch: ClientDataBranch.PricingSurcharge })],
      [updateClientData({ rows: [newData], branch: ClientDataBranch.PricingSurcharge })],
      clientDataTableId,
      true,
    );
  },
});

/**
 * Updates/Delete cell metadata from the database
 *
 * @returns
 */
const updatePricingMetadataEffect = async (
  updates: UpdateClientDataMetadata[],
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  state: AppState,
) => {
  // const { clientId, clientDataType: dataType, clientDataBranch, selectedTable: table } = state.clientData;
  const { clientData, currentUser, pricing } = state;
  const { clientId } = clientData;
  const {
    base: { pricingDataBranch },
  } = pricing;
  const { group: { groupId } = unknownGroup } = currentUser;
  const table = getTableName(clientId);

  let selectedBranch = pricingDataBranch || ClientDataBranch.Pricing;
  let newBranch = false;
  if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
    dispatch(setCreatingBranch());
    selectedBranch = ClientDataBranch.Pricing;
    newBranch = true;
  }

  const cellMetadataUpdates = getUpdatedCellsMetadata(clientId, table, updates).filter(Boolean);

  if (!cellMetadataUpdates.length) return;

  try {
    dispatch(saveClientDataStart());

    if (cellMetadataUpdates.length === 1) {
      const [cellMetadata] = cellMetadataUpdates;
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellMetadata.initiate({
          dataType: ClientDataType.Supplier,
          clientId,
          groupId,
          table,
          cellMetadata,
          branch: selectedBranch,
          rowId: cellMetadata.rowId,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    } else {
      await dispatch(
        clientDataApi.endpoints.updateClientDataCellsMetadata.initiate({
          dataType: ClientDataType.Supplier,
          clientId,
          groupId,
          table,
          cellsMetadata: cellMetadataUpdates,
          branch: selectedBranch,
          newBranch,
          message: `${COMMIT_SAVE_PREFIX} ${clientId}`,
        }),
      );
    }

    dispatch(setPricingBaseDataBranch(selectedBranch));
  } finally {
    dispatch(setCreatingBranchComplete());
    dispatch(saveClientDataComplete());
  }
};

pricingListener.startListening({
  actionCreator: updatePricingMetadata,
  effect: async (action, { dispatch, getState }) => {
    const { payload: updates = [] } = action;
    const state = getState();
    await updatePricingMetadataEffect(updates, dispatch, state);
  },
});

pricingListener.startListening({
  actionCreator: addPricingBaseRow,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const {
      clientData: { clientId },
      pricing: {
        base: { pricingDataBranch },
      },
    } = state;

    let selectedBranch = pricingDataBranch || ClientDataBranch.Pricing;
    let newBranch = false;
    if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch = ClientDataBranch.Pricing;
      newBranch = true;
    }

    const {
      payload: { rows, selectedPricingSheet },
    } = action;
    const attributes: { [type: string]: string | boolean } = {};
    selectedPricingSheet.attributes.forEach((attribute) => {
      attributes[attribute.type] = attribute.value;
    });
    const newPrices: TableData[] = [];
    for (let i = 0; i < rows.length; i += 1) {
      const newPrice: TableData = { ...rows[i], ...attributes, priceSetLabel: selectedPricingSheet.priceSetLabel };
      newPrices.push(newPrice);
    }

    try {
      dispatch(saveClientDataStart());

      const { currentUser } = state;
      const { user, group: { groupId } = unknownGroup } = currentUser;

      const result = await dispatch(
        pricingApi.endpoints.addBasePrices.initiate({
          clientId,
          groupId,
          data: newPrices,
          message: `${COMMIT_PRICING_BASE_SAVE_PREFIX} ${clientId}`,
          branch: selectedBranch,
          newBranch,
          user,
        }),
      );

      if ('error' in result) {
        const { status, data } = result.error as FetchError;
        if (status === 404 && data?.includes(OptionType.RoofPitch)) {
          dispatch(
            openNotificationDialog(
              I18nKeys.PricingBaseAddSizeMissingRoofPitchTitle,
              I18nKeys.PricingBaseAddSizeMissingRoofPitchMessage,
            ),
          );
          dispatch(openDialog({ dialog: Dialogs.Notification }));
        }
      }

      dispatch(setPricingBaseDataBranch(selectedBranch));
    } finally {
      dispatch(setCreatingBranchComplete());
      dispatch(saveClientDataComplete());
    }
  },
});

pricingListener.startListening({
  actionCreator: removePricingBaseRows,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { clientData, pricing } = state;
    const { clientId } = clientData;
    const {
      base: { pricingDataBranch },
    } = pricing;

    const {
      payload: { rows },
    } = action;

    let selectedBranch = pricingDataBranch || ClientDataBranch.Pricing;
    let newBranch = false;
    if (!pricingDataBranch || pricingDataBranch === ClientDataBranch.Main) {
      dispatch(setCreatingBranch());
      selectedBranch = ClientDataBranch.Pricing;
      newBranch = true;
    }

    try {
      const table = getTableName(clientId);

      const data = {
        [table]: rows
          .filter((row) => row[ClientDataFixedColumns.RowId])
          .map((row) => ({ [ClientDataFixedColumns.RowId]: row[ClientDataFixedColumns.RowId] })),
      };
      await updateDataEffect(data, null, true, dispatch, state, selectedBranch, newBranch);

      dispatch(setPricingBaseDataBranch(selectedBranch));
    } finally {
      dispatch(setCreatingBranchComplete());
      dispatch(saveClientDataComplete());
    }
  },
});
