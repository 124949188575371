import { Typography } from '@mui/material';
import React from 'react';
import { ClientPublishedVersions } from '../constants/ClientData';
import { useGetClientName } from '../hooks/useGetClientName';

export const ClientDataApplyDataUpdatesTooltip: React.FC<{ clientPublishedVersions?: ClientPublishedVersions }> = ({
  clientPublishedVersions,
}) => {
  const getClientName = useGetClientName();

  if (clientPublishedVersions?.vendors) {
    return (
      <>
        <Typography variant="caption" gutterBottom component="div">
          Published changes (version {clientPublishedVersions?.latestVersion || 0}) have not yet been applied to all
          vendors.
        </Typography>
        {clientPublishedVersions?.vendors
          ?.filter((vendor) => vendor.published?.structureVersion !== clientPublishedVersions.latestVersion)
          .map((vendor) => (
            <Typography variant="caption" key={vendor.key} component="div">
              {`${getClientName(vendor.key)}: ${
                (vendor.published && `Version ${vendor.published?.structureVersion || 0}`) || `Not published`
              }`}
            </Typography>
          ))}
      </>
    );
  }

  const suppliersToUpdate = clientPublishedVersions?.suppliers
    ?.filter((supplier) => supplier.latestVersion !== supplier.published?.structureVersion)
    .map((supplier) => ({ ...supplier, name: getClientName(supplier.key) }));
  const updateReference =
    clientPublishedVersions?.system?.latestVersion !== clientPublishedVersions?.system?.publishedVersion;

  return (
    <>
      <Typography variant="caption" gutterBottom component="div">
        {(suppliersToUpdate?.length || 0) > 0 && (
          <div>Changes made to {suppliersToUpdate?.map(({ name }) => name).join(', ')} supplier data.</div>
        )}
        {updateReference && (
          <div>Changes made to reference data (version {clientPublishedVersions?.system?.latestVersion || 0}).</div>
        )}
      </Typography>
      {suppliersToUpdate?.map((supplier) => (
        <Typography variant="inherit" component="div" key={supplier.key}>{`${supplier.name} version ${
          supplier.latestVersion || 0
        }`}</Typography>
      ))}
    </>
  );
};
