import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog, setForm, setGroup } from '../ducks/dialogSlice';
import { removeGroup } from '../ducks/groups';
import { Group } from '../types/Group';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch } from '../hooks';
import { ConfiguratorFormType } from '../constants/Configurator';
import { resetClientIds } from '../ducks/viewerSlice';
import { changeGroup as changeGroupFunc } from '../ducks/currentUserSlice';

interface Props {
  group: Group;
}

const useStyles = makeStyles((theme: Theme) => ({
  moreButton: {
    padding: theme.spacing(1),
  },
}));

export const GroupMenu: React.FC<Props> = ({ group }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { groupId } = group;

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const changeGroup = (): void => {
    dispatch(resetClientIds());
    dispatch(changeGroupFunc(groupId));
  };

  const confirmRemoval = (): void => {
    dispatch(
      openConfirmationDialog(
        [removeGroup(group)],
        [],
        t(I18nKeys.GroupMenuConfirmDeleteTitle),
        t(I18nKeys.GroupMenuConfirmDeleteMessage),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  };

  const openConfiguratorDialog = (): void => {
    dispatch(setGroup(group));
    dispatch(setForm(ConfiguratorFormType.EditGroup));
    dispatch(openDialog({ dialog: Dialogs.Configurator }));
  };

  return (
    <div>
      <IconButton
        className={classes.moreButton}
        aria-controls="user-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="user-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {[
          {
            label: t(I18nKeys.GroupMenuGoToGroupOption),
            onClick: changeGroup,
          },
          {
            label: t(I18nKeys.GroupMenuEditGroupOption),
            onClick: openConfiguratorDialog,
          },
          {
            label: t(I18nKeys.GroupMenuRemoveGroupOption),
            onClick: confirmRemoval,
          },
        ].map(({ label, onClick }) => (
          <MenuItem key={label}>
            <ListItemText
              onClick={() => {
                onClick();
                handleClose();
              }}
              primary={label}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
