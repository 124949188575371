import { IconButton } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import widthIncreaseSrc from '../images/widthIncrease.svg';
import widthDecreaseSrc from '../images/widthDecrease.svg';
import { setSearchOpen, toggleSearchExpanded } from '../ducks/clientDataSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';

const useStyles = makeStyles(() => ({
  actions: {
    width: 'auto',
    padding: '16px 8px',
    '& > :not(:last-child)': {
      marginRight: '5px',
    },
  },
}));

interface Props {
  disableIndices: boolean;
  getSearchResultIndex: (increment: number) => void;
}

export const ClientDataSearchActions: React.FC<Props> = ({ disableIndices, getSearchResultIndex }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { search: { expanded = false } = {} } = useAppSelector((state: AppState) => state?.clientData);

  const searchActions = [
    {
      key: 'search-up',
      icon: <KeyboardArrowUp />,
      disabled: disableIndices,
      onClick: () => getSearchResultIndex(-1),
    },
    {
      key: 'search-down',
      icon: <KeyboardArrowDown />,
      disabled: disableIndices,
      onClick: () => getSearchResultIndex(1),
    },
    {
      key: 'search-width-increase',
      icon: <img alt="Width Increase" src={expanded ? widthDecreaseSrc : widthIncreaseSrc} className="ag-icon" />,
      disabled: false,
      onClick: () => dispatch(toggleSearchExpanded()),
    },
    {
      key: 'search-close',
      icon: <Close />,
      disabled: false,
      onClick: () => dispatch(setSearchOpen(false)),
    },
  ];

  return (
    <div className={classes.actions}>
      {searchActions.map((action) => (
        <IconButton key={action.key} onClick={action.onClick} disabled={action.disabled}>
          {action.icon}
        </IconButton>
      ))}
    </div>
  );
};
