import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PricingSurchargeCalculationType, PricingSurchargeSupplierOption } from '@idearoom/types';
import { pricingSurchargeCalculationTypes, SurchargeUpdateProperty } from '../constants/PricingSurcharge';
import { useAppDispatch } from '../hooks';
import { updateSurchargeProperty } from '../ducks/pricingSlice';
import { usePricingRepo } from '../hooks/usePricingRepo';

interface Props {
  disabled: boolean;
  dealer: boolean;
}

export const PricingSurchargeCalculationTypeSelector: React.FC<Props> = ({ disabled, dealer = false }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedPricingSurcharge } = usePricingRepo({
    useBranches: true,
    useSelectedPricingSurcharge: true,
  });
  const { key: surchargeCalculationType, supplierSurcharge } = selectedPricingSurcharge;

  const pricingSelection =
    supplierSurcharge === PricingSurchargeSupplierOption.Inherit
      ? PricingSurchargeCalculationType.None
      : surchargeCalculationType;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selection = (event.target as HTMLInputElement).value;
    dispatch(updateSurchargeProperty([{ property: SurchargeUpdateProperty.Type, value: selection }]));
  };

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <RadioGroup aria-label="pricing-options" name="pricingOptions" value={pricingSelection} onChange={handleChange}>
        {pricingSurchargeCalculationTypes.map(
          (option) =>
            ((dealer && option.dealerOption) || !dealer) && (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={t(option.label)}
              />
            ),
        )}
      </RadioGroup>
    </FormControl>
  );
};
