import { isValidDomain } from './configuratorUtils';

/**
 * Extract domain from an email address.
 */
export const extractEmailAddressDomain = (initialEmailAddress: string | undefined): string | undefined => {
  if (!initialEmailAddress) {
    return undefined;
  }
  let emailAddress = initialEmailAddress.toLowerCase().trim();

  // Remove angle brackets and display name: e.g. "Punxsutawney Phil <pphil@idearoom.com>" -> "pphil@idearoom.com"
  if (emailAddress.includes('<')) {
    // > must be last character
    if (emailAddress[emailAddress.length - 1] !== '>') {
      throw new Error(`Invalid Contact Email Address: ${initialEmailAddress}`);
    }

    const startMarkerIndex = emailAddress.indexOf('<');
    const endMarkerIndex = emailAddress.indexOf('>');

    emailAddress = emailAddress.substring(startMarkerIndex + 1, endMarkerIndex);
  }

  const splitResult = emailAddress.split('@'); // 0=user, 1=domain (user@domain)
  const [, domain] = splitResult;

  if (splitResult.length !== 2 || !domain || !isValidDomain(domain)) {
    throw new Error(`Invalid Contact Email Address: ${initialEmailAddress}`);
  }

  return domain;
};

export const getDomainFromEmail = (email: string): { domain: string; error?: string } => {
  const invalidEmail = 'Invalid Email Address';
  let domain = '';
  try {
    domain = extractEmailAddressDomain(email) || '';
    if (!domain) throw new Error();
    return { domain };
  } catch (e) {
    return { domain: 'your domain', error: invalidEmail };
  }
};
