import React, { forwardRef } from 'react';
import { Paper, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';

const useStyles = makeStyles({
  paper: {
    padding: '2px',
    paddingLeft: '6px',
    resize: 'both',
    overflow: 'auto',
    userSelect: 'text',
    height: '150px',
  },
});

export const ClientDataChangesSummaryDiffCellEditor = forwardRef<ICellEditorReactComp, ICellEditorParams>(
  ({ value, formatValue }, ref) => {
    const classes = useStyles();
    const theme = useTheme();

    const valueFormatted = formatValue(value);
    return (
      <Paper className={classes.paper}>
        <code>
          {Array.isArray(valueFormatted)
            ? valueFormatted.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    // eslint-disable-next-line no-nested-ternary
                    backgroundColor: part.added
                      ? theme.palette.diffAdd.main
                      : part.removed
                      ? theme.palette.diffRemove.main
                      : undefined,
                  }}
                >
                  {part.value}
                </span>
              ))
            : valueFormatted}
        </code>
      </Paper>
    );
  },
);
