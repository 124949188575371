import { Step } from 'react-joyride';
import { TFunction } from 'i18next';
import { tourSteps } from '../constants/TourSteps';
import {
  Columns,
  allAvailableColumns,
  TableFilterType,
  defaultAvailableColumns,
  unknownUserDefaultColumns,
  mobileLogoBarHeight,
  logoBarHeight,
  breadcrumbsHeight,
  clientDataTopBarHeight,
} from '../constants/Viewer';
import { GroupFilter } from '../types/CustomFilter';
import { Dealer } from '../types/Dealer';
import { Group, GroupData } from '../types/Group';
import { unknownGroup } from '../constants/Group';
import { unknownUser, User } from '../types/User';
import { compoundCaseToTitleCase, pluralizeString } from './stringUtils';
import { isCurrentUserAdmin, isCurrentUserManager } from './userUtils';
import { getEnabledOnProperty } from './vendorDataUtils';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { AppRoutes } from '../constants/AppRoutes';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingTab } from '../constants/Pricing';

export const includesPath = (path: string, paths: AppRoutes[] | AppRoutes): boolean => {
  const pathsToInclude = Array.isArray(paths) ? paths : [paths];
  return pathsToInclude.some((pathToInclude) => path.startsWith(pathToInclude));
};

export const getTourSteps = (groups: GroupData[]): Step[] => {
  const steps = tourSteps
    .filter((step) => step.filter(groups))
    .map((step): any => ({
      target: step.target,
      content: step.content,
    }));
  return steps.length > 0 ? [{ ...steps[0], disableBeacon: true }, ...steps.slice(1)] : [];
};

export const getFilterMessage = (
  groupId: string | undefined,
  groupFilters: GroupFilter[],
  tableFilterType: TableFilterType,
): string => {
  let message = '';
  const tableFilters = groupFilters.find((groupfilter) => groupfilter.groupId === groupId);
  const filtersOfType = tableFilters
    ? tableFilters.tableFilters.find((tableFilter) => tableFilter.tableFilterType === tableFilterType)
    : undefined;
  if (filtersOfType && filtersOfType.filters.length > 0) {
    message += 'for';
    // Get filters not containing 'all'
    const filterableResults = filtersOfType.filters.filter(
      (filter) => !filter.selectedFilterValues.some((selected) => selected.key === 'all'),
    );
    filterableResults.forEach((filter, index) => {
      const filterLabel = compoundCaseToTitleCase(filter.filterType || '').toLowerCase();
      const pluralFilter = filter.selectedFilterValues.length > 1 ? pluralizeString(filterLabel) : filterLabel;
      message +=
        filter.filterType && filter.selectedFilterValues.length > 0
          ? ` ${filter.selectedFilterValues.length} ${pluralFilter}`
          : '';
      if (index !== filterableResults.length - 1) {
        if (filterableResults.length > 2 && index === filterableResults.length - 2) {
          message += ', and';
        } else if (filterableResults.length === 2 && index === 0) {
          message += ' and';
        } else {
          message += ',';
        }
      }
    });
  }
  return message;
};

export const getUsersAvailableColumns = (
  user: User = unknownUser,
  group: Group = unknownGroup,
  availableDealers: Dealer[] = [],
): string[] => {
  let availableColumns = defaultAvailableColumns;

  if (user) {
    if (isCurrentUserAdmin(user) || isCurrentUserManager(user)) {
      availableColumns = allAvailableColumns;
    }
  } else {
    availableColumns = unknownUserDefaultColumns;
  }

  if (availableDealers.length < 2) {
    availableColumns = availableColumns.filter((column) => column !== Columns.Dealer);
  }

  if (group && group.configurators) {
    if (group.configurators.length < 2) {
      availableColumns = availableColumns.filter((column) => column !== Columns.Site);
    }
    if (
      !group.configurators.some((config) =>
        getEnabledOnProperty(config.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      )
    ) {
      availableColumns = availableColumns.filter((column) => column !== Columns.Dealer);
    }
  } else {
    availableColumns = availableColumns.filter((column) => column !== Columns.Site && column !== Columns.Dealer);
  }

  return availableColumns;
};

export const getPublishBarMessages = (path: string, t: TFunction, pricingTab?: string) => {
  let previewMessageKey = I18nKeys.PricingTextPreviewInstructions;
  let publishMessageKey = I18nKeys.PricingTextPublishInstructions;

  if (path.startsWith(AppRoutes.Sites)) {
    previewMessageKey = I18nKeys.SiteTextPreviewInstructions;
    publishMessageKey = I18nKeys.SiteTextPublishInstructions;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Base) {
    previewMessageKey = I18nKeys.PricingBasePreviewMessage;
    publishMessageKey = I18nKeys.PricingBasePublishMessage;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Component) {
    previewMessageKey = I18nKeys.PricingComponentPreviewMessage;
    publishMessageKey = I18nKeys.PricingComponentPublishMessage;
  }

  if (path.startsWith(AppRoutes.Pricing) && pricingTab === PricingTab.Surcharge) {
    previewMessageKey = I18nKeys.PricingSurchargePreviewMessage;
    publishMessageKey = I18nKeys.PricingSurchargePublishMessage;
  }
  const [previewMessage, publishMessage] = [previewMessageKey, publishMessageKey].map((key) => (key ? t(key) : ''));

  return {
    previewMessage,
    publishMessage,
  };
};

export const getUnsavedChangesMessage = (path: string, t: TFunction): string => {
  let messageKey;
  if (path.startsWith(AppRoutes.Pricing)) {
    messageKey = I18nKeys.PricingUnsavedChangesConfirmationMessage;
  }
  if (path.startsWith(AppRoutes.Sites)) {
    messageKey = I18nKeys.SitesUnsavedChangesConfirmationMessage;
  }
  return messageKey ? t(messageKey) : '';
};

export const getLogoBarHeight = ({ mobileView, path }: { mobileView: boolean; path: string }): number => {
  if (mobileView) {
    return mobileLogoBarHeight + breadcrumbsHeight;
  }
  if (path.startsWith(AppRoutes.ClientData)) {
    return clientDataTopBarHeight;
  }
  return logoBarHeight + breadcrumbsHeight;
};
