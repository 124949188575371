import { makeStyles } from '@mui/styles';
import {
  Grid2 as Grid,
  FormControl,
  MenuItem,
  Select,
  FilledInput,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getLogosAndWatermarks,
  uploadLogoImage,
  uploadEmailLogoImage,
  uploadWatermarkImage,
} from '../ducks/vendorDataSlice';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { sitesFieldsMap, SiteDetailDataFields, WatermarkPositions } from '../constants/VendorData';
import { config } from '../config/config';
import { I18nKeys } from '../constants/I18nKeys';
import { ImageSelect } from './ImageSelect';
import { disabledSiteDetailField } from '../utils/vendorDataUtils';
import { SiteInputLabel } from './SiteInputLabel';
import { useAppDispatch, useAppSelector } from '../hooks';

const useStyles = makeStyles({
  fieldContainer: {
    padding: '8px',
  },
  inputLabel: {
    marginTop: '16px',
  },
  filledSelect: {
    fontSize: 16,
    height: '55px',
  },
  imageSelect: {
    fontSize: 16,
    height: '128px',
  },
  image: {
    alignSelf: 'center',
    maxWidth: '100%',
    maxHeight: '80px',
  },
  fileInput: {
    display: 'none',
  },
});

interface Props {
  data?: SiteDetail;
  clientId?: string;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesLogosAndWatermarks: React.FC<Props> = ({
  clientId,
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { vendorLogos, vendorEmailLogos, vendorWatermarks } = useAppSelector((state) => state.vendorData);

  const uploadImage = (field: SiteDetailDataFields, image: Blob): void => {
    switch (field) {
      case SiteDetailDataFields.LogoUrl:
        dispatch(uploadLogoImage({ image }));
        break;
      case SiteDetailDataFields.EmailLogoUrl:
        dispatch(uploadEmailLogoImage({ image }));
        break;
      case SiteDetailDataFields.Watermark:
        dispatch(uploadWatermarkImage({ image, data }));
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    // When client has been updated in state get new images
    if (clientId) {
      dispatch(getLogosAndWatermarks());
    }
  }, [dispatch, clientId]);

  const [imageType, setImageType] = React.useState(SiteDetailDataFields.LogoUrl);

  const imageSelectChange = (prop: string, value: string, selectedImageType: SiteDetailDataFields): void => {
    if (value === 'new') {
      setImageType(selectedImageType);
      const fileUploadInput = document.getElementById('file-upload');
      if (fileUploadInput) {
        fileUploadInput.click();
      }
    } else {
      vendorPropChange(prop, value);
    }
  };
  const uploadNewImage = (event: any): void => {
    uploadImage(imageType, event.target.files[0]);
    // Clear out the input value to allow change event on same file name
    event.target.value = null; // eslint-disable-line no-param-reassign
  };
  return (
    <Grid container>
      {config.environment.STAGE !== 'production' && (
        <Grid size={{ xs: 12 }} className={classes.fieldContainer}>
          <Alert severity="warning">{t(I18nKeys.SitesLogosProductionWarning)}</Alert>
        </Grid>
      )}
      <input
        className={classes.fileInput}
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={(e): void => uploadNewImage(e)}
      />
      <ImageSelect
        data={data}
        field={SiteDetailDataFields.LogoUrl}
        images={vendorLogos}
        imageSelectChange={imageSelectChange}
      />
      <ImageSelect
        data={data}
        field={SiteDetailDataFields.EmailLogoUrl}
        images={vendorEmailLogos}
        imageSelectChange={imageSelectChange}
      />
      {data.watermarkPosition !== 'center-text' && data.watermarkPosition !== 'center-text-not-rotated' ? (
        <ImageSelect
          data={data}
          field={SiteDetailDataFields.Watermark}
          images={vendorWatermarks}
          imageSelectChange={imageSelectChange}
        />
      ) : (
        <Grid size={{ xs: 6 }} className={classes.fieldContainer}>
          <FormControl fullWidth>
            <SiteInputLabel
              shrink
              className={classes.inputLabel}
              i18nKey={(sitesFieldsMap[SiteDetailDataFields.WatermarkText] as SiteDetailField).i18nKey}
            />
            <FilledInput
              disabled={disabledSiteDetailField(
                SiteDetailDataFields.WatermarkText,
                data,
                isIdeaRoomUser,
                selectedGroupId,
              )}
              name={SiteDetailDataFields.WatermarkText}
              value={data.watermarkText || ''}
              onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
            />
          </FormControl>
        </Grid>
      )}

      <Grid size={{ xs: 6, sm: 6 }} className={classes.fieldContainer}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabledSiteDetailField(
                  SiteDetailDataFields.ShowLogoOnMobile,
                  data,
                  isIdeaRoomUser,
                  selectedGroupId,
                )}
                name={SiteDetailDataFields.ShowLogoOnMobile}
                checked={!!data.showLogoOnMobile}
                onChange={(e: any): void => vendorPropChange(e.target.name, e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={t((sitesFieldsMap[SiteDetailDataFields.ShowLogoOnMobile] as SiteDetailField).i18nKey)}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabledSiteDetailField(
                  SiteDetailDataFields.ShowLogoWatermarkUnderEstimate,
                  data,
                  isIdeaRoomUser,
                  selectedGroupId,
                )}
                name={SiteDetailDataFields.ShowLogoWatermarkUnderEstimate}
                checked={!!data.showLogoWatermarkUnderEstimate}
                onChange={(e: any): void => vendorPropChange(e.target.name, e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={t((sitesFieldsMap[SiteDetailDataFields.ShowLogoWatermarkUnderEstimate] as SiteDetailField).i18nKey)}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }} className={classes.fieldContainer}>
        <FormControl fullWidth>
          <SiteInputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="watermark-position-select"
            i18nKey={(sitesFieldsMap[SiteDetailDataFields.WatermarkPosition] as SiteDetailField).i18nKey}
          />
          <Select
            disabled={disabledSiteDetailField(
              SiteDetailDataFields.WatermarkPosition,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
            value={data.watermarkPosition || WatermarkPositions.None}
            onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
            inputProps={{
              displayEmpty: false,
              name: SiteDetailDataFields.WatermarkPosition,
              id: 'watermark-position-select',
            }}
            className={classes.filledSelect}
            variant="filled"
          >
            {Object.values(WatermarkPositions).map((position) => (
              <MenuItem key={position} value={position}>
                {t(`sites-logos-configurator-watermark-style-${position}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
