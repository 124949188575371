import { Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientPublishedVersions } from '../constants/ClientData';
import { useGetClientName } from '../hooks/useGetClientName';

const useStyles = makeStyles({
  list: {
    paddingLeft: '25px',
    margin: '0',
  },
});

export const ClientDataSuppliersVersionBumpList: React.FC<{ clientPublishedVersions: ClientPublishedVersions }> = ({
  clientPublishedVersions,
}) => {
  const classes = useStyles();
  const getClientName = useGetClientName();

  return (
    <ul className={classes.list}>
      {clientPublishedVersions?.suppliers
        ?.filter((supplier) => supplier.published?.structureVersion !== supplier.latestVersion)
        .map((supplier) => {
          const isVendorSupplierPublished = !!supplier?.published;
          return (
            <li key={supplier.key}>
              <Typography>
                <Trans
                  i18nKey={
                    (isVendorSupplierPublished
                      ? I18nKeys.ClientDataPublishUpdatesSupplierVersionBump
                      : I18nKeys.ClientDataPublishUpdatesSupplierNotPublishedVersionBump) as string
                  }
                  values={{
                    supplier: getClientName(supplier.key),
                    currentVersion: supplier?.latestVersion || 0,
                    publishedVersion: supplier?.published?.structureVersion || 0,
                  }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </li>
          );
        })}
      {clientPublishedVersions?.system?.latestVersion !== clientPublishedVersions?.system?.publishedVersion && (
        <li>
          <Typography>
            <Trans
              i18nKey={I18nKeys.ClientDataPublishUpdatesReferenceVersionBump as string}
              values={{
                currentVersion: clientPublishedVersions?.system?.latestVersion || 0,
                publishedVersion: clientPublishedVersions?.system?.publishedVersion || 0,
              }}
            />
          </Typography>
        </li>
      )}
    </ul>
  );
};
