import { useClientDataRepo } from './useClientDataRepo';
import { ClientEmailDomainResponse, useGetClientEmailDomainQuery } from '../services/domainApi';
import { DomainStatus } from '../types/DomainVerification';
import { OnlyExistingKeys } from '../types/Repo';
import { getDomainFromEmail } from '../utils/domainUtils';
import { useAppSelector } from '../hooks';
import { isNotSiteDetailBranch } from '../utils/vendorDataUtils';

export const EMPTY_CLIENT_EMAIL_DOMAIN_REFERENCE: Readonly<ClientEmailDomainResponse> = {
  status: DomainStatus.DoesNotExist,
};

type UseDomainRepoReturn = OnlyExistingKeys<{
  isLoadingClientEmailDomain: boolean;
  isFetchingClientEmailDomain: boolean;
  clientEmailDomain: ClientEmailDomainResponse;
  refetchClientEmailDomain: () => Promise<ClientEmailDomainResponse>;
}>;

export const useDomainRepo = (): UseDomainRepoReturn => {
  const { selectedTableData } = useClientDataRepo({
    useSelectedTableData: true,
    skipClientDataFetch: isNotSiteDetailBranch,
  });
  const { emailAddress: contactEmail, quoteEmailFromAddress, quoteEmailFromAddressSame } = selectedTableData[0] || {};

  const { clientId } = useAppSelector((state) => state?.clientData);
  const quoteEmailAddress = quoteEmailFromAddressSame ? contactEmail : quoteEmailFromAddress;
  const quoteEmailFromAddressDomain = getDomainFromEmail(quoteEmailAddress as string);
  const emailAddress =
    quoteEmailFromAddressDomain && !quoteEmailFromAddressDomain.error ? quoteEmailAddress : undefined;
  const {
    currentData: clientEmailDomain = EMPTY_CLIENT_EMAIL_DOMAIN_REFERENCE,
    isLoading: isLoadingClientEmailDomain,
    isFetching: isFetchingClientEmailDomain,
    refetch: refetchClientEmailDomain,
  } = useGetClientEmailDomainQuery(
    { emailAddress: emailAddress as string | undefined, clientId },
    {
      skip: !emailAddress,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
    },
  );

  return {
    clientEmailDomain,
    isLoadingClientEmailDomain,
    isFetchingClientEmailDomain,
    refetchClientEmailDomain,
  };
};
