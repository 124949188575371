import { styled } from '@mui/material/styles';
import { disabled } from '../theme';

type CellContentProps = {
  disabled: boolean;
};

export const CellContent = styled('div')((props: CellContentProps) => ({
  opacity: props.disabled ? disabled : undefined,
}));
