import React from 'react';

import { makeStyles } from '@mui/styles';
import { PanelResizeHandle } from 'react-resizable-panels';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    '&[data-resize-handle-active]': {
      '& > $dragElement': {
        backgroundColor: '#00000040',
        '& > $icon': {
          visibility: 'visible',
        },
      },
    },
  },
  dragElement: {
    transition: 'background-color 0.1s linear',
    position: 'absolute',
    top: '-5px',
    textAlign: 'center',
    width: '100%',
    height: '10px',
    '&:hover': {
      backgroundColor: '#00000014',
      '& > $icon': {
        visibility: 'visible',
      },
    },
  },
  icon: {
    visibility: 'hidden',
    position: 'relative',
    top: '-7px',
    height: '24px',
    width: '24px',
  },
});

export const GridResizeHandle: React.FC = () => {
  const classes = useStyles();

  return (
    <PanelResizeHandle className={classes.root}>
      <div className={classes.dragElement}>
        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>arrow-split-horizontal</title>
          <path d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z" />
        </svg>
      </div>
    </PanelResizeHandle>
  );
};
