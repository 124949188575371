/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { WrappedFieldProps } from 'redux-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InputField } from './InputField';

type Props = WrappedFieldProps & TextFieldProps & { hideHelperText: boolean; errorWithoutTouch: boolean };

interface CustomProps {
  onChange: (event: {
    target: { value: string; name: string };
    stopPropagation: () => void; // Required for Redux Form
    preventDefault: () => void; // Required for Redux Form
  }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange } = props;

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
          stopPropagation: () => false,
          preventDefault: () => false,
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

export const PriceField: React.FC<Props> = (props: Props) => (
  <InputField
    slotProps={{
      input: {
        inputComponent: NumericFormatCustom as any,
      },
    }}
    {...props}
  />
);
