import { OrderStatus, OrderStatusChip } from '../types/OrderStatus';
import {
  LightText,
  DarkText,
  Red as RedColor,
  RedMuted as RedMutedColor,
  Black as BlackColor,
  BlackMuted as BlackMutedColor,
  Blue as BlueColor,
  BlueMuted as BlueMutedColor,
  Green as GreenColor,
  GreenMuted as GreenMutedColor,
  Orange as OrangeColor,
  OrangeMuted as OrangeMutedColor,
  Gray as GrayColor,
  GrayMuted as GrayMutedColor,
} from './ChipColors';

export enum OrderStatusId {
  NewLead = 'NEW_LEAD',
  Qualified = 'QUALIFIED',
  ClosedWon = 'CLOSED_WON',
  ClosedLost = 'CLOSED_LOST',
  NotALead = 'NOT_A_LEAD',
  Deleted = 'DELETED',
}

export const Red: OrderStatusChip = { fontColor: LightText, backgroundColor: RedColor };
export const RedMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: RedMutedColor };
export const Black: OrderStatusChip = { fontColor: LightText, backgroundColor: BlackColor };
export const BlackMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: BlackMutedColor };
export const Blue: OrderStatusChip = { fontColor: LightText, backgroundColor: BlueColor };
export const BlueMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: BlueMutedColor };
export const Green: OrderStatusChip = { fontColor: LightText, backgroundColor: GreenColor };
export const GreenMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: GreenMutedColor };
export const Orange: OrderStatusChip = { fontColor: DarkText, backgroundColor: OrangeColor };
export const OrangeMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: OrangeMutedColor };
export const Gray: OrderStatusChip = { fontColor: LightText, backgroundColor: GrayColor };
export const GrayMuted: OrderStatusChip = { fontColor: DarkText, backgroundColor: GrayMutedColor };

export const OrderStatusChips = [
  Red,
  Black,
  Blue,
  Green,
  Orange,
  Gray,
  RedMuted,
  BlackMuted,
  BlueMuted,
  GreenMuted,
  OrangeMuted,
  GrayMuted,
];

export const defaultStatusFilters: { key: string; name: string; color: string }[] = [
  { key: OrderStatusId.NewLead, name: 'New Lead', color: GrayMuted.backgroundColor },
  { key: OrderStatusId.Qualified, name: 'Qualified', color: BlueMuted.backgroundColor },
  { key: OrderStatusId.ClosedWon, name: 'Closed Won', color: GreenMuted.backgroundColor },
  { key: OrderStatusId.ClosedLost, name: 'Closed Lost', color: RedMuted.backgroundColor },
  { key: OrderStatusId.NotALead, name: 'Not a Lead', color: Red.backgroundColor },
];

export const deletedLeadStatus: OrderStatus = {
  id: OrderStatusId.Deleted,
  name: 'Deleted',
  chip: BlackMuted,
};
