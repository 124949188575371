import React from 'react';
import { Column, ICellRendererParams } from 'ag-grid-community';
import { IconButton } from '@mui/material';
import { PlaylistAdd } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { addRows, generateRowFromObject, getOrderByIndex, getRowOrders } from '../utils/clientDataUtils';
import { AppState } from '../types/AppState';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { goToCellRange } from '../ducks/clientDataSlice';

export const ClientDataAddToTable: React.FC<ICellRendererParams> = ({
  data,
  context = {},
  api,
  columnApi,
}: ICellRendererParams) => {
  const dispatch = useAppDispatch();
  const { clientId, clientDataType, selectedTable } = useAppSelector((state: AppState) => state?.clientData);
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const clientDataId = mapClientAndDataTypeAndTableToUndoStackId(selectedViewerId, clientDataType, selectedTable);

  const rowId = data[ClientDataFixedColumns.RowId];
  const [col] = columnApi.getAllDisplayedVirtualColumns();
  const colId = col.getColId();

  const { rootGridApi, rootGridColApi, selectedTable: table } = context;

  return (
    <IconButton
      disabled={!rowId || !colId || !rootGridApi || !rootGridColApi || !table}
      style={{ padding: 0, height: '100%', width: '100%', display: 'flex' }}
      onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();

        const rowModel = rootGridApi.getModel();
        const addIndex = rowModel.getRowCount();

        const [prevOrder, nextOrder] = [addIndex - 1, addIndex].map((i) => getOrderByIndex(i, api));
        const [order] = getRowOrders(prevOrder, nextOrder, 1, rootGridApi);

        const renderedCols = rootGridApi.getColumns();
        const columns = renderedCols.map((column: Column) => column.getColId());

        const row = generateRowFromObject(clientId, columns, order, data);
        const newRowId = row[ClientDataFixedColumns.RowId];
        addRows(clientDataId, [row], dispatch);
        dispatch(
          goToCellRange({
            table,
            location: {
              startRowId: newRowId,
              endRowId: newRowId,
              columns: [ClientDataFixedColumns.Index],
            },
            rootGridApi,
            rootGridColApi,
            onComplete: () => {
              rootGridApi.deselectAll();
              rootGridApi.getRowNode(newRowId)?.setSelected(true);
            },
          }),
        );
      }}
    >
      <PlaylistAdd fontSize="small" style={{ flex: 'auto' }} />
    </IconButton>
  );
};
