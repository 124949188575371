export type Language = {
  key: string;
  label?: string;
  hidden?: boolean;
  order?: number;
};

export const languages: Language[] = [
  {
    label: 'English',
    key: 'en',
  },
  {
    label: 'Español',
    key: 'es',
  },
  {
    label: 'Français',
    key: 'fr',
  },
  {
    label: 'Deutsch',
    key: 'de',
  },
  {
    label: 'Norwegian',
    key: 'no',
  },
  {
    label: 'Nederlands',
    key: 'nl',
  },
];

export const cimode: Language = {
  key: 'cimode',
};
