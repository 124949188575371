import { Fab, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { SceneEnvironment as SceneEnvironmentType } from '../types/SceneEnvironment';
import { fetchSceneEnvironment, setSceneEnvironmentDialog } from '../ducks/settings';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { SceneEnvironmentAppBar } from './SceneEnvironmentAppBar';
import { SceneEnvironmentTable } from './SceneEnvironmentTable';
import { setSelectedClientId as setSelectedClientIdFunc } from '../ducks/viewerSlice';

const useStyles = makeStyles<Theme>((theme) => ({
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
}));

export const SceneEnvironment: React.FC = () => {
  const classes = useStyles();
  const isIdearoomGroup = useSelector<AppState, boolean>((state) =>
    isIdeaRoomGroup((state.currentUser.group || unknownGroup).groupId),
  );
  const sceneEnvironment = useSelector<AppState, SceneEnvironmentType[]>((state) => state.settings.sceneEnvironment);
  const loading = useSelector<AppState, boolean>((state) => state.settings.loadingSceneEnvironment);
  const searchTerm = useSelector<AppState, string>((state) => state.search.searchTerm);
  const selectedClientId = useSelector<AppState, string>((state) => state.viewer.selectedClientId || '');

  const dispatch = useDispatch();
  const getSceneEnvironments = (clientId: string): AnyAction => dispatch(fetchSceneEnvironment(clientId));
  const openSceneEnvironmentDialog = (clientId: string): void => {
    dispatch(setSceneEnvironmentDialog(clientId, undefined));
    dispatch(openDialog({ dialog: Dialogs.SceneEnvironment }));
  };
  const setSelectedClientId = (clientId: string): AnyAction => dispatch(setSelectedClientIdFunc(clientId));

  useEffect(() => {
    if (selectedClientId) {
      getSceneEnvironments(selectedClientId);
    }
  }, [selectedClientId]);

  const tests = [
    (scene: SceneEnvironmentType): boolean => stringToLowerIncludes(scene.key, searchTerm),
    (scene: SceneEnvironmentType): boolean => stringToLowerIncludes(scene.label, searchTerm),
  ];
  const filteredSceneEnvironment =
    searchTerm.length === 0 ? sceneEnvironment : sceneEnvironment.filter((scene) => tests.some((test) => test(scene)));

  return (
    <>
      {isIdearoomGroup && <SceneEnvironmentAppBar onTabChange={setSelectedClientId} />}
      <SceneEnvironmentTable
        loading={loading}
        clientId={selectedClientId}
        sceneEnvironmentList={filteredSceneEnvironment}
      />
      <Fab
        color="primary"
        className={classes.fab}
        variant="extended"
        onClick={(): void => openSceneEnvironmentDialog(selectedClientId)}
        aria-label="add scene environment"
      >
        <AddIcon />
        <Typography className={classes.fabText}>Add</Typography>
      </Fab>
    </>
  );
};
