import React from 'react';
import { AnyAction } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { useAppDispatch } from '../hooks';
import { PricingSheetNameFormFields } from '../constants/FormFields';

export interface FormData {
  [PricingSheetNameFormFields.SheetTitle]: string;
}

interface CustomProps {
  placeholder: string;
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const PricingSheetNameFormComponent: React.FC<FormProps> = ({ handleSubmit, placeholder }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.PricingSheetName}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoFocus
        autoComplete="off"
        style={{ width: '100%', minWidth: '300px' }}
        component={InputField}
        label={t(I18nKeys.PricingBaseSheetTitleField)}
        placeholder={placeholder}
        name={PricingSheetNameFormFields.SheetTitle}
        variant="filled"
      />
    </Form>
  );
};

export const PricingSheetNameForm = reduxForm<FormData, CustomProps>({ form: Forms.PricingSheetName })(
  PricingSheetNameFormComponent,
);
