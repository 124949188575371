export const config = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_API_GATEWAY_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
    API_PUBLIC_URL: process.env.REACT_APP_API_GATEWAY_API_PUBLIC_URL,
  },
  environment: {
    STAGE: process.env.REACT_APP_STAGE,
  },
  googleAnalytics: {
    GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
  },
  intercom: {
    APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  },
  assets: {
    REFERENCE_ASSETS_ENDPOINT: process.env.REACT_APP_REFERENCE_ASSETS_ENDPOINT,
    VENDOR_ASSETS_ENDPOINT: process.env.REACT_APP_VENDOR_ASSETS_ENDPOINT,
  },
};
