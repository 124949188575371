import React from 'react';
import { ListItemText } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { unknownUser } from '../types/User';
import { CheckMenuItem } from './CheckMenuItem';
import { getBranchAuthorsSummaryLabel } from '../utils/clientDataUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { BRANCH_LABELS } from '../constants/ClientData';

interface Props {
  branch: ClientDataBranch;
  allowCreateBranch?: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>) => void;
}

export const BranchMenuItem: React.FC<Props> = ({ branch, onChange, allowCreateBranch = false }: Props) => {
  const { t } = useTranslation();
  const { user = unknownUser } = useAppSelector((state: AppState) => state?.currentUser);
  const { clientDataBranch } = useAppSelector((state: AppState) => state?.clientData);
  const { activeBranches } = useClientDataRepo({ useBranches: true });

  const canCreateBranch =
    allowCreateBranch && !activeBranches.some((activeBranch) => activeBranch.branchType === branch);

  const dispatch = useAppDispatch();

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>): void => {
    onChange(event);
    if (!canCreateBranch) {
      dispatch(setClientDataBranch(branch));
    } else {
      dispatch(openDialog({ dialog: Dialogs.ClientDataHotfixBranch }));
    }
  };

  return (
    <CheckMenuItem
      disabled={!allowCreateBranch && !activeBranches.some((activeBranch) => activeBranch.branchType === branch)}
      onClick={handleMenuItemClick}
      checkIcon={<Check />}
      checked={clientDataBranch === branch}
    >
      <ListItemText>
        {canCreateBranch
          ? t(I18nKeys.ClientDataBranchMenuCreateBranch, { branch: t(BRANCH_LABELS[branch]) })
          : `${t(BRANCH_LABELS[branch])}${
              branch !== ClientDataBranch.Main
                ? ` ${getBranchAuthorsSummaryLabel(t, activeBranches, branch, user)}`
                : ''
            }`}
      </ListItemText>
    </CheckMenuItem>
  );
};
