import { Configurator } from '../types/Configurator';
import { getClientIdFromClientSupplier, getSupplierKeyFromClientSupplier } from './clientIdUtils';

/**
 * Determines if a configurator has at least one supplier which is
 * not the vendor itself
 *
 * @param config
 * @returns
 */
export const hasSuppliers = (config?: Configurator): boolean => {
  if (!config || !config?.suppliers) return false;

  // Ignore the vendor itself
  const { vendor, suppliers = [] } = config;
  return suppliers.filter((supplier) => supplier.supplierKey !== vendor).length > 0;
};

/**
 * Determines if the current viewer ID, which may be a clientSupplier, is a dealer
 * and should have a dealer view (i.e. ability to inherit from a supplier)
 *
 * @param viewerId
 * @param configs
 * @returns
 */
export const viewingDealer = (viewerId?: string, configs: Configurator[] = []): boolean => {
  const config = configs.find((c) => c.clientId === getClientIdFromClientSupplier(viewerId)) || configs[0];
  const supplierKey = getSupplierKeyFromClientSupplier(viewerId);

  if (!supplierKey || !config) return false;

  const { vendor } = config;
  return supplierKey !== vendor && hasSuppliers(config);
};
