import { ColDef } from 'ag-grid-community';
import { suppressPricingGridKeyboardEvent } from '../utils/keyboardShortcutSuppressionUtils';

export const PricingSheetTableAgGrid = 'PricingSheetBase';

export const rowSpanHeaderWidth = 29;
export const lengthColumnWidth = 54;
export const widthLengthColumnWidth = 69;
export const priceColumnWidth = 80;
export const decimalPriceColumnWidth = 92;

export const defaultColumn: ColDef = {
  resizable: false,
  sortable: false, // do not allow column sorting
  suppressMenu: true, // suppress the column hamburger menu
  suppressMovable: true, // do not allow column dragging
  cellClassRules: {
    'ag-grid-custom-black-out-cell-color': (params) => {
      const { data, colDef } = params;
      const columnId = colDef.colId || params.column.getColId();
      if (!params.value && !data[columnId]) {
        return true;
      }
      return false;
    },
  },
  editable: true,

  suppressKeyboardEvent: suppressPricingGridKeyboardEvent,
};
