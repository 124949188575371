/* eslint-disable @typescript-eslint/no-explicit-any */
import { notAssigned } from '../components/OrderOwnerSelector';
import { newLead, orderStatusOptions } from '../components/OrderStatusSelector';
import { I18nKeys } from '../constants/I18nKeys';
import { OrderStatusId } from '../constants/OrderStatus';
import { i18n } from '../i18n';
import { CustomFilter } from '../types/CustomFilter';
import { unknownDealer } from '../types/Dealer';
import { GroupMember } from '../types/GroupMember';
import { SubmitStatus } from '../types/Order';
import { OrderDealer } from '../types/OrderDealer';
import { OrderOwner, unknownOwner } from '../types/OrderOwner';
import { OrderStatus } from '../types/OrderStatus';
import { getVendorFromClientId } from './clientIdUtils';

export const mapSubmitStatusToLabel = (submitStatus: string): string => {
  const { t } = i18n;
  switch (submitStatus) {
    case SubmitStatus.Draft:
      return t(I18nKeys.SubmitStatusDraft);
    case SubmitStatus.Save:
      return t(I18nKeys.SubmitStatusSave);
    case SubmitStatus.SaveToList:
      return t(I18nKeys.SubmitStatusSaveToList);
    case SubmitStatus.Quote:
      return t(I18nKeys.SubmitStatusQuote);
    case SubmitStatus.Deposit:
    case SubmitStatus.DepositPaying:
    case SubmitStatus.DepositToken:
    case SubmitStatus.DepositCharged:
    case SubmitStatus.DepositPayLater:
    case SubmitStatus.ExternalCheckout:
      return t(I18nKeys.SubmitStatusCheckout);
    case SubmitStatus.Invoice:
      return t(I18nKeys.SubmitStatusInvoice);
    case SubmitStatus.Popup:
      return t(I18nKeys.SubmitStatusPopup);
    default:
      return t(I18nKeys.SubmitStatusUnknown);
  }
};

export const filterOrdersByDealer = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) =>
        selectedValue.key ===
        (order.orderDealerKey || order.dealerKey || order.orderDealerName || order.dealerName || unknownDealer.name),
    ),
  );

export const filterOrdersByOwner = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) =>
        selectedValue.key === (order.ownerEmail || order.ownerName || order.salesRepEmail || unknownOwner.name),
    ),
  );

export const filterOrdersBySite = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some((selectedValue) => selectedValue.key === getVendorFromClientId(order.clientId)),
  );

export const filterOrdersByStatus = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) => selectedValue.key === (order.orderStatusId || OrderStatusId.NewLead),
    ),
  );

export const filterOrdersBySubmitStatus = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) => selectedValue.key === mapSubmitStatusToLabel(order.submitStatus),
    ),
  );

export const getOrderDealerFromOrder = (order: any): OrderDealer => {
  if (order.orderDealerKey) {
    if (order.orderDealerName) {
      return { name: order.orderDealerName, key: order.orderDealerKey };
    }
    return { name: order.orderDealerKey, key: order.orderDealerKey };
  }
  if (order.dealerKey) {
    if (order.dealerName) {
      return { name: order.dealerName, key: order.dealerKey };
    }
    return { name: order.dealerKey, key: order.dealerKey };
  }
  return notAssigned;
};

export const getOrderOwnerFromOrder = (order: any, users?: GroupMember[]): OrderOwner => {
  if (order.ownerEmail) {
    if (order.ownerName) {
      return { name: order.ownerName, email: order.ownerEmail };
    }
    const user = users?.find(({ email }) => email.toLowerCase() === order.ownerEmail.toLowerCase());
    if (user) {
      return { name: user.name, email: user.email.toLowerCase() };
    }
    return { name: order.ownerEmail, email: order.ownerEmail.toLowerCase() };
  }
  if (order.salesRepEmail) {
    if (order.salesRepName) {
      return { name: order.salesRepName, email: order.salesRepEmail };
    }
    const user = users?.find(({ email }) => email.toLowerCase() === order.salesRepEmail.toLowerCase());
    if (user) {
      return { name: user.name, email: user.email.toLowerCase() };
    }
    return { name: order.salesRepEmail, email: order.salesRepEmail.toLowerCase() };
  }
  return notAssigned;
};

export const getOrderStatusFromOrder = (order: any): OrderStatus => {
  if (order.orderStatusId && order.orderStatusFontColor && order.orderStatusBackgroundColor) {
    const { i18nKey } = orderStatusOptions.find(({ id }) => id === order.orderStatusId) || {};
    return {
      id: order.orderStatusId,
      name: order.orderStatusName,
      i18nKey,
      chip: { fontColor: order.orderStatusFontColor, backgroundColor: order.orderStatusBackgroundColor },
    };
  }
  return newLead;
};

export const getOrderStatusLabelFromOrder = (order: any): string => {
  const status = getOrderStatusFromOrder(order);
  return status.i18nKey ? i18n.t(status.i18nKey) : status.name || 'New Lead';
};
