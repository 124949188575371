import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { config } from './config/config';
import { S3Buckets } from './constants/S3';

export const i18n = i18next
  .createInstance()
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18NextHttpBackend) // load translation using http
  .use(LngDetector); // configure language detector for reactjs

i18n.init({
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'navigator'],
  },
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
  react: {
    useSuspense: false, // https://github.com/i18next/react-i18next/issues/766
  },
  load: 'languageOnly',
  backend: {
    loadPath: `${config.apiGateway.URL}/i18n/${S3Buckets.IdeaRoomI18n}/{{lng}}/{{ns}}.json`,
  },
});
