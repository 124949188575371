export const LightText = 'rgba(255,255,255,0.87)';
export const DarkText = 'rgba(0,0,0,0.87)';

export const Red = '#A72827';
export const RedMuted = '#E7C5C6';
export const Black = '#212121';
export const BlackMuted = '#E5E5E5';
export const Blue = '#3E4168';
export const BlueMuted = '#D3D3DC';
export const Green = '#68844D';
export const GreenMuted = '#DBE3D5';
export const Orange = '#E5A761';
export const OrangeMuted = '#F9EAD8';
export const Gray = '#575E64';
export const GrayMuted = '#E0E0E0';

export const chipColors = {
  Red,
  RedMuted,
  Black,
  BlackMuted,
  Blue,
  BlueMuted,
  Green,
  GreenMuted,
  Orange,
  OrangeMuted,
  Gray,
  GrayMuted,
};
