import { Configurator } from '@idearoom/sdk';
import { OptionType } from '@idearoom/types';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Trans, useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CARPORTVIEW,
  mapClientAndDataTypeAndTableToUndoStackId,
  mapClientIdToConfiguratorAndVendor,
} from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataType } from '../constants/ClientDataType';
import { setOptionIconsToGenerate } from '../ducks/clientDataSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { TableData } from '../types/DataGrid';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { config } from '../config/config';
import { unknownGroup } from '../constants/Group';
import { IFCIconImagesSize, IFSIconImagesSize, OptionIconImagesSize } from '../constants/ClientData';
import { clientDataApi } from '../services/clientDataApi';
import { s3Api } from '../services/s3Api';
import { S3Buckets } from '../constants/S3';
import { updateValues } from '../utils/clientDataUtils';

/**
 * Turn it on to see the configurator working, and to download image instead of uploading
 */
const DEBUG_MODE = false;

const DEFAULT_ICON_SCALE = 4;
const DEFAULT_ICON_ZOOM = 0;
const FRONT_CAMERA_ROTATION = { x: 0, y: 0, z: 0 };
const DEFAULT_ICON_CARPORTS_ZOOM = 0.1;
const DEFAULT_ICON_SHEDS_ZOOM = 0.2;

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  statusIcon: {
    width: '30px',
    height: '30px',
    marginRight: '15px',
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
  successColor: {
    color: theme.palette.success.dark,
  },
  dialogActions: { padding: '0px 8px 8px 8px' },
}));

const getOptionTypeFromTable = (table: string): OptionType | undefined => {
  switch (table) {
    case 'cupola':
      return OptionType.Cupola;
    case 'door':
    case 'dormerComponent':
    case 'shelf':
    case 'vent':
    case 'window':
    case 'workbench':
      return OptionType.Component;
    case 'flooring':
      return OptionType.Flooring;
    case 'installationSurface':
      return OptionType.InstallationSurface;
    case 'roofing':
      return OptionType.Roofing;
    case 'siding':
      return OptionType.Siding;
    case 'style':
    case 'styleVariation':
    case 'styleAttributes':
      return OptionType.Style;
    default:
      return undefined;
  }
};

export const ClientDataGenerateOptionIconDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    clientData: { clientId, clientDataBranch = ClientDataBranch.Main, clientDataType, optionIconsToGenerate },
    currentUser: { group: { groupId } = unknownGroup },
  } = useAppSelector((state) => state);
  const { cellMetadata, vendorData: { locale = '', productionURL = '' } = {} } = useClientDataRepo({
    useCellMetadata: true,
    useVendorData: true,
  });
  const dispatch = useAppDispatch();
  const [configuratorSdk, setConfiguratorSdk] = useState<Configurator>();
  const [configuratorComponentRoot, setConfiguratorComponentRoot] = useState<Root>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [generatingIndex, setGeneratingIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);

  const embeddedConfiguratorContainer = 'embedded-configurator-container';
  const embeddedConfigurator = 'embedded-configurator';
  const embeddedConfiguratorDiv = <div id={embeddedConfigurator} style={{ display: 'flex', flex: '1' }} />;
  const ensureEmbeddedConfiguratorDivExists = (): void => {
    if (configuratorSdk) {
      configuratorSdk.destroy();
      setConfiguratorSdk(undefined);
    }
    if (configuratorComponentRoot) {
      configuratorComponentRoot.unmount();
    }
    const configuratorContainerElement = document.getElementById(embeddedConfiguratorContainer);
    if (configuratorContainerElement) {
      const root = createRoot(configuratorContainerElement);
      root.render(embeddedConfiguratorDiv);
      setConfiguratorComponentRoot(root);
    }
  };

  const handleClose = (): void => {
    ensureEmbeddedConfiguratorDivExists();
    dispatch(setOptionIconsToGenerate(undefined));
    setDialogOpen(false);
    setIsSuccess(false);
    setIsUploading(false);
    setIsError(undefined);
    setGeneratingIndex(0);
  };

  useEffect(() => {
    if (optionIconsToGenerate) {
      setDialogOpen(true);

      const clientBranch = clientDataType === ClientDataType.Vendor ? clientDataBranch : ClientDataBranch.Main;
      const structureBranch = clientDataType === ClientDataType.Supplier ? clientDataBranch : ClientDataBranch.Main;
      const systemBranch = ClientDataBranch.Main;
      const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);

      setIsLoading('Loading embedded configurator');

      const generateIcons = async () => {
        let configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
        if (config.environment.STAGE !== 'localhost') {
          // Force use HTTPS as our SDK doesn't allow non-secure URLs inside secure sites
          configuratorUrl = configuratorUrl.replace('http://', 'https://');
        }
        configuratorUrl =
          `${configuratorUrl}` +
          `${configuratorUrl.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2` +
          `&clientSettings=${clientBranch}` +
          `&structureSettings=${structureBranch}` +
          `&systemSettings=${systemBranch}` +
          `&fetchFromServer=true`;
        const sdk = new Configurator(configuratorUrl, embeddedConfigurator);
        setConfiguratorSdk(sdk);
        try {
          const optionType = getOptionTypeFromTable(optionIconsToGenerate.table);
          if (!optionType) {
            throw new Error(`Missing option type information for table ${optionIconsToGenerate.table}.`);
          }

          const iconScale = DEFAULT_ICON_SCALE;
          let iconZoom;
          let iconWidth;
          let iconHeight;
          if (optionType === OptionType.Style) {
            iconZoom = DEFAULT_ICON_SHEDS_ZOOM;
            iconWidth = IFSIconImagesSize.Width;
            iconHeight = IFSIconImagesSize.Height;
            if (configurator === CARPORTVIEW) {
              iconZoom = DEFAULT_ICON_CARPORTS_ZOOM;
              iconWidth = IFCIconImagesSize.Width;
              iconHeight = IFCIconImagesSize.Height;
            }
          } else {
            iconZoom = DEFAULT_ICON_ZOOM;
            iconWidth = OptionIconImagesSize.Width;
            iconHeight = OptionIconImagesSize.Height;
            if (optionType === OptionType.Component) iconZoom = DEFAULT_ICON_CARPORTS_ZOOM;
          }

          await new Promise<void>((resolve, reject) => {
            const errorListener = (error: any) => {
              reject(error);
              sdk.removeOnErrorListener(errorListener);
            };

            sdk.onConnected = async () => {
              resolve();
              sdk.removeOnErrorListener(errorListener);
            };
            sdk.addOnErrorListener(errorListener);
          });

          sdk.closeLocationDialog();

          const uploadList: Promise<{
            row: TableData;
            fileUrl: string;
          }>[] = [];

          let tableData: TableData[] = [];
          if (optionType === OptionType.Style && optionIconsToGenerate.column === 'value1') {
            tableData = await dispatch(
              clientDataApi.endpoints.getClientDataTableData.initiate(
                {
                  dataType: clientDataType,
                  branch: clientDataBranch,
                  clientId,
                  groupId,
                  table: optionIconsToGenerate.table,
                },
                { subscribe: false },
              ),
            ).unwrap();
          }

          let currentlyLoadedSupplier: string | undefined;
          for (let i = 0; i < optionIconsToGenerate.rows.length; i += 1) {
            const row = optionIconsToGenerate.rows[i];

            let optionKey = row.key as string;
            // Workaround for Sheds Style table
            if (optionType === OptionType.Style && optionIconsToGenerate.column === 'value1') {
              const keyRow = tableData.find(
                (rowData) => rowData.style === row.style && rowData.property === 'style key',
              );
              if (keyRow) {
                optionKey = keyRow.value1 as string;
              } else {
                throw new Error(
                  `Couldn't find 'style key' for [${row.style}] style. Make sure to add a key for that style first.`,
                );
              }
            }

            if (!optionKey) {
              throw new Error(`Couldn't find the ${optionIconsToGenerate.table} key value for ${i + 1}.`);
            }

            setIsLoading((row.label as string) || optionKey);
            setGeneratingIndex(i + 1);

            const { supplierKey } = row;
            if (
              clientDataType === ClientDataType.Vendor &&
              supplierKey &&
              typeof supplierKey === 'string' &&
              supplierKey !== currentlyLoadedSupplier
            ) {
              // eslint-disable-next-line no-await-in-loop
              await sdk.changeStructureSupplier(supplierKey);
              currentlyLoadedSupplier = supplierKey;
            }

            let image;
            if (optionType === OptionType.Style) {
              // eslint-disable-next-line no-await-in-loop
              await sdk.changeStyle(optionKey);

              // eslint-disable-next-line no-await-in-loop
              image = await sdk.saveScreenshot(iconWidth * iconScale, iconHeight * iconScale, true, iconZoom, true);
            } else {
              // eslint-disable-next-line no-await-in-loop
              image = await sdk.saveOptionScreenshot(
                optionType,
                optionKey,
                iconWidth * iconScale,
                iconHeight * iconScale,
                true,
                iconZoom,
                true,
                FRONT_CAMERA_ROTATION,
              );
            }

            if (!image.src) {
              throw new Error(`Failed to generate ${optionIconsToGenerate.table} image.`);
            }

            if (DEBUG_MODE) {
              window.open(image.src.replace('image/png', 'image/octet-stream'));
            } else {
              const name = `icon-${configurator}-${clientDataType}-${vendor}-${optionKey}-${Date.now()}.png`;
              uploadList.push(
                dispatch(
                  s3Api.endpoints.uploadFile.initiate({
                    bucket: S3Buckets.Assets,
                    path: `images`,
                    file: {
                      name,
                      content: image.src.replace(/data:image\/[a-z]+;base64/i, ''),
                      contentType: 'image/png',
                    },
                  }),
                )
                  .unwrap()
                  .then(({ fileUrl }) => ({
                    fileUrl,
                    row,
                  })),
              );
            }
          }
          setIsUploading(true);

          if (uploadList.length > 0) {
            const uploaded = await Promise.all(uploadList);
            const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
              clientId,
              clientDataType,
              optionIconsToGenerate.table,
            );

            updateValues(
              clientDataTableId,
              uploaded.map((upload) => ({
                table: optionIconsToGenerate.table,
                data: upload.row,
                column: optionIconsToGenerate.column,
                oldValue: upload.row[optionIconsToGenerate.column],
                newValue: upload.fileUrl,
              })),
              cellMetadata,
              dispatch,
            );
          }

          setIsLoading(undefined);
          setIsUploading(false);
          setIsSuccess(true);
        } catch (error) {
          setIsLoading(undefined);
          setIsError(`${error}`);
        }
      };

      generateIcons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionIconsToGenerate]);

  let headerI18nKey = I18nKeys.ClientDataGenerateOptionIconDialogGenerating;
  if (isUploading) {
    headerI18nKey = I18nKeys.ClientDataGenerateOptionIconDialogUploading;
  } else if (generatingIndex === 0) {
    headerI18nKey = I18nKeys.ClientDataGenerateOptionIconDialogPreparing;
  }

  return (
    <>
      <Dialog open={isDialogOpen} maxWidth="lg">
        <DialogContent style={{ minWidth: 350 }} className={classes.dialogContent}>
          {isLoading && <CircularProgress className={classes.statusIcon} size={30} style={{ padding: '2px' }} />}
          {isSuccess && <CheckCircleIcon className={`${classes.statusIcon} ${classes.successColor}`} />}
          {isError && <CancelIcon className={`${classes.statusIcon} ${classes.errorColor}`} />}
          <div>
            {isLoading && optionIconsToGenerate && (
              <>
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                >
                  <Trans
                    i18nKey={headerI18nKey as string}
                    values={{
                      count: optionIconsToGenerate.rows.length,
                      actual: generatingIndex,
                      total: optionIconsToGenerate.rows.length,
                      option: getOptionTypeFromTable(optionIconsToGenerate.table)?.replace('-', ' ') || 'option',
                    }}
                  />
                </Typography>
                {!isUploading && (
                  <Typography gutterBottom variant="body1">
                    {isLoading}
                  </Typography>
                )}
              </>
            )}
            {isSuccess && optionIconsToGenerate && (
              <Typography
                gutterBottom
                variant="body1"
                style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                <Trans
                  i18nKey={I18nKeys.ClientDataGenerateOptionIconDialogSuccess as string}
                  values={{
                    count: optionIconsToGenerate.rows.length,
                    total: optionIconsToGenerate.rows.length,
                    option: getOptionTypeFromTable(optionIconsToGenerate.table)?.replace('-', ' ') || 'option',
                  }}
                />
              </Typography>
            )}
            {isError && (
              <>
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                >
                  <Trans i18nKey={I18nKeys.ClientDataGenerateOptionIconDialogError as string} />
                </Typography>
                <Alert severity="error" icon={false}>
                  <code>{isError}</code>
                </Alert>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="primary">
            {t(I18nKeys.DialogCloseButton)}
          </Button>
        </DialogActions>
      </Dialog>
      <div
        id={embeddedConfiguratorContainer}
        style={{
          ...((isLoading || isSuccess || isError) && !DEBUG_MODE ? { opacity: '0' } : {}),
          ...(DEBUG_MODE ? { zIndex: 1000 } : {}),
          display: isDialogOpen ? 'flex' : 'none',
          position: 'absolute',
          flex: '1',
          minHeight: '1000px',
          maxHeight: '1000px',
          minWidth: '1000px',
          maxWidth: '1000px',
        }}
      >
        {embeddedConfiguratorDiv}
      </div>
    </>
  );
};
