import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CircularProgress, IconButton, Theme, alpha } from '@mui/material';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  text: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    fontWeight: '500',
    color: alpha(theme.palette.text.primary, 0.62),
  },
  loading: {
    margin: '16px',
    position: 'absolute',
    right: '0',
  },
}));

interface Props {
  onClearClick?(): void;
  searchTerm?: string;
  displayCloseButton?: boolean;
  resultsDisplay?: {
    count: number;
    index?: number;
    loading?: boolean;
    label?: string;
  };
}

export const SearchInputEndAdornment: React.FC<Props> = ({
  onClearClick,
  searchTerm,
  displayCloseButton = true,
  resultsDisplay: { count, index, loading = false, label = 'results' } = {},
}) => {
  const classes = useStyles();
  const closeEnabled = displayCloseButton && !!searchTerm?.length;
  const loadingResults = count !== undefined && loading;
  const resultsEnabled = !!count && !loading && !!searchTerm?.length;

  return (
    <>
      {loadingResults && <CircularProgress size={20} className={classes.loading} />}
      {resultsEnabled && (
        <div className={classes.text} style={{ padding: !closeEnabled ? '12px' : undefined }}>
          {index === undefined ? `${count} ${label}` : `${index >= count ? count : index + 1} of ${count}`}
        </div>
      )}
      {closeEnabled && (
        <IconButton
          onClick={(): void => {
            if (onClearClick) onClearClick();
          }}
          size="large"
        >
          <Close />
        </IconButton>
      )}
    </>
  );
};
