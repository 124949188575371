import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../constants/SortDirection';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { Header } from './Table';

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    fontWeight: 'bold',
  },
}));

interface Props {
  header: Header;
  sortProperties: SortProperty[];
  sortableProperties: SortableProperty[];
  onSortChange(key: string, direction?: SortDirection, defaultValue?: string): void;
  getHeaderClass?(property: string): string;
}

export const TableHeaderCell: React.FC<Props> = ({
  header,
  sortProperties,
  sortableProperties,
  onSortChange,
  getHeaderClass,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sortProperty, setSortProperty] = React.useState(
    sortProperties.find((sp: SortProperty) => sp.key === header.property),
  );

  const key: string = header.sortKey || header.property;
  const sortableProperty: SortableProperty | undefined = sortableProperties.find(
    ({ property, sortKey }: SortableProperty) => (sortKey || property) === key,
  );

  const onClick: React.MouseEventHandler = () => {
    let direction: SortDirection | undefined;
    const { defaultValue } = sortableProperty || { defaultValue: undefined };

    if (sortProperty === undefined) {
      direction = SortDirection.Asc;
    } else if (sortProperty.direction === SortDirection.Asc) {
      direction = SortDirection.Desc;
    }

    setSortProperty(direction ? { key, direction } : undefined);
    onSortChange(key, direction, defaultValue);
  };

  const activeSort = sortProperties?.some((sp) => sp.key === key);
  return (
    <TableCell
      align={header.numeric ? 'right' : 'left'}
      key={header.property}
      className={`${classes.tableCell} ${getHeaderClass ? getHeaderClass(header.property) : ''}`}
    >
      {!sortableProperty && <>{t(header.i18nKey)}</>}
      {sortableProperty && (
        <TableSortLabel
          active={activeSort}
          // eslint-disable-next-line no-nested-ternary
          direction={sortProperty ? (sortProperty.direction === SortDirection.Asc ? 'asc' : 'desc') : undefined}
          onClick={sortableProperty ? onClick : undefined}
          sx={{ '& svg': { visibility: activeSort ? 'visible' : 'hidden' } }}
        >
          {t(header.i18nKey)}
        </TableSortLabel>
      )}
    </TableCell>
  );
};
