import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfiguratorFormType } from '../constants/Configurator';
import { ConfiguratorFormTextField, ConfiguratorFormValues } from '../types/Configurator';
import { I18nKeys } from '../constants/I18nKeys';
import { ConfiguratorFormFields } from '../constants/FormFields';

interface Props {
  form: ConfiguratorFormType;
  formValues: ConfiguratorFormValues;
  errors: Record<string, string>;
  field: ConfiguratorFormTextField;
  onChange: (event: any) => void;
}

export const ConfiguratorDialogTextField: React.FC<Props> = ({ form, formValues, errors, field, onChange }) => {
  const { t } = useTranslation();

  const { fieldName, required = false, getHelperLabel, disabled: disabledFunc } = field;
  let { label } = field;

  const helperLabel = getHelperLabel ? getHelperLabel(form) : undefined;
  const disabled = disabledFunc ? disabledFunc(form) : false;
  if (
    fieldName === ConfiguratorFormFields.Name &&
    [ConfiguratorFormType.AddNewConfigurator, ConfiguratorFormType.EditConfigurator].includes(form)
  )
    label = I18nKeys.ConfiguratorFormFieldName;

  return (
    <TextField
      name={fieldName}
      label={`${t(label)} ${required ? '*' : ''}`}
      fullWidth
      variant="filled"
      helperText={errors[fieldName] || (helperLabel && t(helperLabel))}
      error={!!errors[fieldName]}
      onChange={onChange}
      value={formValues[fieldName] || ''}
      disabled={disabled}
    />
  );
};
