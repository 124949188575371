import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ConfiguratorFormType } from '../constants/Configurator';
import { ConfiguratorFormRadioSelectField, ConfiguratorFormValues } from '../types/Configurator';
import { ConfiguratorDialogSelectField } from './ConfiguratorDialogSelectField';

const useStyles = makeStyles({
  radioOption: {
    padding: '0px 0px 8px',
  },
  radioLabel: {
    padding: '0px 17px',
  },
});

interface Props {
  form: ConfiguratorFormType;
  formValues: ConfiguratorFormValues;
  errors: Record<string, string>;
  fields: ConfiguratorFormRadioSelectField[];
  onChange: (event: any) => void;
}

export const ConfiguratorDialogRadioSelectField: React.FC<Props> = ({ form, formValues, errors, fields, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { radioGroup } = fields[0];
  return (
    <FormControl fullWidth>
      <RadioGroup name={radioGroup} value={formValues[radioGroup] || ''} onChange={onChange}>
        {fields.map((field) => {
          const { fieldName, radioOption, radioLabel } = field;
          return (
            <div key={fieldName} className={classes.radioOption}>
              <FormControlLabel
                classes={{
                  label: classes.radioLabel,
                }}
                value={radioOption}
                control={<Radio />}
                label={t(radioLabel)}
              />
              <ConfiguratorDialogSelectField
                form={form}
                formValues={formValues}
                errors={errors}
                field={field}
                onChange={onChange}
              />
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
