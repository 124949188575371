/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import JoyRide, { Step, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { I18nKeys } from '../constants/I18nKeys';
import { endTour, tourNextOrPrevious } from '../ducks/viewerSlice';

type TooltipProps = TooltipRenderProps;

const Tooltip: React.FC<TooltipProps> = ({
  continuous,
  index,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  closeProps,
}: TooltipProps) => {
  const { t } = useTranslation();
  return (
    <Card {...tooltipProps}>
      <CardContent>
        {step.title && (
          <Typography gutterBottom variant="h5" component="h2">
            {step.title}
          </Typography>
        )}
        <Typography variant="body2">{step.content}</Typography>
      </CardContent>
      <CardActions>
        {index > 0 && (
          <Button color="secondary" variant="text" {...backProps}>
            {t(I18nKeys.TourBackButton)}
          </Button>
        )}
        {continuous && (
          <Button color="primary" variant="text" {...primaryProps}>
            {t(I18nKeys.TourNextButton)}
          </Button>
        )}
        {!continuous && (
          <Button color="primary" variant="text" {...closeProps}>
            {t(I18nKeys.DialogGotItButton)}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

interface StateProps {
  tourRun: boolean;
  tourSteps: Step[];
  tourStepIndex: number;
}

interface DispatchProps {
  tourCallback(data: any, run: boolean): void;
}

type Props = StateProps & DispatchProps;

const TourComponent: React.FC<Props> = ({ tourRun, tourSteps, tourStepIndex, tourCallback }: Props) => (
  <JoyRide
    run={tourRun}
    stepIndex={tourStepIndex}
    steps={tourSteps}
    continuous={false}
    tooltipComponent={Tooltip}
    callback={(data): void => tourCallback(data, tourRun)}
    styles={{
      options: {
        zIndex: 1300,
      },
    }}
  />
);

const mapStateToProps = ({ viewer: { tourRun, tourSteps, tourStepIndex } }: AppState): StateProps => ({
  tourRun,
  tourSteps,
  tourStepIndex,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  tourCallback: (data, run): void => {
    const { action, index, type, status } = data;

    if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && run) || status === STATUS.FINISHED) {
      dispatch(endTour());
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch(tourNextOrPrevious(index + (action === ACTIONS.PREV ? -1 : 1)));
    }
  },
});

export const Tour = connect(mapStateToProps, mapDispatchToProps)(TourComponent);
