import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { InputField } from './redux-form/InputField';
import { ProfileFormFields } from '../constants/FormFields';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '20px',
  },
  field: {
    width: '100%',
    marginBottom: '2em',
  },
  button: {
    flex: '0 0 auto',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  form: {
    width: '80%',
    maxWidth: '800px',
    minWidth: '300px',
  },
}));

export interface FormData {
  [ProfileFormFields.FirstName]: string | undefined;
  [ProfileFormFields.LastName]: string | undefined;
  [ProfileFormFields.Email]: string | undefined;
  [ProfileFormFields.PhoneNumber]: string | undefined;
}

type FormProps = InjectedFormProps<FormData>;

const ProfileFormComponent: React.FC<FormProps> = ({ error, handleSubmit, submitting }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const onSubmit = (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Profile}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    });

  return (
    <Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoComplete="given-name"
        className={classes.field}
        validate={required}
        variant="outlined"
        name={ProfileFormFields.FirstName}
        component={InputField}
        label={`${t(I18nKeys.FieldFirstName)}*`}
      />

      <Field
        autoComplete="family-name"
        className={classes.field}
        validate={required}
        variant="outlined"
        name={ProfileFormFields.LastName}
        component={InputField}
        label={`${t(I18nKeys.FieldLastName)}*`}
      />

      <Field
        className={classes.field}
        disabled
        validate={required}
        variant="outlined"
        name={ProfileFormFields.Email}
        component={InputField}
        label={`${t(I18nKeys.FieldEmail)}*`}
      />

      <Field
        className={classes.field}
        variant="outlined"
        name={ProfileFormFields.PhoneNumber}
        component={InputField}
        label={t(I18nKeys.ProfilePhoneNumber)}
      />

      {!!error && <Typography className={classes.error}>{error}</Typography>}

      <LoadingButton
        key="create-group-btn"
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        loading={submitting}
        disabled={submitting}
      >
        {t(I18nKeys.ProfileUpdateProfile)}
      </LoadingButton>
    </Form>
  );
};

export const ProfileForm = reduxForm<FormData>({
  form: Forms.Profile,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(connect()(ProfileFormComponent));
