import { AppRoutes } from '../constants/AppRoutes';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { QueryParams } from '../constants/QueryParams';
import { getUrlWithQueryParams } from './vendorUtils';

export const getClientDataTableLink = (
  groupId: string,
  clientDataId: string,
  clientDataBranch?: ClientDataBranch,
  range?: { start?: string; end?: string; columns?: string[]; table?: string; branch?: string },
): string => {
  if (!groupId || !clientDataId || !clientDataBranch || !range?.table) return '';

  const queryParams = [
    {
      key: QueryParams.StartRowId,
      queryParamKey: QueryParams.StartRowId,
      value: range.start && range.end ? encodeURIComponent(range.start) : undefined,
    },
    {
      key: QueryParams.EndRowId,
      queryParamKey: QueryParams.EndRowId,
      value: range.start && range.end ? encodeURIComponent(range.end) : undefined,
    },
    {
      key: QueryParams.Columns,
      queryParamKey: QueryParams.Columns,
      value: range.columns ? encodeURIComponent(range.columns.join(',')) : undefined,
    },
  ].filter((param) => param.value);

  const baseUrl = `${window.location.protocol}//${window.location.host}${AppRoutes.ClientData}/${groupId}/${clientDataId}/${clientDataBranch}/${range.table}`;

  return getUrlWithQueryParams(baseUrl, queryParams);
};
