import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';

export type ClientEmailDomainResponse = {
  status: string;
  records?: {
    [key: string]: {
      host: string;
      type: string;
      expectedValue: string;
      currentValue: string;
      priority?: string;
      verified: boolean;
    };
  };
};

export type CreateMailgunDomainResponse = {
  success: boolean;
};

export const domainApi = createApi({
  reducerPath: 'domainApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/sending-domain',
  }),
  endpoints: (builder) => ({
    /**
     * Gets the client's email domain information
     *
     * @param domain
     * @returns client email domain information
     */
    getClientEmailDomain: builder.query<
      ClientEmailDomainResponse,
      { emailAddress: string | undefined; clientId: string }
    >({
      query: ({ emailAddress, clientId }) => ({
        url: `/`,
        method: 'get',
        params: {
          emailAddress,
        },
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Creates a Mailgun domain for the client domain
     *
     * @param domain
     * @returns Mailgun domain creation response
     */
    createMailgunDomain: builder.mutation<CreateMailgunDomainResponse, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),
  }),
});

export const { useGetClientEmailDomainQuery, useCreateMailgunDomainMutation } = domainApi;
