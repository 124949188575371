import { DialogTitle, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { I18nKeys } from '../constants/I18nKeys';
import { config } from '../config/config';
import { Environment } from '../constants/Environment';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    backgroundColor: `#F443361A`,
    color: '#F44336',
    fontWeight: theme.typography.fontWeightBold,
    padding: '0 0',
    paddingBottom: '4px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  dialogTitleNoProduction: {
    fontWeight: theme.typography.fontWeightBold,
    padding: '20px 24px',
  },
  dialogTitleStripes: {
    width: 'auto',
    height: '15px',
    marginBottom: '4px',
    backgroundImage: `linear-gradient(135deg, rgba(244, 67, 54, 0.1) 22.22%, #F44336 22.22%, #F44336 50%, rgba(244, 67, 54, 0.1) 50%, rgba(244, 67, 54, 0.1) 72.22%, #F44336 72.22%, #F44336 100%)`,
    backgroundSize: '25.46px 25.46px',
    '&$animated': {
      animation: '$moveStripes 0.5s infinite linear',
    },
  },
  animated: {},
  '@keyframes moveStripes': {
    '0%': { backgroundPositionX: '0px' },
    '100%': { backgroundPositionX: '25.46px' },
  },
}));

export const ClientDataPublishDialogStripes: React.FC<{ isPublishing?: boolean }> = ({ isPublishing }) => {
  const environment = config.environment.STAGE || 'development';
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogTitle
      className={environment === Environment.Production ? classes.dialogTitle : classes.dialogTitleNoProduction}
    >
      {environment === Environment.Production && (
        <div className={`${classes.dialogTitleStripes} ${isPublishing ? classes.animated : ''}`} />
      )}
      {t(I18nKeys.ClientDataPublishDialogTitle, { context: environment })}
    </DialogTitle>
  );
};
