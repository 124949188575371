import React from 'react';
import { Chip, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface MenuItem {
  id?: number | string;
  key?: number | string;
  value?: number | string;
  name?: string;
  label?: string;
}

interface Props {
  items: MenuItem[];
  defaultValue?: string;
  onItemClicked?(item: any): void;
}

export const ChipMenu: React.FC<Props> = ({ items, defaultValue, onItemClicked }: Props) => {
  const [chipLabel, setChipLabel] = React.useState(
    // eslint-disable-next-line no-nested-ternary
    defaultValue
      ? (
          items.find((item) => item.id === defaultValue || item.key === defaultValue || item.value === defaultValue) ||
          {}
        ).name ||
          (
            items.find(
              (item) => item.id === defaultValue || item.key === defaultValue || item.value === defaultValue,
            ) || {}
          ).label ||
          (items[0] ? items[0].name || items[0].label : '')
      : items[0]
      ? items[0].name || items[0].label
      : '',
  );
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();

  const handleChipClicked = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setMenuAnchorEl(anchorElRef.current);
    setMenuOpen(!menuOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any): void => {
    event.stopPropagation();
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, item: MenuItem): void => {
    event.stopPropagation();
    setMenuOpen(false);
    setMenuAnchorEl(null);
    setChipLabel(item.name || item.label || '');
    if (onItemClicked) onItemClicked(item.id || item.key || item.value);
  };

  return (
    <>
      <Chip
        deleteIcon={<ArrowDropDownIcon />}
        clickable
        onDelete={handleChipClicked}
        onClick={handleChipClicked}
        label={chipLabel}
        ref={anchorElRef}
      />
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleClose}>
        {items.map((item: MenuItem) => (
          <MenuItem
            key={item.key || item.id || item.value}
            value={item.value || item.key || item.id}
            onClick={(event: React.MouseEvent<HTMLElement>): void => handleMenuItemClick(event, item)}
          >
            {item.name || item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
