export enum AppRoutes {
  ClientData = '/portal/data',
  ForgotPassword = '/password/new',
  Groups = '/portal/groups',
  Leads = '/portal/leads',
  Orders = '/portal/orders',
  Dealers = '/portal/dealers',
  Portal = '/portal',
  Payments = '/portal/payments',
  Pricing = '/portal/pricing',
  Profile = '/portal/profile',
  Usage = '/portal/usage',
  Users = '/portal/users',
  Reports = '/portal/reports',
  Settings = '/portal/settings',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Sites = '/portal/sites',
  Sso = '/sso',
}

export const routesWithConfirmation = [AppRoutes.Pricing, AppRoutes.Sites];

export enum AppRoutesWithConfirmation {
  Pricing = '/portal/pricing',
  Sites = '/portal/sites',
}
