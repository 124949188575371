import React from 'react';
import { ITooltipParams } from 'ag-grid-community';
import { Paper, styled } from '@mui/material';
import { Tooltip } from './ClientDataTooltip';

export const HeaderTooltip = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  padding: theme.spacing(2),
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  fontWeight: 400,
  color: '#FFF',
  background: '#3F3F3FE5',
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
}));

export const PricingGridTooltip: React.FC<ITooltipParams> = ({ location, value }: ITooltipParams) => {
  if (location === 'header') {
    return <HeaderTooltip elevation={4}>{value}</HeaderTooltip>;
  }

  return <Tooltip elevation={4}>{value}</Tooltip>;
};
