import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { ClientDataPreviewFields } from '../constants/FormFields';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxLabel: {
    paddingLeft: theme.spacing(2),
  },
  descriptionField: {
    width: '100%',
    minWidth: '500px',
    marginTop: theme.spacing(1),
  },
}));

export interface FormData {
  [ClientDataPreviewFields.Description]: string;
}

interface CustomProps {
  onSubmit: { (values: FormData): void };
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const ClientDataPreviewFormComponent: React.FC<FormProps> = ({ handleSubmit, onSubmit }: FormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        autoFocus
        variant="filled"
        autoComplete="off"
        className={classes.descriptionField}
        component={InputField}
        label={t(I18nKeys.ClientDataHotfixDescriptionField)}
        name={ClientDataPreviewFields.Description}
        multiline
        rows={3}
      />
    </Form>
  );
};

export const ClientDataPreviewForm = reduxForm<FormData, CustomProps>({ form: Forms.ClientDataPreview })(
  ClientDataPreviewFormComponent,
);
