import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';
import { ClientDataEditor } from '../constants/ClientData';
import { getInitialEditorValue } from '../utils/clientDataUtils';

export const ClientDataTextEditor = forwardRef<ICellEditorReactComp, ICellEditorParams>(
  (props: ICellEditorParams, ref) => {
    const [value, setValue] = useState<string | undefined>(getInitialEditorValue(props));
    const [selection, setSelection] = useState<{ start: number; end: number } | undefined>(undefined);
    const refInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      refInput.current?.focus();
    }, []);

    useEffect(() => {
      // Update the selection following a paste, only after the value has been updated
      if (selection !== undefined) {
        const { start, end } = selection;
        refInput.current?.setSelectionRange(start, end);
        setSelection(undefined);
      }
    }, [value, selection]);

    useImperativeHandle(ref, () => ({
      getEditorType() {
        return ClientDataEditor.Standard;
      },

      getValue() {
        return value;
      },

      insertValue(val: string) {
        const maxSelection = value?.length || 0;
        let { selectionStart, selectionEnd } = (refInput.current || {}) as {
          selectionStart?: number;
          selectionEnd?: number;
        };
        // As a default, paste at the end of the current value
        if (selectionStart === undefined) selectionStart = maxSelection;
        if (selectionEnd === undefined) selectionEnd = maxSelection;

        const newSelectionStart = selectionStart + val.length;
        setSelection({ start: newSelectionStart, end: newSelectionStart });
        setValue((value || '').substring(0, selectionStart) + val + (value || '').substring(selectionEnd));
      },

      setSelection(newSelection: { start: number; end: number }) {
        setSelection(newSelection);
      },

      getSelection() {
        return { start: refInput.current?.selectionStart, end: refInput.current?.selectionEnd };
      },
    }));

    return (
      <div className="ag-cell-edit-wrapper">
        <div className="ag-cell-editor ag-text-field ag-input-field">
          <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
            <input
              value={value}
              ref={refInput}
              onChange={(event) => setValue(event.target.value)}
              className="ag-input-field-input ag-text-field-input"
            />
          </div>
        </div>
      </div>
    );
  },
);
