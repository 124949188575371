import { Column, ICellRendererParams } from 'ag-grid-community';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles(() => ({
  checkbox: {
    padding: '0px',
  },
}));

export const ClientDataBooleanCell: React.FC<ICellRendererParams> = ({ column, value, node }: ICellRendererParams) => {
  const classes = useStyles();
  const columnId = (column as Column).getColId();

  const onCheckboxChanged = (event: any) => {
    const { checked } = event.target;
    node.setDataValue(columnId, checked ? 1 : 0);
  };

  return (
    <Checkbox
      size="small"
      className={classes.checkbox}
      checked={value === 1 || value === '1'}
      onChange={onCheckboxChanged}
    />
  );
};
