import React from 'react';
import { makeStyles } from '@mui/styles';
import { IdeaRoomLogo } from './IdeaRoomLogo';

const useStyles = makeStyles({
  ideaRoomLogo: {
    width: '18px',
    marginRight: '8px',
  },
});

export const IdeaRoomOnlyIndicator: React.FC = () => {
  const classes = useStyles();
  return <IdeaRoomLogo className={classes.ideaRoomLogo} />;
};
