import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';

interface Props {
  onTabChange(clientId: string): void;
}

// eslint-disable-next-line no-shadow
enum WhatsNewComponentTabs {
  SalesView = 'salesview',
  ShedView = 'shedview',
  CarportView = 'carportview',
}

export const WhatsNewAppBar: React.FC<Props> = ({ onTabChange }: Props) => {
  const { t } = useTranslation();
  const [currentClientId, setCurrentClientId] = React.useState(WhatsNewComponentTabs.SalesView);

  React.useEffect(() => onTabChange(currentClientId), [currentClientId]);

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  return (
    <AppBar position="static" color="inherit">
      <Tabs
        value={currentClientId}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: WhatsNewComponentTabs): void => {
          setCurrentClientId(newValue);
          onTabChange(newValue);
        }}
        aria-label="whats-new-tabs"
        variant={tabsVariant}
        indicatorColor="primary"
        textColor="inherit"
      >
        <Tab
          label={t(I18nKeys.WhatsNewTabSalesView)}
          value={WhatsNewComponentTabs.SalesView}
          id={`${WhatsNewComponentTabs.SalesView}-tab`}
          aria-controls={`${WhatsNewComponentTabs.SalesView}-tab`}
        />
        <Tab
          label={t(I18nKeys.WhatsNewTabShedView)}
          value={WhatsNewComponentTabs.ShedView}
          id={`${WhatsNewComponentTabs.ShedView}-tab`}
          aria-controls={`${WhatsNewComponentTabs.ShedView}-tab`}
        />
        <Tab
          label={t(I18nKeys.WhatsNewTabCarportView)}
          value={WhatsNewComponentTabs.CarportView}
          id={`${WhatsNewComponentTabs.CarportView}-tab`}
          aria-controls={`${WhatsNewComponentTabs.CarportView}-tab`}
        />
      </Tabs>
    </AppBar>
  );
};
