import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HighlightOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IStatusPanelParams } from 'ag-grid-community';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
  },
  buttonIcon: {
    marginRight: '6px',
    fontSize: '1.1rem',
    color: '#4286F4',
  },
  buttonText: {
    fontWeight: 'normal',
    fontSize: '13px',
    color: '#4286F4',
    textTransform: 'none',
  },
}));

export interface IClearGridFiltersStatusPanel {
  setVisible(visible: boolean): void;
  isVisible(): boolean;
}

export const ClearGridFilters: React.FC<IStatusPanelParams> = forwardRef((props: IStatusPanelParams, ref) => {
  const { api: gridApi } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    setVisible: (visibility: boolean) => {
      setVisible(visibility);
    },
    isVisible: () => visible,
  }));

  const clearFilters = () => {
    const model = gridApi.getFilterModel();
    if (!model) return;
    Object.keys(model).forEach((column) => {
      const columnFilterInstance = gridApi.getFilterInstance(column);
      columnFilterInstance?.setModel(null);
    });
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged('quickFilter');
  };

  if (!visible) return null;

  return (
    <Button aria-label="clear column filter" onClick={clearFilters} className={classes.button}>
      <HighlightOff className={classes.buttonIcon} />
      <span className={`${classes.buttonText} .ag-theme-alpine`}>{t(I18nKeys.StatusBarRemoveFilter)} </span>
    </Button>
  );
});

ClearGridFilters.displayName = 'ClearGridFilters';
