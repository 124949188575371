/* global sessionStorage */

/**
 * Gets a query parameter from the current url.
 *
 * @param queryParam The name of the query parameter
 * @returns The value of the query parameter
 */
export const getQueryParam = (queryParam: string): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(queryParam);
};

/**
 * Set a query param in url if it exists in sessionStorage.
 *
 * @param queryParam The name of the query parameter
 */
export const setQueryParam = (param: string): void => {
  const paramValue = sessionStorage.getItem(param);
  if (paramValue) {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(param)) {
      searchParams.set(param, paramValue);
      window.location.search = searchParams.toString();
    }
  }
};

/**
 * Removes a query param from the url if it exists.
 * Also removes the param from sessionStorage.
 *
 * @param param  The name of the query parameter
 */
export const removeQueryParam = (param: string): void => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has(param)) {
    searchParams.delete(param);
    window.location.search = searchParams.toString();
  }
  sessionStorage.removeItem(param);
};

/**
 * Get the path part at the given index
 *
 * URL format: /portal/path/parts
 *
 * @param index index of the path part to get
 * @returns value of the path part at the given index
 */
export const getPathPart = (index: number): string => {
  const { pathname } = window.location;
  if (!pathname) {
    return '';
  }
  return pathname.split('/')[index];
};
