/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem } from '@mui/material';

type Props = Pick<SelectProps, 'className' | 'style' | 'label' | 'disabled'> & {
  options: string[];
  renderValue?: { (selected: string[]): string };
  renderItemValue?: { (value: string): string };
  input: Pick<WrappedFieldInputProps, 'onChange' | 'value'>;
  meta?: Pick<WrappedFieldMetaProps, 'touched' | 'error'>;
};

const SELECT_ALL_VALUE = 'select-all';

export const MultiselectField: React.FC<Props> = (props: Props) => {
  const {
    className,
    style,
    input: { onChange, value = [] },
    label,
    options,
    disabled,
    meta: { touched, error } = {},
    renderValue = (selected: string[]) => selected.join(', '),
    renderItemValue = (itemValue: string) => itemValue,
    ...otherProps
  } = props;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value: newValue },
    } = event;
    const newValueAsStringArray = typeof newValue === 'string' ? newValue.split(',') : newValue;
    if (newValueAsStringArray.includes(SELECT_ALL_VALUE)) {
      if (newValueAsStringArray.length <= options.length) {
        onChange([...options]);
      } else {
        onChange([]);
      }
    } else {
      onChange(newValueAsStringArray);
    }
  };

  return (
    <FormControl className={className} style={style} fullWidth variant="filled">
      <InputLabel id="multiple-select-label">{label}</InputLabel>
      <Select
        {...otherProps}
        defaultValue={undefined}
        labelId="multiple-select-label"
        id="multiple-select-checkbox"
        multiple
        value={value as string[]}
        onChange={handleChange}
        renderValue={renderValue}
        error={!!(touched && error)}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        <MenuItem key="select-all" value={SELECT_ALL_VALUE}>
          <Checkbox checked={value.length > 0} indeterminate={value.length > 0 && value.length < options.length} />
          <ListItemText primary="(Select All)" />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.includes(option)} />
            <ListItemText primary={renderItemValue(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
