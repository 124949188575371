import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { DealersState } from '../types/DealersState';
import { Dealer } from '../types/Dealer';

// Action types

export enum DealersActionTypes {
  DISMISS_IGNORE_IMPORT_ALERT = 'DISMISS_IGNORE_IMPORT_ALERT',
  FETCH_ALL_DEALERS = 'FETCH_ALL_DEALERS',
  FETCH_DEALERS = 'FETCH_DEALERS',
  FETCH_DEALERS_ERROR = 'FETCH_DEALERS_ERROR',
  FETCH_DEALERS_SUCCESS = 'FETCH_DEALERS_SUCCESS',
  REMOVE_DEALER = 'REMOVE_DEALER',
  REMOVE_DEALER_SUCCESS = 'REMOVE_DEALER_SUCCESS',
  SAVE_DEALER_SUCCESS = 'SAVE_DEALER_SUCCESS',
  SET_DEALER_DIALOG = 'SET_DEALER_DIALOG',
}

// Reducer

export const INITIAL_DEALERS_STATE: DealersState = {
  dealers: [],
  dialogClientId: undefined,
  dialogDealer: undefined,
  loading: false,
  logoUrl: undefined,
};

export const dealersReducer: Reducer<DealersState> = (state = INITIAL_DEALERS_STATE, dealersAction) => {
  switch (dealersAction.type) {
    case DealersActionTypes.FETCH_ALL_DEALERS:
    case DealersActionTypes.FETCH_DEALERS: {
      return { ...state, dealers: [], loading: true };
    }
    case DealersActionTypes.FETCH_DEALERS_SUCCESS: {
      const {
        payload: { dealers, logoUrl },
      } = dealersAction;

      return { ...state, dealers, loading: false, logoUrl };
    }
    case DealersActionTypes.FETCH_DEALERS_ERROR: {
      return { ...state, dealers: [], loading: false, logoUrl: undefined };
    }
    case DealersActionTypes.SAVE_DEALER_SUCCESS: {
      const {
        payload: { dealer },
      } = dealersAction;

      const { dealers } = state;
      const newDealers = dealers.map((d) => (d.key === dealer.key ? dealer : d));

      return { ...state, dealers: newDealers };
    }
    case DealersActionTypes.REMOVE_DEALER_SUCCESS: {
      const {
        payload: {
          dealer: { key },
        },
      } = dealersAction;

      const { dealers } = state;
      const newDealers = dealers.filter((d) => d.key !== key);

      return { ...state, dealers: newDealers };
    }
    case DealersActionTypes.SET_DEALER_DIALOG: {
      const {
        payload: { dialogDealer, dialogClientId, logoUrl },
      } = dealersAction;
      return { ...state, dialogDealer, dialogClientId, logoUrl };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const fetchDealers = (clientId: string): AnyAction => action(DealersActionTypes.FETCH_DEALERS, { clientId });

export const fetchDealersError = (): AnyAction => action(DealersActionTypes.FETCH_DEALERS_ERROR);

export const fetchDealersSuccess = (dealers: Dealer[], logoUrl: string | undefined): AnyAction =>
  action(DealersActionTypes.FETCH_DEALERS_SUCCESS, { dealers, logoUrl });

export const removeDealer = (clientId: string, dealer: Dealer): AnyAction =>
  action(DealersActionTypes.REMOVE_DEALER, { clientId, dealer });

export const removeDealerSuccess = (dealer: Dealer): AnyAction =>
  action(DealersActionTypes.REMOVE_DEALER_SUCCESS, { dealer });

export const saveDealerSuccess = (dealer: Dealer): AnyAction =>
  action(DealersActionTypes.SAVE_DEALER_SUCCESS, { dealer });

export const setDealerDialog = (dialogClientId?: string, dialogDealer?: Dealer, logoUrl?: string): AnyAction =>
  action(DealersActionTypes.SET_DEALER_DIALOG, { dialogDealer, dialogClientId, logoUrl });
