import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    alignSelf: 'center',
  },
});

export const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};
