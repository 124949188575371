import React from 'react';
import { ITooltipParams } from 'ag-grid-community';
import { Paper, styled } from '@mui/material';

export const Tooltip = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  padding: theme.spacing(1),
  maxWidth: '300px',
  fontSize: '13px',
  fontWeight: 700,
  color: '#606368',
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
}));

export const ClientDataTooltip: React.FC<ITooltipParams> = ({ value }: ITooltipParams) => (
  <Tooltip elevation={4}>{value}</Tooltip>
);
