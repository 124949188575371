import React from 'react';
import { FormControl, FormHelperText, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks';
import { ConfiguratorFormFieldType, ConfiguratorFormType } from '../constants/Configurator';
import {
  ConfiguratorFormRadioSelectField,
  ConfiguratorFormSelectField,
  ConfiguratorFormValues,
} from '../types/Configurator';
import { AppState } from '../types/AppState';
import { ConfiguratorFormFields } from '../constants/FormFields';
import { ConfiguratorDialogSelectOption } from './ConfiguratorDialogSelectOption';
import { fuzzyMatchIncludes } from '../utils/stringUtils';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 'calc(50% + 8px)', // '50%
    left: '50%',
    marginTop: '-1.2em',
    marginLeft: -12,
  },
  radioSelect: {
    margin: '0px 0px 0px 42px',
    width: 'calc(100% - 42px)',
  },
  inputLabel: {
    marginTop: '14px',
  },
  search: {
    padding: '16px',
  },
});

interface Props {
  form: ConfiguratorFormType;
  formValues: ConfiguratorFormValues;
  errors: Record<string, string>;
  field: ConfiguratorFormSelectField | ConfiguratorFormRadioSelectField;
  onChange: (event: any) => void;
}

export const ConfiguratorDialogSelectField: React.FC<Props> = ({ form, formValues, errors, field, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { viewer: { theme: { contactBarColor = '#F36F3A' } = {} } = {} } = useAppSelector(
    (state: AppState) => state || {},
  );

  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const clearSearchTerm = (): void => setSearchTerm('');

  const {
    type,
    fieldName,
    required = false,
    getHelperLabel,
    selectOptions,
    loading = false,
    selectLabel,
    disabled: disabledFunc,
  } = field;
  const { radioGroup, radioOption } = field as any;

  const disabled =
    (type === ConfiguratorFormFieldType.RadioSelect &&
      formValues[radioGroup as ConfiguratorFormFields] !== radioOption) ||
    (disabledFunc && disabledFunc(form));
  const helperLabel = getHelperLabel ? getHelperLabel(form) : undefined;

  const selectedOption = selectOptions.find((option) => option.value === formValues[fieldName]) || null;

  return (
    <FormControl className={type === ConfiguratorFormFieldType.RadioSelect ? classes.radioSelect : undefined} fullWidth>
      <Autocomplete
        id={fieldName}
        disabled={loading || disabled}
        value={selectedOption}
        options={selectOptions}
        filterOptions={(options, state) => {
          const { inputValue } = state;
          const filtered = options.filter((option) => {
            const { label, value } = option;
            return fuzzyMatchIncludes(label, inputValue) || fuzzyMatchIncludes(value, inputValue);
          });
          return filtered;
        }}
        fullWidth
        autoHighlight
        clearIcon={null}
        open={open}
        onOpen={(): void => setOpen(true)}
        onClose={(): void => setOpen(false)}
        getOptionLabel={(option) => t(option.label)}
        renderOption={(_, option) => {
          const { value } = option;
          const onClick = () => {
            onChange({ target: { value, name: fieldName } });
            clearSearchTerm();
            setOpen(false);
          };
          return (
            <ConfiguratorDialogSelectOption key={value} option={option} options={selectOptions} onClick={onClick} />
          );
        }}
        renderInput={(params) => (
          <>
            {loading && <CircularProgress className={classes.overlay} size={24} color="primary" />}
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={`${t(selectLabel)} ${required ? '*' : ''}`}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setSearchTerm(event.target.value)}
              value={searchTerm}
              error={!!errors[fieldName]}
              variant="filled"
            />
          </>
        )}
      />
      {(!!errors[fieldName] || (helperLabel && !disabled)) && (
        <FormHelperText error={!!errors[fieldName]} style={errors[fieldName] ? {} : { color: contactBarColor }}>
          {errors[fieldName] || (
            <Trans
              i18nKey={helperLabel as string}
              components={{ underline: <span style={{ textDecoration: 'underline' }} /> }}
            />
          )}
        </FormHelperText>
      )}
    </FormControl>
  );
};
