import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { mapClientAndDataTypeAndTableToUndoStackId } from '../utils/clientIdUtils';
import { NoteDialog } from './NoteDialog';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { UpdateClientDataMetadata } from '../ducks/clientDataSlice';
import { updateCellMetadata } from '../utils/pricingSheetUtils';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { Dialogs } from '../constants/Dialogs';

export const PricingBaseNoteDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pricingDataBranch = ClientDataBranch.Main } = useAppSelector((state) => state.pricing.base);

  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );

  const selectedTable = selectedViewerId?.startsWith('shedview') ? 'basePrice' : 'pricingBase';

  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(
    selectedViewerId,
    ClientDataType.Supplier,
    selectedTable,
  );
  const { cellMetadata } = usePricingRepo({ useCellMetadata: true });
  const saveNote = (updates: UpdateClientDataMetadata[]) => {
    updateCellMetadata(clientDataTableId, updates, dispatch);
  };

  return (
    <NoteDialog
      dialogKey={Dialogs.PricingBaseNote}
      branch={pricingDataBranch}
      cellMetadata={cellMetadata}
      saveNote={saveNote}
    />
  );
};
