import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { I18nKeys } from '../constants/I18nKeys';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { unknownSceneEnvironment } from '../constants/SceneEnvironment';
import { SceneEnvironmentDialogFormFields } from '../constants/FormFields';
import { SceneEnvironmentForm } from './SceneEnvironmentForm';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';

export const SceneEnvironmentDialog: React.FC = () => {
  const { t } = useTranslation();
  const sceneEnvironment = useSelector<AppState, SceneEnvironment>(
    (state) => state.settings.sceneEnvironmentDialog || unknownSceneEnvironment,
  );
  const { clientId = '', updating: submitting = false } = sceneEnvironment;
  const initialValues = {
    [SceneEnvironmentDialogFormFields.ClientId]: clientId,
    [SceneEnvironmentDialogFormFields.Key]: sceneEnvironment.key,
    [SceneEnvironmentDialogFormFields.Label]: sceneEnvironment.label,
    [SceneEnvironmentDialogFormFields.FileUrl]: sceneEnvironment.fileUrl,
    [SceneEnvironmentDialogFormFields.PreviewUrl]: sceneEnvironment.previewUrl,
    [SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier]: sceneEnvironment.maxCameraDistanceMultiplier,
    [SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength]: sceneEnvironment.maxDiagonalBuildingLength,
  };

  const dispatch = useDispatch();
  const save = (): void => {
    dispatch(submit(Forms.SceneEnvironment));
  };
  const closeDialog = (): void => {
    dispatch(reset(Forms.SceneEnvironment));
    dispatch(closeDialogFunc());
  };

  return (
    <Dialog dialogKey={Dialogs.SceneEnvironment}>
      <DialogTitle>
        {`${initialValues.KEY ? t(I18nKeys.DialogTypeEdit) : t(I18nKeys.DialogTypeAdd)} ${t(
          I18nKeys.SceneEnvironment,
        )}`}
      </DialogTitle>
      <DialogContent>
        <SceneEnvironmentForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={submitting} onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogCancelButton)}
        </LoadingButton>
        <LoadingButton loading={submitting} onClick={(): void => save()} color="primary">
          {t(I18nKeys.DialogSaveButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
