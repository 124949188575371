import React from 'react';
import { Dialogs } from '../constants/Dialogs';
import { PublishResultDialog } from './PublishResultDialog';
import { ClientDataType } from '../constants/ClientDataType';
import { useAppSelector } from '../hooks';
import { useGetSupplierVendorsListQuery } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';

export const PricingPublishResultDialog: React.FC = () => {
  const { clientId } = useAppSelector((state) => state.clientData);
  const { publishMergeResult } = useAppSelector((state) => state.pricing);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const { data: vendorsToPublish = [] } = useGetSupplierVendorsListQuery(
    { clientId, groupId },
    { refetchOnFocus: false, skip: !clientId || !groupId },
  );

  return (
    <PublishResultDialog
      publishMergeResult={publishMergeResult}
      dialogKey={Dialogs.PricingPublishResult}
      clientDataType={ClientDataType.Supplier}
      vendorsToPublish={vendorsToPublish}
    />
  );
};
