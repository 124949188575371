export enum PriceColumn {
  price = 'price',
  region1 = 'region1',
  region2 = 'region2',
  region3 = 'region3',
  region4 = 'region4',
  region5 = 'region5',
  region6 = 'region6',
  region7 = 'region7',
  region8 = 'region8',
  region9 = 'region9',
  region10 = 'region10',
  region11 = 'region11',
  region12 = 'region12',
  region13 = 'region13',
  region14 = 'region14',
  region15 = 'region15',
  region16 = 'region16',
  region17 = 'region17',
  region18 = 'region18',
  region19 = 'region19',
  region20 = 'region20',
}
