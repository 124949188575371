import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';
import { Client } from '../types/Client';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/client',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of all clients
     */
    getClients: builder.query<Client[], void>({
      query: () => ({
        url: `/`,
        method: 'get',
      }),
    }),

    /**
     * Gets a client by id
     */
    getClientById: builder.query<Client, string>({
      query: (clientIdentifier) => ({
        url: `/${clientIdentifier}`,
        method: 'get',
      }),
    }),

    /**
     * Adds a client
     */
    addClient: builder.mutation<Client, Client>({
      query: (client) => ({
        url: `/`,
        method: 'post',
        init: {
          body: client,
        },
      }),
    }),

    /**
     * Updates a client
     */
    updateClient: builder.mutation<Client, Client>({
      query: (client) => ({
        url: `/${client.id}`,
        method: 'put',
        init: {
          body: client,
        },
      }),
    }),

    /**
     * Deletes a client
     */
    deleteClient: builder.mutation<void, string>({
      query: (clientIdentifier) => ({
        url: `/${clientIdentifier}`,
        method: 'del',
      }),
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
