import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { fetchGroup } from '../ducks/group';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { NotificationSettings } from './NotificationSettings';
import { PortalI18n } from './PortalI18n';
import { SceneEnvironment as SceneEnvironmentComponent } from './SceneEnvironment';
import { WhatsNew as WhatsNewComponent } from './WhatsNew';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '100%',
  },
});

interface OwnProps {
  groupId: string;
}

// eslint-disable-next-line no-shadow
enum SettingsComponentTabs {
  WhatsNew = 'WHATS_NEW',
  Notifications = 'NOTIFICATIONS',
  I18n = 'I18N',
  SceneEnvironment = 'SCENE_ENVIRONMENT',
}

export const Settings: React.FC<OwnProps> = ({ groupId }: OwnProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [tab, setTab] = React.useState(SettingsComponentTabs.WhatsNew);

  React.useEffect(() => {
    if (groupId) {
      dispatch(fetchGroup(groupId));
    }
  }, [groupId]);

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  const tabs = [
    {
      label: t(I18nKeys.WhatsNew),
      value: SettingsComponentTabs.WhatsNew,
      id: 'whats-new-tab',
      hidden: false,
    },
    {
      label: t(I18nKeys.SettingsTabI18n),
      value: SettingsComponentTabs.I18n,
      id: 'i18n-tab',
      hidden: !isIdeaRoomGroup(groupId),
    },
    {
      label: t(I18nKeys.SettingsTabNotifications),
      value: SettingsComponentTabs.Notifications,
      id: 'notifications-tab',
      hidden: false,
    },
    {
      label: t(I18nKeys.SettingsTabSceneEnvironment),
      value: SettingsComponentTabs.SceneEnvironment,
      id: 'scene-environment-tab',
      hidden: !isIdeaRoomGroup(groupId),
    },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: SettingsComponentTabs): void => {
            setTab(newValue);
          }}
          aria-label="settings-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {tabs.map(
            (appBarTab) =>
              !appBarTab.hidden && (
                <Tab
                  key={appBarTab.id}
                  label={appBarTab.label}
                  value={appBarTab.value}
                  id={appBarTab.id}
                  aria-controls={appBarTab.id}
                />
              ),
          )}
        </Tabs>
      </AppBar>

      {tab === SettingsComponentTabs.WhatsNew && <WhatsNewComponent />}
      {tab === SettingsComponentTabs.Notifications && <NotificationSettings />}
      {tab === SettingsComponentTabs.I18n && <PortalI18n />}
      {tab === SettingsComponentTabs.SceneEnvironment && <SceneEnvironmentComponent />}
    </div>
  );
};
