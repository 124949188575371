import { AnyAction, Dispatch } from 'redux';
import { undoManager } from '../services/undoManager';

/**
 * Helper function for adding a command to the undo manager
 *
 * @param undoFunction
 * @param redoFunction
 * @param executeCommand
 */
export const addFunctionCommandToUndo = (
  undoFunction: Function,
  redoFunction: Function,
  clientDataTableId: string,
  executeCommand = false,
): void => {
  undoManager.add(
    {
      undo() {
        undoFunction();
      },
      redo() {
        redoFunction();
      },
    },
    clientDataTableId,
    executeCommand,
  );
};

/**
 * Helper function for adding a dispatch command to the undo manager
 *
 * @param dispatch
 * @param actionsToUndo
 * @param actionsToRedo
 * @param executeCommand
 */
export const addDispatchCommandToUndo = (
  dispatch: Dispatch,
  actionsToUndo: AnyAction[],
  actionsToRedo: AnyAction[],
  clientDataTableId: string,
  executeCommand = false,
): void => {
  undoManager.add(
    {
      undo() {
        actionsToUndo.forEach((action: AnyAction) => dispatch(action));
      },
      redo() {
        actionsToRedo.forEach((action: AnyAction) => dispatch(action));
      },
    },
    clientDataTableId,
    executeCommand,
  );
};
