import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../hooks';

export const Logo: React.FC = () => {
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    viewer: { theme: { logoUrl = '/ir-logo.png' } = {} },
  } = useAppSelector((state) => state || {});

  return logoUrl ? (
    <img style={{ height: mobileView ? '48px' : '70px' }} alt="logo" src={logoUrl} />
  ) : (
    <CircularProgress size={24} color="primary" />
  );
};
