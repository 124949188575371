import React from 'react';
import { portalService } from '../services/portalService';

const METABASE_SITE_URL = 'https://idearoom.metabaseapp.com';

interface Props {
  clientId: string;
  dashboardId: string;
}

export const Dashboard: React.FC<Props> = ({ clientId, dashboardId }: Props) => {
  const [src, setSrc] = React.useState<string | undefined>(undefined);
  const [expired, setExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchToken = async (id: string) => {
      const { token } = (await portalService.getMetabaseToken(id, dashboardId)) as {
        token: string;
      };
      setSrc(`${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`);
      setExpired(false);

      // Wait 10 minutes before expiring the token
      setTimeout(() => setExpired(true), 10 * 60 * 1000);
    };

    if (clientId) {
      fetchToken(clientId);
    }
  }, [clientId, expired]);

  if (!src) return null;
  return <iframe height="100%" width="100%" frameBorder="0" title="product-analytics" src={src} />;
};
