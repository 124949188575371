import React from 'react';
import { Divider, ListItemText, Menu, MenuList } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckMenuItem } from './CheckMenuItem';

type KeyValue = {
  key: string | number;
  value: string | number;
  i18nKey?: string;
  label?: string;
  keyboardShortcut?: string;
  section?: string;
};

interface Props {
  menuAnchorEl: HTMLElement | null;
  options: KeyValue[];
  checkedOptions?: any[];
  selectedOptions?: any[];
  checkedIcon?: React.ReactNode;
  onClick: (value: any) => void;
  onClose: () => void;
  visibleRows?: number;
  searchInput?: React.ReactNode;
  topMenuOptions?: React.ReactNode;
  bottomMenuOptions?: React.ReactNode;
  pinnedBottomOptions?: React.ReactNode;
  listRef?: React.Ref<HTMLUListElement>;
  onKeyDown?: (i: number) => (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => void;
  maxMenuHeight?: number | string;
  menuProps?: any;
}

export const AnchorMenu: React.FC<Props> = ({
  menuAnchorEl,
  options,
  checkedOptions,
  selectedOptions,
  checkedIcon,
  onClick,
  onClose,
  visibleRows = 4.5,
  searchInput = null,
  topMenuOptions = null,
  bottomMenuOptions = null,
  pinnedBottomOptions = null,
  listRef,
  onKeyDown,
  maxMenuHeight = undefined,
  menuProps = {},
}: Props) => {
  const { t, i18n } = useTranslation();
  const menuOpen = Boolean(menuAnchorEl);

  const ITEM_HEIGHT = 48;

  const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    onClose();
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, option: KeyValue): void => {
    event.stopPropagation();
    onClick(option.key);
  };

  let sections = options
    .filter(({ section }, i, self) => section && self.findIndex(({ section: s }) => s === section) === i)
    .map(({ section }) => options.filter(({ section: s }) => s === section));
  if (!sections.length) sections = [options];

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      keepMounted
      anchorEl={menuAnchorEl}
      open={menuOpen}
      onClose={handleClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...menuProps}
    >
      <MenuList ref={listRef} style={{ maxHeight: maxMenuHeight || ITEM_HEIGHT * visibleRows, overflow: 'overlay' }}>
        {topMenuOptions}
        {searchInput}
        {sections &&
          sections.map(
            (sectionOptions: KeyValue[], i, allSections: KeyValue[][]) =>
              sectionOptions &&
              sectionOptions.map((option: any, j, allOptions: any[]) => {
                const checked = checkedOptions && checkedOptions.includes(option.key);
                const selected = selectedOptions && selectedOptions.includes(option.key);
                return [
                  <CheckMenuItem
                    id={option.key}
                    key={option.key}
                    value={option}
                    checked={checked}
                    checkIcon={checkedIcon}
                    onClick={(event: React.MouseEvent<HTMLElement>): void => handleMenuItemClick(event, option)}
                    onKeyDown={onKeyDown && onKeyDown(i)}
                    selected={selected}
                  >
                    <ListItemText style={{ paddingRight: option.keyboardShortcut ? '10px' : 0 }}>
                      {option.label ||
                        (option.i18nKey && i18n.exists(option.i18nKey) && t(option.i18nKey)) ||
                        option.value}
                    </ListItemText>
                    {option.keyboardShortcut && <div color="text.secondary">{option.keyboardShortcut}</div>}
                  </CheckMenuItem>,
                  j === allOptions.length - 1 && i !== allSections.length - 1 ? <Divider /> : null,
                ];
              }),
          )}
        {bottomMenuOptions}
      </MenuList>
      {pinnedBottomOptions}
    </Menu>
  );
};
