import DOMPurify from 'dompurify';
import { decode } from 'he';
import { defaultAllowedHTMLTags } from '../constants/Input';

export const sanitizeInput = (input: string, allowedTags: string[] = defaultAllowedHTMLTags): string => {
  const decoded = decode(DOMPurify.sanitize(input, { ALLOWED_TAGS: allowedTags }));
  // In the case of a closing number tag (e.g. </1>), DOMPurify will convert it to <!-- 1 -->.
  // This is a workaround to convert it back to the original format.
  return decoded.replace(/<!--\s*(\d{1,2})\s*-->/g, '</$1>');
};
