import React from 'react';
import { connect } from 'react-redux';
import { SignUpFormFields } from '../constants/FormFields';
import { AppState } from '../types/AppState';
import { SignUpForm, FormData } from './SignUpForm';

interface StateProps {
  initialValues: FormData;
}

const SignUpComponent: React.FC<StateProps> = ({ initialValues }: StateProps) => (
  <SignUpForm initialValues={initialValues} />
);

const mapStateToProps = ({ signUp: { firstName, lastName } }: AppState): StateProps => ({
  initialValues: {
    [SignUpFormFields.FirstName]: firstName,
    [SignUpFormFields.LastName]: lastName,
  } as FormData,
});

export const SignUp = connect(mapStateToProps)(SignUpComponent);
