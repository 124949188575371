import React from 'react';
import { styled } from '@mui/material/styles';
import { WhatsNew } from '../types/WhatsNew';
import { CellContent } from './CellContent';

// nesting a fixed table allows us to avoid hard-coding widths on the parent table
// stackoverflow.com/questions/14826992/text-overflow-ellipsis-in-table-cell-without-width
const Table = styled('table')({
  width: '100%',
  tableLayout: 'fixed',
});

const TD = styled('td')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

type Props = {
  row: WhatsNew;
};

export const MessageCell: React.FC<Props> = ({ row }: Props) => (
  <Table>
    <tbody>
      <tr>
        <TD>
          <CellContent disabled={row.readonly}>{row.message}</CellContent>
        </TD>
      </tr>
    </tbody>
  </Table>
);
