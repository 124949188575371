import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { SignUpState } from '../types/SignUpState';

// Action types

export enum SignUpActionTypes {
  SET_SIGN_UP_STATE = 'SET_SIGN_UP_STATE',
}

// Reducer

export const INITIAL_SIGN_UP_STATE: SignUpState = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  authData: undefined,
};

export const signUpReducer: Reducer<SignUpState> = (state = INITIAL_SIGN_UP_STATE, reducerAction) => {
  switch (reducerAction.type) {
    case SignUpActionTypes.SET_SIGN_UP_STATE: {
      const {
        payload: { authData, email, firstName, lastName },
      } = reducerAction;

      return { ...state, authData, email, firstName, lastName };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const setSignUpState = (signUpState: SignUpState): AnyAction =>
  action(SignUpActionTypes.SET_SIGN_UP_STATE, signUpState);
