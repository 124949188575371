import { Group, GroupSettings } from '../types/Group';

export const defaultGroupSettings: GroupSettings = {
  dealerNotifications: false,
  salesRepNotifications: false,
};

export const unknownGroup: Group = {
  updating: false,
  groupName: '',
  groupId: '',
  configurators: [],
  settings: defaultGroupSettings,
};

export enum GroupCacheTagType {
  Group = 'group', // eslint-disable-line @typescript-eslint/no-shadow
  Groups = 'groups',
}
