import { OrderStatusChips } from '../constants/OrderStatus';
import { OrderStatusChip } from '../types/OrderStatus';

/**
 * Takes a hex color string and looks up the corresponding OrderStatusChip
 *
 * @param color
 */
export function getOrderStatusChipFromBackgroundColor(color: string): OrderStatusChip {
  const orderStatusChip = OrderStatusChips.find((chip) => chip.backgroundColor.toLowerCase() === color.toLowerCase());

  if (!orderStatusChip) {
    throw new Error(`chip not found for: ${JSON.stringify(color)}`);
  }

  return orderStatusChip;
}
