import { Grid2 as Grid, Button, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { useAppSelector, useAppDispatch } from '../hooks';
import { SearchType, SearchOptions } from '../constants/ClientData';
import { I18nKeys } from '../constants/I18nKeys';
import { setSearchOptions } from '../ducks/clientDataSlice';
import { cantEditBranchData } from '../utils/clientDataUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';

interface Props {
  onReplace: (replaceAll: boolean) => void;
}

export const ClientDataSearchButtons: React.FC<Props> = ({ onReplace }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientDataBranch,
    isCreatingBranch,
    search: {
      type: searchType = SearchType.AllTables,
      options: searchOptions = {},
      result: { ids: resultIds = [] } = {},
    } = {},
  } = useAppSelector((state: AppState) => state?.clientData);
  const { activeBranches, isLoadingClientTables, isLoadingClientTableColumns } = useClientDataRepo({
    useBranches: true,
    useClientTables: true,
    useClientTablesColumns: true,
  });

  const replaceUnavailable =
    [SearchType.AllVendorsCurrentTable, SearchType.AllVendorsAllTables].includes(searchType) || !resultIds.length;

  const cantEditData =
    isLoadingClientTables ||
    isLoadingClientTableColumns ||
    isCreatingBranch ||
    cantEditBranchData(clientDataBranch, activeBranches);

  const searchButtons: {
    i18nKey: I18nKeys;
    onClick: () => void;
    disabled?: boolean;
    variant?: 'contained' | 'outlined' | 'text';
  }[] = [
    {
      i18nKey: searchOptions[SearchOptions.FindAll] ? I18nKeys.FindOneButton : I18nKeys.FindAllButton,
      disabled: replaceUnavailable,
      onClick: () => dispatch(setSearchOptions({ [SearchOptions.FindAll]: !searchOptions[SearchOptions.FindAll] })),
    },
    ...(searchOptions[SearchOptions.ShowReplace]
      ? [
          {
            i18nKey: I18nKeys.ReplaceAllButton,
            onClick: () => onReplace(true),
            disabled: replaceUnavailable || cantEditData,
          },
          {
            i18nKey: I18nKeys.ReplaceOneButton,
            onClick: () => onReplace(false),
            disabled: replaceUnavailable || cantEditData,
          },
        ]
      : [
          {
            i18nKey: I18nKeys.EnableReplaceButton,
            onClick: () => dispatch(setSearchOptions({ ...searchOptions, [SearchOptions.ShowReplace]: true })),
            disabled: replaceUnavailable || cantEditData,
          },
        ]),
  ];

  return (
    <Grid style={{ width: 'auto', alignSelf: 'self-end' }}>
      {searchButtons.map((button) =>
        button.disabled ? (
          <Tooltip key={button.i18nKey} title={t(I18nKeys.ClientDataSearchButtonDisabledTooltip)}>
            <span>
              <Button disabled key={button.i18nKey} variant={button.variant}>
                <div>{t(button.i18nKey)}</div>
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button key={button.i18nKey} onClick={button.onClick} variant={button.variant}>
            <div>{t(button.i18nKey)}</div>
          </Button>
        ),
      )}
    </Grid>
  );
};
