/**
 * Takes phone numbers and attempts to format them in a consistent manner. If the format can't be guessed the number
 * is returned as is.
 *
 * Examples:
 *  '1-208-867-5309' -> '(208) 867-5309',
 *  '208-867-5309'   -> '(208) 867-5309',
 *  '867-5309'       -> '867-5309'
 *
 * @param phoneNumber
 */
export const formatPhoneNumber = (phoneNumber?: string): string => {
  if (!phoneNumber) return '';

  let formattedNumber = phoneNumber;
  const numbersOnly = phoneNumber.replace(/[^0-9]/g, '');
  if (numbersOnly.length === 11 && numbersOnly[0] === '1') {
    formattedNumber = `(${numbersOnly.substring(1, 4)}) ${numbersOnly.substring(4, 7)}-${numbersOnly.substring(7)}`;
  } else if (numbersOnly.length === 10) {
    formattedNumber = `(${numbersOnly.substring(0, 3)}) ${numbersOnly.substring(3, 6)}-${numbersOnly.substring(6)}`;
  } else if (numbersOnly.length === 7) {
    formattedNumber = `${numbersOnly.substring(0, 3)}-${numbersOnly.substring(3)}`;
  }
  return formattedNumber;
};
