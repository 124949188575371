/* eslint-disable react/jsx-props-no-spreading */
import { Column, ICellRendererParams } from 'ag-grid-community';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Theme, Tooltip } from '@mui/material';
import Dropzone, { FileRejection, ErrorCode } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { hasCellMetadataProperty, updateValues } from '../utils/clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { CellMetadataProperty } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useGetClientDataTableDataQuery } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { config } from '../config/config';
import { I18nKeys } from '../constants/I18nKeys';
import { S3Buckets } from '../constants/S3';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  IDEAROOM_CLIENT_ID,
  getVendorFromClientId,
  mapClientAndDataTypeAndTableToUndoStackId,
} from '../utils/clientIdUtils';
import { s3Api } from '../services/s3Api';
import { toBase64 } from '../utils/fileUtils';
import { SystemGroups } from '../constants/SystemGroups';

const useStyles = makeStyles<Theme>((theme) => ({
  noteHandle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderBottom: '7px solid transparent',
    borderRight: '7px solid #4994EC',
    right: '-1px',
    top: '0px',
  },
  indexColumn: {
    backgroundColor: 'var(--ag-header-background-color)',
    justifyContent: 'center',
  },
  checkbox: {
    padding: '0px',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
  },
  image: {
    height: '100%',
  },
  dropzoneDiv: {
    height: '100%',
  },
}));

export const ClientDataImageCell: React.FC<ICellRendererParams> = ({
  data,
  column,
  value = '',
}: ICellRendererParams) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const rowId = data[ClientDataFixedColumns.RowId];
  const columnId = (column as Column).getColId();
  const dispatch = useAppDispatch();
  const { clientId, selectedTable, clientDataType } = useAppSelector((state) => state?.clientData);

  const { cellMetadata } = useClientDataRepo({ useCellMetadata: true });
  const hasNote = hasCellMetadataProperty(cellMetadata, rowId, columnId, CellMetadataProperty.Note);
  const imageFilenameIsFullUrl = value && value.startsWith('http');

  const { data: imageRefTableData = [] } = useGetClientDataTableDataQuery(
    {
      dataType: ClientDataType.Reference,
      branch: ClientDataBranch.Main,
      clientId: IDEAROOM_CLIENT_ID,
      groupId: SystemGroups.IdeaRoom,
      table: 'image',
    },
    { skip: imageFilenameIsFullUrl || !value },
  );

  const imgAssetPath = imageRefTableData.find((imageRef) => imageRef.file === value)?.fileUrl || value;

  let imgSrc: string | undefined;

  if (imageFilenameIsFullUrl || imgAssetPath) {
    // FIXME: Dialog is the only table that stores images in a different bucket)
    if (selectedTable === 'dialog') {
      imgSrc = imageFilenameIsFullUrl
        ? value
        : `${config.assets.VENDOR_ASSETS_ENDPOINT}/${getVendorFromClientId(clientId)}/${value}`;
    } else {
      imgSrc = imageFilenameIsFullUrl ? value : `${config.assets.REFERENCE_ASSETS_ENDPOINT}/${imgAssetPath}`;
    }
  }

  const handleOnDrop = async (newImageFile: File[]) => {
    if (newImageFile.length > 0) {
      try {
        const base64 = await toBase64(newImageFile[0]);

        const name = `upload-${Date.now()}-${newImageFile[0].name}`;
        const fileLink = await dispatch(
          s3Api.endpoints.uploadFile.initiate({
            bucket: S3Buckets.Assets,
            path: `images/`,
            file: {
              name,
              content: base64,
              contentType: newImageFile[0].type,
            },
          }),
        )
          .unwrap()
          .then(({ fileUrl }) => fileUrl);
        const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, selectedTable);

        updateValues(
          clientDataTableId,
          [
            {
              table: selectedTable,
              data,
              column: columnId,
              oldValue: value,
              newValue: fileLink,
            },
          ],
          cellMetadata,
          dispatch,
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toast.error(t(I18nKeys.ClientDataUploadImageUnknownError));
      }
    }
  };

  const handleOnDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      switch (fileRejections[0].errors[0].code) {
        case ErrorCode.FileTooLarge:
          toast.error(t(I18nKeys.ClientDataUploadImageTooLarge));
          break;
        case ErrorCode.FileInvalidType:
          toast.error(t(I18nKeys.ClientDataUploadImageInvalidType));
          break;
        default:
          toast.error(t(I18nKeys.ClientDataUploadImageUnknownError));
          break;
      }
    }
  };

  return (
    <span>
      <Dropzone
        accept={{
          'image/jpeg': [],
          'image/png': [],
        }}
        onDrop={handleOnDrop}
        onDropRejected={handleOnDropRejected}
        multiple={false}
        maxSize={1000000} // 1 MB
        noClick
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.dropzoneDiv}>
            <input {...getInputProps()} />
            {imgSrc && (
              <Tooltip
                enterDelay={300}
                disableInteractive
                title={<img src={imgSrc} alt="" style={{ maxHeight: '260px', maxWidth: '404px' }} />}
                classes={{ tooltip: classes.tooltip }}
              >
                <img src={imgSrc} alt="invalid link" className={classes.image} />
              </Tooltip>
            )}
          </div>
        )}
      </Dropzone>
      {hasNote && <div className={classes.noteHandle} />}
    </span>
  );
};
