import { TableFilterType } from '../constants/Viewer';
import { AppState } from '../types/AppState';
import { SelectedFilterValues, GroupFilter } from '../types/CustomFilter';

export const getGroupFilters = (appState: AppState): GroupFilter[] | undefined => appState.viewer.groupFilters;

export const getGroupFilterParts = (
  appState: AppState,
): {
  groupId: string | undefined;
  groupFilters: GroupFilter[];
  tableFilterType: TableFilterType;
  filterType: string | undefined;
  selectedFilterValues: SelectedFilterValues[];
} => ({
  groupId: appState.currentUser.group ? appState.currentUser.group.groupId : undefined,
  groupFilters: appState.viewer.groupFilters,
  tableFilterType: appState.viewer.tableFilterType,
  filterType: appState.viewer.filterType,
  selectedFilterValues: appState.viewer.selectedFilterValues,
});
