import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatus } from '../constants/User';
import { Dialogs } from '../constants/Dialogs';
import { removeMember, sendNewMemberInvitation, resendMemberInvitation, resetMemberPassword } from '../ducks/group';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { GroupMember } from '../types/GroupMember';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { isIdeaRoomUser } from '../utils/userUtils';
import { AppState } from '../types/AppState';
import { unknownUser } from '../types/User';
import { initiateImpersonation } from '../ducks/currentUserSlice';

interface Props {
  member: GroupMember;
}

export const GroupMemberMenu: React.FC<Props> = ({ member }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { user = unknownUser } = useAppSelector((state: AppState) => state?.currentUser);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="group-member-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="user-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {member.status === UserStatus.Confirmed && (
          <MenuItem>
            <ListItemText
              onClick={(event: React.MouseEvent<HTMLElement>): void => {
                dispatch(resetMemberPassword(member));
                handleClose(event);
              }}
              primary={t(I18nKeys.GroupMemberMenuResetPassword)}
            />
          </MenuItem>
        )}

        {member.status === UserStatus.ResetRequired && (
          <MenuItem>
            <ListItemText
              onClick={(event: React.MouseEvent<HTMLElement>): void => {
                dispatch(sendNewMemberInvitation(member));
                handleClose(event);
              }}
              primary={t(I18nKeys.GroupMemberMenuSendInvitation)}
            />
          </MenuItem>
        )}

        {member.status === UserStatus.ForceChangePassword && (
          <MenuItem>
            <ListItemText
              onClick={(event: React.MouseEvent<HTMLElement>): void => {
                dispatch(resendMemberInvitation(member));
                handleClose(event);
              }}
              primary={t(I18nKeys.GroupMemberMenuResendInvitation)}
            />
          </MenuItem>
        )}

        {isIdeaRoomUser(user) && (
          <MenuItem>
            <ListItemText
              onClick={(event: React.MouseEvent<HTMLElement>): void => {
                dispatch(initiateImpersonation(member));
                handleClose(event);
              }}
              primary={t(I18nKeys.GroupMemberMenuImpersonate)}
            />
          </MenuItem>
        )}

        <MenuItem>
          <ListItemText
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              dispatch(
                openConfirmationDialog(
                  [removeMember(member)],
                  [],
                  t(I18nKeys.GroupMemberMenuConfirmDeleteTitle),
                  t(I18nKeys.GroupMemberMenuConfirmDeleteUserMessage, { userEmail: member?.email }),
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
              handleClose(event);
            }}
            primary={t(I18nKeys.GroupMemberMenuDelete)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
