import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { splitByMatches } from '../utils/searchUtils';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getPricingSheetLabelParts } from '../utils/pricingSheetUtils';
import { PricingSheet } from '../types/PricingSheet';

type Props = {
  classes?: Record<string, string>;
  pricingSheet: PricingSheet;
  setRef: (instance: HTMLSpanElement | null) => void;
  searchTerm?: string;
};

export const PricingAttributesLabel: React.FC<Props> = ({ classes = {}, pricingSheet, setRef, searchTerm = '' }) => {
  const { t } = useTranslation();

  const {
    viewer: { selectedPricingTabId: pricingTab = '' },
  } = useAppSelector((state: AppState) => state || {});

  return (
    <ul className={classes.list}>
      {/* Per design the title is shown with a single bullet point, sorted alphabetically */}
      {getPricingSheetLabelParts(pricingSheet, pricingTab, t).map((label = '') => (
        <li key={label}>
          <Typography ref={setRef} className={classes.attributes} variant="body2">
            {splitByMatches(label, searchTerm, {}).map((part, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${label}-${index}`} style={{ backgroundColor: part.highlight ? '#FDFF80' : undefined }}>
                {part.text}
              </span>
            ))}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
