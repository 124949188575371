import { Permission } from '@idearoom/types';
import { i18n } from '../i18n';
import { SelectedFilterValues } from '../types/CustomFilter';
import { I18nKeys } from './I18nKeys';

export const getPermissionLabel = (permission: Permission): string | undefined => {
  switch (permission) {
    case Permission.Admin: {
      return i18n.t(I18nKeys.PermissionAdmin);
    }
    case Permission.Manager: {
      return i18n.t(I18nKeys.PermissionManager);
    }
    case Permission.Sales: {
      return i18n.t(I18nKeys.PermissionSales);
    }
    case Permission.SuperUser: {
      return i18n.t(I18nKeys.PermissionSuperUser);
    }
    default: {
      return undefined;
    }
  }
};

export const availablePermissions: SelectedFilterValues[] = [
  { key: Permission.Admin, name: getPermissionLabel(Permission.Admin) || i18n.t(I18nKeys.PermissionAdmin) },
  { key: Permission.Manager, name: getPermissionLabel(Permission.Manager) || i18n.t(I18nKeys.PermissionManager) },
  { key: Permission.Sales, name: getPermissionLabel(Permission.Sales) || i18n.t(I18nKeys.PermissionSales) },
  {
    key: Permission.SuperUser,
    name: getPermissionLabel(Permission.SuperUser) || i18n.t(I18nKeys.PermissionSuperUser),
  },
];
