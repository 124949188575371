import { IconButton, Tooltip, useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import revertIcon from '../images/revertIcon.svg';

export const ClientDataChangesSummaryDiffRevertCell: React.FC<ICellRendererParams> = ({
  valueFormatted,
  context,
  node,
}: ICellRendererParams) => {
  const theme = useTheme();

  if (Array.isArray(valueFormatted)) {
    return (
      <>
        <div
          style={{ width: 'calc(100% - 28px)', display: 'inline-block', overflow: 'auto', textOverflow: 'ellipsis' }}
        >
          {valueFormatted.map((part, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                // eslint-disable-next-line no-nested-ternary
                backgroundColor: part.added
                  ? theme.palette.diffAdd.main
                  : part.removed
                  ? theme.palette.diffRemove.main
                  : undefined,
              }}
            >
              {part.value}
            </span>
          ))}
        </div>
        <Tooltip title="Revert">
          <IconButton
            style={{ position: 'absolute', right: '5px', padding: '0' }}
            size="small"
            onClick={() => context?.revertChange(node)}
          >
            <img alt="Revert" src={revertIcon} className="ag-icon" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return <span>{valueFormatted}</span>;
};
