import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog, setConfigurator, setForm } from '../ducks/dialogSlice';
import { removeConfiguratorFromGroup } from '../ducks/group';
import { Configurator } from '../types/Configurator';
import { useAppDispatch } from '../hooks';
import { ConfiguratorFormType } from '../constants/Configurator';

interface Props {
  configurator: Configurator;
}

export const ConfiguratorMenu: React.FC<Props> = ({ configurator }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const openConfiguratorDialog = (): void => {
    dispatch(setConfigurator(configurator));
    dispatch(setForm(ConfiguratorFormType.EditConfigurator));
    dispatch(openDialog({ dialog: Dialogs.Configurator }));
  };

  const confirmRemoval = (): void => {
    dispatch(
      openConfirmationDialog(
        [removeConfiguratorFromGroup(configurator)],
        [],
        t(I18nKeys.ConfiguratorMenuConfirmRemoveTitle),
        t(I18nKeys.ConfiguratorMenuConfirmRemoveMessage),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  };

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="user-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="user-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {[
          {
            label: t(I18nKeys.ConfiguratorMenuEditOption),
            onClick: openConfiguratorDialog,
          },
          {
            label: t(I18nKeys.ConfiguratorMenuRemoveOption),
            onClick: confirmRemoval,
          },
        ].map(({ label, onClick }) => (
          <MenuItem key={label}>
            <ListItemText
              onClick={() => {
                onClick();
                handleClose();
              }}
              primary={label}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
