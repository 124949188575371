import React from 'react';
import { AnyAction } from 'redux';
import { Field, Form, InjectedFormProps, Validator, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PricingBaseAddSizeFormFields } from '../constants/FormFields';
import { AppState } from '../types/AppState';

export interface FormData {
  [PricingBaseAddSizeFormFields.Width]: number;
  [PricingBaseAddSizeFormFields.Length]: number;
}

interface CustomProps {
  hasPriceWithSize: boolean;
  hasPriceWithZero: boolean;
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const useStyles = makeStyles(() => ({
  labels: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '16px',
    alignSelf: 'end',
    paddingBottom: '8px',
  },
  errorField: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    color: '#F44336',
    paddingLeft: '16px',
    paddingTop: '4px',
  },
  widthField: {
    width: '90px',
  },
  lengthFiled: {
    width: '90px',
    marginLeft: '16px',
  },
}));

const hasPriceWithSizeValidator: Validator = (value: string | number, allValues: FormData, props: FormProps) => {
  if (props.hasPriceWithSize || value === 0) {
    return 'error';
  }
  return undefined;
};

const PricingBaseAddSizeFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  hasPriceWithSize,
  hasPriceWithZero,
}: FormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { useMetricUnits } = useAppSelector((state: AppState) => state?.viewer);

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.PricingBaseAddSize}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  const parseMeasurement = (val: string) => {
    if (Number.isNaN(val)) {
      return 0;
    }
    return Number(val);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex' }}>
        <Field
          className={classes.widthField}
          autoFocus
          type="number"
          autoComplete="off"
          validate={hasPriceWithSizeValidator}
          parse={parseMeasurement}
          component={InputField}
          hideHelperText
          errorWithoutTouch
          label={t(I18nKeys.PricingBaseAddSizeDialogWidth)}
          name={PricingBaseAddSizeFormFields.Width}
          variant="filled"
          normalize={(value: any, previousValue: any, allValues: any[]) => (value < 0 ? previousValue : value)}
        />
        <Typography className={classes.labels}>
          {t(useMetricUnits ? I18nKeys.PricingbaseaddSizeFormMetricUnit : I18nKeys.PricingbaseaddSizeFormImperialUnit)}
        </Typography>
        <Typography className={classes.labels}>×</Typography>
        <Field
          className={classes.lengthFiled}
          autoComplete="off"
          type="number"
          validate={hasPriceWithSizeValidator}
          parse={parseMeasurement}
          hideHelperText
          errorWithoutTouch
          component={InputField}
          label={t(I18nKeys.PricingBaseAddSizeDialogLength)}
          name={PricingBaseAddSizeFormFields.Length}
          variant="filled"
          normalize={(value: any, previousValue: any, allValues: any[]) => (value < 0 ? previousValue : value)}
        />
        <Typography className={classes.labels}>
          {t(useMetricUnits ? I18nKeys.PricingbaseaddSizeFormMetricUnit : I18nKeys.PricingbaseaddSizeFormImperialUnit)}
        </Typography>
      </div>

      {hasPriceWithSize && (
        <Typography className={classes.errorField}>{t(I18nKeys.PricingbaseaddSizeFormErrorSizeExists)}</Typography>
      )}
      {hasPriceWithZero && (
        <Typography className={classes.errorField}>{t(I18nKeys.PricingbaseaddSizeFormErrorZero)}</Typography>
      )}
    </Form>
  );
};

export const PricingBaseAddSizeForm = reduxForm<FormData, CustomProps>({
  form: Forms.PricingBaseAddSize,
})(PricingBaseAddSizeFormComponent);
