import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { IconButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { useAppDispatch } from '../hooks';
import { goToCellRange } from '../ducks/clientDataSlice';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';

export const ClientDataGoToCellRange: React.FC<ICellRendererParams> = ({
  data,
  context = {},
  columnApi,
}: ICellRendererParams) => {
  const dispatch = useAppDispatch();

  const rowId = data[ClientDataFixedColumns.RowId];
  const [col] = columnApi.getAllDisplayedVirtualColumns();
  const colId = col.getColId();

  const { rootGridApi, rootGridColApi, selectedTable: table } = context;

  const goToCellInRootGrid = (): void => {
    if (rootGridApi && rootGridColApi && table) {
      dispatch(
        goToCellRange({
          table,
          location: { startRowId: rowId, endRowId: rowId, columns: [ClientDataFixedColumns.Index] },
          rootGridApi,
          rootGridColApi,
        }),
      );
    }
  };

  return (
    <IconButton
      disabled={!rowId || !colId || !rootGridApi || !table}
      style={{ padding: 0, height: '100%', width: '100%', display: 'flex' }}
      onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        goToCellInRootGrid();
      }}
    >
      <OpenInNew fontSize="small" style={{ flex: 'auto' }} />
    </IconButton>
  );
};
