export const DEFAULT_SCENE_KEY = 'default';

export interface SceneEnvironment {
  clientId?: string;
  key: string;
  label: string;
  fileUrl: string;
  previewUrl: string;
  maxCameraDistanceMultiplier?: number;
  maxDiagonalBuildingLength?: number;
  updating?: boolean;
  isNew?: boolean;
}
