export const defaultAllowedHTMLTags = [
  '#comment',
  'a',
  'abbr',
  'acronym',
  'b',
  'blockquote',
  'br',
  'code',
  'div',
  'em',
  'i',
  'img',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'table',
  'td',
  'tr',
  'ul',
];
