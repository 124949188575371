import React from 'react';
import { Chip, Divider, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BlueMuted, DarkText, GrayMuted } from '../constants/ChipColors';
import { OrderDealer } from '../types/OrderDealer';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { SearchInput } from './SearchInput';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';

interface Props {
  readonly: boolean;
  dealer: OrderDealer;
  options: OrderDealer[];
  onOrderDealerChange(orderDealer: OrderDealer): void;
}

export const notAssigned: OrderDealer = { key: '', name: i18n.t(I18nKeys.OrderDealerSelectorNotAssigned) };

export const OrderDealerSelector: React.FC<Props> = ({
  readonly,
  dealer = notAssigned,
  options,
  onOrderDealerChange,
}: Props) => {
  const [chipLabel, setChipLabel] = React.useState(dealer.name);
  const [dealerKey, setDealerKey] = React.useState(dealer.key);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();
  const [filteredOptions, setFilteredOptions] = React.useState<OrderDealer[]>(options);
  const [searchTerm, setDialogSearchTerm] = React.useState<string>('');

  React.useEffect(() => {
    // don't bother searching until there's at least 2 characters
    if (searchTerm.length < 2) {
      setFilteredOptions(options);
      return;
    }
    const tests = [
      (option: OrderDealer): boolean => stringToLowerIncludes(option.name, searchTerm),
      (option: OrderDealer): boolean => stringToLowerIncludes(option.key, searchTerm),
    ];

    setFilteredOptions(options.filter((option) => tests.some((test) => test(option))));
  }, [options, searchTerm]);

  const resetSearchTerm = (): void => setDialogSearchTerm('');

  const handleChipClicked = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setMenuAnchorEl(anchorElRef.current);
    setMenuOpen(!menuOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any): void => {
    event.stopPropagation();
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (orderDealer: OrderDealer): void => {
    onOrderDealerChange(orderDealer);
    if (!readonly) {
      setChipLabel(orderDealer.name);
      setDealerKey(orderDealer.key);
    }
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const formatLabel = (label: string | undefined): string => {
    if (!label) return '';
    return label.length > 20 ? label.slice(0, 20).concat('...') : label;
  };

  return (
    <>
      <Chip
        deleteIcon={<ArrowDropDownIcon style={{ color: DarkText }} />}
        clickable
        onDelete={handleChipClicked}
        onClick={handleChipClicked}
        label={formatLabel(chipLabel)}
        ref={anchorElRef}
        style={{ backgroundColor: dealerKey ? BlueMuted : GrayMuted }}
      />
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleClose}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => {
            event.stopPropagation();
          }}
        >
          {options.length > 10 && (
            <SearchInput
              searchTerm={searchTerm}
              startAdornment={<SearchIcon />}
              variant="outlined"
              onClearClick={resetSearchTerm}
              onChange={setDialogSearchTerm}
            />
          )}
        </MenuItem>
        <MenuItem
          key=""
          onClick={(event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
            handleMenuItemClick(notAssigned);
          }}
        >
          {notAssigned.name}
        </MenuItem>
        <Divider />
        {filteredOptions.map((option) => (
          <MenuItem
            key={option.key}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              event.stopPropagation();
              handleMenuItemClick(option);
            }}
          >
            {option.name ? option.name : option.key}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
