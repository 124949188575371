import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { SecondaryActionsState } from '../types/SecondaryActionsState';

export enum SecondaryActionsActionTypes {
  SET_SECONDARY_ACTIONS = 'SET_SECONDARY_ACTIONS',
}

export const INITIAL_SECONDARY_ACTIONS_STATE: SecondaryActionsState = {
  secondaryActions: [],
};

export const secondaryActionsReducer: Reducer<SecondaryActionsState> = (
  state = INITIAL_SECONDARY_ACTIONS_STATE,
  secondaryActionsAction,
) => {
  switch (secondaryActionsAction.type) {
    case SecondaryActionsActionTypes.SET_SECONDARY_ACTIONS: {
      const {
        payload: { secondaryActions },
      } = secondaryActionsAction;
      return { ...state, secondaryActions };
    }
    default: {
      return state;
    }
  }
};

export const setSecondaryActions = (secondaryActions: JSX.Element[]): AnyAction =>
  action(SecondaryActionsActionTypes.SET_SECONDARY_ACTIONS, {
    secondaryActions,
  });
