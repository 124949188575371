import React from 'react';

export const DropdownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M8.70627 11.9139L11.2963 14.5039C11.6863 14.8939 12.3163 14.8939 12.7063 14.5039L15.2963 11.9139C15.9263 11.2839 15.4763 10.2039 14.5863 10.2039H9.40627C8.51627 10.2039 8.07627 11.2839 8.70627 11.9139Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
