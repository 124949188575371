import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AppState } from '../types/AppState';
import { startPaymentIntegration } from '../ducks/integrationSlice';
import { integrationApi } from '../services/integrationApi';
import { unknownGroup } from '../constants/Group';
import { closeDialog } from '../ducks/dialogSlice';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';

export const integrationListener = createListenerMiddleware<AppState>();

integrationListener.startListening({
  actionCreator: startPaymentIntegration,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const {
      currentUser: { group: { groupId, configurators } = unknownGroup },
      viewer: { selectedClientId = '' },
    } = state;
    const allClientIds = (configurators || [])
      ?.map((config) => mapConfiguratorToClientId(config))
      .filter((clientId) => clientId !== selectedClientId);
    // add selectedClientId as first to be used as default to load vendor data
    const clientIds = [selectedClientId, ...allClientIds];

    const createPaymentIntegration = dispatch(
      integrationApi.endpoints.createPaymentIntegration.initiate({
        clientIds,
        groupId,
      }),
    );
    createPaymentIntegration.unsubscribe();
    const { data } = await createPaymentIntegration;
    const { onboardingUrl } = data || {};

    if (onboardingUrl) {
      window.open(onboardingUrl, '_self');
    }
    dispatch(closeDialog());
  },
});
