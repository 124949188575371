import { DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useGetClientPublishedVersionsQuery } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import {
  clientHasDatasetsToUpdate,
  setClientDataNewSupplierUpdatesDialogSkip,
  shouldSkipClientDataNewSupplierUpdatesDialog,
} from '../utils/clientDataUtils';
import { unknownGroup } from '../constants/Group';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

export const ClientDataNewSupplierUpdatesDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientDataType, clientDataBranch, clientId } = useAppSelector((state) => state.clientData);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const { currentData: clientPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, groupId, dataType: clientDataType },
    {
      skip:
        !clientId ||
        !groupId ||
        !clientDataType ||
        clientDataType !== ClientDataType.Vendor ||
        clientDataBranch !== ClientDataBranch.Main,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );
  const openDialogKey = useAppSelector((state) => state.dialog.key);

  useEffect(() => {
    if (
      !openDialogKey &&
      clientHasDatasetsToUpdate(clientPublishedVersions) &&
      !shouldSkipClientDataNewSupplierUpdatesDialog(clientId, clientPublishedVersions)
    ) {
      setClientDataNewSupplierUpdatesDialogSkip(clientId, clientPublishedVersions);
      dispatch(openDialog({ dialog: Dialogs.ClientDataNewSupplierUpdates }));
    }
  }, [clientPublishedVersions]);

  return (
    <Dialog dialogKey={Dialogs.ClientDataNewSupplierUpdates} maxWidth="xs">
      <DialogContent className={classes.dialogContent}>
        <Typography>
          <Trans i18nKey={I18nKeys.ClientDataNewSupplierUpdatesDialogText as string} />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={() => {
            dispatch(closeDialogFunc());
          }}
          color="success"
        >
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
