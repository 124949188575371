import { Box, Card, CardActions, CardContent, CircularProgress, Hidden } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { setSceneEnvironmentDialog } from '../ducks/settings';
import { Header, Table } from './Table';
import { DEFAULT_SCENE_KEY, SceneEnvironment } from '../types/SceneEnvironment';
import { SceneEnvironmentMenu } from './SceneEnvironmentMenu';
import { I18nKeys } from '../constants/I18nKeys';
import { SceneEnvironmentIconPreview } from './SceneEnvironmentIconPreview';

interface SceneEnvironmentActionCellProps {
  row: SceneEnvironment;
  showSceneEnvironmentMenu: boolean;
}

const getSceneEnvironmentActionComponent = (): React.FC<any> => {
  const Cell: React.FC<SceneEnvironmentActionCellProps> = ({
    row,
    showSceneEnvironmentMenu = true,
  }: SceneEnvironmentActionCellProps) => {
    const sceneEnvironment = row;
    const { updating, key } = sceneEnvironment;
    return (
      <div style={{ display: 'flex' }}>
        {updating && <CircularProgress size={24} color="primary" />}

        {!updating && showSceneEnvironmentMenu && key !== DEFAULT_SCENE_KEY && (
          <SceneEnvironmentMenu sceneEnvironment={sceneEnvironment} />
        )}
      </div>
    );
  };

  return Cell;
};

interface SceneEnvironmentIconPreviewCellProps {
  row: SceneEnvironment;
}

const getSceneEnvironmentIconPreviewComponent = (): React.FC<any> => {
  const Cell: React.FC<SceneEnvironmentIconPreviewCellProps> = ({ row }: SceneEnvironmentIconPreviewCellProps) => {
    const sceneEnvironment = row;
    const { previewUrl } = sceneEnvironment;
    return <SceneEnvironmentIconPreview url={previewUrl} />;
  };

  return Cell;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: { flex: 1 },
  button: {
    color: theme.palette.text.primary,
  },
  cards: {
    flex: 1,
    paddingBottom: '70px',
  },
  card: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardHeader: { paddingBottom: '0' },
  cardActions: { paddingTop: '0' },
  cardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  cardContentLine: {
    color: theme.palette.text.secondary,
  },
}));

interface Props {
  loading: boolean;
  clientId: string;
  sceneEnvironmentList: SceneEnvironment[];
}

export const SceneEnvironmentTable: React.FC<Props> = ({ clientId, loading, sceneEnvironmentList }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openSceneEnvironmentDialog = (sceneEnvironment: SceneEnvironment): void => {
    dispatch(setSceneEnvironmentDialog(clientId, sceneEnvironment));
    dispatch(openDialog({ dialog: Dialogs.SceneEnvironment }));
  };

  const headers: Header[] = [
    { i18nKey: I18nKeys.SceneEnvironmentFieldKey, property: 'key' },
    { i18nKey: I18nKeys.SceneEnvironmentFieldLabel, property: 'label' },
    { i18nKey: I18nKeys.SceneEnvironmentFieldFileUrl, property: 'fileUrl' },
    {
      i18nKey: I18nKeys.SceneEnvironmentFieldPreviewUrl,
      property: 'previewUrl',
      CellComponent: getSceneEnvironmentIconPreviewComponent(),
    },
    { i18nKey: I18nKeys.SceneEnvironmentFieldMaxCameraDistanceMultiplier, property: 'maxCameraDistanceMultiplier' },
    { i18nKey: I18nKeys.SceneEnvironmentFieldMaxDiagonalBuildingLength, property: 'maxDiagonalBuildingLength' },
    { i18nKey: '', property: 'actions', CellComponent: getSceneEnvironmentActionComponent() },
  ];

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Table
          headers={headers}
          rows={sceneEnvironmentList}
          loading={loading}
          handleRowClick={(row): void => openSceneEnvironmentDialog(row)}
        />
      </Hidden>
      <Hidden smUp>
        <Box className={classes.cards}>
          {sceneEnvironmentList &&
            sceneEnvironmentList.length > 0 &&
            sceneEnvironmentList.map((sceneEnvironment) => {
              const ActionComponent = getSceneEnvironmentActionComponent();
              const IconPreviewComponent = getSceneEnvironmentIconPreviewComponent();
              return (
                <Card className={classes.card} key={sceneEnvironment.key}>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentLine}>{sceneEnvironment.key}</Box>
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentLine}>{sceneEnvironment.label}</Box>
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentLine}>{sceneEnvironment.fileUrl}</Box>
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <IconPreviewComponent row={sceneEnvironment} />
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentLine}>{sceneEnvironment.maxCameraDistanceMultiplier}</Box>
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentLine}>{sceneEnvironment.maxDiagonalBuildingLength}</Box>
                  </CardContent>
                  <CardActions disableSpacing className={classes.cardActions}>
                    <ActionComponent row={sceneEnvironment} showSceneEnvironmentMenu={false} />
                  </CardActions>
                </Card>
              );
            })}
        </Box>
      </Hidden>
    </div>
  );
};
