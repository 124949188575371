import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid2 as Grid, Typography, Box, FormControl } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import ColorPicker from '@idearoom/mui-color-picker';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { SiteDetail } from '../types/ClientData';
import { addSitesErrors, removeSitesError } from '../ducks/vendorDataSlice';
import { useAppDispatch } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  colorPickerContainer: {
    '&>:nth-child(2)': {
      '&>:nth-child(1)': {
        // Align lower right corner of color picker to
        // upper right corner of color option
        [theme.breakpoints.down('xl')]: {
          right: '0px',
          bottom: '55px',
        },
        // Align lower left corner of color picker to
        // lower right corner of color option
        [theme.breakpoints.up('md')]: {
          left: '100%',
          bottom: '0px',
        },
      },
    },
  },
  filledInput: {
    '& div': {
      height: '55px',
      backgroundColor: '#F0F0F0',
      padding: '12px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '& input': {
      color: 'black',
    },
  },
  swatch: {
    height: '55px',
    width: '55px',
    display: 'inline-block',
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: '#878787',
    borderWidth: '1px',
  },
  swatchControl: {
    flexBasis: '55px',
    flexGrow: 0,
    flexShrink: 0,
    marginRight: '16px',
  },
}));

interface Props {
  i18nKey: string;
  name: string;
  vendorPropChange: Function;
  data: SiteDetail | undefined;
  errors: { [props: string]: string }[];
  disabled?: boolean;
}

const sanitizeAndValidateColor = (input: string): string | null => {
  // Regex to match allowed characters in CSS color values
  const allowedColorRegex = /[#0-9a-fA-F,.\s()a-zA-Z]+/g;

  // Clean the input by removing disallowed characters
  const cleanedInput = (input.match(allowedColorRegex) || []).join('').trim();

  // Regexes for validating color formats
  const hexColorRegex = /^#([0-9a-fA-F]{3}){1,2}$/;
  const rgbColorRegex = /^rgb\(\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?)\s*\)$/;
  const rgbaColorRegex = /^rgba\(\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*([0-9]{1,3}%?),\s*(0|1|0?\.\d+)\s*\)$/;
  const namedColorRegex =
    /^(red|blue|green|yellow|black|white|gray|grey|pink|orange|purple|brown|lime|cyan|magenta|teal)$/i;

  // Validate if the cleaned string is a valid color
  if (
    hexColorRegex.test(cleanedInput) ||
    rgbColorRegex.test(cleanedInput) ||
    rgbaColorRegex.test(cleanedInput) ||
    namedColorRegex.test(cleanedInput)
  ) {
    return cleanedInput;
  }

  // Return null if the cleaned string is not a valid color
  return null;
};

export const ColorOption: React.FC<Props> = ({
  vendorPropChange,
  data = {},
  i18nKey = '',
  name = '',
  errors = [],
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const swatchId = `swatch-${name}`;
  const colorPickerId = `color-picker-${name}`;
  const value = (data as any)[name];

  const handleColorChange = (color: string): void => {
    const sanitizedColor = sanitizeAndValidateColor(color);
    if (sanitizedColor) {
      vendorPropChange(name, sanitizedColor);
    } else {
      dispatch(addSitesErrors([{ [name]: 'Invalid color value' }]));
    }
  };

  const debouncedColorChangeHandler = useMemo(() => debounce(handleColorChange, 500), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(
    () => () => {
      debouncedColorChangeHandler.cancel();
    },
    [],
  );

  useEffect(() => {
    const swatch = document.getElementById(swatchId) as any;
    const swatchStyle = swatch.style as any;
    swatchStyle.backgroundColor = value;

    const colorPicker = document.getElementById(colorPickerId) as any;
    if (colorPicker && value) {
      colorPicker.value = value.toUpperCase();
    }
  }, [value, swatchId, colorPickerId]);

  const error = errors.find((err) => err[name]);

  return (
    <Grid container size={{ xs: 12 }}>
      <Grid size={{ xs: 5, md: 6 }} className={classes.textContainer}>
        <Typography>{t(i18nKey)}</Typography>
      </Grid>
      <Grid size={{ xs: 7, md: 6 }} className={classes.textContainer}>
        <FormControl className={classes.swatchControl}>
          <Box id={swatchId} className={classes.swatch} />
        </FormControl>
        <FormControl fullWidth className={classes.colorPickerContainer}>
          <ColorPicker
            id={colorPickerId}
            name={name}
            className={classes.filledInput}
            defaultValue={value}
            value={value}
            onChange={(color: string): void => {
              dispatch(removeSitesError(name));
              debouncedColorChangeHandler(color);
            }}
            variant="standard"
            helperText={error ? error[name] : undefined}
            error={!!error}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
