import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & CheckboxProps;

export const CheckboxField: React.FC<Props> = (props: Props) => {
  const { input } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Checkbox color="primary" checked={!!input.value} {...props} {...input} />;
};
