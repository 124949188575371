import {
  PricingSurcharge,
  PricingSurchargeCalculation,
  PricingSurchargeCalculationType,
  PricingSurchargeCategory,
  PricingSurchargeCondition,
  PricingSurchargeConditionType,
  PricingSurchargeRule,
  PricingSurchargeVaryCondition,
} from '@idearoom/types';
import { PricingSurchargeError } from '../types/PricingSurcharge';
import { I18nKeys } from './I18nKeys';

export const PRICING_SURCHARGE_COLUMN = 'surcharges';

export const pricingSurchargeCalculationTypes: { value: string; label: string; dealerOption: boolean }[] = [
  { value: PricingSurchargeCalculationType.None, label: I18nKeys.PricingOptionNoChange, dealerOption: true },
  { value: PricingSurchargeCalculationType.Surcharge, label: I18nKeys.PricingOptionSurcharge, dealerOption: true },
  { value: PricingSurchargeCalculationType.LineItem, label: I18nKeys.PricingOptionLineItem, dealerOption: false },
];

export const PricingCategories: { value: string; label: string; tooltip: string }[] = [
  {
    value: PricingSurchargeCategory.BasePrice,
    label: I18nKeys.PricingCategoriesBasePrice,
    tooltip: I18nKeys.PricingCategoriesBasePriceTooltip,
  },
  {
    value: PricingSurchargeCategory.Structure,
    label: I18nKeys.PricingCategoriesStructure,
    tooltip: I18nKeys.PricingCategoriesStructureTooltip,
  },
  {
    value: PricingSurchargeCategory.DoorsAndWindows,
    label: I18nKeys.PricingCategoriesDoorsWindows,
    tooltip: I18nKeys.PricingCategoriesDoorsWindowsTooltip,
  },
  {
    value: PricingSurchargeCategory.AddOns,
    label: I18nKeys.PricingCategoriesAddOns,
    tooltip: I18nKeys.PricingCategoriesAddOnsTooltip,
  },
  {
    value: PricingSurchargeCategory.Services,
    label: I18nKeys.PricingCategoriesServices,
    tooltip: I18nKeys.PricingCategoriesServicesTooltip,
  },
  {
    value: PricingSurchargeCategory.CustomDiscountExpense,
    label: I18nKeys.PricingCategoriesCustomDiscountExpense,
    tooltip: I18nKeys.PricingCategoriesCustomDiscountExpenseTooltip,
  },
];

export const shedPricingCategories: { value: string; surchargeLabel: string; lineItemLabel: string }[] = [
  {
    value: PricingSurchargeCategory.BasePrice,
    surchargeLabel: I18nKeys.ShedPricingCategoriesSurchargeBasePrice,
    lineItemLabel: I18nKeys.ShedPricingCategoriesLineItemBasePrice,
  },
  {
    value: PricingSurchargeCategory.Subtotal,
    surchargeLabel: I18nKeys.ShedPricingCategoriesSurchargeSubtotal,
    lineItemLabel: I18nKeys.ShedPricingCategoriesLineItemSubtotal,
  },
];

export enum PricingSurchargeErrorType {
  Calculation = 'calculation',
  Condition = 'condition',
}

export const PricingSurchargeConditions: PricingSurchargeVaryCondition[] = [
  {
    value: PricingSurchargeConditionType.Length,
    label: I18nKeys.PricingSurchargeConditionLength,
    range: true,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Width,
    label: I18nKeys.PricingSurchargeConditionWidth,
    range: true,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Size,
    label: I18nKeys.PricingSurchargeConditionSize,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Style,
    label: I18nKeys.PricingSurchargeConditionStyle,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Siding,
    label: I18nKeys.PricingSurchargeConditionSiding,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Roof,
    label: I18nKeys.PricingSurchargeConditionRoofType,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Gauge,
    label: I18nKeys.PricingSurchargeConditionGauge,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.Region,
    label: I18nKeys.PricingSurchargeConditionRegion,
    range: false,
    options: [],
    excludeFromExport: false,
  },
  {
    value: PricingSurchargeConditionType.State,
    label: I18nKeys.PricingSurchargeConditionState,
    range: false,
    options: [],
    excludeFromExport: true,
  },
  {
    value: PricingSurchargeConditionType.Subtotal,
    label: I18nKeys.PricingSurchargeConditionSubtotal,
    range: true,
    options: [],
    excludeFromExport: true,
  },
];

export enum DefaultSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 0.01,
}

export enum InitialSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 1,
}

export enum NoSurchargeValues {
  AmountChange = 0,
  PercentChange = 0,
  RoundTo = 1,
}

export enum MaximumDigits {
  PercentChange = 6,
}

export const defaultSurchargeLabel = 'Material Surcharge';
export const selectAllLabel = '(Select All)';

export const defaultSurchargeCalculation: PricingSurchargeCalculation = {
  amountChange: InitialSurchargeValues.AmountChange,
  percentChange: InitialSurchargeValues.PercentChange,
  roundTo: InitialSurchargeValues.RoundTo,
};

export const defaultPricingSurcharge: PricingSurcharge = {
  key: PricingSurchargeCalculationType.None,
  categories: [PricingSurchargeCategory.BasePrice],
  label: '',
  isTaxable: true,
  affectsDeposit: true,
  calculation: defaultSurchargeCalculation,
  rules: [],
};

export const noSurchargeCalculation: PricingSurchargeCalculation = {
  amountChange: NoSurchargeValues.AmountChange,
  percentChange: NoSurchargeValues.PercentChange,
  roundTo: NoSurchargeValues.RoundTo,
};

export const noPricingSurcharge: PricingSurcharge = {
  key: PricingSurchargeCalculationType.None,
  categories: [PricingSurchargeCategory.BasePrice],
  label: '',
  isTaxable: true,
  affectsDeposit: true,
  calculation: noSurchargeCalculation,
  rules: [],
};

export const newSurchargeRule = (id: number, conditions: PricingSurchargeCondition[]): PricingSurchargeRule => ({
  id,
  conditions,
  calculation: defaultSurchargeCalculation,
});

export const defaultPricingSurchargeErrors: PricingSurchargeError[] = [];

export const clientSupplierDelimiter = ':';

export const maxRoundTo = 100;

export const pricingText: any = {
  revertConfirmationTitle: I18nKeys.PricingTextRevertConfirmationTitle,
  revertConfirmationBody: I18nKeys.PricingTextRevertConfirmationBody,
  publishConfirmationTitle: I18nKeys.PricingTextPublishConfirmationTitle,
  publishConfirmationBody: I18nKeys.PricingTextPublishConfirmationBody,
  previewInstructions: I18nKeys.PricingTextPreviewInstructions,
  publishInstructions: I18nKeys.PricingTextPublishInstructions,
};
export const environmentToken = '$env';

export enum SurchargeCalculationProperty {
  AmountChange = 'amountChange',
  PercentChange = 'percentChange',
  RoundTo = 'roundTo',
}

export enum SurchargeRuleProperty {
  Conditions = 'conditions',
  Calculation = 'calculation',
}

export enum SurchargeUpdateProperty {
  Type = 'key',
  SupplierKey = 'supplierKey',
  SupplierSurchargeOption = 'supplierSurcharge',
  Categories = 'categories',
  IsSubtotal = 'isSubtotal',
  Calculation = 'calculation',
  Rules = 'rules',
  Label = 'label',
  IsTaxable = 'isTaxable',
  AffectsDeposit = 'affectsDeposit',
}
