import React from 'react';
import { Grid2 as Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import pricingSheetIcon from '../images/pricingSheetIcon.svg';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: `${theme.spacing(5)}`,
  },
  title: {
    paddingTop: `${theme.spacing(3)}`,
    fontWeight: 700,
    fontSize: '20px',
    maxWidth: '50%',
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: `${theme.spacing(3)}`,
    fontWeight: 400,
    maxWidth: '50%',
    fontSize: '16px',
    textAlign: 'center',
  },
}));

export const PricingSheetEmptyLayout: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.content}>
      <img alt="Empty price sheet" src={pricingSheetIcon} />
      <Typography variant="h5" className={classes.title}>
        {t(I18nKeys.PricingBaseEmptyTitle)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {t(I18nKeys.PricingBaseEmptySubtitle)}
      </Typography>
    </Grid>
  );
};
