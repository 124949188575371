import React from 'react';
import { Tooltip } from '@mui/material';

export const dateCellRenderer = ({
  value,
  data,
  tooltipField,
}: {
  value: string;
  data: any | undefined;
  tooltipField?: string;
}) => {
  const date = new Date(value);
  // Format date as MM/DD/YYYY HH:mm:ss
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);

  if (tooltipField) {
    return (
      <Tooltip title={data[tooltipField]}>
        <div>{formattedDate}</div>
      </Tooltip>
    );
  }
  return <div>{formattedDate}</div>;
};
