import { DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { formValueSelector, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { Forms } from '../constants/Forms';
import { ClientDataPreviewForm } from './ClientDataPreviewForm';
import { ClientDataPreviewFields } from '../constants/FormFields';
import { getConfiguratorPreviewUrl } from '../utils/clientDataUtils';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { useSaveBranchMetadataMutation } from '../services/clientDataApi';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import { Dialogs } from '../constants/Dialogs';
import { unknownGroup } from '../constants/Group';
import { ClientDataBranchMetadata } from '../types/ClientData';

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: { padding: '0px 8px 8px 8px' },
  contentText: {
    marginTop: `${theme.spacing(2)}`,
  },
}));

interface StateProps {
  dialogKey: Dialogs;
  dialogTitle: string | undefined;
  dialogMessage: string;
  dialogConfirmationButton: string;
  branch?: ClientDataBranchMetadata;
  dataType: ClientDataType;
  clientBranch?: ClientDataBranch;
  structureBranch?: ClientDataBranch;
  systemBranch?: ClientDataBranch;
}

export const PreviewDialog: React.FC<StateProps> = ({
  dialogKey,
  dialogTitle,
  dialogMessage,
  dialogConfirmationButton,
  branch,
  clientBranch,
  structureBranch,
  systemBranch,
  dataType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [saveBranchMetadata] = useSaveBranchMetadataMutation();

  const [configuratorUrl, setConfiguratorUrl] = useState('');
  const currentBranchDescription = branch?.description;

  const { clientId } = useAppSelector((state: AppState) => state?.clientData);

  const { vendorData: { locale = '', productionURL = '' } = {}, isLoadingVendorData } = useClientDataRepo({
    useVendorData: true,
  });

  const formDataDescription = useAppSelector((state) =>
    formValueSelector(Forms.ClientDataPreview)(state, ClientDataPreviewFields.Description),
  );

  useEffect(() => {
    if (clientId && !isLoadingVendorData) {
      const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
      const url = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
      if (url !== configuratorUrl) setConfiguratorUrl(url);
    }
  }, [clientId, configuratorUrl, isLoadingVendorData]);

  const saveBranchDescription = () => {
    if (branch && formDataDescription !== currentBranchDescription) {
      saveBranchMetadata({
        branch: branch.branchType,
        dataType,
        clientId,
        description: formDataDescription,
        groupId,
      });
    }
  };

  return (
    <Dialog dialogKey={dialogKey} maxWidth="sm" onClosed={saveBranchDescription}>
      {dialogTitle && <DialogTitle id="preview-dialog-title">{dialogTitle}</DialogTitle>}
      <DialogContent>
        <ClientDataPreviewForm
          initialValues={{
            [ClientDataPreviewFields.Description]: currentBranchDescription,
          }}
          onSubmit={async () => {
            const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientBranch, structureBranch, systemBranch);
            window.open(previewUrl, '_blank', 'noopener noreferrer');
            dispatch(closeDialogFunc());
            saveBranchDescription();
          }}
        />
        <DialogContentText
          id="preview-dialog-description"
          className={classes.contentText}
          dangerouslySetInnerHTML={{
            __html: dialogMessage,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={(): void => {
            saveBranchDescription();
            dispatch(closeDialogFunc());
          }}
          color="primary"
          id={`${dialogKey}-preview-dialog-cancel-button`}
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(submit(Forms.ClientDataPreview));
          }}
          disabled={!configuratorUrl}
          color="primary"
          id={`${dialogKey}-preview-dialog-preview-button`}
        >
          {dialogConfirmationButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
