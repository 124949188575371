import { Column, ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { AppState } from '../types/AppState';
import { useAppSelector } from '../hooks';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { SearchType } from '../constants/ClientData';
import { withinCurrentSelection, splitByMatches } from '../utils/searchUtils';
import { SearchResultPart } from '../types/ClientData';

export const ClientDataSearchCell: React.FC<ICellRendererParams> = ({
  data,
  column,
  value,
  valueFormatted = '',
  context = {},
}: ICellRendererParams) => {
  const rowId = data[ClientDataFixedColumns.RowId];
  const columnId = (column as Column).getColId();

  const {
    search: { value: searchValue = '', type: searchType = SearchType.AllTables, options: searchOptions = {} } = {},
  } = useAppSelector((state: AppState) => state?.clientData);

  const { highlightSearchResults = false, rootGridApi } = context;
  const outsideSelection =
    searchType === SearchType.CurrentSelection && !withinCurrentSelection(rowId, columnId, rootGridApi);

  const parts: { text: string; highlight: boolean; key: string }[] = (
    (highlightSearchResults && searchValue && !outsideSelection
      ? splitByMatches(valueFormatted || value, searchValue, searchOptions)
      : [
          {
            text: valueFormatted || value,
            highlight: false,
          },
        ]) as SearchResultPart[]
  ).map((part, i) => ({ ...part, key: `${rowId}-${columnId}-${i}` }));

  return (
    <>
      {parts.map((part) => (
        <span
          key={part.key}
          style={part.highlight && highlightSearchResults ? { backgroundColor: '#FDFF80', padding: '3px 1px' } : {}}
        >
          {part.text}
        </span>
      ))}
    </>
  );
};
