/* eslint-disable react-hooks/rules-of-hooks */

import { OnlyExistingKeys } from '../types/Repo';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { useGetPaymentsQuery } from '../services/leadApi';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { Order } from '../types/Order';

const EMPTY_PAYMENTS_REFERENCE: Readonly<Order[]> = [];

type UsePaymentRepoReturn<UsePaymentsType> = OnlyExistingKeys<{
  payments: UsePaymentsType extends true ? Order[] : never;
  isLoadingPayments: UsePaymentsType extends true ? boolean : never;
}>;

export const usePaymentRepo = <UsePaymentsType extends boolean = false>({
  usePayments,
}: {
  usePayments?: UsePaymentsType;
} = {}): UsePaymentRepoReturn<UsePaymentsType> => {
  const {
    currentUser: { group: { groupId = '' } = unknownGroup },
  } = useAppSelector((state: AppState) => state);

  const result: any = {};
  if (usePayments) {
    const {
      currentUser: { group: { configurators: groupConfigurators = [] } = unknownGroup },
      systemGroupOrderConfigurators: { systemGroupOrderConfigurators = [] },
    } = useAppSelector((state: AppState) => state);

    const configurators = (
      isIdeaRoomGroup(groupId)
        ? systemGroupOrderConfigurators
        : groupConfigurators.map((c) => ({ configurator: c.key, vendor: c.vendor }))
    )
      .map((c) => `${c.configurator}-${c.vendor}`)
      .join(',');
    const paymentRequest = {
      options: {
        queryStringParameters: {
          configurators,
        },
      },
    };

    const skip = !configurators.length;
    const { data: payments = EMPTY_PAYMENTS_REFERENCE, isFetching: isLoadingLeads } = useGetPaymentsQuery(
      { paymentRequest },
      {
        skip,
        refetchOnMountOrArgChange: true,
      },
    );
    result.payments = payments;
    result.isLoadingPayments = isLoadingLeads;
  }

  return result;
};
